import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { SprintEndReport} from './sprint-end-report';

@Injectable()
export class SprintEndReportService extends BaseHttpService
{
    sprintEndReportUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.sprintEndReportUrl = this.config.baseUrl + '/rest/sprint-end-report';
    }

    public retrieve(sprintEndReportId : string) : Observable<SprintEndReport>
    {
        let url = this.sprintEndReportUrl + "/" + sprintEndReportId;
        return super.baseRetrieveObject(SprintEndReport, url);        
    }

    public retrieveBySprintId(sprintId : string) : Observable<SprintEndReport>
    {
        let url = `${this.sprintEndReportUrl}/sprint/${sprintId}`;
        return super.baseRetrieveObject(SprintEndReport, url);
    }
}