<div *ngIf="project" class="grid card card-w-title">
    <div *ngIf="sprintList && sprintList.length <= 0">
        <h3>No active sprints for project {{project.description}}</h3>
    </div>

    <div *ngFor="let sprint of sprintList" class="grid col-12" (click)="onClick(sprint)">
        <sprint-heading [sprint]="sprint" [showButton]="true"></sprint-heading>     
        <sprint-points-carried-over class="col-12 sm:col-12 md:col-3" [sprint]="sprint"></sprint-points-carried-over>
        <sprint-scope class="col-12 sm:col-12 md:col-3" [sprint]="sprint"></sprint-scope>
        <sprint-commitment class="col-12 sm:col-12 md:col-3" [sprint]="sprint"></sprint-commitment>

        <sprint-points-injected class="col-12 sm:col-12 md:col-3" [sprint]="sprint"></sprint-points-injected>
        <p-message class="col-12 hover-class" *ngIf="sprintDurationChanged(sprint) || sprintStartDayChanged(sprint)" severity="error" [text]="sprintCadenceChangedMessage(sprint)" [closable]="false" (click)="onSprintCadenceChangedClicked()"></p-message>
        <br/>
    </div>


    <h3 class="col-12">Future Sprints</h3>
    <sprint-list *ngIf="futureSprints" class="col-12 sm:col-12 md:col-12" [sprintList]="futureSprints" [lazy]="true" 
        [rows]="5" [total]="futureSprints.total"
        (onLazyLoad)="onLazyLoadFuture($event)" [paginator]="true">
    </sprint-list>

    <h3 class="col-12">Completed Sprints</h3>
    <sprint-list *ngIf="completedSprints" class="col-12 sm:col-12 md:col-12" [sprintList]=completedSprints [lazy]="true" 
        [rows]="5" [total] = "completedSprints.total"
        (onLazyLoad)="onLazyLoadCompleted($event)" [paginator]="true">
    </sprint-list>
</div>