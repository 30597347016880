import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { ReleaseService } from './release.service';
import { Release } from './release';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Component({
  selector: 'release-detail-summary',
  templateUrl: './release-detail-summary.component.html',
  styleUrls: ['./release-detail-summary.component.css' ],
  providers: [ ReleaseService ]
})

export class ReleaseDetailSummaryComponent extends BaseComponent implements OnInit 
{ 
    projectId : string;
    activeReleaseList : Release[];
 
    inactiveReleaseList : ValueObjectList<Release>;
    inactiveSortOrder : number = 1;
    inactiveSortField : string = "name";

    futureReleaseList : ValueObjectList<Release>;
    futureSortOrder : number = 1;
    futureSortField : string = "name";

    completedReleaseList : ValueObjectList<Release>;
    completedSortOrder : number = 1;
    completedSortField : string = "name";

    maxRows = 5;

    constructor( private releaseService : ReleaseService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        this.inactiveReleaseList = new ValueObjectList<Release>();
        this.futureReleaseList = new ValueObjectList<Release>();
        this.completedReleaseList = new ValueObjectList<Release>();
    }

    public ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        this.releaseService.retrieveActive(this.projectId)
               .subscribe(result => this.processActiveReleases(result),
                          error => this.handleError404Okay(error) );

    }

    public processActiveReleases(result : Release[]) : void
    {
        this.activeReleaseList = result;
    }

    public processCompletedReleases(result : ValueObjectList<Release>) : void
    {
        this.completedReleaseList = result;
    }

    public processFutureReleases(result : ValueObjectList<Release>) : void
    {
        this.futureReleaseList = result;
    }

    public processInactiveReleases(result : ValueObjectList<Release>) : void
    {
        this.inactiveReleaseList = result;
    }
    
    private onClick(release : Release) : void
    {
        this.router.navigate(['/dashboard/release-detail', release.releaseId]);
    }

    public onLazyLoadFuture(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.releaseService.retrieveFuture(this.projectId, startAt, maxResult, this.futureSortField, this.futureSortOrder)
            .subscribe(result => this.processFutureReleases(result),
                       error => this.handleError404Okay(error) );
 
    }

    public onSortClickedFuture(event:any)
    {
        this.futureSortOrder = event.order;
        this.futureSortField = event.field;

        // The timeout makes up for some weirdo eventing with data table sorting - 
        // revisit after upgrading primeng / angular.
        setTimeout( () => 
            this.releaseService.retrieveFuture(this.projectId, 0, this.maxRows, this.futureSortField, this.futureSortOrder)
                .subscribe(result => this.processFutureReleases(result),
                           error => this.handleError404Okay(error) ), 500 );
    }

    public onLazyLoadCompleted(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.releaseService.retrieveCompleted(this.projectId, startAt, maxResult, this.completedSortField, this.completedSortOrder)
            .subscribe(result => this.processCompletedReleases(result),
                       error => this.handleError404Okay(error) );
    }

    public onSortClickedCompleted(event:any)
    {
        this.completedSortOrder = event.order;
        this.completedSortField = event.field;

        // The timeout makes up for some weirdo eventing with data table sorting - 
        // revisit after upgrading primeng / angular.
        setTimeout( () => 
            this.releaseService.retrieveCompleted(this.projectId, 0, this.maxRows, this.completedSortField, this.completedSortOrder)
                .subscribe(result => this.processCompletedReleases(result),
                       error => this.handleError404Okay(error) ), 500 );
    }

    public onLazyLoadInactive(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.releaseService.retrieveInactive(this.projectId, startAt, maxResult, this.inactiveSortField, this.inactiveSortOrder)
            .subscribe(result => this.processInactiveReleases(result),
                       error => this.handleError404Okay(error) );
    }
    
    public onSortClickedInactive(event:any)
    {
        this.inactiveSortOrder = event.order;
        this.inactiveSortField = event.field;

        // The timeout makes up for some weirdo eventing with data table sorting - 
        // revisit after upgrading primeng / angular.
        setTimeout( () => 
            this.releaseService.retrieveInactive(this.projectId, 0, this.maxRows, this.inactiveSortField, this.inactiveSortOrder)
                .subscribe(result => this.processInactiveReleases(result),
                       error => this.handleError404Okay(error) ), 500 );
    }

}
