import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { UserAccount } from '../user-account/user-account';
import { Config } from '../config/config';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class UserAccountService extends BaseHttpService
{
    userAccountUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.userAccountUrl = config.baseUrl + "/rest/useraccount";
    }

    public retrieve() : Observable<UserAccount>
    {
        return super.baseRetrieveObject(UserAccount, this.userAccountUrl);
    }

    public retrieveByUserId(userId : string) : Observable<UserAccount>
    {
        let url : string = this.userAccountUrl + "/" + userId;
        return super.baseRetrieveObject(UserAccount, url);
    }

    public retrieveAll(startAt : number, maxResult : number) : Observable<ValueObjectList<UserAccount>>
    {
        let url : string = this.userAccountUrl + "/subset";
        return super.baseRetrieveSubset(UserAccount, url, startAt, maxResult);
    }

    public create(userAccount : UserAccount) : Observable<UserAccount>
    {
        return super.baseCreateObject(UserAccount, this.userAccountUrl, userAccount);    
    }

    public update(userAccount : UserAccount) : Observable<UserAccount>
    {
        return super.baseUpdateObject(UserAccount, this.userAccountUrl, userAccount);    
    }

    public delete(userAccount : UserAccount) : Observable<String>
    {
        let url : string = this.userAccountUrl + "/" + userAccount.userId;
        return super.baseDeleteObject(url);
    }

    public resetPassword() : Observable<any>
    {
        let url : string = this.userAccountUrl + '/reset-password'
        return super.baseUpdate(url, null, this.handleResetPassword, super.baseHandleError);
    }

    public resetPasswordByUser(userAccount : UserAccount) : Observable<any>
    {
        let url : string = this.userAccountUrl + '/reset-password/' + userAccount.userId;
        return super.baseUpdate(url, null, this.handleResetPassword, super.baseHandleError);
    } 
    
    protected handleResetPassword(result : any) : any
    {
        return result;
    }
}