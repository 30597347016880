import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { BaseOrgTimeSeriesComponent } from '../../base/component/base-org-time-series-component.component';

import { Project } from '../../project/project';

import { SprintFact } from '../../sprint-fact/sprint-fact';
import { LLUtils } from '../../utilities/ll-utils';

import * as _ from 'lodash';

@Component({
  selector: 'org-cost-per-point-timeline',
  templateUrl: './org-cost-per-point-timeline.component.html',
  styleUrls: ['./org-cost-per-point-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})

export class OrgCostPerPointTimelineComponent extends BaseOrgTimeSeriesComponent implements OnInit, OnChanges 
{ 
    constructor( protected router : Router,
                 protected timeSeriesService : TimeSeriesService) 
    { 
        super(router, timeSeriesService);
        this.options.onClick = (event, array) => { this.graphClickEvent(event, array) }
    }

    public ngOnInit() : void
    {
        super.ngOnInit();
        this.options['plugins']['title'].text = `Cost Per Point Trend By Project`;
    }

    public retrieveTimeSeriesByProject(project : Project) : void
    {
        this.timeSeriesService.retrieve(project, project.projectId, TimeSeries.PROJECTCOSTPERPOINT, 10)
            .subscribe(result => this.processTimeSeries(project.projectId, result),
                        error => this.handleError404Okay(error) );
    }

    protected processTimeSeries(projectId : string, result: TimeSeries)
    {
        if (_.isEqual(this.timeSeriesMap.get(projectId), result) == true)
            return;
    
        this.timeSeriesMap.set(projectId, result);
        this.buildGraph();
    }

    protected buildGraph() : void
    {
        // First, build all the labels.
        let labelArray : string[] = [];

        for (let i = 10; i > 0; i-- )
        {
            switch (i)
            {
                case 1:
                    labelArray.push("Current Sprint");
                    break;

                case 2:
                    labelArray.push("Last Sprint");
                    break;
        
                default:
                    labelArray.push(`${i} sprints ago`);
                    break;
            }
        }

        this.data = {
            labels: labelArray
        }

        // Next, plot the projects.
        // Put in logic to check if data changed.
        this.data.datasets = [];

        for (let project of this.projectList)
        {
            let timeSeries = this.timeSeriesMap.get(project.projectId);

            // If we didn't have our project loaded yet, continue.
            if (timeSeries == undefined)
                continue;

            let hexColor : string = LLUtils.uuidToColor(timeSeries.objectId);
            let color = LLUtils.hexToRgb(hexColor, 1.0);
            let transparentColor = LLUtils.hexToRgb(hexColor, .40);

            let paddedValues : number[] = this.padTimeSeries(timeSeries.timeSeriesList.map( function(a) { return LLUtils.round(a.getFieldNumber(SprintFact.COSTPERPOINT), 2); } ), 10);

            this.data.datasets.push({
                    type: 'line',
                    label: this.getProjectName(timeSeries.objectId),
                    backgroundColor: transparentColor,
                    hoverBackgroundColor: color,
                    borderColor: color,
                    borderWidth: 2,
                    fill: false,
                    tension: .5,
                    data: paddedValues
            });
        }
    }

}
