import { BaseSprint } from '../base/value-object/base-sprint';
import { Project } from '../project/project';

import * as moment from 'moment';

export class Sprint extends BaseSprint
{
    initialize(sprint : Sprint) : void
    {
        super.initialize(sprint);
    }

}
