import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { Project } from '../../project/project';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { PluralPipe } from '../../pipes/plural.pipe';

import * as moment from 'moment';
import * as _ from 'lodash';

let globalTimeSeries : TimeSeries = undefined;      // for use in callbacks.

@Component({
  selector: 'point-distribution-timeline',
  templateUrl: './point-distribution-timeline.component.html',
  styleUrls: ['./point-distribution-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})
export class PointDistributionTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    colorWheel : Map<number, string> = new Map<number, string>();

    dataset : any = [];

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router); 
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : User Story Point Distribution By Sprint`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTPOINTDISTRIBUTION, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    }

    processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;  
            globalTimeSeries = result;

            this.buildColorWheel();
            
            this.dataset = this.processTimeSeriesList();
            
            this.data = {
                datasets: this.dataset       
              };

              this.options['tooltips'] = {
                   callbacks: {
                      label: function(t, d) {
                        return d.datasets[t.datasetIndex].label + 
                                ':'+ moment(t.xLabel).format("MMM Do YYYY") + ',Count:' + lookupCount(t.xLabel, t.yLabel) + ')';
                      }
                   }
                }
        }
    }

    protected processTimeSeriesList() : any
    {
        let result = [];

        for (let timeSeriesItem of this.timeSeries.timeSeriesList)
        {
            for (let key of timeSeriesItem.getDataKeys() )
                result.push(this.createDataPoint(timeSeriesItem.sprintId, timeSeriesItem.timestamp, key, timeSeriesItem.getFieldNumber(key) ) );
        }

        return result;
    }

    protected createDataPoint(sprintId : string, timestamp : Date, pointValue : string, pointCount : number) : any
    {
        let backgroundColor : string = this.colorWheel.get(Number.parseFloat(pointValue) );
        let borderColor : string = this.colorWheel.get(Number.parseFloat(pointValue) ).replace("0.2)", "1.0)");

        let plural : PluralPipe = new PluralPipe();

        let result = {
            label: [ plural.transform(Number.parseFloat(pointValue), "point") ],
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            hoverBackgroundColor: borderColor,
            sprintId: sprintId,                     // sprintid is not part of chartjs but is preserved.
            data: [{
                x: timestamp,
                y: Number.parseFloat(pointValue),
                r: this.getRadius(pointCount)
            }]

        };

        return result;
    }

    protected buildColorWheel() : void
    {
        let pointDistribution : Array<number> = new Array<number>();

        // Create a unique list of the points we're retrieved.
        for (let timeSeriesItem of this.timeSeries.timeSeriesList)
        {
            for (let key of timeSeriesItem.getDataKeys() )
            {
                if (pointDistribution.indexOf(Number.parseFloat(key)) == -1)
                    pointDistribution.push(Number.parseFloat(key) );
            }
        }

        pointDistribution = pointDistribution.sort(function(a,b) { return a - b;} );

        // For each unique point, create color.
        for (let i = 0; i < pointDistribution.length; i++)
        {
            let percentage : number = ((i + 1) / pointDistribution.length) * 100;
            let color : string = this.getColor(100 - percentage, .20);

            this.colorWheel.set(pointDistribution[i], color);
        }
    }

    public getColor(value : number, transparency : number ) : string 
    {
        var hue = Math.round(value * 1.2);
 
        return `hsla(${hue},100%,50%, ${transparency})`;
    }  
    
    public getRadius(count : number) : number
    {
        let radius = 1;

        // Predefine radii for up to 20, over 20 always same size.
        let radiusArray = [0,4,6,8,10,12,14,16,18,20,22,24,26,20,22,24,25,26,27,28,29,30]

        if (count > 20)
            radius = 30;
        else
            radius = radiusArray[count];

        return radius;
    }

    // Override navigate in base class.
    public navigate(datasetIndex : number) : void
    {
        let sprintId : string = this.dataset[datasetIndex].sprintId;        
        this.router.navigate(['/dashboard/sprint-detail', sprintId]);            
    }

    // Override click event in base class.
    public graphClickEvent(event : MouseEvent, array: any[]) : void 
    {
        if (array.length > 0)
        {
            let index : number = array[0]._datasetIndex;
            this.navigate(index);            
        }
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
}

// Used by label callback.
function lookupCount(timestamp : Date, searchKey : string) : number
{
    let result : number = undefined;

    for (let timeSeriesItem of globalTimeSeries.timeSeriesList)
    {
        for (let key of timeSeriesItem.getDataKeys() )
        {
            if (timeSeriesItem.timestamp.valueOf() == timestamp.valueOf() && Number.parseFloat(key) == Number.parseFloat(searchKey) )
            {
                result = timeSeriesItem.getFieldNumber(key);
                break;
            }
        }
    }

    return result;
}
