import { Directive} from '@angular/core';
import { Component, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from './base-ui-component.component';
import { TimeSeries } from '../../time-series/time-series';

import * as moment from 'moment';
import * as _ from 'lodash';

@Directive()
export class BaseSprintTimeSeriesComponent extends BaseUIComponent 
{ 
    timeSeries : TimeSeries;
    
    constructor( protected router : Router) 
    { 
        super(router);
        this.options.onClick = (event, array) => { this.graphClickEvent(event, array) }
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    public navigate(timeSeriesListIndex : number) : void
    {
        let sprintId : string = this.timeSeries.timeSeriesList[timeSeriesListIndex].sprintId;
        this.router.navigate(['/dashboard/sprint-detail', sprintId]);            
    }

    public graphClickEvent(event : MouseEvent, array: any[]) : void 
    {
        if (array.length > 0)
        {
            let index : number = array[0].index;
            this.navigate(index);            
        }
    }
    
}
