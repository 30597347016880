import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { UserStoryFact } from '../user-story-fact/user-story-fact';
import { UserStory } from '../user-story/user-story';

export class BacklogItem extends BaseBacklogItemObject
{
    public objectId : string;

    // Used by the UI to determine if we should show facts.
    public showFacts : boolean = false;

    // Used by the UI to determine if this is a ghost story.
    public ghostStory : boolean = false;

    public initialize(backlogItem : BacklogItem) : void
    {
        if (backlogItem == null)
            return;
        
        super.initialize(backlogItem);

        this.objectId = backlogItem.objectId;
        this.objectType = backlogItem.objectType;
    }

    public initializeFromUserStory(userStory : UserStory) : void
    {
        if (userStory == null)
            return;
        
        super.initialize(userStory);

        this.objectId = userStory.userStoryId;
        this.objectType = BacklogItem.USERSTORY;
        this.backlogItemFactList = userStory.userStoryFactList;
    }


    public hasCarriedOver() : boolean
    {
        let result : boolean = false;

        if (this.backlogItemFactList != null && this.isUserStory() )
        {
            if (this.getFact(UserStoryFact.SPRINTSWORKEDON) != null)
            {
                let sprintsWorkedOnFact : Array<string> = this.getFact(UserStoryFact.SPRINTSWORKEDON).getFactStringList();

                if (sprintsWorkedOnFact.length > 1)
                    result = true;                
            }
        }

        return result;
    }
}