<p-card class="release-point-burnup">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start align-content-center align-fields-center">
                <span class="title-text">Release Progress&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="scope && pointsCompleted" pTooltip="Percent Complete" tooltipPosition="left">{{ percentComplete() | round }}%</span>
                <span *ngIf="activeReleaseUnsized">
                    <a *ngIf="activeReleaseUnsized.getFactBoolean()" [routerLink]="['/dashboard/content-detail/key', RELEASEESTIMATE]">
                        <i pTooltip="Active Release Unsized" tooltipPosition="left" class="warning material-icons">warning</i>
                    </a>
                </span>
             </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="displayPoints == false">{{ release.name }}</span>
            <span *ngIf="scope && pointsCompleted && displayPoints == true">{{ pointsRemaining() | round }} pts remaining</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>

<zoom-graph #zoomGraph type="bar" [sourceData]="data" [sourceOptions]="options"></zoom-graph>
