import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';

import { Content } from '../../content/content';

import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import * as moment from 'moment';
import * as _ from "lodash"; 

@Component({
  selector: 'sprint-commitment-timeline',
  templateUrl: './sprint-commitment-timeline.component.html',
  styleUrls: ['./sprint-commitment-timeline.component.scss' ],
  encapsulation : ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class SprintCommitmentTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges { 

    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;
    
    averageVelocityFact : ProjectFact;
    velocityTrendFact : ProjectFact;
    velocityUnreliableFact : ProjectFact;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private projectFactService : ProjectFactService) 
    { 
        super(router);
        this.options['scales'] = {
                                    x: { stacked: true, display: false},
                                    y: { stacked: true, beginAtZero:true }
                                }          
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Velocity Achieved vs. Sprint Commitment`;
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTVELOCITY, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    
        this.projectFactService.retrieve(this.project, ProjectFact.AVERAGEVELOCITY)
            .subscribe(result =>this.processAverageVelocityFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.VELOCITYTREND)
            .subscribe(result => this.processVelocityTrendFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.VELOCITYUNRELIABLE)
            .subscribe(result => this.processVelocityUnreliableFact(result),
                       error => this.handleError404Okay(error) );
           
    }

    private processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        stack: 'dataset-1',
                        type:'bar',
                        label: 'Commitment',
                        backgroundColor: this.BLUETRANSPARENT,
                        hoverBackgroundColor: this.BLUE,
                        borderColor: this.BLUE,
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintCommitment"); } )
                    },
                    {
                        stack: 'dataset-2',
                        type:'bar',
                        label: 'Velocity',
                        backgroundColor: this.GREENTRANSPARENT,
                        hoverBackgroundColor: this.GREEN,
                        borderColor: this.GREEN,
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("velocity"); } )
                    },
                    {
                        stack: 'dataset-2',
                        type:'bar',
                        label: 'Leaked Velocity',
                        backgroundColor: this.REDTRANSPARENT,
                        hoverBackgroundColor: this.RED,
                        borderColor: this.RED,
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("leakedVelocity"); } )
                    },
                    {
                        type:'line',
                        label: 'Three Sprint Average',
                        data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("averageVelocity") ); } )
                    }
                ]
            }
        }
    }

    private processAverageVelocityFact(result : ProjectFact) : void
    {
        if (_.isEqual(this.averageVelocityFact, result) == false)
            this.averageVelocityFact = result;
    }

    private processVelocityTrendFact(result : ProjectFact ) : void
    {
        if (_.isEqual(this.velocityTrendFact, result) == false)
            this.velocityTrendFact = result;
    }

    private processVelocityUnreliableFact(result : ProjectFact ) : void
    {
        if (_.isEqual(this.velocityUnreliableFact, result) == false)
            this.velocityUnreliableFact = result;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

    public get VELOCITYUNRELIABLE() : string
    {
        return Content.VELOCITYUNRELIABLE;
    }
}