import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'colorbox',
  templateUrl: './colorbox.component.html',
  styleUrls: ['./colorbox.component.scss' ],
  providers: [ ]
})

export class ColorboxComponent implements OnInit { 

    active: boolean;

    @Input()
    color : string;
    
    @Input()
    icon: string;

    @Input()
    name: string;

    @Input()
    detail: string;

    constructor() 
    { 
        this.active = false;
        this.color = "";
        this.icon = "";
        this.name = "";
        this.detail = "";
    }

    ngOnInit() : void
    {
        this.active = true;
    }

    public colorclass() : string
    {
        let result : string = "colorbox-1";

        if (this.color.toLowerCase() == "green")
            result = "colorbox-1";

        if (this.color.toLowerCase() == "blue")
            result = "colorbox-2";

        if (this.color.toLowerCase() == "purple")
            result = "colorbox-3"

        if (this.color.toLowerCase() == "teal")
            result = "colorbox-4"

        return result;
    }
}
