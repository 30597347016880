<div class="grid col-12 card card-w-title">
    <div *ngIf="project" class="col-12">
        <h3 class="col-12 sm:col-12 md:col-9">Team Cost - {{ project.description }}</h3>
    </div>

    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div *ngIf="projectTeamCostList" class="col-12">
        <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="projectTeamCostId"
            [rowHover]="true" [value]="projectTeamCostList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedProjectTeamCost">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col><!-- Effective Date -->
                        <col><!-- End Date -->
                        <col><!-- Cost Per Month -->
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            Effective Date
                        </th>
                        <th>
                            End Date
                        </th>
                        <th>
                            Cost Per Month
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-projectTeamCost>
                    <tr [pSelectableRow]="rowData">
                        <td>
                            <span>{{ projectTeamCost.effectiveDate | dateFormat }}</span>                        
                        </td>
                        <td>
                            <span *ngIf="projectTeamCost.currentDateEndDate == false">{{ projectTeamCost.endDate | dateFormat }}</span>                        
                            <span *ngIf="projectTeamCost.currentDateEndDate == true">Current Date</span> 
                        </td>
                        <td>
                            <span>{{ projectTeamCost.costPerMonth | currency:'USD':'symbol' }}</span>                        
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="3">
                            No records found
                        </td>
                    </tr>
                </ng-template>
        </p-table>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-3">
            <button pButton type="button" class="col-12" label="Add" (click)="onAdd()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-3">
            <button pButton type="button" class="col-12" label="Update" [disabled]="selectedProjectTeamCost == undefined" (click)="onUpdate()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-3">
            <button pButton type="button" class="col-12" label="Delete" [disabled]="selectedProjectTeamCost == undefined" (click)="onDelete()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-3">
            <button pButton type="button" class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
