import { BaseValueObject } from '../base/value-object/base-value-object';
import { Sprint } from '../sprint/sprint';

export class SprintEndReport extends BaseValueObject
{
    sprintEndReportId : string;
    sprint : Sprint;
	title : string;
	detail : string;

	public initialize(sprintEndReport : SprintEndReport) : void
	{
		super.initialize(sprintEndReport);

        this.sprintEndReportId = sprintEndReport.sprintEndReportId;
        this.title = sprintEndReport.title;
        this.detail = sprintEndReport.detail;

        this.sprint = this.initializeObject(Sprint, sprintEndReport.sprint);
	}
}