import { BaseValueObject } from '../base/value-object/base-value-object';
import { GoalText } from '../admin/goal-text/goal-text';

import * as moment from 'moment';

export class Goal extends BaseValueObject
{
	public goalId : string;
	public projectId : string;
	public sprintId : string;
	public goalText : GoalText;
	public percentComplete : number;
	public dateCompleted : Date;
	public goalEvaluated : Date;

    initialize(goal : Goal) : void
    {
        super.initialize(goal);                

        this.goalId = goal.goalId;
        this.projectId = goal.projectId;
        this.sprintId = goal.sprintId;
        this.percentComplete = goal.percentComplete;
        this.dateCompleted = moment(goal.dateCompleted).toDate();
        this.goalEvaluated = moment(goal.goalEvaluated).toDate();

        this.goalText = this.initializeObject(GoalText, goal.goalText);
    }
}
