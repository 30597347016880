import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { ProjectService } from './project.service';
import { Project } from './project';

import { ProjectFactService } from '../project-fact/project-fact.service';
import { ProjectFact } from '../project-fact/project-fact';

import { ProjectStatusService } from '../project-status/project-status.service';
import { ProjectStatus } from '../project-status/project-status';

import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../org-source-system/org-source-system';

import { SourceSystem } from '../source-system/source-system';
import { Content } from '../content/content';

import { LoggedInUser } from '../authentication/logged-in-user';
import { Preference } from '../preference/preference';

import { Subscription, Observable } from "rxjs";
import { timer } from "rxjs";

import * as _ from "lodash"; 

@Component({
  selector: 'project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss' ],
  providers: [ ProjectService, ProjectFactService, ProjectStatusService, OrgSourceSystemService ]
})

export class ProjectSummaryComponent extends BaseComponent implements OnInit, OnDestroy 
{ 
    @Input()
    project : Project;
    projectId : string;

    @Input()
    card : boolean = true;
    cssClassList : string[];

    @Input()
    details : boolean = true;

    projectStatus : ProjectStatus;
    scrumMaturityFact : ProjectFact;
    topScrumMaturityFact : ProjectFact;
    orgSourceSystem : OrgSourceSystem;
    notFound : boolean = false;

    projectLoading : boolean = undefined;

    private refreshSubscription: Subscription;
    private refreshTimer : Observable<number>;

    constructor( private projectService : ProjectService,  
                 private projectStatusService : ProjectStatusService, 
                 private projectFactService : ProjectFactService,       
                 private orgSourceSystemService : OrgSourceSystemService,        
                 private route: ActivatedRoute,
                 protected router: Router) 

    { 
        super(router);

        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        // Subscribe to changes just in the parameters passed in.
        // Ensures form will refresh.
        this.route.params.subscribe(params => {
            this.project = undefined;
            this.projectId = params['projectId'];
        });
    }

    ngOnInit() : void
    {
        this.notFound = false;
        this.applyStyle();
        this.setupRefresh();
    }

    ngOnDestroy() : void
    {
        this.refreshSubscription.unsubscribe();
    }

    private setupRefresh() : void
    {
        // Make sure these are refreshing.
        this.refreshTimer = timer(0, 30000);
        this.refreshSubscription = this.refreshTimer.subscribe(t => { this.refreshStatus(t) } );
    }

    private refreshStatus(ticks : number) : void
    {
        this.loadData();
    }

    private applyStyle() : void 
    {
        this.cssClassList = [];
        this.cssClassList.push("grid");
        this.cssClassList.push("ll-nogutter-top-bottom");

        if (this.card)
        {
            this.cssClassList.push("card");
            this.cssClassList.push("card-w-title");
        }
    }

    loadData() : void
    {
        // Build component via input.
        if (this.project != undefined)
            this.projectId = this.project.projectId;

        this.projectService.retrieve(this.projectId)
            .subscribe(result => this.processProject(result),
                       error => this.handleError(error) );

        this.orgSourceSystemService.retrieveByProjectId(this.projectId)
            .subscribe(result => this.processOrgSourceSystem(result),
                       error => this.handleError(error) );

        this.projectFactService.retrieveById(this.projectId, ProjectFact.SCRUMMATURITY)
            .subscribe(result=> this.processScrumMaturityFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieveById(this.projectId, ProjectFact.TOPSCRUMATURITY)
            .subscribe(result=> this.processTopScrumMaturityFact(result),
                        error => this.handleError404Okay(error) );
           
        this.projectStatusService.retrieve(this.projectId)
            .subscribe(result => this.processProjectStatus(result),
                       error => this.handleError404Okay(error) );
            
    }

    private processProject(result:Project) : void
    {
        this.project = result;
    }

    private processOrgSourceSystem(result : OrgSourceSystem) : void
    {
        this.orgSourceSystem = result;
    }

    private processScrumMaturityFact(result : ProjectFact) : void 
    {
        this.scrumMaturityFact = result;
    }

    private processTopScrumMaturityFact(result : ProjectFact) : void 
    {
        this.topScrumMaturityFact = result;
    }

    public scrumMaturityRatingLabel() : string
    {
        let ratingLabel : string = "0.00";
        
        if (this.scrumMaturityFact.getFactNumber() != undefined)
            ratingLabel = this.scrumMaturityFact.getFactNumber().toFixed(2);
        
        return ratingLabel;
    }

    public topScrumMaturityRatingLabel() : string
    {
        let ratingLabel : string = "0.00" 
        
        if (this.topScrumMaturityFact.getFactNumber() != undefined)
            ratingLabel = this.topScrumMaturityFact.getFactNumber().toFixed(2);

        return ratingLabel;
    }

    private processProjectStatus(result : ProjectStatus)
    {
        this.projectStatus = result;

        if (this.projectStatus != undefined)
        {
            this.notFound = false;
            this.projectLoading = this.projectStatus.initialLoad;
        }
    }

    private onSprintHistoryClick() : void
    {
        this.router.navigate(['/dashboard/sprint-detail-summary', this.project.projectId]);
    }

    protected onDetailClick() : void
    {
        this.router.navigate(['/dashboard/project-detail', this.project.projectId]);
    }

    public get ratingLabel() : string {
        let result : string = "0.0";

        result = this.scrumMaturityFact.getFactNumber().toLocaleString("us-EN", {style : 'decimal', minimumIntegerDigits: 1, minimumFractionDigits : 2 });
        return result;
    }

    public onScrumMaturityClick() : void 
    {
        this.router.navigate(['/dashboard/content-detail/key', Content.TEAMSCRUMMATURITY], { queryParams: { projectId: this.projectId } });
    }

    public onTopScrumMaturityClick() : void 
    {
        this.router.navigate(['/dashboard/content-detail/key', Content.SCRUMMATURITY], { queryParams: { projectId: this.projectId } });
    }

    public getIsAdmin() : boolean
    {
        let result : boolean = false;

        if (LoggedInUser.getLoggedInUser() != undefined)
            result = LoggedInUser.getLoggedInUser().isAdmin();
            
        return result;
    }

    protected get AGILECENTRAL() : string 
    {
        return SourceSystem.AGILECENTRAL;
    }

    protected get JIRA_AGILE() : string 
    {
        return SourceSystem.JIRA_AGILE;
    }

    protected get JIRA_API() : string 
    {
        return SourceSystem.JIRA_API;
    }
    
    protected get TFS() : string
    {
        return SourceSystem.TFS;
    }

    protected get JIRAAGILE() : string 
    {
        return Content.JIRAAGILE;
    }

    protected get CAAGILECENTRAL() : string
    {
        return Content.CAAGILECENTRAL;
    }

    protected get MICROSOFTTFS() : string
    {
        return Content.MICROSOFTTFS;
    }

    // Override superclass.
    protected handleError404Okay(error : Response | any) : void
    {
        if (error.status)
        {
            switch (error.status)
            {
                case 404:
                    this.notFound = true;
                    break;

                default:
                    super.handleError404Okay(error);
                    break;
            }
        }
        else
        {
            this.addErrorMessage("Error occurred.  Please try again");
        }
   }

   public displayMaturityRating() : boolean
   {
       let result : boolean = false;

        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            if (LoggedInUser.getLoggedInUser().hasPreference(Preference.DISPLAYMATURITYRATING) )
                result = true;
            else
                result = false;
        }

       return result;
   }

}
