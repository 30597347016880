<div class="pages-body flex flex-column">
    <div class="align-self-center mt-auto mb-auto">
        <div class="input-panel flex flex-column card reset-password-panel">

        <div class="col-12">
            <img class="logo" src="../../public/images/lucidlift-blue-alpha.png">
        </div>
        
        <p-messages class="loginError" [value]="errorMessages"></p-messages>

        <div class="col-12">&nbsp;<!-- filler --></div>

        <div class="grid" (keyup.enter)="onSubmit()">
            <div class="col-12">
                <span class="p-float-label">
                    <input type="password" class="col-12" name="password" #passwordTag pPassword autocomplete="off" [(ngModel)]="password">
                    <label>Password</label>
                </span>
            </div>

            <div class="col-12"><!-- filler --></div>

            <div class="col-12">
                <span class="p-float-label">
                    <input type="password" class="col-12" name="confirmPassword" #confirmPasswordTag pPassword autocomplete="off" [(ngModel)]="confirmPassword">
                    <label>Confirm Password</label>
                </span>
            </div>

            <div class="col-12">&nbsp;</div>

            <div class="col-12 grid">
                <div *ngIf="!resetSuccessful" class="col-12">
                    <div class="col-12">
                        <button pButton id="updatePasswordButton" class="col-12" label="Update Password" icon="fa-lg fa-user-circle" [disabled]="passwordReset == undefined" (click)="onSubmit()"></button>
                    </div>

                    <div class="col-12">
                        <button pButton id="cancelButton" class="col-12 secondary" label="Cancel"  (click)="onCancel()"></button>
                    </div>
                </div>
                <div *ngIf="resetSuccessful" class="col-12">
                    <div class="col-12">
                        <button pButton id="continueButton" class="col-12" label="Continue" icon="fa-lg fa-user-circle" (click)="onContinue()"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
