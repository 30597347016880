import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { OrgConfig } from './org-config';

@Injectable()
export class OrgConfigService extends BaseHttpService
{
    orgConfigUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.orgConfigUrl = this.config.baseUrl + '/rest/org-config';
    }

    public retrieve(pOrgConfigId : string) : Observable<OrgConfig>
    {
        let url = this.orgConfigUrl + `/${pOrgConfigId}`;
        return super.baseRetrieveObject(OrgConfig, url);
    }

    public retrieveByKey(pOrgId : string, pConfigKey) : Observable<OrgConfig>
    {
        let url = this.orgConfigUrl + `/orgId/${pOrgId}/configKey/${pConfigKey}`;
        return super.baseRetrieveObject(OrgConfig, url);
    }

    public create(orgConfig: OrgConfig) : Observable<OrgConfig>
    {
        let url = this.orgConfigUrl;
        return super.baseCreateObject(OrgConfig, url, orgConfig);   
    }

    public upsert(orgConfig: OrgConfig) : Observable<OrgConfig>
    {
        let url = this.orgConfigUrl + "/upsert";
        return super.baseCreateObject(OrgConfig, url, orgConfig);   
    }

    public update(orgConfig: OrgConfig) : Observable<OrgConfig>
    {
        let url = this.orgConfigUrl;
        return super.baseUpdateObject(OrgConfig, url, orgConfig);   
    }

    public delete(orgConfig: OrgConfig) : Observable<any>
    {
        let url = this.orgConfigUrl + `/${orgConfig.orgConfigId}`;
        return super.baseDeleteObject(url);
    }
}
