import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesItem } from '../../time-series/time-series-item';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { Project } from '../../project/project';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'mini-app-alert-count-overview',
  templateUrl: './mini-app-alert-count-overview.component.html',
  styleUrls: ['./mini-app-alert-count-overview.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})

export class MiniAppAlertCountOverviewComponent extends BaseUIComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    timeSeries : TimeSeries;
    appAlertCountNumber : number;    
    appAlertCountTrend: string;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Alert Trends`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTALERTCOUNT, 20)
            .subscribe(result =>this.processAppAlertCount(result),
                       error => this.handleError404Okay(error) );
    }

    private processAppAlertCount(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {

            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'line',
                        label: 'App Alerts',
                        backgroundColor: this.YELLOWTRANSPARENT,
                        pointHoverBackgroundColor: this.YELLOW,
                        borderColor: this.YELLOW,
                        borderWidth: 2,
                        fill:true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("total"); } )
                    }
                ]
            }

            this.setAppAlertCountNumber();
            this.setAppAlertTrend();
        }
    }

    protected setAppAlertCountNumber() : void
    {
        let timeSeriesList : TimeSeriesItem[] = this.timeSeries.timeSeriesList;

        if (timeSeriesList.length > 0)
            this.appAlertCountNumber = timeSeriesList[timeSeriesList.length-1].getFieldNumber("total");
        else
            this.appAlertCountNumber = 0;
    }

    protected setAppAlertTrend() : void
    {
        let timeSeriesList : TimeSeriesItem[] = this.timeSeries.timeSeriesList;

        let twoSprintsAgo : number = 0;
        let oneSprintAgo : number = 0;
        let lastSprint : number = 0;

        this.appAlertCountTrend = "neutral";

        if (timeSeriesList.length >= 3)
        {
            let length = timeSeriesList.length;

            twoSprintsAgo = timeSeriesList[length-3].getFieldNumber("total");
            oneSprintAgo = timeSeriesList[length-2].getFieldNumber("total")
            lastSprint = timeSeriesList[length-1].getFieldNumber("total");

            if (twoSprintsAgo < oneSprintAgo && oneSprintAgo < lastSprint)
                this.appAlertCountTrend = "up";

            if (twoSprintsAgo > oneSprintAgo && oneSprintAgo > lastSprint)
                this.appAlertCountTrend = "down";
        }
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

}
