import { Component, OnInit, Input  } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';
import { BacklogItem } from './backlog-item';
import { BacklogItemService } from './backlog-item.service';

import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';

import { Message } from 'primeng/api';

@Component({
  selector: 'backlog-item-deleted-message',
  templateUrl: './backlog-item-deleted-message.component.html',
  styleUrls: ['./backlog-item-deleted-message.component.scss' ],
  providers: [ BacklogItemService ]
})

export class BacklogItemDeletedMessageComponent extends BaseComponent implements OnInit 
{ 
    relatedBacklogItem : BacklogItem;

    @Input()
    baseBacklogItemObject : BaseBacklogItemObject;

    constructor(protected router : Router,
                private backlogItemService : BacklogItemService) 
    { 
        super(router);
    }

    ngOnInit() : void
    {
        if (this.baseBacklogItemObject != undefined)
        {
            if (this.baseBacklogItemObject.deleted == true)
            {
                this.backlogItemService.retrieveBySourceSystemKey(this.baseBacklogItemObject.project.projectId, 
                    this.baseBacklogItemObject.orgSourceSystemId, this.baseBacklogItemObject.sourceSystemKey)
                        .subscribe(result=>this.processRelatedBacklogItem(result),
                                   error => this.handleError404Okay(error) );
            }
        }
    }

    protected processRelatedBacklogItem(result : BacklogItem)
    {
        this.relatedBacklogItem = result;
    }

    protected relatedMessage() : Message[] 
    {
        let message : string = 'This backlog item has been deleted.  Click on this message to see latest version.';
        let messages: Message[] = [];
        
        messages.push({ severity:'info', summary:'', detail: message });

        return messages;
    }

    protected onRelatedMessageClicked() : void 
    {
        let url : string = undefined;

        if (this.relatedBacklogItem.objectType == BacklogItem.USERSTORY)
            url = '/dashboard/user-story-detail';

        if (this.relatedBacklogItem.objectType == BacklogItem.DEFECT)
            url = '/dashboard/defect-detail';

        if (this.relatedBacklogItem.objectType == BacklogItem.CHORE)
            url = '/dashboard/chore-detail';

        if (this.relatedBacklogItem.objectType == BacklogItem.SPIKE)
            url = '/dashboard/spike-detail';
            
        this.router.navigate([url, this.relatedBacklogItem.objectId]);        
    }

    protected deletedMessage() : Message[] 
    {
        let message : string = 'This backlog item has been deleted.';
        let messages: Message[] = [];
        
        messages.push({ severity:'info', summary:'', detail: message });

        return messages;
    }

    public isDeleted() : boolean 
    {
        let result : boolean = false;

        if (this.baseBacklogItemObject != undefined)
            result = this.baseBacklogItemObject.deleted;
            
        return result
    }

}
