import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';

import { LoginService } from './login.service';
import { BaseComponent } from '../base/component/base-component.component';

import { LoggedInUser } from './logged-in-user';

@Component({
  template: ``
})

export class LogoutComponent extends BaseComponent implements OnInit, OnDestroy
{
  returnUrl : string;

  constructor(private loginService:LoginService,
              protected router:Router,
              private route: ActivatedRoute,
              private zone: NgZone) 
  { 
    super(router);
  }

  public ngOnInit() : void
  {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.loginService.logout();
    LoggedInUser.setLoggedInUser(undefined);
    this.router.navigate(['login'], { queryParams: { returnUrl: this.returnUrl }});
  }

  public ngOnDestroy() : void
  {
      // this.zone.runOutsideAngular(() => { location.reload(); });
  }
}
