<div class="card overview epic-defect-potential" (click)="onclick">
    <div class="overview-content clearfix">
        <span class="overview-title">Open Defects</span>
        <span *ngIf="openDefectTrend" class="overview-badge">
            <i *ngIf="openDefectTrend == 'up'" class="material-icons" tooltipPosition="left" pTooltip="Trending Up">trending_up</i>
            <i *ngIf="openDefectTrend == 'down'" class="material-icons" tooltipPosition="left" pTooltip="Trending Down">trending_down</i>
            <i *ngIf="openDefectTrend == 'neutral'" class="material-icons" tooltipPosition="left" pTooltip="Trending Flat">trending_flat</i>
        </span>
        <span *ngIf="openDefectCountFact" class="overview-detail">{{openDefectCountFact.getFactNumber()}} total</span>
    </div>
    <div class="overview-footer">
        <div *ngIf="defectCountList">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </div>
</div>
