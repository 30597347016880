<div class="grid card card-w-title">
    <p-blockUI [blocked]="blockedDocument"></p-blockUI>

    <h3>Add Source System </h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="sourceSystem">Source System:</label>
            <p-dropdown (onChange)="onSourceSystemChanged($event)" class="col-12" [style]="{'width':'300px'}" id="sourceSystem" #sourceSystemTag name="sourceSystem" [options]="sourceSystemItems" [(ngModel)]="selectedSourceSystem"></p-dropdown>
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="sourceAuthType">Authentication:</label>
            <p-dropdown class="col-12" [style]="{'width':'300px'}" id="sourceAuthType" #sourceAuthTypeTag name="sourceAuthType" [options]="sourceAuthTypeItems" [(ngModel)]="selectedSourceAuthType"></p-dropdown>
        </div>

        <div *ngIf="selectedSourceAuthType == COOKIEAUTH" class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="sourceAuthEndPoint">Auth Endpoint:</label>
            <input class="col-10 input-padding" pInputText size="30" #sourceAuthEndPointTag id="sourceAuthEndPoint" [(ngModel)]="sourceAuthEndPoint" name="sourceAuthEndPoint" />
            <div class="col-2"></div>
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="userId">User Id:</label>
            <input class="col-10 input-padding" pInputText size="30" #userIdTag id="userId" [(ngModel)]="userId"  name="userId" />
            <div class="col-2"></div>
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="password">Password / API Token:</label>
            <input class="col-10 input-padding" pPassword size="30" #passwordTag id="password" type="password"  [(ngModel)]="password" name="password" />
            <div class="col-2"></div>
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="confirmPassword">Confirm Password / API Token:</label>
            <input class="col-10 input-padding" pPassword size="30" #confirmPasswordTag id="confirmPassword" type="password"  [(ngModel)]="confirmPassword" name="confirmPassword" />
            <div class="col-2"></div>
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="apiUrl">API Url:</label>
            <input class="col-10 input-padding" pInputText size="30" #apiUrlTag id="apiUrl" [(ngModel)]="apiUrl" name="apiUrl" />
            <div class="col-2"></div>
        </div>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12" label="Add" (click)="onAdd()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>
</div>
