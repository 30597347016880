import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { InboxItemService } from './inbox-item.service';
import { InboxItem } from './inbox-item';

import { BaseComponent } from '../base/component/base-component.component';
import { MessagingService } from '../event/messaging.service';
import { InboxItemReadEvent } from '../event/inbox-item-read-event';

@Component({
  selector: 'inbox-item-detail',
  templateUrl: './inbox-item-detail.component.html',
  styleUrls: ['./inbox-item-detail.component.css'],
  providers: [ InboxItemService ]
})
export class InboxItemDetailComponent extends BaseComponent implements OnInit
{ 
    inboxItem : InboxItem;
    inboxItemId : string;

    constructor(protected router:Router,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private inboxItemService: InboxItemService,
                private messagingService : MessagingService) 
    {
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.inboxItemId = params['inboxItemId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.inboxItem = undefined;
            this.inboxItemId = params['inboxItemId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        this.inboxItemService.retrieve(this.inboxItemId)
            .subscribe(result=>this.processInboxItem(result),
                       error => this.handleError(error) );
    }

    private processInboxItem(result: InboxItem) : void
    {
        this.inboxItem = result;

        this.inboxItem.read = true;
        this.inboxItemService.update(this.inboxItem)
            .subscribe(result => this.processInboxItemUpdate(result),
                       error => this.handleError(error) );
    }

    private processInboxItemUpdate(result : InboxItem) : void
    {
        this.messagingService.publish(new InboxItemReadEvent() );
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
     }

    provideInboxItemDetail() : SafeHtml
    {
        return this.sanitizer.bypassSecurityTrustHtml(this.inboxItem.body);
    }

}
