import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { AlertText } from './alert-text';
import { AlertTextService } from './alert-text.service';

import { Content } from '../../content/content';
import { ContentService } from '../../content/content.service';

import { ContextVariable } from '../context-variable/context-variable';
import { BaseComponent } from '../../base/component/base-component.component';

import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'alert-text',
  templateUrl: './alert-text.component.html',
  styleUrls: ['./alert-text.component.css'],
  providers: [ AlertTextService, ContentService, ConfirmationService ]
})
export class AlertTextComponent extends BaseComponent implements OnInit
{
    backlogItemContextList : ContextVariable[];
    sprintContextList : ContextVariable[];
    releaseContextList : ContextVariable[];
    projectContextList : ContextVariable[];

    alertTextList : AlertText[];
    selectedAlertText : AlertText;

    severityList: SelectItem[];
    contentList: SelectItem[];

    constructor(protected router:Router,
                private alertTextService : AlertTextService, 
                private contentService : ContentService,
                private confirmationService : ConfirmationService) 
    {
      super(router);
      this.initializeContextVariableLists();
    }

    ngOnInit() : void
    {
        super.clearErrorMessages();

        this.severityList = [];
        this.severityList.push({label:'Critical', value:1});
        this.severityList.push({label:'Warning', value:2});
        this.severityList.push({label:'Informational', value:3}); 

        this.contentService.retrieveAll()
            .subscribe(result => this.processContentList(result),
                       error => this.handleError(error) );        

        this.retrieveAlertTextList();
    }

    public eventTypeText(eventType : number) : string
    {
        let result = "Unknown";

        if (eventType == AlertText.USERSTORY)
            result = "User Story";

        if (eventType == AlertText.SPRINT)
            result = "Sprint";

        if (eventType == AlertText.DEFECT)
            result = "Defect";

        if (eventType == AlertText.CHORE)
            result = "Chore";

        if (eventType == AlertText.RELEASE)
            result = "Release";

        if (eventType == AlertText.PROJECT)
            result = "Project";

        if (eventType == AlertText.SPIKE)
            result = "Spike";
            
        return result;
    }

    public retrieveAlertTextList() : void
    {
        this.selectedAlertText = undefined;

        this.alertTextService.retrieveAll()
          .subscribe(result => this.processAlertTextList(result) );
    }

    private processAlertTextList(alertTextList : AlertText[]): void
    {
      this.alertTextList = alertTextList;
      this.selectedAlertText = undefined;
    }

    private processContentList(result : Array<Content>) : void
    {
        this.contentList = [];
        this.contentList.push( { label:"(none)", value: undefined } );

        for (let content of result)
        {
            this.contentList.push( { label:content.contentKey, value: content.contentId } );
        }
    }

    public alertTextSelect() : void
    {

    }

    public alertTextUnselect() : void
    {
        this.selectedAlertText = undefined;
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public onUpdate() : void
    {
        this.clearErrorMessages();
        
        if (LLUtils.isStringEmpty(this.selectedAlertText.alertTitle) == true)
        {
            this.addErrorMessage("Alert Title must be provided.");
            return;
        }

        if (LLUtils.isStringEmpty(this.selectedAlertText.alertSummary) == true)
        {
            this.addErrorMessage("Alert Summary must be provided.");
            return;
        }

        if (LLUtils.isStringEmpty(this.selectedAlertText.alertDetail) == true)
        {
            this.addErrorMessage("Alert Detail must be provided.");
            return;
        }

        this.alertTextService.update(this.selectedAlertText)
            .subscribe(result => this.updateSuccessful(result), 
                       error => this.handleError(error) ); 
    }

    private updateSuccessful(result : AlertText) : void
    {
        this.addInfoMessage("Update successful!");
        this.retrieveAlertTextList();
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
     }

     private initializeContextVariableLists() : void
     {
         // Backlog Item Context Variables:
         this.backlogItemContextList = new Array<ContextVariable>();
         this.backlogItemContextList.push(new ContextVariable("${projectSourceSystemKey}", "Project Source System Key") );
         this.backlogItemContextList.push(new ContextVariable("${projectDescription}", "Project Description Key") );
//         this.backlogItemContextList.push(new ContextVariable("${sourceSystemKey}", "Backlog Item Source System Key") );
//         this.backlogItemContextList.push(new ContextVariable("${sourceSystemKeyLink}", "Backlog Item Source System Key Hyperlink") );
         this.backlogItemContextList.push(new ContextVariable("${formattedId}", "Backlog Item Formatted Id Key") );
         this.backlogItemContextList.push(new ContextVariable("${formattedIdLink}", "Backlog Item Formatted Id Hyperlink") );
         this.backlogItemContextList.push(new ContextVariable("${projectAverageVelocity}", "Three sprint average velocity") );
         this.backlogItemContextList.push(new ContextVariable("${beginningSprint}", "Beginning of Sprint (True/False)") );
         this.backlogItemContextList.push(new ContextVariable("${endingSprint}", "Ending of Sprint (True/False)") );
         this.backlogItemContextList.push(new ContextVariable("${sprintsWorkedOn}", "# of sprints story worked on") );
         this.backlogItemContextList.push(new ContextVariable("${storyPoints}", "Story Points") );
         this.backlogItemContextList.push(new ContextVariable("${title}", "Backlog Item Title") );
         this.backlogItemContextList.push(new ContextVariable("${description}", "Backlog Item Description") );
         this.backlogItemContextList.push(new ContextVariable("${sprintEndDate}", "Sprint End Date for Story") );
         this.backlogItemContextList.push(new ContextVariable("${userStoryBlocked}", "List of blockers (user story)") );
         this.backlogItemContextList.push(new ContextVariable("${defectBlocked}", "List of blockers (defects)") );
         this.backlogItemContextList.push(new ContextVariable("${choreBlocked}", "List of blockers (chores)") );
         this.backlogItemContextList.push(new ContextVariable("${spikeBlocked}", "List of blockers (spikes)") );
         this.backlogItemContextList.push(new ContextVariable("${actionType}", "Type of action (mail/appAlert)") );
         this.backlogItemContextList.push(new ContextVariable("<@content key='contentKey' title='title'/>", "Link to application content") );

         // Sprint Context Variables.
         this.sprintContextList = new Array<ContextVariable>();
         this.sprintContextList.push(new ContextVariable("${projectSourceSystemKey}", "Project Source System Key") );
         this.sprintContextList.push(new ContextVariable("${projectDescription}", "Project Description Key") );
         this.sprintContextList.push(new ContextVariable("${sourceSystemKey}", "Sprint Source System Key") );
         this.sprintContextList.push(new ContextVariable("${projectAverageVelocity}", "Three sprint average velocity") );
         this.sprintContextList.push(new ContextVariable("${sprintAverageVelocity}", "Three sprint average velocity") );
         this.sprintContextList.push(new ContextVariable("${pointsCarriedOver}", "Points carried into sprint") );
         this.sprintContextList.push(new ContextVariable("${title}", "Sprint Title") );
         this.sprintContextList.push(new ContextVariable("${sprintTitleLink}", "Sprint Title Hyperlink") );
         this.sprintContextList.push(new ContextVariable("${description}", "Sprint Description") );
         this.sprintContextList.push(new ContextVariable("${totalStoryPoints}", "Total Story Points in Sprint") );
         this.sprintContextList.push(new ContextVariable("${sprintOpenBacklogItemList}", "Close Sprint Open Backlog Items") );
         this.sprintContextList.push(new ContextVariable("${actionType}", "Type of action (mail/appAlert)") );
         this.sprintContextList.push(new ContextVariable("<@content key='contentKey' title='title'/>", "Link to application content") );
         this.sprintContextList.push(new ContextVariable("${actionType}", "Type of action (mail/appAlert)") );
         this.sprintContextList.push(new ContextVariable("<@content key='contentKey' title='title'/>", "Link to application content") );

         // Release Context Variables.
         this.releaseContextList = new Array<ContextVariable>();
         this.releaseContextList.push(new ContextVariable("${projectSourceSystemKey}", "Project Source System Key") );
         this.releaseContextList.push(new ContextVariable("${projectDescription}", "Project Description Key") );
         this.releaseContextList.push(new ContextVariable("${projectAverageVelocity}", "Three sprint average velocity") );
         this.releaseContextList.push(new ContextVariable("${sourceSystemKey}", "Release Source System Key") );
         this.releaseContextList.push(new ContextVariable("${description}", "Release Description") );
         this.releaseContextList.push(new ContextVariable("${releaseDescriptionLink}", "Release Description Hyperlink") );
         this.releaseContextList.push(new ContextVariable("${unsizedStoryList}", "List of unsized stories") );
         this.releaseContextList.push(new ContextVariable("${dueDate}", "Date release is due to be shipped.") );
         this.releaseContextList.push(new ContextVariable("${releasePredictedCompletion}", "Date release is predicted to be shipped.") );
         this.releaseContextList.push(new ContextVariable("${actionType}", "Type of action (mail/appAlert)") );
         this.releaseContextList.push(new ContextVariable("<@content key='contentKey' title='title'/>", "Link to application content") );

         // Project context variables.
         this.projectContextList = new Array<ContextVariable>();
         this.projectContextList.push(new ContextVariable("${projectSourceSystemKey}", "Project Source System Key") );
         this.projectContextList.push(new ContextVariable("${projectDescription}", "Project Description Key") );
         this.projectContextList.push(new ContextVariable("${projectDescriptionLink}", "Project Description Hyperlink") );
         this.projectContextList.push(new ContextVariable("${projectExpectedSprintBeginDate}", "Expected Sprint Begin") );
         this.projectContextList.push(new ContextVariable("${sprintDuration}", "Most recent sprint duration") );
         this.projectContextList.push(new ContextVariable("${sprintStartDay}", "Most recent sprint start day") );
         this.projectContextList.push(new ContextVariable("${projectAverageVelocity}", "Three sprint average velocity") );
         this.projectContextList.push(new ContextVariable("${backlogBacklogDepth}", "Backlog Depth") );
         this.projectContextList.push(new ContextVariable("${actionType}", "Type of action (mail/appAlert)") );
         this.projectContextList.push(new ContextVariable("<@content key='contentKey' title='title'/>", "Link to application content") );

     }
}
