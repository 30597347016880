<div *ngIf="content" class="grid card card-w-title">
    <h3 class="col-12">{{content.contentTitle}}</h3>
    <h4 class="col-12">{{content.contentSummary}}</h4>
    <div class="col-12 content-detail">
        <div [innerHTML]="provideContentDetail()">
        </div>
    </div>
    <div class="buttonGroup">
        <button #returnButtonTag pButton type="button" label="Return to List" (click)="onReturn()"></button>
    </div>
</div>
