import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { OrgImage } from '../../org-image/org-image';
import { OrgImageService } from '../../org-image/org-image.service';

import { BaseComponent } from '../../base/component/base-component.component';

import { FileUpload } from 'primeng/fileupload';
import { LoggedInUser } from '../../authentication/logged-in-user';
import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'org-config',
  templateUrl: './org-config.component.html',
  styleUrls: ['./org-config.component.scss'],
  providers: [ OrgImageService, ConfirmationService ]
})
export class OrgConfigComponent extends BaseComponent implements OnInit
{
  @ViewChild("companyLogFileTag")
  fileUploadComponent : FileUpload;

  orgImage : OrgImage = new OrgImage();

  constructor(protected router:Router,
              protected orgImageService : OrgImageService, 
              protected confirmationService : ConfirmationService,
              protected changeDetector : ChangeDetectorRef) 
  {
    super(router);
  }

  public ngOnInit() : void
  {
    super.clearErrorMessages();
    this.initializeObjects();
    this.loadData();
  }

  public loadData() : void
  {
    this.orgImageService.retrieveByOrgId(LoggedInUser.getLoggedInUser().organization.orgId)
      .subscribe(result => this.processOrgImage(result), 
                  error => this.handleError404Okay(error) );
  }

  public processOrgImage(orgImage : OrgImage)
  {
    this.orgImage = orgImage;
  }

  public initializeObjects() : void
  {
    this.orgImage = new OrgImage();

    if (this.fileUploadComponent != undefined)
      this.fileUploadComponent.clear();
  }

  public uploadHandler(event : any) : void
  {      
    let files : File[] = this.fileUploadComponent.files;
    
    // Should only be one file.
    if (files.length != 1)
      return;

    // Set image attributes
    this.orgImage.orgId = LoggedInUser.getLoggedInUser().organization.orgId;
    this.orgImage.orgImageType = files[0].type;

    let file : File = files[0];

    // Process the array buffer.
    file.arrayBuffer().then(buffer => this.processArrayBuffer(buffer) );
  }

  public processArrayBuffer(buffer : any) : void
  {
    this.orgImage.orgImage = this.arrayBufferToBase64(buffer);
    this.changeDetector.detectChanges();
  }

  public getImage() : string
  {
    let image = `data:${this.orgImage.orgImageType};base64,${this.orgImage.orgImage}`;
    return image;
  }

  public onReset() : void
  {
    this.clearErrorMessages();        

    this.confirmationService.confirm(
      { message: 'Are you sure you wish to reset the form?',
          accept: () => 
          {
            this.initializeObjects();
            this.loadData();
          }
      });
  }

  public onSave() : void
  {
    this.clearErrorMessages();     
    
    if (this.orgImage == undefined || LLUtils.isStringEmpty(this.orgImage.orgImage) )
    {
      this.addInfoMessage("No image to save yet.");
      return;
    }

    this.orgImageService.upsert(this.orgImage)
      .subscribe(result => this.saveSuccessful(result),
                 error => this.handleError(error) );
  }

  public saveSuccessful(result : OrgImage)
  {
    this.addInfoMessage("Image saved successfully");

    this.fileUploadComponent.clear();
    this.processOrgImage(result);
  }

  public onDelete() : void
  {
    this.clearErrorMessages();        

    if (this.orgImage.orgImageId == undefined)
    {
      this.addInfoMessage("Image hasn't been saved yet.  Nothing to delete");
      return;
    }

    this.confirmationService.confirm(
      { message: 'Are you sure you wish to delete this image?',
          accept: () => 
          {
            this.orgImageService.delete(this.orgImage)
              .subscribe(result => this.deleteSuccessful(result), 
                         error => this.handleError(error) );
          }
      });
  }

  public deleteSuccessful(result : any)
  {
    this.addInfoMessage("Image deleted successfully");
    this.initializeObjects();
    this.loadData();
  }

  public onCancel() : void
  {
    this.router.navigate(['dashboard']);
  }

  protected handleError(error : any) : any
  {
    super.addErrorMessage("Error occurred.  Please try again");
    return error.message || error;
  }

  protected arrayBufferToBase64(buffer) : string 
  {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;

    for (var i = 0; i < len; i++) 
        binary += String.fromCharCode( bytes[ i ] );

    return window.btoa( binary );
  }     
}
