<div style="width:100%" *ngIf="displayVertical == true && releases != undefined && releases.length > 0">
    <div style="width:100%" *ngFor="let release of releases; let i=index">
        <span class="grid col-12" *ngIf="release.childRelease == false">
            <label *ngIf="i == 0" class="col-4" for="release">Release:</label>
            <div *ngIf="i > 0" class="col-4"><!-- filler --></div> 

            <a class="col-8" [routerLink]="['/dashboard/release-detail', release.releaseId]">
                <div class="col-12 backlog-item-release" id="release">
                    {{release.name}}
                </div>
            </a>
        </span>        
    </div>
</div>

<span *ngIf="displayVertical == false && releases != undefined && releases.length > 0">
    <span *ngFor="let release of releases; let i=index">
        <span *ngIf="release.childRelease == false">
            <a [routerLink]="['/dashboard/release-detail', release.releaseId]">
                <span class="backlog-item-release">{{release.shortName}}</span>
            </a>
        </span>
    </span>
</span>