<p-table [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="objectId"
    [rowHover]="true" [value]="viewBacklogItemList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
    (onLazyLoad)="loadData($event)">    
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:75px">                        <!-- priority -->
                <col style="width:180px">                       <!-- id -->
                <col style="width:125px">                       <!-- status -->
                <col>                                           <!-- description -->
                <col style="width:75px">                        <!-- points -->
                <col *ngIf="displayCosts" style="width:125px">  <!-- display costs-->
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>
                    Priority
                </th>
                <th>
                    Id
                </th>
                <th>
                    Status
                </th>
                <th>
                    Description
                </th>
                <th>
                    Points
                </th>
                <th *ngIf="displayCosts">
                    Costs
                </th>
           </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns" let-backlogItem>
            <tr [pSelectableRow]="rowData" [ngClass]="invokeCallback(backlogItem, index)">
                <td>
                    <priority-rank [priorityRank]="backlogItem.priorityRank"></priority-rank>
                </td>
                <td>
                    <div *ngIf="backlogItem.isUserStory()" class="flex align-content-center align-items-center">
                        <a *ngIf="buildSourceSystemLink(backlogItem) && backlogItem.ghostStory == false" href="{{ buildSourceSystemLink(backlogItem) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'vertical-align' : 'middle','padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                        <i *ngIf="backlogItem.ghostStory == false" class="material-icons" [ngStyle]="{'padding-right':'5px','color':'blue'}" pTooltip="Story">description</i>
                        <i *ngIf="backlogItem.ghostStory == true" class="fa fa-snapchat-ghost fa-lg" [ngStyle]="{'padding-right':'5px'}" pTooltip="Ghost Story - Story"></i>
                        <a [routerLink]="['/dashboard/user-story-detail', backlogItem.objectId]">{{backlogItem.formattedId}}</a>
                    </div>
                    <div *ngIf="backlogItem.isDefect()" class="flex align-content-center align-items-center">
                        <a *ngIf="buildSourceSystemLink(backlogItem) && backlogItem.ghostStory == false" href="{{ buildSourceSystemLink(backlogItem) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'vertical-align' : 'middle','padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                        <i *ngIf="backlogItem.ghostStory == false" class="material-icons" [ngStyle]="{'padding-right':'5px','color':'red'}" pTooltip="Defect">bug_report</i>
                        <i *ngIf="backlogItem.ghostStory == true" class="fa fa-snapchat-ghost fa-lg" [ngStyle]="{'padding-right':'5px'}" pTooltip="Ghost Story - Defect"></i>
                        <a *ngIf="backlogItem.isDefect()" [routerLink]="['/dashboard/defect-detail', backlogItem.objectId]">{{backlogItem.formattedId}}</a>
                    </div>
                    <div *ngIf="backlogItem.isChore()" class="flex align-content-center align-items-center">
                        <a *ngIf="buildSourceSystemLink(backlogItem) && backlogItem.ghostStory == false" href="{{ buildSourceSystemLink(backlogItem) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'vertical-align' : 'middle','padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                        <i *ngIf="backlogItem.ghostStory == false" class="material-icons" [ngStyle]="{'padding-right':'5px','color':'orange'}" pTooltip="Chore">build</i>
                        <i *ngIf="backlogItem.ghostStory == true" class="fa fa-snapchat-ghost fa-lg" [ngStyle]="{'padding-right':'5px'}" pTooltip="Ghost Story - Chore"></i>
                        <a *ngIf="backlogItem.isChore()" [routerLink]="['/dashboard/chore-detail', backlogItem.objectId]">{{backlogItem.formattedId}}</a>
                    </div>
                    <div *ngIf="backlogItem.isSpike()" class="flex align-content-center align-items-center">
                        <a *ngIf="buildSourceSystemLink(backlogItem) && backlogItem.ghostStory == false" href="{{ buildSourceSystemLink(backlogItem) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'vertical-align' : 'middle','padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                        <i *ngIf="backlogItem.ghostStory == false" class="material-icons" [ngStyle]="{'padding-right':'5px','color':'green'}" pTooltip="Spike">explore</i>
                        <i *ngIf="backlogItem.ghostStory == true" class="fa fa-snapchat-ghost fa-lg" [ngStyle]="{'padding-right':'5px'}" pTooltip="Ghost Story - Spike"></i>
                        <a *ngIf="backlogItem.isSpike()" [routerLink]="['/dashboard/spike-detail', backlogItem.objectId]">{{backlogItem.formattedId}}</a>
                    </div>
                    <div *ngIf="backlogItem.isDependency()" class="flex align-content-center align-items-center">
                        <a *ngIf="buildSourceSystemLink(backlogItem) && backlogItem.ghostStory == false" href="{{ buildSourceSystemLink(backlogItem) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'vertical-align' : 'middle','padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                        <i *ngIf="backlogItem.ghostStory == false" class="material-icons" [ngStyle]="{'padding-right':'5px','color':'black'}" pTooltip="Dependency">autorenew</i>
                        <i *ngIf="backlogItem.ghostStory == true" class="fa fa-snapchat-ghost fa-lg" [ngStyle]="{'padding-right':'5px'}" pTooltip="Ghost Story - Dependency"></i>
                        <a *ngIf="backlogItem.isDependency()" [routerLink]="['/dashboard/dependency-detail', backlogItem.objectId]">{{backlogItem.formattedId}}</a>
                    </div>
                </td>
                <td>
                    {{ backlogItem.status }}
                </td>
                <td>
                    <div class="flex align-content-center align-items-center">
                        {{backlogItem.title}}
                        <span *ngIf="hasCarriedOver(backlogItem)" pTooltip="Part of {{sprintsWorkedOn(backlogItem)}} sprints">
                            <a [routerLink]="['/dashboard/content-detail/key', CARRYOVERKEY]" class="carry-over">
                                <i class="material-icons">redo</i>
                            </a>
                        </span>
                        <span *ngIf="blocked(backlogItem)" pTooltip="Blocked">
                            <a [routerLink]="['/dashboard/content-detail/key', BLOCKERS]" class="blocked">
                                <i class="material-icons">block</i>                    
                            </a>
                        </span>
                        <span *ngIf="openBacklogItemClosedSprint(backlogItem)" class="openBacklogItemClosedSprint" pTooltip="Open Backlog Item in a Closed Sprint!">
                            <i class="material-icons">event_busy</i>
                        </span>
                        <span *ngIf="missingDescription(backlogItem)" pTooltip="Missing Description">
                            <a [routerLink]="['/dashboard/content-detail/key', GOODSTORIES]" class="missing-description">
                                <i class="material-icons">find_in_page</i>
                            </a>
                        </span>
                        <span *ngIf="poorAgileStory(backlogItem)" pTooltip="Check Story Form">
                            <a [routerLink]="['/dashboard/content-detail/key', GOODSTORIES]" class="poor-agile-story">
                                <i class="material-icons">description</i>
                            </a>
                        </span>
                        <span *ngIf="poorAcceptanceCriteria(backlogItem)" pTooltip="Sparse Acceptance Criteria">
                            <a [routerLink]="['/dashboard/content-detail/key', ACCEPTANCECRITERIA]" class="poor-acceptance-criteria">
                                <i class="material-icons">done_all</i>
                            </a>
                        </span>
                        <span *ngIf="addedToCurrentSprint(backlogItem)" pTooltip="Added To Sprint">
                            <a [routerLink]="['/dashboard/content-detail/key', ADDEDTOSPRINT]" class="added-to-current-sprint">
                                <i class="material-icons">note_add</i>
                            </a>
                        </span>
        
                        <span *ngIf="changedMidSprint(backlogItem) && hasPointChangeDetail(backlogItem) == false" pTooltip="Points Changed">
                            <a [routerLink]="['/dashboard/content-detail/key', POINTSCHANGED]" class="changed-mid-sprint">
                                <i class="material-icons">settings_backup_restore</i>
                            </a>
                        </span>
        
                        <span *ngIf="changedMidSprint(backlogItem) && hasPointChangeDetail(backlogItem) == true" (mouseenter)="pointChangeDetailOverlay.openOverlay($event, backlogItem)">
                            <a [routerLink]="['/dashboard/content-detail/key', POINTSCHANGED]" class="changed-mid-sprint">
                                <i class="material-icons">settings_backup_restore</i>
                            </a>
                        </span>
                    </div>
                    <div style="margin-top:3px; margin-bottom:2px">
                        <a *ngIf="displaySprint && backlogItem.sprint != undefined " style="margin:2px 2px 2px 0px" [routerLink]="['/dashboard/sprint-detail', backlogItem.sprint.sprintId]">
                            <span class="backlog-item-sprint">{{backlogItem.sprint.shortTitle}}</span>
                        </a>
                        <release-link-list *ngIf="backlogItem.releases && backlogItem.releases.length > 0" style="margin:2px 2px 2px 0px" [releases]="backlogItem.releases"></release-link-list>
                        <a *ngIf="backlogItem.epic && backlogItem.epic != undefined" style="margin:2px 2px 2px 0px" [routerLink]="['/dashboard/epic-detail', backlogItem.epic.epicId]">
                            <span class="backlog-item-epic">{{backlogItem.epic.shortName}}</span>
                        </a>
                    </div>
                </td>
                <td>
                    <story-point [backlogItem]="backlogItem"></story-point>
                </td>
                <td *ngIf="displayCosts">
                    <span *ngIf="calculateCost(backlogItem)">{{ calculateCost(backlogItem) | currency:'USD':true}} </span>
                    <span *ngIf="!calculateCost(backlogItem)">N/A</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td *ngIf="displayCosts == true" [attr.colspan]="6">
                    No records found
                </td>
                <td *ngIf="displayCosts == undefined || displayCosts == false" [attr.colspan]="5">
                    No records found
                </td>
            </tr>
        </ng-template>
</p-table>

<point-change-detail #pointChangeDetailOverlay></point-change-detail>
