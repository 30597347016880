<div class="grid card card-w-title">
    <p-blockUI [blocked]="blockedDocument"></p-blockUI>

    <h3 class="col-12">Source System Configuration</h3>
    <h4 class="col-12">
        This page is for managing the source systems lucidLIFT
        will connect to.  Once you have added the source systems you 
        are interested in, select the projects you wish to manage by clicking
        <a [routerLink]="['/dashboard/admin/project-filter']" [routerLinkActive]="['active-menulink']">here</a>.
    </h4>        

    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <p-table *ngIf="orgSourceSystemList" class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
        dataKey="orgSourceSystemId" [rowHover]="true" [value]="orgSourceSystemList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedOrgSourceSystem">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- description -->
                    <col><!-- userid -->
                    <col><!-- apiurl -->
                    <col><!-- datecreated -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Source System
                    </th>
                    <th>
                        User Id For Access
                    </th>
                    <th>
                        API Url
                    </th>
                    <th>
                        Date Created
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-orgSourceSystem>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ orgSourceSystem.sourceSystem.description }}
                    </td>
                    <td>
                        {{ orgSourceSystem.sourceSystemUserId }}
                    </td>
                    <td>
                        {{ orgSourceSystem.sourceSystemApiUrl }}
                    </td>
                    <td>
                        {{ orgSourceSystem.dateCreated | dateFormat }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="4">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12" #addButtonTag pButton type="button" label="Add" (click)="onAdd()"></button>
        </div>            
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12" #updateButtonTag pButton type="button" [disabled]="selectedOrgSourceSystem == null" label="Update" (click)="onUpdate()"></button>
        </div>            
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12" #deleteButtonTag pButton type="button" [disabled]="selectedOrgSourceSystem == null" label="Delete" (click)="onDelete()"></button>
        </div>            
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12 secondary" #cancelButtonTag pButton type="button" label="Cancel" (click)="onCancel()"></button>
        </div>            
    </div>
    
    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
