<div class="grid card card-w-title">
    <h3>Update User Account</h3>

    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="grid col-12">
            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="firstName">First Name:</label>
                <input class="col-12" pInputText maxlength="50" #firstNameTag id="firstName" [(ngModel)]="userAccount.firstName"  name="firstName" />
            </div>

            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="lastName">Last Name:</label>
                <input class="col-12" pInputText maxlength="50" #lastNameTag id="lastName" [(ngModel)]="userAccount.lastName"  name="lastName" />
            </div>
        </div>

        <div class="grid col-12">
            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="address1">Address Line 1:</label>
                <input class="col-12" pInputText maxlength="100" #address1Tag id="address1" [(ngModel)]="userAccount.addressLine1"  name="address1" />
            </div>

            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="address2">Address Line 2:</label>
                <input class="col-12" pInputText maxlength="100" #address2Tag id="address2" [(ngModel)]="userAccount.addressLine2"  name="address2"/>
            </div>
        </div>

        <div class="grid col-12 flex align-content-center align-items-center">
            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="city">City:</label>
                <input class="col-12" pInputText maxlength="100" #cityTag id="city" [(ngModel)]="userAccount.city"  name="city" />
            </div>

            <div class="col-12 sm:col-12 md:col-6 grid-nogutter">
                <label class="col-12" for="stateId">State:</label>
                <div class="col-12">
                    <p-dropdown [style]="{'width':'300px'}" id="stateId" #stateIdTag name="stateId" [options]="stateItems" [(ngModel)]="selectedStateId" ></p-dropdown>
                </div>
            </div>
        </div>

        <div class="grid col-12">
            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="zipcode">Zipcode:</label>
                <input class="col-12" pInputText maxlength="5" #zipcodeTag id="zipcode" [(ngModel)]="userAccount.zipcode"  name="zipcode" />
            </div>

            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="emailAddress">Email Address:</label>
                <input class="col-12" pInputText maxlength="100" #emailAddressTag id="emailAddress" [(ngModel)]="userAccount.emailAddress"  name="emailAddress" />
            </div>
        </div>

        <div class="grid col-12">
            <div class="col-12 sm:col-12 md:col-4">
                <label class="col-4 sm:col-4 md:col-4" for="isActive">Active User:</label>
                <span class="col-8 sm:col-8 md:col-8">
                    <p-inputSwitch id="isActive" #isActiveTag [(ngModel)]="userAccount.isActive" name="isActive" pTooltip="{{userAccount.isActive}}"></p-inputSwitch>
                </span>
            </div>

            <div class="col-12 sm:col-12 md:col-4">
                <label class="col-4 sm:col-4 md:col-4" for="admin">Admin:</label>
                <span class="col-8 sm:col-8 md:col-8">
                    <p-inputSwitch id="admin" #adminTag [(ngModel)]="admin" name="admin" pTooltip="{{admin}}"></p-inputSwitch>
                </span>
            </div>

            <div class="col-12 sm:col-12 md:col-4">
                <label class="col-6 sm:col-6 md:col-6" for="displayMaturity">Display Maturity:</label>
                <span class="col-6 sm:col-6 md:col-6">
                    <p-inputSwitch id="displayMaturity" #displayMaturityTag [(ngModel)]="displayMaturity" name="displayMaturity" pTooltip="{{displayMaturity}}"></p-inputSwitch>
                </span>
            </div>
        </div>

        <div class="grid col-12">
            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-4 sm:col-4 md:col-4" for="finuser">Financial Access:</label>
                <span class="col-8 sm:col-8 md:col-8">
                    <p-inputSwitch id="finuser" #finuserTag [(ngModel)]="finuser" name="finuser" pTooltip="{{finuser}}"></p-inputSwitch>
                </span>
            </div>

            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-4 sm:col-4 md:col-4" for="finadmin">Financial Admin:</label>
                <span class="col-8 sm:col-8 md:col-8">
                    <p-inputSwitch id="finadmin" #finadminTag [(ngModel)]="finadmin" name="finadmin" pTooltip="{{finadmin}}"></p-inputSwitch>
                </span>
            </div>
        </div>

        <p-pickList [responsive]="true" class="projectPickList col-12" [source]="sourceProjectList" 
            [target]="userAccount.userProjects" [sourceHeader]="'Available Projects'" [targetHeader]="'Assigned Projects'"
            [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-project pTemplate="item">
                    <div>
                        {{project.description}}
                    </div>
                </ng-template>
        </p-pickList>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-4">
            <button pButton class="col-12" id="updateButton" #updateButtonTag label="Update" (click)="onUpdate()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-4">
            <button pButton class="col-12" id="resetPasswordButton" #resetPasswordButtonTag label="Reset Password" (click)="onResetPassword()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-4">
            <button pButton class="col-12 secondary" id="cancelButton" #cancelButtonTag label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>    
</div>
