import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseProjectTeamCostComponent } from './base-project-team-cost.component';
import { ConfirmationService } from 'primeng/api';

import { Project } from '../project/project';
import { ProjectService } from '../project/project.service';

import { ProjectTeamCost } from './project-team-cost';
import { ProjectTeamCostService } from './project-team-cost.service';
import { DateUtils } from '../utilities/date-utils';

import { LLUtils } from '../utilities/ll-utils';

@Component({
  selector: 'project-team-cost-add',
  templateUrl: './project-team-cost-add.component.html',
  styleUrls: ['./project-team-cost-add.component.scss' ],
  providers: [ ProjectService, ProjectTeamCostService, ConfirmationService ]
})

export class ProjectTeamCostAddComponent extends BaseProjectTeamCostComponent implements OnInit 
{ 
    projectId : string;

    project : Project;

    effectiveDate : Date = undefined;
    endDate : Date = undefined;
    costPerMonth : number = 0;
    currentDateEndDate : boolean = false;

    constructor( protected projectService : ProjectService,
                 protected projectTeamCostService : ProjectTeamCostService,
                 protected confirmationService : ConfirmationService,
                 protected route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);

        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.project = undefined;
            this.projectId = params['projectId'];
            this.loadData();
        });
    }

    public loadData() : void
    {
        this.project = undefined;

        this.projectService.retrieve(this.projectId)
                .subscribe(result => this.processProjectDetail(result),
                           error => this.handleError(error) );
    }

    private processProjectDetail(result : Project) : void
    {
        this.project = result;
    }

    public onAdd() : void
    {
        this.clearErrorMessages();

        if (this.validatePage(this.effectiveDate, 
                    this.endDate, 
                    this.currentDateEndDate, 
                    this.costPerMonth) == false)
            return;

        this.confirmationService.confirm(
            { message: 'Are you sure you wish to add these costs?',
                accept: () => 
                {
                    this.addProjectTeamCost();
                }
            });
    }

    public addProjectTeamCost() : void 
    {
        let projectTeamCost : ProjectTeamCost = new ProjectTeamCost();
        projectTeamCost.project = this.project;
        projectTeamCost.effectiveDate = this.effectiveDate;
        projectTeamCost.endDate = this.endDate;
        projectTeamCost.costPerMonth = this.costPerMonth;
        projectTeamCost.currentDateEndDate = this.currentDateEndDate;
        

        this.projectTeamCostService.create(projectTeamCost)
            .subscribe(result => this.addProjectTeamCostSuccessful(result),
                       error => this.handleError(error) );
    }

    private addProjectTeamCostSuccessful(result : ProjectTeamCost) : void
    {
        this.addInfoMessage("Successfully added team costs!");
        this.effectiveDate = result.effectiveDate;
        this.endDate = result.endDate;
        this.costPerMonth = result.costPerMonth;
    }
}
