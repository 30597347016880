import { BaseValueObject } from '../base/value-object/base-value-object';
import { Content } from './content';

export class ContentDetail extends Content
{
	contentDetail : string;
	
	public initialize(content : ContentDetail) : void
	{
		super.initialize(content);
        this.contentDetail = content.contentDetail;
	}
}