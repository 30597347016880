import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseFilledDoughnutComponent } from '../../base/component/base-filled-doughnut.component';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { PluralPipe } from '../../pipes/plural.pipe';

import * as moment from 'moment';

@Component({
  selector: 'sprint-scope',
  templateUrl: './sprint-scope.component.html',
  styleUrls: ['./sprint-scope.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class SprintScopeComponent extends BaseFilledDoughnutComponent implements OnInit 
{ 
    @Input()
    sprint : BaseSprint;

    @Output() click = new EventEmitter<any>();

    sprintScopeFact : SprintFact;
    pointsCompletedFact : SprintFact;

    constructor( protected router : Router,
                 private sprintFactService : SprintFactService)
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTSCOPE)
            .subscribe(result => this.processSprintScopeFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.POINTSCOMPLETED)
            .subscribe(result => this.processPointsCompletedFact(result),
                       error => this.handleError404Okay(error) );
    }

    public processSprintScopeFact(result : SprintFact) : void
    {
        this.sprintScopeFact = result;
        this.drawGraph();
    }

    public processPointsCompletedFact(result : SprintFact) : void
    {
        this.pointsCompletedFact = result;
        this.drawGraph();
    }
    
    public drawGraph() : void
    {
        if (this.sprintScopeFact == undefined || this.pointsCompletedFact == undefined)
            return;

        this.data = {
            labels : ['Points Remaining', 'Points Completed'],
            datasets: [
                {
                    type:'doughnut',
                    label: '',
                    data: [ this.sprintScopeFact.getFactNumber() - this.pointsCompletedFact.getFactNumber(),
                            this.pointsCompletedFact.getFactNumber()
                     ],

                    backgroundColor: [
                        "rgba(0, 0, 256, .40)",
                        "rgba(0, 256, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(0, 256, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2
                    ],
                    hoverBackgroundColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(0, 256, 0, 1)"
                    ],
                    hoverBorderColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(0, 256, 0, 1)"
                    ]
                }
            ]
        };

        this.configureOptions();        
    }

    public percentComplete() : number 
    {
        let result : number = 0;

        if (this.sprintScopeFact != undefined && this.pointsCompletedFact != undefined)
        {
            if (this.sprintScopeFact.getFactNumber() > 0)
            {
                result = (this.pointsCompletedFact.getFactNumber() / this.sprintScopeFact.getFactNumber() ) * 100;
            }
        }

        return result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    protected buildDescription() : string
    {
        let result : string = "";

        if (this.sprintScopeFact != undefined)
        {
            let pluralPipe = new PluralPipe();
            result = pluralPipe.transform(this.sprintScopeFact.getFactNumber(), "pt");
        }

        return result;
    }
}