<div class="grid col-12 grid-nogutter">
    <div class="grid col-12">
        <span class="grid col-12">
            <span *ngIf="isDarkSprint() == true" style="margin-right:30px">
                <h3 style="margin: auto 0px" pBadge value="Dark Sprint" severity="success"><a [routerLink]="['/dashboard/sprint-detail', sprint.sprintId]">{{sprint.title}} - {{sprint.project.description}}</a></h3>
            </span>
            <span *ngIf="isDarkSprint() == false">
                <h3 style="margin: auto 0px"><a [routerLink]="['/dashboard/sprint-detail', sprint.sprintId]">{{sprint.title}} - {{sprint.project.description}}</a></h3>
            </span>
            <span style="margin-right:5px">&nbsp;</span>
            <button *ngIf="showButton" pButton label="Details" class="detail secondary" (click)="onClick(sprint)"></button>
        </span>
        <h4 class="col-12" style="margin:0px; padding:0px" *ngIf="sprint.description">{{ sprint.description }}</h4>
        <colorbox *ngIf="sprint.sprintBeginDate" class="col-12 sm:col-12 md:col-6" [name]="'Sprint Begin Date'" [detail]="sprint.sprintBeginDate | dateFormat" [color]="'blue'" [icon]="'event'"></colorbox>                
        <colorbox *ngIf="sprint.sprintEndDate" class="col-12 sm:col-12 md:col-6" [name]="'Sprint End Date'" [detail]="sprint.sprintEndDate | dateFormat" [color]="'blue'" [icon]="'event'"></colorbox>                
    </div>
</div>
