import { Component, OnInit, OnChanges, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { SprintFact } from '../../sprint-fact/sprint-fact';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service'; 

import { RoundPipe } from '../../pipes/round.pipe';
import { Content } from '../../content/content';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'defect-density-timeline',
  templateUrl: './defect-density-timeline.component.html',
  styleUrls: ['./defect-density-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService, TimeSeriesService ]
})

export class DefectDensityTimelineComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    project : Project;

    @Output() click = new EventEmitter<any>();

    timeSeries : TimeSeries;

    projectDefectDensity : ProjectFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private projectFactService : ProjectFactService,
                 private timeSeriesService : TimeSeriesService) 
    {
        super(router);

        this.options = {
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Defect Density'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void
    {
        if (this.project != undefined)
        {
            this.projectFactService.retrieve(this.project, ProjectFact.DEFECTDENSITY)
                .subscribe(result => this.processProjectDefectDensityFact(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTDEFECTDENSITY, 10)
                .subscribe(result =>this.processTimeSeries(result),
                           error => this.handleError404Okay(error) ); 
                
        }
    }

    private processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'line',
                        label: 'Defect Density',
                        backgroundColor: 'rgba(256, 0, 0, .40)',
                        pointHoverBackgroundColor: 'rgba(256, 0, 0, 1)',
                        borderColor: 'rgba(256, 0, 0, 1)',
                        borderWidth: 2,
                        fill:true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) 
                                { 
                                    let round : RoundPipe = new RoundPipe();
                                    return round.transform(a.getFieldNumber(SprintFact.DEFECTDENSITY), 2) 
                                })
                    }
                ]
            }
        }
    }

    public processProjectDefectDensityFact(result : ProjectFact )
    {
        if (_.isEqual(this.projectDefectDensity, result) == false)
            this.projectDefectDensity = result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    public get DEFECTDENSITY() : string
    {
        return Content.DEFECTDENSITY;
    }
}
