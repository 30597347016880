import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';
import { ResetPasswordService } from './reset-password.service';
import { PasswordReset } from './password-reset';
import { UserAccount } from '../user-account/user-account';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [ './reset-password.component.scss' ],
  providers: [ ResetPasswordService ]
})

export class ResetPasswordComponent extends BaseComponent implements OnInit
{ 
    password : string;
    confirmPassword : string;
    resetTokenId : string;
    passwordReset : PasswordReset = undefined;
    resetSuccessful : boolean;

    constructor(protected router : Router, private route: ActivatedRoute,
                private resetPasswordService : ResetPasswordService ) 
    { 
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.resetTokenId = params['resetTokenId'];
        });

        // Subscribe to changes just in the parameters passed in.
        // Ensures form will refresh.
        this.route.params.subscribe(params => {
            this.passwordReset = undefined;
            this.resetTokenId = params['resetTokenId'];
            this.loadData();
        });

    }

    public ngOnInit()
    {
        this.addInfoMessage("Please enter a new password.");
    }

    public loadData() : void
    {
        this.resetSuccessful = false;

        this.resetPasswordService.retrieve(this.resetTokenId)
            .subscribe(result => this.processPasswordReset(result),
                       error => this.handleRetrieveError(error) );
    }

    public processPasswordReset(passwordReset: PasswordReset) : void
    {
        this.passwordReset = passwordReset;
    }

    public handleRetrieveError(error : any) : void
    {
        this.clearErrorMessages();
        this.passwordReset = undefined;

        if (error.status == 404)
        {
            this.addErrorMessage("Invalid Password Reset Token.  Cannot reset password");
        }
        else
        {
            this.addErrorMessage("System Error.  Please try again.");
        }
    }

    public onSubmit() : void
    {
        this.clearErrorMessages(); 

        if (this.password != this.confirmPassword)
        {
            this.addInfoMessage("Password and confirm password are not equal.");
            return;
        }

        if (this.password.length < 6)
        {
            super.addErrorMessage("Password must be at least six characters long.");
            return;
        }
        
        if (UserAccount.isStrongPassword(this.password) == false)
        {
            super.addErrorMessage("Password must consist of at least six characters, have one uppercase letter, one lowercase letter, one number, and one special character.");
            return;
        }

        // Set the new password
        //this.passwordReset.userAccount.password = this.password;
        this.passwordReset.password = this.password;

        // Update.
        this.resetPasswordService.resetPassword(this.passwordReset)
            .subscribe(result => this.processResetResponse(result),
                       error => this.handleResetError(error) );

    }

    public processResetResponse(authToken : string) : void
    {
        this.resetSuccessful = true;
        localStorage.setItem('auth-token', authToken);
        this.addInfoMessage("Password reset succesful!  Press 'Continue' to login.");
    }

    public handleResetError(error: any)
    {
        this.resetSuccessful = false;
        localStorage.removeItem('auth-token');
        this.addErrorMessage("Error resetting password.  Please try again.");
    }

    public onCancel() : void
    {
        this.router.navigate(['login']);
    }

    public onContinue() : void
    {
        this.router.navigate(['dashboard']);
    }
}
