<div class="grid card card-w-title">
    <h3 class="col-12">Goal Text Configuration</h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12 sm:col-12 md:col-12">
        <div class="grid col-12 sm:col-12 md:col-4">
            <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="goalTextKey" [rowHover]="true" 
                [value]="goalTextList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedGoalText" (onRowSelect)="goalTextSelect()"
                (onRowUnselect)="goalTextUnselect()">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:300px"><!-- description -->
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            Goal Key
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-goalText>
                    <tr [pSelectableRow]="rowData">
                        <td>
                            {{ goalText.sequence }} - {{ goalText.goalTextKey }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="1">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div *ngIf="selectedGoalText" class="col-12 sm:col-12 md:col-8">
            <div class="grid col-12">
                <label class="col-4" for="goalTitle">Title:</label>
                <input pInputText maxlength="100" class="col-8" id="goalTitle" #goalTitleTag [(ngModel)]="selectedGoalText.goalTitle" name="goalTitle" required />
            </div>
            <div class="grid col-12">
                <label class="col-4" for="goalDetail">Detail:</label>
                <textarea pInputTextarea maxlength="500" rows="10" cols="30" class="col-8 textarea-class" id="goalDetail" #goalDetailTag [(ngModel)]="selectedGoalText.goalDetail" name="goalDetail" required></textarea>
            </div>
            <div class="grid col-12">
                <label class="col-4" for="sequence">Sequence:</label>
                <input type="number" min=0 pInputText maxlength="10" class="col-8" id="sequence" #sequenceTag [(ngModel)]="selectedGoalText.sequence" name="sequence" required />
            </div>
            <div class="grid col-12 flex align-content-center align-items-center">
                <label class="col-4" for="content">Content:</label>
                <p-dropdown [options]="contentList" name="content" [(ngModel)]="selectedGoalText.contentKey"></p-dropdown>                
            </div>
        </div>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12" [disabled]="selectedGoalText == undefined" label="Update" (click)="onUpdate()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>
</div>
