import { Injectable }    from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService
{
    loginUrl : string;

    constructor(private http: HttpClient, private config: Config) 
    { 
      this.loginUrl = config.baseUrl + "/rest/login";
    }

    public login(emailAddress: string, password: string): Observable<string> 
    {
        let credentials:Array<string> = new Array<string>();
        credentials.push(emailAddress);
        credentials.push(password);
        
        let data : string = JSON.stringify(credentials);

        return this.http.put(this.loginUrl, data, { headers: this.createHeaders(), responseType: 'text'} );
    }

  private handleError(error: any): any
  {
    localStorage.removeItem('auth-token');
    return false;
  }

  public logout()
  {
    localStorage.removeItem('auth-token');
  }

  public isLoggedIn() : boolean
  {
    if (localStorage.getItem('auth-token') != null)
      return true;
    else
      return false;
  }

  protected createHeaders() : HttpHeaders
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return headers;
  }

}