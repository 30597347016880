import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';

import { Project } from '../../project/project';

@Component({
  selector: 'mini-backlog-count',
  templateUrl: './mini-backlog-count.component.html',
  styleUrls: ['./mini-backlog-count.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService ]
})

export class MiniBacklogCountComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    project : Project;

    openStoryCount : ProjectFact;
    openDefectCount : ProjectFact;
    openChoreCount : ProjectFact;
    openSpikeCount : ProjectFact;
    openDependencyCount : ProjectFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private projectFactService : ProjectFactService) 
    { 
        super(router); 

        this.options = {
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Backlog Breakdown'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            animations: false
        }
    }

    public ngOnInit() : void
    {
        this.retrieveData();
    }

    private retrieveData()
    {
        this.projectFactService.retrieve(this.project, ProjectFact.OPENSTORYCOUNT)
            .subscribe(result => this.processOpenStoryCount(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.OPENDEFECTCOUNT)
            .subscribe(result => this.processOpenDefectCount(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.OPENCHORECOUNT)
            .subscribe(result => this.processOpenChoreCount(result),
                       error => this.handleError404Okay(error) );        

        this.projectFactService.retrieve(this.project, ProjectFact.OPENSPIKECOUNT)
            .subscribe(result => this.processOpenSpikeCount(result),
                       error => this.handleError404Okay(error) );        

        this.projectFactService.retrieve(this.project, ProjectFact.OPENDEPENDENCYCOUNT)
            .subscribe(result => this.processOpenDependencyCount(result),
                       error => this.handleError404Okay(error) );        
    }

    private processOpenStoryCount(result : ProjectFact) : void
    {
        this.openStoryCount = result;
        this.buildGraph();
    }

    private processOpenDefectCount(result : ProjectFact) : void
    {
        this.openDefectCount = result;
        this.buildGraph();
    }

    private processOpenChoreCount(result : ProjectFact) : void
    {
        this.openChoreCount = result;
        this.buildGraph();
    }

    private processOpenSpikeCount(result : ProjectFact) : void
    {
        this.openSpikeCount = result;
        this.buildGraph();
    }

    private processOpenDependencyCount(result : ProjectFact) : void
    {
        this.openDependencyCount = result;
        this.buildGraph();
    }

    private buildGraph() : void
    {
        if (this.openDefectCount == undefined || this.openStoryCount == undefined || this.openChoreCount == undefined || this.openSpikeCount == undefined || this.openDependencyCount == undefined)
            return;

        this.data = {
            labels : ['Stories', 'Defects', 'Chores', 'Spikes', 'Dependencies' ],
            datasets: [
                {
                    type:'pie',
                    label: '',
                    data: [ this.openStoryCount.getFactNumber(), this.openDefectCount.getFactNumber(), 
                            this.openChoreCount.getFactNumber(), this.openSpikeCount.getFactNumber(),
                            this.openDependencyCount.getFactNumber()
                          ],
                    backgroundColor: [
                        "rgba(0, 256, 0, .40)",
                        "rgba(256, 0, 0, .40)",
                        "rgba(0, 0, 256, .40)",
                        "rgba(256, 256, 0, .40)",
                        "rgba(0, 0, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(0, 256, 0, 1)",
                        "rgba(256, 0, 0, 1)",
                        "rgba(0, 0, 256, 1)",
                        "rgba(256, 256, 0, 1)",
                        "rgba(0, 0, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2, 2, 2, 2
                    ],
                    hoverBackgroundColor: [
                        "rgba(0, 256, 0, 1)",
                        "rgba(256, 0, 0, 1)",
                        "rgba(0, 0, 256, 1)",
                        "rgba(256, 256, 0, 1)",
                        "rgba(0, 0, 0, 1)"
                    ]
                }
            ]
        }
    }

    private percentDefects() : number
    {
        let result : number = 0;

        if (this.openDefectCount != undefined && this.openStoryCount != undefined && 
            this.openChoreCount != undefined && this.openSpikeCount != undefined  &&
            this.openDependencyCount != undefined)
        {
            let total = this.openDefectCount.getFactNumber() + this.openStoryCount.getFactNumber() + this.openChoreCount.getFactNumber() + this.openSpikeCount.getFactNumber() + this.openDependencyCount.getFactNumber();

            if (total != 0)
                result = Math.ceil(this.openDefectCount.getFactNumber() / total * 100);
        }

        return result;
    }
}
