import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { Epic } from './epic';
import { EpicFact } from '../epic-fact/epic-fact';
import { EpicFactService } from '../epic-fact/epic-fact.service';

@Component({
  selector: 'epic-heading',
  templateUrl: './epic-heading.component.html',
  styleUrls: ['./epic-heading.component.scss' ],
  providers: [ EpicFactService ]
})
export class EpicHeadingComponent extends BaseComponent implements OnInit 
{
    @Input() 
    epic : Epic;

    @Input()
    showButton : boolean = false;

    @Input()
    displayCosts : boolean = false;

    epicScopeFact : EpicFact = undefined;
    epicPointsCompletedFact : EpicFact = undefined;

    constructor( private epicFactService : EpicFactService,
                 protected router: Router) 
     { 
        super(router);
    }

    ngOnInit() : void
    {
        this.epicFactService.retrieveById(this.epic.epicId, EpicFact.EPICSCOPE)
            .subscribe(result => this.processEpicScopeFact(result),
                    error => this.handleError404Okay(error) );

        this.epicFactService.retrieveById(this.epic.epicId, EpicFact.EPICPOINTSCOMPLETED)
            .subscribe(result => this.processEpicPointsCompletedFact(result),
                       error => this.handleError404Okay(error) );
    }

    private processEpicScopeFact(result : EpicFact)
    {
        this.epicScopeFact = result;
    }

    private processEpicPointsCompletedFact(result : EpicFact)
    {
        this.epicPointsCompletedFact = result;
    }

    public onDefectClick() : void
    {
        this.router.navigate(['/dashboard/defect-backlog', this.epic.project.projectId]);
    }

    public onClick(epic : Epic) : void
    {
        this.router.navigate(['/dashboard/epic-detail', epic.epicId]);
    }
    
}
