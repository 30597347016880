import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { BacklogItemService } from '../backlog-item/backlog-item.service';
import { BacklogItem } from '../backlog-item/backlog-item';

import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../org-source-system/org-source-system';

import { ProjectService } from '../project/project.service';
import { Project } from '../project/project';

import { SprintService } from '../sprint/sprint.service';
import { SprintDetail } from '../sprint/sprint-detail';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Component({
  selector: 'defect-backlog',
  templateUrl: './defect-backlog.component.html',
  styleUrls: ['./defect-backlog.component.css' ],
  providers: [ OrgSourceSystemService, ProjectService, SprintService, BacklogItemService ]
})
export class DefectBacklogComponent extends BaseComponent implements OnInit 
{ 
    projectId : string;
    project : Project;
    orgSourceSystem : OrgSourceSystem;
    sprintDetailList : SprintDetail[];
    defectBacklog : ValueObjectList<BacklogItem>;
    closedDefects : boolean;

    rows : number = 20;

    constructor( private orgSourceSystemService : OrgSourceSystemService,
                 private projectService : ProjectService,
                 private sprintService : SprintService,
                 private backlogItemService : BacklogItemService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        this.sprintDetailList = [];
        this.closedDefects = false;
    }

    public ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        this.projectService.retrieve(this.projectId)
                .subscribe(result => this.processProject(result) );

        this.orgSourceSystemService.retrieveByProjectId(this.projectId)
            .subscribe(result => this.processOrgSourceSystem(result), 
                       error => this.handleError(error) );
    
        this.sprintService.retrieveCurrentSprintDetailList(this.projectId)
                .subscribe(result => this.processCurrentSprints(result));

        this.defectBacklog = new ValueObjectList<BacklogItem>();
    }

    public processProject(result: Project) : void
    {
        this.project = result;
    }

    private processOrgSourceSystem(result: OrgSourceSystem) : void
    {
        this.orgSourceSystem = result;
    }

    public processCurrentSprints(result : SprintDetail[]) : void
    {
        this.sprintDetailList = result;
    }

    public processDefectBacklog(result: ValueObjectList<BacklogItem> ) : void
    {
        this.defectBacklog = result;
    }

    public onClosedClicked()
    {
        this.backlogItemService.retrieveProductBacklogByObjectType(this.project, BacklogItem.DEFECT, 
            this.closedDefects, 0, this.rows)
            .subscribe(result=>this.processDefectBacklog(result),
                       error => this.handleError(error) );
    }

    public onLazyLoad(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.backlogItemService.retrieveProductBacklogByObjectType(this.project, BacklogItem.DEFECT, 
            this.closedDefects, startAt, maxResult)
            .subscribe(result=>this.processDefectBacklog(result),
                       error => this.handleError(error) );
    }
}
