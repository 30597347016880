import { BaseValueObject } from '../base/value-object/base-value-object';

export class Error extends BaseValueObject
{
    public errorNumber : number;
	public description : string;
	
	public static readonly NOTFOUND : number = 10;
	public static readonly EXCEPTION :number = 20;
	public static readonly VALIDATION : number = 30;

    public initialize(error : Error) : void
    {
        super.initialize(error);
                
        this.errorNumber = error.errorNumber;
        this.description = error.description;
    }
    
}