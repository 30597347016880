import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base/service/base-http.service';

import { SourceSystem } from './source-system';

@Injectable()
export class SourceSystemService extends BaseHttpService
{
    sourceSystemUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.sourceSystemUrl = this.config.baseUrl + '/rest/sourcesystem';
    }

    public retrieveAll() : Observable<SourceSystem[]>
    {
        return super.baseRetrieveList(SourceSystem, this.sourceSystemUrl);
    }
}