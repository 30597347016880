import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { SprintFact } from '../../sprint-fact/sprint-fact';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'scrum-maturity-closed-sprints-timeline',
  templateUrl: './scrum-maturity-closed-sprints-timeline.component.html',
  styleUrls: ['./scrum-maturity-closed-sprints-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class ScrumMaturityClosedSprintsTimelineComponent extends BaseUIComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    timeSeries : TimeSeries;
    scrumMaturityAverageClosedSprints : ProjectFact = undefined;    
    scrumMaturityTrendClosedSprints: string = undefined;
    hasData : boolean = undefined;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Highest Scrum Maturity Achieved By Sprint`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTSCRUMMATURITYCLOSEDSPRINTS, 10)
            .subscribe(result =>this.processScrumMaturityClosedSprintsTimeSeries(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.SCRUMMATURITYAVERAGECLOSEDSPRINTS)
            .subscribe(result => this.processScrumMaturityAverageClosedSprints(result) ,
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.SCRUMMATURITYTRENDCLOSEDSPRINTS)
            .subscribe(result => this.processScrumMaturityTrendClosedSprints(result) ,
                       error => this.handleError404Okay(error) );
    }

    private processScrumMaturityClosedSprintsTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            if (this.timeSeries.timeSeriesList.length <= 0)
                this.hasData = false;
            else
                this.hasData = true;

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'line',
                        label: 'Scrum Maturity',
                        backgroundColor: this.ORANGETRANSPARENT,
                        pointHoverBackgroundColor: this.ORANGE,
                        borderColor: this.ORANGE,
                        borderWidth: 2,
                        fill: true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SCRUMMATURITY); } )
                    }
                ]
            }
        }
    }

    protected processScrumMaturityAverageClosedSprints(result : ProjectFact) : void
    {
        if (_.isEqual(this.scrumMaturityAverageClosedSprints, result) == false)
        {
            this.scrumMaturityAverageClosedSprints = result;
        }
    }

    protected processScrumMaturityTrendClosedSprints(result : ProjectFact) : void
    {
        if (_.isEqual(this.scrumMaturityTrendClosedSprints, result.getFactString() ) == false)
        {
            this.scrumMaturityTrendClosedSprints = result.getFactString();
        }
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
}
