import { Component, OnInit, Input, Output, OnChanges, EventEmitter, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';

import { Project } from '../project/project';

import { Epic } from './epic';
import { EpicFact } from '../epic-fact/epic-fact';

import { ContentDetail } from '../content/content-detail';

@Component({
  selector: 'epic-dashboard-summary',
  templateUrl: './epic-dashboard-summary.component.html',
  styleUrls: ['./epic-dashboard-summary.component.scss' ],
  providers: [  ]
})

export class EpicDashboardSummaryComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    rows : number = 0;

    @Input()
    total : number = 0;

    @Input()
    paginator : boolean = false;

    @Input()
    lazy : boolean = false;

    @Input()
    projectList : Project[];

    @Input()
    epicList : Epic[] = [];

    selectedProject : Project;

    @Input()
    sortOrder : number = -1;

    @Input()
    sortField : string = "percentComplete";

    @Input()
    styleWidth : string = "width:100%";

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    @Output()
    onSortClicked = new EventEmitter<any>();

    constructor(protected router : Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void
    {
    }

    public loadData(event : any) 
    {
        //event.first = First row offset
        //event.rows = Number of rows per page
        //add more records to the cars array
        this.onLazyLoad.emit(event);
    }

    public getEpicList(project : Project) : Epic[]
    {
        let result : Epic[] = []

        for (let epic of this.epicList)
        {
            if (epic.project.projectId == project.projectId)
                result.push(epic);
        }

        return result;
    }


    public projectEpicsToDo(project : Project) : number
    {
        let result : number = 0;

        for (let epic of this.epicList)
        {
            if (epic.project.projectId == project.projectId &&
                epic.getFact(EpicFact.FUTUREEPIC) != undefined && 
                epic.getFact(EpicFact.FUTUREEPIC).getFactBoolean() == true)
            result = result + 1;
        }

        return result;
    }

    public totalEpicsToDo() : number
    {
        let result : number = 0;

        for (let project of this.projectList)
            result = result + this.projectEpicsToDo(project);

        return result;
    }

    public projectEpicsInProgress(project : Project) : number
    {
        let result : number = 0;

        for (let epic of this.epicList)
        {
            if (epic.project.projectId == project.projectId &&
                ((epic.getFact(EpicFact.ACTIVEEPIC) != undefined && 
                 epic.getFact(EpicFact.ACTIVEEPIC).getFactBoolean() == true) ||
                 (epic.getFact(EpicFact.INACTIVEEPIC) != undefined && 
                 epic.getFact(EpicFact.INACTIVEEPIC).getFactBoolean() == true) ) )
            {
                result = result + 1;
            }
        }

        return result;
    }

    public totalEpicsInProgress() : number
    {
        let result : number = 0;

        for (let project of this.projectList)
            result = result + this.projectEpicsInProgress(project);

        return result;
    }

    public projectEpicsCompleted(project : Project) : number
    {
        let result : number = 0;

        for (let epic of this.epicList)
        {
            if (epic.project.projectId == project.projectId &&
                epic.getFact(EpicFact.COMPLETEDEPIC) != undefined && 
                epic.getFact(EpicFact.COMPLETEDEPIC).getFactBoolean() == true)
            result = result + 1;
        }

        return result;
    }

    public totalEpicsCompleted() : number
    {
        let result : number = 0;

        for (let project of this.projectList)
            result = result + this.projectEpicsCompleted(project);

        return result;
    }

    public projectEpicsTotal(project : Project) : number
    {
        let result : number = 0;

        for (let epic of this.epicList)
        {
            if (epic.project.projectId == project.projectId)
                result = result + 1;
        }

        return result;
    }

    public totalEpicsTotal() : number
    {
        let result : number = 0;

        for (let project of this.projectList)
            result = result + this.projectEpicsTotal(project);

        return result;
    }
    
    public projectEpicsPercentComplete(project : Project) : number
    {
        let result : number = 0;

        let total : number = this.projectEpicsTotal(project);
        let completed : number = this.projectEpicsCompleted(project);

        if (total > 0)
            result = Math.round(completed / total * 100);

        return result;
    }

    public totalEpicsPercentComplete() : number
    {
        let result : number = 0;

        let total : number = this.totalEpicsTotal();
        let completed : number = this.totalEpicsCompleted();

        if (total > 0)
            result = Math.round(completed / total * 100);

        return result;
    }

    public get STORYPOINTS() : string
    {
        return ContentDetail.STORYPOINTS;
    }

    public projectRowStyleClass(project : Project, index : number) : string
    {
        let result : string = null;
       
        if (this.projectEpicsCompleted(project) > 0 && this.projectEpicsInProgress(project) == 0 && this.projectEpicsToDo(project) == 0)
        {
            if (index % 2 == 0)
                result = 'row-style-class-green-even';
            else
                result = 'row-style-class-green-odd';
        }

        return result;
    }

    public customSort(event: SortEvent) : void
    {
        switch (event.field)
        {
            case 'percentComplete':
                event.data.sort((project1 : Project, project2 : Project) => {
                
                    let value1 = this.projectEpicsPercentComplete(project1);
                    let value2 = this.projectEpicsPercentComplete(project2);
                    let result = this.sorter(value1, value2, event.order);

                    return result;
                }); 

                break;
            
            case 'description':
                event.data.sort((project1 : Project, project2 : Project) => {
                
                    let value1 = project1.description;
                    let value2 = project2.description;
                    let result = this.sorter(value1, value2, event.order);

                    return result;
                }); 

                break; 

            case 'todo':
                    event.data.sort((project1 : Project, project2 : Project) => {
                    
                        let value1 = this.projectEpicsToDo(project1);
                        let value2 = this.projectEpicsToDo(project2);
                        let result = this.sorter(value1, value2, event.order);
    
                        return result;
                    }); 
    
                    break;
    
            case 'inprogress':
                    event.data.sort((project1 : Project, project2 : Project) => {
                    
                        let value1 = this.projectEpicsInProgress(project1);
                        let value2 = this.projectEpicsInProgress(project2);
                        let result = this.sorter(value1, value2, event.order);

                        return result;
                    }); 

                    break;

            case 'completed':
                    event.data.sort((project1 : Project, project2 : Project) => {
                    
                        let value1 = this.projectEpicsCompleted(project1);
                        let value2 = this.projectEpicsCompleted(project2);
                        let result = this.sorter(value1, value2, event.order);

                        return result;
                    }); 

                    break;

            case 'total':
                    event.data.sort((project1 : Project, project2 : Project) => {
                    
                        let value1 = this.projectEpicsTotal(project1);
                        let value2 = this.projectEpicsTotal(project2);
                        let result = this.sorter(value1, value2, event.order);

                        return result;
                    }); 

                    break;
                        
            default:
        }

    /*
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        });
    */
    }

    public sorter( value1 : any, value2 : any, order : number) : number
    {
        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (order * result);
    }

}

