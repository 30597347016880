import { BaseValueObject } from '../base/value-object/base-value-object';

export class OrgConfig extends BaseValueObject 
{
    public orgConfigId : string;
	public orgId : string;
	public configKey : string;
    public configValue : string;

	// Common keys.
	public static readonly POINTINJECTIONTHESHOLDPCT : string = "pointInjectionThesholdPct";
	public static readonly RELEASETARGETRANGEPCT : string = "releaseTargetRangePct";
	public static readonly DARKSPRINTS : string = "darkSprints";
	public static readonly SCRUMMATURITYFACTBUILDER : string = "scrumMaturityFactBuilder";
	public static readonly GOALBUILDER : string = "goalBuilder";
	public static readonly ENABLEORGANIZATIONSUMMARY : string = "enableOrganizationSummary";

    public initialize(orgConfig : OrgConfig) : void
    {
        super.initialize(orgConfig);

        this.orgConfigId = orgConfig.orgConfigId;
        this.orgId = orgConfig.orgId;
        this.configKey = orgConfig.configKey;
        this.configValue = orgConfig.configValue;
    }

}