import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MessagesModule } from 'primeng/messages';
import { Message } from 'primeng/api';
import { Router } from '@angular/router';

import { ValueObjectList } from '../value-object/value-object-list';
import { Error } from '../../error/error';
import { Directive } from '@angular/core';

@Directive()
export abstract class BaseComponent
{
    protected messages: Message[];

    constructor(protected router : Router)
    {
        this.clearErrorMessages();
    }

    protected clearErrorMessages() : void
    {
        this.errorMessages = [];
    }

    public addErrorMessage(errorDetail : string)
    {
        this.errorMessages = [ ... this.errorMessages, {severity:'error', summary:'', detail: errorDetail } ];
        this.scrollToTop();
    }

    public updateErrorMessage(errorDetail : string)
    {
        this.errorMessages = [ ... [], {severity:'error', summary:'', detail: errorDetail } ];
        this.scrollToTop();
    }

    public addInfoMessage(infoDetail : string)
    {
        this.errorMessages = [ ... this.errorMessages, {severity:'info', summary:'', detail: infoDetail } ];
        this.scrollToTop();
    }

    protected handleError(error : any) : void
    {
        if (error.status)
        {
            switch (error.status)
            {
                case 0: // net::ERR_CONNECTION_REFUSED
                    this.router.navigate(['/error']);
                    break;

                case 302:
                    this.router.navigate(['/logout'] );

                    break;
                
                case 400:
                    let errorList : ValueObjectList<Error> = this.buildErrorList(error);

                    for (let error of errorList)
                        this.addErrorMessage(error.description);

                    break;

                case 401:
                    this.router.navigate(['/unauthorized'] );

                    break;

                case 500:
                    this.router.navigate(['/error']);

                    break;

                default:
                    this.addErrorMessage("Error occurred.  Please try again");

                    break;
            }

        }
        else
        {
            this.addErrorMessage("Error occurred.  Please try again");
        }
    }

    protected handleError404Okay(error : any) : void
    {
        if (error.status)
        {
            switch (error.status)
            {
                case 0: // net::ERR_CONNECTION_REFUSED
                    this.router.navigate(['/error']);
                    break;
                
                case 302:
                    this.router.navigate(['/logout'] );

                    break;

                case 400:
                    let errorList : ValueObjectList<Error> = this.buildErrorList(error);

                    for (let error of errorList)
                        this.addErrorMessage(error.description);

                    break;

                case 401:
                    this.router.navigate(['/unauthorized'] );

                    break;

                case 404:
                    // 404 okay - just break
                    break;

                case 500:
                    this.router.navigate(['/error']);
                    
                    break;

                default:
                    this.addErrorMessage("Error occurred.  Please try again");

                    break;
            }
        }
        else
        {
            this.addErrorMessage("Error occurred.  Please try again");
        }
    }


    protected buildErrorList (response : HttpErrorResponse) : ValueObjectList<Error>
    {
        let resultList : ValueObjectList<Error> = new ValueObjectList<Error>();

        resultList.startAt = response.error[ValueObjectList.STARTAT];
        resultList.maxResult = response.error[ValueObjectList.MAXRESULT];
        resultList.total = response.error[ValueObjectList.TOTAL];
        let responseList  = response.error[ValueObjectList.RESULT] as Error[];

        for (let responseData of responseList)
        {
            let object = new Error;
            object.initialize(responseData);

            resultList.push(object);
        }
        
        return resultList;
    }

    public get errorMessages() : Message[] 
    {
        return this.messages;
    }

    public set errorMessages(messages : Message[]) 
    {
        this.messages = messages;
    }

    public scrollToTop() : void
    {
        window.scrollTo(0, 0);
    }

    public isDesktop() : boolean 
    {
        return window.innerWidth > 991;
    }

    public isMobile() : boolean
    {
        return window.innerWidth <= 991;
    }

}