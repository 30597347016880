import { Component, Input } from '@angular/core';
import { UserAccount } from '../user-account/user-account';
import { trigger, state, transition, style, animate } from '@angular/animations';

import { AppComponent } from '../app.component';
import { DashboardComponent } from './dashboard.component';
import { BaseDashboardProfileComponent } from './base-dashboard-profile.component';

@Component({
    selector: 'dashboard-profile',
    templateUrl: './dashboard-profile.component.html',
    styleUrls: ['./dashboard-profile.component.scss' ],
    animations: [
        trigger('menu', [
            state('hiddenAnimated', style({
                height: '0px',
                paddingBottom: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
                overflow: 'visible'
            })),
            state('visible', style({
                opacity: 1,
                'z-index': 100
            })),
            state('hidden', style({
                opacity: 0,
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('visible => hidden', animate('.1s linear')),
            transition('hidden => visible', [style({transform: 'scaleY(0.8)'}), animate('.12s cubic-bezier(0, 0, 0.2, 1)')])
        ])
    ]
})
export class DashboardProfileComponent extends BaseDashboardProfileComponent
{
    @Input() userAccount : UserAccount;
    active: boolean;

    constructor(public dashboard: DashboardComponent, public app: AppComponent) 
    {
        super(dashboard, app);        
    }
}