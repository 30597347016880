import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { ProjectFact } from './project-fact';
import { Project } from '../project/project';

@Injectable()
export class ProjectFactService extends BaseHttpService
{
    private projectFactUrl: string;

    constructor(http: HttpClient, private config: Config)
    {
        super(http);
        this.projectFactUrl = this.config.baseUrl + '/rest/projectfact';
    }

    public retrieve(project: Project, factName : string) : Observable<ProjectFact>
    {
        let url = this.projectFactUrl + `?projectId=${project.projectId}&name=${factName}`;
        return super.baseRetrieveObject(ProjectFact, url);
    }

    public retrieveById(projectId: string, factName : string) : Observable<ProjectFact>
    {
        let url = this.projectFactUrl + `?projectId=${projectId}&name=${factName}`;
        return super.baseRetrieveObject(ProjectFact, url);
    }

}