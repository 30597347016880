import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { Goal } from './goal';
import { Config } from '../config/config';

import { Project } from '../project/project';

@Injectable()
export class GoalService extends BaseHttpService
{
    goalUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.goalUrl = config.baseUrl + "/rest/goal";
    }

    public retrieveByProject(projectId : string, limit : number) : Observable<Goal[]>
    {
        let requestUrl = this.goalUrl + "/project/" + projectId + "/list?limit=" + limit;
        return super.baseRetrieveList(Goal, requestUrl);
    }

    public retrieve(goalId : string) : Observable<Goal>
    {
        let requestUrl = this.goalUrl + "/" + goalId;
        return super.baseRetrieveObject(Goal, requestUrl);
    }
}