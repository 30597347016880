import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { Defect } from './defect';
import { DefectCount } from './defect-count';
import { Project } from '../project/project';
import { Config } from '../config/config';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class DefectService extends BaseHttpService
{
    protected defectUrl : string

    constructor(http: HttpClient, config: Config) 
    {
        super(http);
        this.defectUrl = config.baseUrl + "/rest/defect";
    }

    public retrieve(defectId : string) : Observable<Defect>
    {
        let requestUrl = this.defectUrl + "/" + defectId;
        return super.baseRetrieveObject(Defect, requestUrl);
    }

    public retrieveDefectBacklog(project : Project, closed : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<Defect>>
    {
        let requestUrl = this.defectUrl + "/" + project.projectId + "/subset?closed=" + closed;
        return super.baseRetrieveSubset(Defect, requestUrl, startAt, maxResult);
    }

    public retrieveReleaseBacklog(release : Release) : Observable<Defect[]>
    {
        let requestUrl = this.defectUrl + "/releasebacklog/" + release.releaseId;
        return super.baseRetrieveList(Defect, requestUrl);
    }

    public retrieveEpicBacklog(epic : Epic) : Observable<Defect[]>
    {
        let requestUrl = this.defectUrl + "/epicbacklog/" + epic.epicId;
        return super.baseRetrieveList(Defect, requestUrl);
    }
}
