<ul class="layout-menu">
    <li dashboard-submenu *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
</ul>

<!---
<ul dashboard-submenu [item]="model" root="true" 
    class="ultima-menu ultima-main-menu clearfix" 
    [reset]="reset" visible="true">
</ul>
-->
