import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseFilledDoughnutComponent } from '../../base/component/base-filled-doughnut.component';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { PluralPipe } from '../../pipes/plural.pipe';

import { ContentDetail } from '../../content/content-detail';

import * as moment from 'moment';

@Component({
  selector: 'sprint-points-injected',
  templateUrl: './sprint-points-injected.component.html',
  styleUrls: ['./sprint-points-injected.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class SprintPointsInjectedComponent extends BaseFilledDoughnutComponent implements OnInit 
{ 
    @Input()
    sprint : BaseSprint;

    @Output() click = new EventEmitter<any>();

    sprintScopeFact : SprintFact;
    pointsInjectedFact : SprintFact;
    beginningSprintFact : SprintFact;
    
    constructor( protected router : Router,
                 private sprintFactService : SprintFactService)
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTSCOPE)
            .subscribe(result => this.processSprintScopeFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.POINTSINJECTED)
            .subscribe(result => this.processPointsInjectedFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.BEGINNINGSPRINT)
            .subscribe(result => this.processBeginningSprintFact(result),
                       error => this.handleError404Okay(error) );
    }

    public processSprintScopeFact(result : SprintFact) : void
    {
        this.sprintScopeFact = result;
        this.drawGraph();
    }

    public processPointsInjectedFact(result : SprintFact) : void
    {
        this.pointsInjectedFact = result;
        this.drawGraph();
    }
    
    public processBeginningSprintFact(result : SprintFact) : void
    {
        this.beginningSprintFact = result;
    }
    
    public drawGraph() : void
    {
        if (this.sprintScopeFact == undefined || this.pointsInjectedFact == undefined)
            return;

        this.data = {
            labels : ['Other Scope', 'Points Injected'],
            datasets: [
                {
                    type:'doughnut',
                    label: '',
                    data: [ this.sprintScopeFact.getFactNumber() - this.pointsInjectedFact.getFactNumber(),
                            this.pointsInjectedFact.getFactNumber()
                     ],

                    backgroundColor: [
                        "rgba(0, 0, 256, .40)",
                        "rgba(256, 0, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(256, 0, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2
                    ],
                    hoverBackgroundColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(256, 0, 0, 1)"
                    ],
                    hoverBorderColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(256, 0, 0, 1)"
                    ],

                }
            ]
        };

        this.configureOptions();
    }

    public percentInjected() : number 
    {
        let result : number = 0;

        if (this.sprintScopeFact != undefined && this.pointsInjectedFact != undefined)
        {
            if (this.sprintScopeFact.getFactNumber() > 0)
            {
                result = (this.pointsInjectedFact.getFactNumber() / this.sprintScopeFact.getFactNumber() ) * 100;
            }
        }

        return result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    private get ADDEDTOSPRINT() : string
    {
        return ContentDetail.ADDEDTOSPRINT;
    }

    protected buildDescription() : string
    {
        let result : string = "";

        if (this.pointsInjectedFact != undefined)
        {
            let pluralPipe = new PluralPipe();
            result = pluralPipe.transform(this.pointsInjectedFact.getFactNumber(), "pt");
        }

        return result;
    }
}