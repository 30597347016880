import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';

import { ReleaseFactService } from '../../release-fact/release-fact.service';
import { ReleaseFact } from '../../release-fact/release-fact';
import { Release } from '../../release/release';

import * as moment from 'moment';

@Component({
  selector: 'release-point-placeholder-status',
  templateUrl: './release-point-placeholder-status.component.html',
  styleUrls: ['./release-point-placeholder-status.component.scss' ],
  providers: [ ProjectFactService, ReleaseFactService ]
})

export class ReleasePointPlaceholderStatusComponent extends BaseUIComponent implements OnInit 
{ 
    @Input()
    release : Release;

    numberOfSprints : ReleaseFact;
    placeholderCompletion : ReleaseFact;
    placeholderSprintsRemaining : ReleaseFact;
    placeholderVelocity : ProjectFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private projectFactService: ProjectFactService,
                private releaseFactService: ReleaseFactService) 
    { 
        super(router);

        this.options = {
            indexAxis : 'y',
            plugins: {
                title: {
                    display: false,
                    text: 'Sprints Remaining'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    ngOnInit() : void
    {
        this.projectFactService.retrieve(this.release.project, ProjectFact.PLACEHOLDERVELOCITY)
            .subscribe(result =>this.processProjectPlaceholderVelocity(result),
                        error => this.handleError404Okay (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.PLACEHOLDERSPRINTSREMAINING)
            .subscribe(result =>this.processPlaceholderSprintsRemaining(result),
                       error => this.handleError404Okay (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASENUMBEROFSPRINTS)
            .subscribe(result =>this.processNumberOfSprints(result),
                       error => this.handleError (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.PLACEHOLDERCOMPLETION)
            .subscribe(result =>this.processPlaceholderCompletion(result),
                       error => this.handleError404Okay(error) );
    }

    private processProjectPlaceholderVelocity(result : ProjectFact) : void
    {
        this.placeholderVelocity = result;
        this.buildGraph();
    }

    private processPlaceholderSprintsRemaining(result : ReleaseFact) : void
    {
        // -1 indicated an average velocity of zero.
        if (result.getFactNumber() > -1)
        {
            this.placeholderSprintsRemaining = result;
            this.buildGraph();
        }
    }

    private processNumberOfSprints(result : ReleaseFact) : void
    {
        this.numberOfSprints = result;
        this.buildGraph();
    }

    private processPlaceholderCompletion(result : ReleaseFact) : void
    {
        this.placeholderCompletion = result;
    }

    private buildGraph()
    {
        if (this.placeholderSprintsRemaining == undefined || this.numberOfSprints == undefined)
            return;

        this.data = {
            labels : ['Sprints'],
            datasets: [  
                {
                    type:'bar',
                    label: 'Remaining',
                    backgroundColor: this.BLUETRANSPARENT,
                    hoverBackgroundColor: this.BLUE,
                    borderColor: this.BLUE,
                    borderWidth: 2,
                    data: [ this.placeholderSprintsRemaining.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Completed',
                    backgroundColor: this.ORANGETRANSPARENT,
                    hoverBackgroundColor: this.ORANGE,
                    borderColor: this.ORANGE,
                    borderWidth: 2,
                    data: [ this.numberOfSprints.getFactNumber() ]
                }
            ]
        }
    }
}
