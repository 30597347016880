import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Slider } from 'primeng/slider';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive( { selector: 'p-slider' } )
export class SliderMoveDirective implements OnInit, OnDestroy 
{
  @Input() debounceTime = 1000;
  @Output() singleChange = new EventEmitter();

  private changes = new Subject();
  private subscription: Subscription;

  constructor(private host: Slider) 
  { 
  }

  public ngOnInit() : void
  {
    // Subscribe to change events.
    this.host.onChange.subscribe(() => this.handleHostOnChange() );

    // Collect changes and then submit a single event.
    this.subscription = this.changes.pipe( debounceTime(this.debounceTime) )
      .subscribe(e => this.singleChange.emit(e));
  }

  public ngOnDestroy() : void 
  {
    this.host.onChange.unsubscribe();
    this.subscription.unsubscribe();
  }

  public handleHostOnChange() : void
  {
    this.changes.next();
  }
}