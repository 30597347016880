import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { UserStory } from './user-story';
import { Config } from '../config/config';


@Injectable()
export class UserStoryService extends BaseHttpService
{
    protected userStoryUrl : string

    constructor(http: HttpClient, config: Config) 
    {
        super(http);
        this.userStoryUrl = config.baseUrl + "/rest/userstory";
    }

    public retrieve(userStoryId : string) : Observable<UserStory>
    {
        let requestUrl = this.userStoryUrl + "/" + userStoryId;
        return super.baseRetrieveObject(UserStory, requestUrl);
    }

}
