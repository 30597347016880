import { Component, OnInit, ViewEncapsulation, ViewChild, OnChanges } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';
import { Project } from '../../project/project';

import { LLUtils } from '../../utilities/ll-utils';

import * as _ from 'lodash';

// Internal class to make sorting easy.
class ProjectAverageMaturity {
    projectId : string;
    description : string;
    maturity : number;
}

@Component({
  selector: 'org-scrum-maturity',
  templateUrl: './org-scrum-maturity.component.html',
  styleUrls: ['./org-scrum-maturity.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService ]
})
export class OrgScrumMaturityComponent extends BaseUIComponent implements OnChanges, OnInit 
{
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    projectList : Project[] = [];

    @Input()
    maturityGoal : number = 3.00;
    lastMaturityGoal : number = undefined;

    projectAverageMaturityMap : Map<string, ProjectAverageMaturity> = new Map<string, ProjectAverageMaturity>();

    data: any;
    options: any;

    constructor(protected router : Router,
                protected projectFactService: ProjectFactService) 
    { 
        super(router);

        this.options = {
            indexAxis: 'y',
            scales: { y: { display: true }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Three Sprint Average Maturity'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            tooltips: { position : 'nearest'}
        }

        this.options.onClick = (event, array) => { this.graphClickEvent(event, array) }
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }
    
    public loadData() : void
    {
        let deleteOccurred : boolean = false;
        let maturityGoalChanged : boolean = false;

        if (this.maturityGoal != this.lastMaturityGoal)
            maturityGoalChanged = true;

        // Clean up map - deselect projects if removed.
        for (let projectId of this.projectAverageMaturityMap.keys() )
        {
            let found : boolean = false;

            for (let project of this.projectList)
            {
                if (projectId == project.projectId)
                {
                    found = true;
                    break;
                }
            }

            if (found == false)
            {
                this.projectAverageMaturityMap.delete(projectId);
                deleteOccurred = true;
            }
        }

        // If a delete occurred, be sure to rebuild graph.
        if (deleteOccurred || maturityGoalChanged)
            this.buildGraph();

        for (let project of this.projectList)
        {
            this.projectFactService.retrieve(project, ProjectFact.SCRUMMATURITYAVERAGECLOSEDSPRINTS)
                .subscribe(result => this.processScrumMaturityAverage(project, result),
                           error => this.handleError (error) );
        }
    }

    protected processScrumMaturityAverage(project : Project, result : ProjectFact) : void
    {
        let projectAverageMaturity : ProjectAverageMaturity = new ProjectAverageMaturity();
        projectAverageMaturity.projectId = project.projectId;
        projectAverageMaturity.description = project.description;
        projectAverageMaturity.maturity = LLUtils.round(result.getFactNumber(), 2);

        if (_.isEqual(this.projectAverageMaturityMap.get(project.projectId), projectAverageMaturity) == true)
            return;

        this.projectAverageMaturityMap.set(project.projectId, projectAverageMaturity);
        this.buildGraph();
    }

    protected buildGraph() : void
    {
        let projectAverageMaturity : ProjectAverageMaturity[] = Array.from(this.projectAverageMaturityMap.values() );
        let sortedProjectAverageMaturity : ProjectAverageMaturity[] = projectAverageMaturity.sort((data1, data2) => { return data2.maturity - data1.maturity } );

        this.data = {
            labels : sortedProjectAverageMaturity.map((maturity : ProjectAverageMaturity) => { return maturity.description; } )
        };

        let colorArray : string[] = [];
        let transparentColorArray : string[] = [];

        for (let maturity of sortedProjectAverageMaturity)
        {
            let hexColor : string = LLUtils.uuidToColor(maturity.projectId);
            let color = LLUtils.hexToRgb(hexColor, 1.0);
            let transparentColor = LLUtils.hexToRgb(hexColor, .40);

            colorArray.push(color);
            transparentColorArray.push(transparentColor);
        }

        this.data.datasets = [];
        this.data.datasets.push({
            backgroundColor: transparentColorArray.map((transparentColor : string) => { return transparentColor } ),
            hoverBackgroundColor: colorArray.map((color : string) => { return color } ),
            borderColor: colorArray.map((color : string) => { return color } ),
            borderWidth: 2,
            label: 'Three Sprint Average Maturity',
            data: sortedProjectAverageMaturity.map((maturity : ProjectAverageMaturity) => { return maturity.maturity } )
        });
        
        this.buildMaturityAnnotation();
    }

    public buildMaturityAnnotation() : void
    {
        let enabled = false;

        if (this.projectList.length > 0)
            enabled = true;

        // Update the maturity goal.
        this.lastMaturityGoal = this.maturityGoal;

        // Try to annotate graph with goal.
        this.options['plugins']['annotation'] = {
            annotations: {
              line1: {
                type: 'line',
                display: enabled,
                label: {
                    enabled: enabled,
                    backgroundColor: 'black',
                    borderColor: 'black',
                    borderRadius: 10,
                    borderWidth: 2,
                    content: 'Goal',
                    rotation: 270
                },
                xMin: this.maturityGoal,
                xMax: this.maturityGoal,
                borderColor: this.GREEN,
                borderWidth: 5,
                verticalMode: true
              }
            }
        } 

    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

    public navigate(datasetIndex : number, listIndex : number) : void
    {
       let projectId : string = this.projectList[datasetIndex].projectId;
       this.router.navigate(['/dashboard/project-detail', projectId]);            
    }

    public graphClickEvent(event : MouseEvent, array: any[]) : void 
    {
        if (array.length > 0)
        {
            let datasetIndex : number = array[0].datasetIndex;
            let index : number = array[0].index;
            this.navigate(datasetIndex, index);            
        }
    }
}
