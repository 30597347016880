import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { UserAlertPreference } from './user-alert-preference';

@Injectable()
export class UserAlertPreferenceService extends BaseHttpService
{
    userAlertPreferenceUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.userAlertPreferenceUrl = this.config.baseUrl + '/rest/user-alert-preference';
    }

    public retrieve() : Observable<UserAlertPreference[]>
    {
        let url = this.userAlertPreferenceUrl + "/list";
        return super.baseRetrieveList(UserAlertPreference, url);
    }

    public update(userAlertPreferenceList: UserAlertPreference[]) : Observable<UserAlertPreference[]>
    {
        let url = this.userAlertPreferenceUrl + "/list";
        return super.baseUpdateList(UserAlertPreference, url, userAlertPreferenceList);   
    }
}
