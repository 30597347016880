import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BaseComponent } from '../base/component/base-component.component';


@Component({
  template: ``
})

export class SprintDetailReloadComponent extends BaseComponent
{
    returnUrl : string;
    sprintId : string;

    constructor(protected router:Router, private route: ActivatedRoute) 
    { 
        super(router);

        this.route.params.forEach((params: Params) => {
            this.sprintId = params['sprintId'];
        });

        this.router.navigate(['/dashboard/sprint-detail', this.sprintId]);            
    }
}
