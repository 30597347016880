import { BaseAgileProjectObject } from '../base/value-object/base-agile-project-object';
import { EpicFact } from '../epic-fact/epic-fact';

export class Epic extends BaseAgileProjectObject
{
    epicId : string;
    formattedId : string;
    name : string;
    description : string;
    statusCategory : string;
    storyPoints : number;

    epicFactList : EpicFact[] = [];

    public initialize(epic : Epic) : void
    {
        super.initialize(epic);
                
        this.epicId = epic.epicId;
        this.formattedId = epic.formattedId;
        this.name = epic.name;
        this.description = epic.description;
        this.statusCategory = epic.statusCategory;
        this.storyPoints = epic.storyPoints;

        if (epic.epicFactList != undefined)
            this.epicFactList = this.initializeList(EpicFact, epic.epicFactList);
        else   
            this.epicFactList = undefined;
    }

    public get shortName() : string
    {
        let result : string = this.name;

        if (this.name != undefined && this.name.length > 40)
            result = this.name.substr(0, 40);

        return result;
    }

    public get shortDescription() : string
    {
        let result : string = this.description;

        if (this.description != undefined && this.description.length > 40)
            result = this.description.substr(0, 40);

        return result;
    }

    public getFact(factName : string) : EpicFact
    {
        let result : EpicFact = undefined;

        if (this.epicFactList != undefined)
        {
            for (let epicFact of this.epicFactList)
            {
                if (epicFact.name == factName)
                {
                    result = epicFact;
                    break;
                }
            }
        }

        return result;
    }


    // Convenience methods for use in tables, etc.
    public get status() : string
    {
        let result : string = "";

        if (this.getFact(EpicFact.ACTIVEEPIC) != undefined && 
            this.getFact(EpicFact.ACTIVEEPIC).getFactBoolean() == true)
            result = "ACTIVE";

        if (this.getFact(EpicFact.INACTIVEEPIC) != undefined && 
            this.getFact(EpicFact.INACTIVEEPIC).getFactBoolean() == true)
            result = "INACTIVE";

        if (this.getFact(EpicFact.COMPLETEDEPIC) != undefined && 
            this.getFact(EpicFact.COMPLETEDEPIC).getFactBoolean() == true)
            result = "COMPLETED";

        if (this.getFact(EpicFact.FUTUREEPIC) != undefined && 
            this.getFact(EpicFact.FUTUREEPIC).getFactBoolean() == true)
            result = "FUTURE";

        return result;
    }

    public get completed() : boolean
    {
        let result : boolean = false;

        if (this.getFact(EpicFact.COMPLETEDEPIC) != undefined && 
            this.getFact(EpicFact.COMPLETEDEPIC).getFactBoolean() == true)
            result = true;

        return result;
    }

    public get scope() : number
    {
        let result : number = 0;

        if (this.getFact(EpicFact.EPICSCOPE) != undefined)
            result = this.getFact(EpicFact.EPICSCOPE).getFactNumber();

        return result;
    }

    public get pointsCompleted() : number
    {
        let result : number = 0;

        if (this.getFact(EpicFact.EPICPOINTSCOMPLETED) != undefined)
            result = this.getFact(EpicFact.EPICPOINTSCOMPLETED).getFactNumber();

        return result;
    }

    public get pointsCompletedCurrentPI() : number
    {
        let result : number = 0;

        if (this.getFact(EpicFact.EPICPOINTSCOMPLETEDCURRENTQUARTERPI) != undefined)
            result = this.getFact(EpicFact.EPICPOINTSCOMPLETEDCURRENTQUARTERPI).getFactNumber();

        return result;
    }

    public get pointsCompletedLastPI() : number
    {
        let result : number = 0;

        if (this.getFact(EpicFact.EPICPOINTSCOMPLETEDLASTQUARTERPI) != undefined)
            result = this.getFact(EpicFact.EPICPOINTSCOMPLETEDLASTQUARTERPI).getFactNumber();

        return result;
    }

    public get percentComplete() : number
    {
        let result : number = 0;

        let scope = this.scope;
        let completed = this.pointsCompleted;

        if (scope != 0)
            result = (completed / scope) * 100.00

        return Math.round(result);
    }

    public get epicUnsized() : boolean
    {
        let result : boolean = false;

        if (this.getFact(EpicFact.EPICSTORIESUNSIZED) != undefined && this.getFact(EpicFact.EPICSTORIESUNSIZED).getFactNumber() > 0)
            result = true;

        return result;
    }
}
