import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { ContentService } from '../../content/content.service';
import { Content } from '../../content/content';
import { OrganizationService } from '../../organization/organization.service';
import { Organization } from '../../organization/organization';

import { BaseComponent } from '../../base/component/base-component.component';

@Injectable()
@Component({
  selector: 'content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css'],
  providers: [ ContentService, OrganizationService, ConfirmationService ]
})
export class ContentListComponent extends BaseComponent implements OnInit
{ 
    contentList : Content[];
    organizationList : Organization[];

    selectedContent : Content;

    constructor(protected router:Router,
                protected confirmationService : ConfirmationService,
                protected contentService: ContentService, 
                protected organizationService : OrganizationService ) 
    {
      super(router);
    }

    ngOnInit() : void
    {
        super.clearErrorMessages();
        this.retrieveList();
    }

    public retrieveList() : void
    {
        this.contentService.retrieveAll()
          .subscribe(result => this.processContentList(result) );

        this.organizationService.retrieveAll()
          .subscribe(result => this.processOrganizationList(result) );
    }

    private processContentList(contentList : Content[]): void
    {
      this.contentList = contentList
      this.selectedContent = null;
    }

    public processOrganizationList(result : Organization[] ) : void
    {
      this.organizationList = result;
    }

    public onAdd() : void
    {
      this.router.navigate(['/dashboard/admin/content-add']);
    }

    public onUpdate() : void
    {
      this.router.navigate(['/dashboard/admin/content-update', this.selectedContent.contentId]);
    }

    public onView() : void
    {
      this.router.navigate(['/dashboard/admin/content-view', this.selectedContent.contentId]);
    }

    public onDelete() : void
    {
      this.confirmationService.confirm(
        { message: 'Are you sure you wish to delete the selected row?',
            accept: () => 
            {
              this.deleteContent(this.selectedContent);
            }
        });
    }

    public onCancel() : void
    {
       this.router.navigate(['/dashboard']);
    }
    
    private deleteContent(content : Content ) : void
    {
        this.contentService.delete(content)
          .subscribe(result => this.deleteSuccessful(),
                     error => this.handleDeleteError(error) );
    }

    private deleteSuccessful()
    {
      this.clearErrorMessages();
      this.addInfoMessage("Delete successful!");
      this.retrieveList();
    }

    private handleDeleteError(error: any) : void
    {
      this.clearErrorMessages();
      this.addErrorMessage("Error Deleting Content.  Please try again.");
    }

    public getOrganizationName(pOrgId : string) : string
    {
      let result : string = "[ Default ]";

      for (let organization of this.organizationList)
      {
        if (organization.orgId == pOrgId)
        {
          result = organization.name;
          break;
        }
      }

      return result;
    }
}
