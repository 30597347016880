import { BaseValueObject } from '../base/value-object/base-value-object';

export class Preference extends BaseValueObject
{
    preferenceId : number
	preferenceDescription : string

    public static readonly DAILY_SUMMARY : number = 120;
    public static readonly DISPLAYMATURITYRATING : number = 130;
    
    public initialize(preference : Preference)
    {
        super.initialize(preference);
        
        this.preferenceId = preference.preferenceId;
        this.preferenceDescription = preference.preferenceDescription;
    }

    static createPreference(preferenceId : number) : Preference
    {
        let preference : Preference = new Preference();

        switch (preferenceId)
        {
            case Preference.DAILY_SUMMARY:
                preference.preferenceId = Preference.DAILY_SUMMARY;
                preference.preferenceDescription = "Daily Summary";

                break;

            case Preference.DISPLAYMATURITYRATING:
                preference.preferenceId = Preference.DISPLAYMATURITYRATING;
                preference.preferenceDescription = "Display Maturity Rating";

                break;
    
        }

        return preference;
    }

}