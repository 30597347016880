import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { SprintFact } from '../../sprint-fact/sprint-fact';

import { Sprint } from '../../sprint/sprint';
import { ContentDetail } from '../../content/content-detail';

@Component({
  selector: 'sprint-agile-story',
  templateUrl: './sprint-agile-story.component.html',
  styleUrls: ['./sprint-agile-story.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class SprintAgileStoryComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    sprint : Sprint;

    agileStoryCount : SprintFact;
    totalStoryCount : SprintFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private sprintFactService : SprintFactService) 
    { 
        super(router); 

        this.options = {
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Sprint Agile Story'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    public ngOnInit() : void
    {
        this.retrieveData();
    }

    public retrieveData()
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTSTORYCOUNT)
            .subscribe(result => this.processSprintStoryCount(result),
                       error => this.handleError(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.STORYAGILEFORMCOUNT)
            .subscribe(result => this.processStoryAgileFormCount(result),
                       error => this.handleError(error) );
    }

    public processSprintStoryCount(result : SprintFact) : void
    {
        this.totalStoryCount = result;
        this.buildGraph();
    }

    public processStoryAgileFormCount(result : SprintFact) : void
    {
        this.agileStoryCount = result;
        this.buildGraph();
    }

    public buildGraph() : void
    {
        if (this.totalStoryCount == undefined || this.agileStoryCount == undefined)
            return;

        this.data = {
            labels : ['Good Stories', 'Check Story Form'],
            datasets: [
                {
                    type:'pie',
                    animations: false,
                    label: '',
                    data: [ this.agileStoryCount.getFactNumber(),
                            this.totalStoryCount.getFactNumber() - this.agileStoryCount.getFactNumber()
                     ],

                    backgroundColor: [
                        "rgba(0, 256, 0, .40)",
                        "rgba(256, 0, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(0, 256, 0, 1)",
                        "rgba(256, 0, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2
                    ],
                    hoverBackgroundColor: [
                        "rgba(0, 256, 0, 1)",
                        "rgba(256, 0, 0, 1)"
                    ]
                }
            ]
        }
    }

    public percentGoodStories() : number
    {
        let result : number = 0;

        if (this.agileStoryCount != undefined && this.totalStoryCount != undefined)
        {
            if (this.totalStoryCount.getFactNumber() != 0)
                result = Math.ceil(this.agileStoryCount.getFactNumber() / this.totalStoryCount.getFactNumber() * 100);
        }

        return result;
    }

    public get GOODSTORIES() : string
    {
        return ContentDetail.GOODSTORIES;
    }
}
