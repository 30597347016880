import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'floor' })
export class FloorPipe implements PipeTransform 
{
    transform(value: number): number 
    {
        let result : number = Math.floor(value);
        return result;
  }
}