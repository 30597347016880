<p-card class="mini-velocity-overview">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Average Velocity&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="velocityTrendFact">
                    <i *ngIf="velocityTrendFact.getFactString() == 'up'" class="material-icons" pTooltip="Trending Up" tooltipPosition="left">trending_up</i>
                    <i *ngIf="velocityTrendFact.getFactString() == 'down'" class="material-icons" pTooltip="Trending Down" tooltipPosition="left">trending_down</i>
                    <i *ngIf="velocityTrendFact.getFactString() == 'neutral'" class="material-icons" pTooltip="Trending Flat" tooltipPosition="left">trending_flat</i>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="averageVelocityFact" pTooltip="Three Sprint Average" tooltipPosition="left">{{averageVelocityFact.getFactNumber() | round }} points / sprint</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>

<zoom-graph #zoomGraph type="bar" [sourceData]="data" [sourceOptions]="options"></zoom-graph>