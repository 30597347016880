import { BaseSprint } from '../base/value-object/base-sprint';
import { Project } from '../project/project';
import { BacklogItem } from '../backlog-item/backlog-item';
import { SprintFact } from '../sprint-fact/sprint-fact';

import * as moment from 'moment';

export class SprintDetail extends BaseSprint
{
    backlogItemList : BacklogItem[];

    initialize(sprintDetail : SprintDetail) : void
    {
        super.initialize(sprintDetail);
        this.backlogItemList = this.initializeList(BacklogItem, sprintDetail.backlogItemList);
    }

    public isFutureSprint() : boolean
    {
    	let result : boolean = false;
    	
    	if (this.sprintBeginDate == null || this.sprintEndDate == null)
    		result = true;
    	
    	return result;
    }

    public get completedStoryPoints() : number
    {
    	let result : number = 0.00;
    	
    	if (this.backlogItemList.length > 0)
    	{
        	for (let backlogItem of this.backlogItemList)
        	{
        		if (backlogItem.isCompleted() )
        		{
        			if (backlogItem.storyPoints != null)
        				result = result + backlogItem.storyPoints;
        		}
        	}
    	}
        
    	return result;	
    }

    public get totalStoryPoints() : number
    {
    	let result : number = 0.00;
    	
    	if (this.backlogItemList.length > 0)
    	{
        	for (let backlogItem of this.backlogItemList)
        	{
                if (backlogItem.storyPoints != null)
                    result = result + backlogItem.storyPoints;
        	}
    	}
        
    	return result;	
    }

    public get defectList() : Array<BacklogItem>
    {
        let result : Array<BacklogItem> = new Array<BacklogItem>();

        for (let backlogItem of this.backlogItemList)
        {
            if (backlogItem.isDefect() == true)
                result.push(backlogItem);
        }

        return result;
    }
}
