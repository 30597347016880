<p-card class="release-point-placeholder-status">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Placeholder Completion</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="placeholderVelocity" pTooltip="Placeholder Velocity" tooltipPosition="left">
                    {{placeholderVelocity.getFactNumber() | round }} pts/sprint
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="placeholderCompletion">
                {{ placeholderCompletion.getFactTimestamp() | dateFormat }}
            </span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="numberOfSprints && placeholderSprintsRemaining" class="chart-style">
            <!--
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
            -->
        </div>    
    </span>
</p-card>