import { BaseAgileProjectObject } from './base-agile-project-object';
import { Release } from '../../release/release';
import { Epic } from '../../epic/epic';
import { Sprint } from '../../sprint/sprint';
import { BaseFactObject } from './base-fact-object';

import * as moment from 'moment';

export abstract class BaseBacklogItemObject extends BaseAgileProjectObject
{
    public formattedId : string;
    public epic : Epic;
    public releases : Release[];
    public sprint : Sprint;
    public status : string;
    public statusCategory : string;
    public priorityRank : string;
    public lexoRank : string;
    public title : string;
    public description : string;
    public acceptanceCriteria : string;
    public storyPoints : number;
    public sourceSystemStoryPoints : number;
    public sourceSystemDateCreated : Date;
    public sourceSystemDateModified : Date;
    public objectType : string;
    public unfinished : boolean;
    public altSourceSystemKey : string;

    public backlogItemFactList : BaseFactObject[];

    // Priority constants.
	public static readonly PRIORITYHIGHEST : string = "priorityHighest";
	public static readonly PRIORITYHIGH : string = "priorityHigh";
	public static readonly PRIORITYMEDIUM : string = "priorityMedium";
	public static readonly PRIORITYLOW : string = "priorityLow";
	public static readonly PRIORITYLOWEST : string = "priorityLowest";
	public static readonly PRIORITYUNKNOWN : string = "priorityUnknown";

    public static readonly DONE : string = "done";

    // Supported object types.
    public static readonly USERSTORY : string = "story";
    public static readonly DEFECT : string = "defect";
    public static readonly CHORE : string = "chore";
    public static readonly SPIKE : string = "spike";
    public static readonly DEPENDENCY : string = "dependency";
    
    public initialize(baseBacklogItem : BaseBacklogItemObject) : void
    {
        if (baseBacklogItem == null)
            return;
        
        super.initialize(baseBacklogItem);

        this.formattedId = baseBacklogItem.formattedId;
        this.status = baseBacklogItem.status;
        this.statusCategory = baseBacklogItem.statusCategory;
        this.priorityRank = baseBacklogItem.priorityRank;
        this.lexoRank = baseBacklogItem.lexoRank;
        this.title = baseBacklogItem.title;
        this.description = baseBacklogItem.description;
        this.acceptanceCriteria = baseBacklogItem.acceptanceCriteria;
        this.storyPoints = baseBacklogItem.storyPoints;
        this.sourceSystemStoryPoints = baseBacklogItem.sourceSystemStoryPoints;
        this.sourceSystemDateCreated = moment(baseBacklogItem.sourceSystemDateCreated).toDate();
        this.sourceSystemDateModified = moment(baseBacklogItem.sourceSystemDateModified).toDate();
        this.objectType = baseBacklogItem.objectType;
        this.unfinished = baseBacklogItem.unfinished;
        this.altSourceSystemKey = baseBacklogItem.altSourceSystemKey;
        
        if (baseBacklogItem.releases != null)
            this.releases = this.initializeList(Release, baseBacklogItem.releases);
        else
            this.releases = undefined;

        if (baseBacklogItem.epic != null)
            this.epic = this.initializeObject(Epic, baseBacklogItem.epic);
        else
            this.epic = undefined;

        if (baseBacklogItem.sprint != null)
            this.sprint = this.initializeObject(Sprint, baseBacklogItem.sprint);
        else
            this.sprint = undefined;

        if (baseBacklogItem.backlogItemFactList != null)
            this.backlogItemFactList = this.initializeList(BaseFactObject, baseBacklogItem.backlogItemFactList);
        else   
            this.backlogItemFactList = undefined;

    }

    public isCompleted() : boolean
    {
        let result : boolean = false;

        if (this.statusCategory.toLowerCase() === BaseBacklogItemObject.DONE)
            result = true;

        return result;
    }

    public isUserStory() : boolean
	{
		let result : boolean = false;
		
		if (this.objectType == BaseBacklogItemObject.USERSTORY)
			result = true;
		
		return result;
	}

	public isDefect() : boolean
	{
		let result : boolean = false;
		
		if (this.objectType == BaseBacklogItemObject.DEFECT)
			result = true;
		
		return result;
	}	

	public isChore() : boolean
	{
		let result : boolean = false;
		
		if (this.objectType == BaseBacklogItemObject.CHORE)
			result = true;
		
		return result;
	}	

	public isSpike() : boolean
	{
		let result : boolean = false;
		
		if (this.objectType == BaseBacklogItemObject.SPIKE)
			result = true;
		
		return result;
	}	

	public isDependency() : boolean
	{
		let result : boolean = false;
		
		if (this.objectType == BaseBacklogItemObject.DEPENDENCY)
			result = true;
		
		return result;
	}	

    public getFact(factName : string) : BaseFactObject
    {
        let result : BaseFactObject = null;

        if (this.backlogItemFactList != null)
        {
            for (let backlogItemFact of this.backlogItemFactList)
            {
                if (backlogItemFact.name == factName)
                {
                    result = backlogItemFact;
                    break;
                }
            }
        }

        return result;
    }
    
}