import { Component, OnInit, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { ProjectFact } from '../../project-fact/project-fact';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';

import * as moment from 'moment';

@Component({
  selector: 'sprint-velocity',
  templateUrl: './sprint-velocity.component.html',
  styleUrls: ['./sprint-velocity.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService, SprintFactService ]
})

export class SprintVelocityComponent extends BaseSprintTimeSeriesComponent implements OnInit 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    sprint : BaseSprint;

    @Output() click = new EventEmitter<any>();

    averageVelocityFact : SprintFact;
    velocityTrendFact : SprintFact;

    projectAverageVelocityFact : ProjectFact;

    constructor( protected router : Router,
                 private projectFactService : ProjectFactService,
                 private sprintFactService : SprintFactService,
                 private timeSeriesService : TimeSeriesService) 
    { 
        super(router);
    }

    ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.sprint.project.description} : Velocity By Sprint`;

        this.timeSeriesService.retrieveBySprint(this.sprint.project.projectId, this.sprint.sprintId, TimeSeries.PROJECTVELOCITY, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    
        this.projectFactService.retrieve(this.sprint.project, ProjectFact.AVERAGEVELOCITY)
            .subscribe(result =>this.processProjectAverageVelocityFact(result),
                    error => this.handleError404Okay(error) );
           
        this.sprintFactService.retrieve(this.sprint, SprintFact.AVERAGEVELOCITY)
            .subscribe(result =>this.processAverageVelocityFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.VELOCITYTREND)
            .subscribe(result =>this.processVelocityTrendFact(result),
                       error => this.handleError404Okay(error) );
    }

    processTimeSeries(result: TimeSeries)
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Velocity',
                    backgroundColor: this.GREENTRANSPARENT,
                    borderColor: this.GREEN,
                    hoverBackgroundColor: this.GREEN,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("velocity"); } )
                }
            ]
        }
    }

    processAverageVelocityFact(result : SprintFact )
    {
        this.averageVelocityFact = result;
    }

    processVelocityTrendFact(result : SprintFact )
    {
        this.velocityTrendFact = result;
    }

    private processProjectAverageVelocityFact(result : ProjectFact) : void
    {
        this.projectAverageVelocityFact = result;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
    
}