import { Component, OnInit, OnChanges, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'commitment-attainment-timeline',
  templateUrl: './commitment-attainment-timeline.component.html',
  styleUrls: ['./commitment-attainment-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class CommitmentAttainmentTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    @Output() click = new EventEmitter<any>();

    commitmentAttainmentAverageFact : ProjectFact;
    commitmentAttainmentTrendFact : ProjectFact;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router); 
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Commitment Attainment %`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.COMMITMENTATTAINMENT, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.COMMITMENTATTAINMENTAVERAGE)
            .subscribe(result => this.processCommitmentAttainmentAverage(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.COMMITMENTATTAINMENTTREND)
            .subscribe(result => this.processCommitmentAttainmentTrend(result),
                       error => this.handleError404Okay(error) );
    }

    private processCommitmentAttainmentAverage(result : ProjectFact) : void
    {
        if (_.isEqual(this.commitmentAttainmentAverageFact, result) == false)
            this.commitmentAttainmentAverageFact = result;
    }

    private processCommitmentAttainmentTrend(result : ProjectFact) : void
    {
        if (_.isEqual(this.commitmentAttainmentTrendFact, result) == false)
            this.commitmentAttainmentTrendFact = result;
    }

    private processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type: 'line',
                        label: 'Percent Attainment',
                        backgroundColor: this.GREENTRANSPARENT,
                        pointHoverBackgroundColor: this.GREEN,
                        borderColor: this.GREEN,
                        borderWidth: 2,
                        fill: true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("commitmentAttainment") * 100); } )
                    }
                ]
            }
        }
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

}