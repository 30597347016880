<div class="grid card card-w-title">
    <h3 class="col-12">Organzation Configuration</h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="col-3">Company Logo File:</div>
        <div class="col-9">
            <p-fileUpload id="companyLogoFile" #companyLogFileTag customUpload="true" (uploadHandler)="uploadHandler($event)" [fileLimit]="1" accept="image/*" auto="true" mode="basic"></p-fileUpload>
        </div>
        <div *ngIf="orgImage.orgImage != undefined" class="col-12" style="background-color:#0D47A1">
            <img *ngIf="orgImage.orgImage != undefined" [src]="getImage()" width="180" height="40" />
        </div>
    </div>

    <div class="grid col-12 sm:col-12 md:col-12">
        <div class="col-12 sm:col-12 md:col-3">
            <button pButton class="col-12" id="resetButton" #resetButtonTag label="Reset" (click)="onReset()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-3">
            <button pButton class="col-12" id="saveButton" #saveButtonTag label="Save" (click)="onSave()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-3">
            <button pButton class="col-12" id="deleteButton" #deleteButtonTag label="Delete" (click)="onDelete()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-3">
            <button pButton class="col-12 secondary" id="cancelButton" #cancelButtonTag label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
