import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { Zipcode } from './zipcode';
import { Config } from '../config/config';


@Injectable()
export class ZipcodeService extends BaseHttpService
{
    zipcodeUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.zipcodeUrl = config.baseUrl + "/rest/zipcode";
    }

    public retrieve(zipcode : string, stateId: string) : Observable<Zipcode>
    {
        let requestUrl = this.zipcodeUrl + "/" + zipcode + "/" + stateId;
        return super.baseRetrieveObject(Zipcode, requestUrl);
    }
}