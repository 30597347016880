import * as moment from 'moment';

export class DateUtils {
    
    public static calcBusinessDays(startDate : Date, endDate : Date) : number 
    { 
        var day = moment(startDate);
        let businessDays : number = 0;
  
        while (day.isBefore(endDate,'day') ) 
        {
          if (day.day()!=0 && day.day()!=6) 
            businessDays += 1;

          day.add(1,'d');
        }

        return businessDays;
    }

    public static isDateAfter(startDate : Date, endDate : Date) : boolean
    {
      let result : boolean = false;

      var date = moment(endDate);
      
      result = date.isAfter(startDate);
      
      return result;
    }

    public static currentQuarter() : string
    {
      let result = `${moment().year()}-${moment().quarter()}`;
      return result;
    }
}