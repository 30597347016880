import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { SprintFact } from '../../sprint-fact/sprint-fact';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'cost-per-point-timeline',
  templateUrl: './cost-per-point-timeline.component.html',
  styleUrls: ['./cost-per-point-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class CostPerPointTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    costPerPointFact : ProjectFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router); 
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Cost Per Story Point`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTCOSTPERPOINT, 10)
            .subscribe(result => this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.COSTPERPOINT)
            .subscribe(result => this.processCostPerPoint(result),
                       error => this.handleError404Okay(error) );
    }

    private processCostPerPoint(result : ProjectFact) : void
    {
        if (_.isEqual(this.costPerPointFact, result) == false)
            this.costPerPointFact = result;
    }

    public processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       
            
            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type: 'bar',
                        label: 'Cost/Point',
                        backgroundColor: this.ORANGETRANSPARENT,
                        hoverBackgroundColor: this.ORANGE,
                        borderColor: this.ORANGE,
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber(SprintFact.COSTPERPOINT) ); } )
                    },
                    {
                        type:'line',
                        label: 'Three Sprint Average',
                        data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber(SprintFact.AVERAGECOSTPERPOINT)); } )
                    }
                        
                ]
            }
        }
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

}