import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';

import { Router } from '@angular/router';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { SprintFact } from '../../sprint-fact/sprint-fact';

import { ContentDetail } from '../../content/content-detail';

import { BaseComponent } from '../../base/component/base-component.component';

import * as moment from 'moment';

@Component({
  selector: 'points-injected',
  templateUrl: './points-injected.component.html',
  styleUrls: ['./points-injected.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class PointsInjectedComponent extends BaseComponent implements OnInit { 

    @Input()
    sprint : BaseSprint;

    sprintOverloaded : SprintFact;
    sprintCommitment : SprintFact;
    sprintPointsInjected : SprintFact;
    sprintScope : SprintFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private sprintFactService : SprintFactService) 
    { 
        super(router);

        this.options = {
            indexAxis: 'y',
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true }
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Sprint Points Injected'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    ngOnInit() : void
    {
        this.retrieveData();
    }

    retrieveData() : void
    {
        this.sprintCommitment = undefined;
        this.sprintScope = undefined;
        this.sprintPointsInjected = undefined;

        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTCOMMITMENT)
            .subscribe(result => this.processSprintCommitment(result),       
                       error => super.handleError(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.POINTSINJECTED)
            .subscribe(result => this.processSprintPointsInjected(result),
                       error => this.handleError(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTSCOPE)
            .subscribe(result => this.processSprintScope(result),
                       error => this.handleError(error) );

    }
    
    processSprintCommitment(result : SprintFact) : void 
    {
        this.sprintCommitment = result; 
        this.drawGraph();
    }

    processSprintPointsInjected(result : SprintFact) : void
    {
        this.sprintPointsInjected = result; 
        this.drawGraph();      
    }

    processSprintScope(result : SprintFact) : void
    {
        this.sprintScope = result; 
        this.drawGraph();      
    }

    drawGraph()
    {
        if (this.sprintCommitment == undefined || this.sprintPointsInjected == undefined || this.sprintScope == undefined)
            return;

        this.data = {
            labels : [ moment(this.sprint.sprintEndDate).format("MMM Do YYYY") ],
            datasets: [
                {
                    type:'bar',
                    label: 'Points Injected',
//                    backgroundColor: '#FF0000',
                    backgroundColor: 'rgba(255, 0, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 0, 0, 1)',
                    borderColor: 'rgba(255, 0, 0, 1)',
                    borderWidth: 2,
                    data: [ this.sprintPointsInjected.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Commitment',
//                    backgroundColor: '#FF9B00',
                    backgroundColor: 'rgba(255, 155, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 155, 0, 1)',
                    borderColor: 'rgba(255, 155, 0, 1)',
                    borderWidth: 2,
                    data: [ this.sprintCommitment.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Sprint Total',
//                    backgroundColor: '#42A5F5',
                    backgroundColor: 'rgba(66, 165, 245, .40)',
                    hoverBackgroundColor: 'rgba(66, 165, 245, 1)',
                    borderColor: 'rgba(66, 165, 245, 1)',
                    borderWidth: 2,
                    data: [ this.sprintScope.getFactNumber() ]
                },
                
            ]
        }
    }

    public get percentInjected() : number
    {
        let result : number = 0;

        if (this.sprintPointsInjected != undefined && this.sprintScope != undefined)
        {
            if (this.sprintScope.getFactNumber() != 0)
                result = (this.sprintPointsInjected.getFactNumber() / this.sprintScope.getFactNumber() ) * 100; 
        }
        
        return result;
    }

    private get ADDEDTOSPRINT() : string
    {
        return ContentDetail.ADDEDTOSPRINT;
    }
}
