import { Component, Input, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { Project } from '../project/project';

@Component({
    selector: 'dashboard-menu',
    templateUrl: './dashboard-menu.component.html',
    styleUrls: ['./dashboard-menu.component.scss'],
    encapsulation : ViewEncapsulation.None
})
export class DashboardMenuComponent implements OnInit, OnChanges 
{
    @Input() reset: boolean;
    @Input() projectList : Project[];

    model: any[];

    constructor(public dashboard:DashboardComponent)
    {
    }
    
    public ngOnInit() 
    {
        this.init();
    }

    public ngOnChanges() 
    {
        this.init();
    }

    private init() {

        this.model = [
            { 
              // Consider added organization level here.
              items: [
                { label: 'Dashboard',   
                  icon: 'ui-icon-dashboard', 
                  routerLink: ['/dashboard'],
                  visible: true                   
                }
              ],
            }
        ];

        for (let project of this.projectList )
            this.model[0].items.push(this.buildMenu(project) );
    }

    private buildMenu(project : Project ) : any
    {
        var result = {
            label : this.getProjectDescription(project.description),
            routerLink: ['/dashboard/project-detail', project.projectId],
            icon: 'ui-icon-group',
            visible: true,            
            items: [
                { label : 'Releases', routerLink:['/dashboard/release-detail-summary', project.projectId], icon:'ui-icon-event-available', visible: true },
                { label : 'Epics', routerLink:['/dashboard/epic-detail-summary', project.projectId], icon:'ui-icon-explicit', visible: true  },
                { label : 'Sprints', routerLink:['/dashboard/sprint-detail-summary', project.projectId], icon:'ui-icon-equalizer', visible: true  },
                { label : 'Product Backlog', routerLink:['/dashboard/product-backlog', project.projectId], icon:'ui-icon-view-list', visible: true  },
                { label : 'Defect Backlog', routerLink:['/dashboard/defect-backlog', project.projectId], icon:'ui-icon-bug-report', visible: true  },
                { label : 'Alerts', routerLink:['/dashboard/app-alert-list', project.projectId], icon:'ui-icon-warning', visible: true  },
            ]        
            
        }

        // Make sure user can see the team cost link
        if (this.dashboard.userAccount.isFinAdmin() )
            result.items.push( { label : 'Team Cost', routerLink:['/dashboard/project-team-cost-list', project.projectId], icon:'ui-icon-monetization-on', visible: true } );

        return result;
    }

    private getProjectDescription(description : string) : string 
    {
/*
        let result : string = '';

        if (description != undefined)
            result = description.substr(0, 23);

        return result;
*/
        return description;
    }
}

