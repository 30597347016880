import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { Dependency } from './dependency';
import { Project } from '../project/project';
import { Config } from '../config/config';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class DependencyService extends BaseHttpService
{
    protected dependencyUrl : string

    constructor(http: HttpClient, config: Config) 
    {
        super(http);
        this.dependencyUrl = config.baseUrl + "/rest/dependency";
    }

    public retrieve(dependencyId : string) : Observable<Dependency>
    {
        let requestUrl = this.dependencyUrl + "/" + dependencyId;
        return super.baseRetrieveObject(Dependency, requestUrl);
    }

    public retrieveDependencyBacklog(project : Project, closed : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<Dependency>>
    {
        let requestUrl = this.dependencyUrl + "/" + project.projectId + "/subset?closed=" + closed;
        return super.baseRetrieveSubset(Dependency, requestUrl, startAt, maxResult);
    }

    public retrieveReleaseBacklog(release : Release) : Observable<Dependency[]>
    {
        let requestUrl = this.dependencyUrl + "/releasebacklog/" + release.releaseId;
        return super.baseRetrieveList(Dependency, requestUrl);
    }

    public retrieveEpicBacklog(epic : Epic) : Observable<Dependency[]>
    {
        let requestUrl = this.dependencyUrl + "/epicbacklog/" + epic.epicId;
        return super.baseRetrieveList(Dependency, requestUrl);
    }
}
