import { BaseFactObject } from '../base/value-object/base-fact-object';

export class SpikeFact extends BaseFactObject
{
	// SPIKE FACT CONSTANTS
	public static readonly SPRINTSWORKEDON : string = "spikeSprintsWorkedOn";
	public static readonly POINTSONSPIKE : string = "pointsOnSpike";
	public static readonly BLOCKED : string = "spikeBlocked";
	public static readonly POINTSINRANGE : string = "spikePointsInRange";
	public static readonly OPENSPIKECLOSEDSPRINT : string = "openSpikeClosedSprint";

    spikeFactId : string;
	spikeId : string;
    projectId : string;

    public initialize(spikeFact : SpikeFact) : void
    {
        super.initialize(spikeFact);
        this.spikeFactId = spikeFact.spikeFactId;
        this.spikeId = spikeFact.spikeId;
        this.projectId = spikeFact.projectId;
    }
}