<p-table *ngIf="sprintList" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="sprintId"
    [rowHover]="true" [value]="sprintList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
    (onLazyLoad)="loadData($event)">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col><!-- description -->
                <col style="width:150px"><!-- Sprint Begin -->
                <col style="width:150px"><!-- Sprint End -->
                <col style="width:150px"><!-- Duration -->
                <col style="width:150px"><!-- Points -->
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>
                    Description
                </th>
                <th>
                    Sprint Begin
                </th>
                <th>
                    Sprint End
                </th>
                <th>
                    Duration
                </th>
                <th>
                    Points
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-sprint>
            <tr [pSelectableRow]="rowData">
                <td>
                    <div class="sprint-center">
                        <a [routerLink]="['/dashboard/sprint-detail', sprint.sprintId]">{{sprint.title}}</a>
                        <span *ngIf="sprint.isFutureSprint() == false && sprintCommitmentMet(sprint) == false" pTooltip="Commitment Missed!">
                            <a [routerLink]="['/dashboard/content-detail/key', SPRINTCOMMITMENT]" class="sprint-commitment">
                                <i class="material-icons">cancel</i>
                            </a>
                        </span>
                        <span *ngIf="sprint.isFutureSprint() == false && sprintDurationChanged(sprint) == true" pTooltip="Duration Changed!">
                            <a [routerLink]="['/dashboard/content-detail/key', SPRINTCADENCE]" class="sprint-duration-changed">
                                <i class="material-icons">restore</i>
                            </a>
                        </span>
                        <span *ngIf="sprint.isFutureSprint() == false && sprintStartDayChanged(sprint) == true" pTooltip="Start Day Changed!">
                            <a [routerLink]="['/dashboard/content-detail/key', SPRINTCADENCE]" class="sprint-start-day-changed">
                                <i class="material-icons">event_busy</i>
                            </a>
                        </span>
                    </div>
                </td>
                <td>
                    <div *ngIf="sprint.sourceSystemSprintBeginDate">{{sprint.sourceSystemSprintBeginDate | dateFormat }}</div>
                </td>
                <td>
                    <div *ngIf="sprint.sourceSystemSprintEndDate">{{sprint.sourceSystemSprintEndDate | dateFormat }}</div>
                </td>
                <td>
                    <div *ngIf="sprintDuration(sprint) > 0">{{ sprintDuration(sprint) | plural:'day' }}</div>
                </td>
                <td>
                    <div class="sprint-center">
                        <span *ngIf="sprint.isFutureSprint() == false && sprintVelocity(sprint) <= 0"> 
                            <a [routerLink]="['/dashboard/content-detail/key', ZEROPOINTSPRINT]" class="zero-point-sprint" pTooltip="Zero Point Sprint" tooltipPosition="left">
                            {{ sprintVelocity(sprint) }}
                            </a>
                        </span>
                        <span *ngIf="sprint.isFutureSprint() == false && sprintVelocity(sprint) > 0"> {{ sprintVelocity(sprint) }} </span>
                        <span *ngIf="sprint.isFutureSprint() == true && sprintScope(sprint) > 0"> {{ sprintScope(sprint) }} </span>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="5">
                    No records found
                </td>
            </tr>
        </ng-template>
</p-table>
