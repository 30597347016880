import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'text2html' })
export class Text2HtmlPipe implements PipeTransform 
{
    transform(text: string): string 
    {
        let result = "";
        result = text.replace(new RegExp('\n', 'g'), "<br>");
        return result;
  }
}