import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config/config';
import { BaseHttpService } from '../../base/service/base-http.service';
import { Observable } from 'rxjs';

import { GoalText } from './goal-text';

@Injectable()
export class GoalTextService extends BaseHttpService
{
    goalTextUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.goalTextUrl = this.config.baseUrl + '/rest/goal-text';
    }

    public retrieveAll() : Observable<GoalText[]>
    {
        let url = this.goalTextUrl + "/list";
        return super.baseRetrieveList(GoalText, url);
    }

    public update(goalText: GoalText) : Observable<GoalText>
    {
        let url = this.goalTextUrl;
        return super.baseUpdateObject(GoalText, url, goalText);   
    }
}
