import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';
import { Project } from '../../project/project';

import { ContentDetail } from '../../content/content-detail';

import * as moment from 'moment';

@Component({
  selector: 'mini-backlog-depth',
  templateUrl: './mini-backlog-depth.component.html',
  styleUrls: ['./mini-backlog-depth.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService ]
})

export class MiniBacklogDepthComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    project : Project;

    backlogDepth : ProjectFact;
    sizedStories : ProjectFact;
    unsizedStories : ProjectFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private projectFactService: ProjectFactService) 
    { 
        super(router);

        this.options = {
            indexAxis : 'y',
            plugins: {
                title: {
                    display: false,
                    text: 'Backlog Depth'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    ngOnInit() : void
    {
        this.retrieveData();
    }

    private retrieveData() : void
    {
        this.projectFactService.retrieve(this.project, ProjectFact.BACKLOGDEPTH)
            .subscribe(result => this.processBacklogDepth(result),
                       error => this.handleError (error) );
        this.projectFactService.retrieve(this.project, ProjectFact.BACKLOGSTORIESSIZED)
            .subscribe(result =>this.processBacklogSizedStories(result),
                       error => this.handleError (error) );
        this.projectFactService.retrieve(this.project, ProjectFact.BACKLOGSTORIESUNSIZED)
            .subscribe(result =>this.processBacklogUnsizedStories(result),
                       error => this.handleError (error) );
    }

    private processBacklogDepth(result : ProjectFact) : void
    {
        this.backlogDepth = result;
    }

    private processBacklogSizedStories(result : ProjectFact) : void
    {
        this.sizedStories = result;
        this.buildGraph();
    }

    private processBacklogUnsizedStories(result : ProjectFact) : void
    {
        this.unsizedStories = result;
        this.buildGraph();
    }

    private buildGraph()
    {
        if (this.sizedStories == undefined || this.unsizedStories == undefined)
            return;

        this.data = {
            labels : [ 'Stories' ],
            datasets: [  
                {
                    type:'bar',
                    label: 'Unsized',
                    backgroundColor: 'rgba(255, 0, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 0, 0, 1)',
                    borderColor: 'rgba(255, 0, 0, 1)',
                    borderWidth: 2,
                    data: [ this.unsizedStories.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Sized',
                    backgroundColor: 'rgba(0, 255, 0, .40)',
                    hoverBackgroundColor: 'rgba(0, 255, 0, 1)',
                    borderColor: 'rgba(0, 255, 0, 1)',
                    borderWidth: 2,
                    data: [ this.sizedStories.getFactNumber() ]
                }
            ]
        }
    }

    private percentUnsized() : number 
    {
        let result : number = 0;

        if (this.sizedStories != undefined && this.unsizedStories != undefined)
        {
            let total = this.sizedStories.getFactNumber() + this.unsizedStories.getFactNumber();

            if (total != 0)
                result = Math.ceil(this.unsizedStories.getFactNumber() / total * 100);
        }

        return result;
    }

    protected get BACKLOGDEPTH() : string
    {
        return ContentDetail.BACKLOGDEPTH;
    }
}
