import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ContentService } from '../content/content.service';
import { ContentDetail } from './content-detail';

import { BaseComponent } from '../base/component/base-component.component';

@Component({
  selector: 'content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.css'],
  providers: [ ContentService ]
})
export class ContentDetailComponent extends BaseComponent implements OnInit, AfterViewInit
{ 
    content : ContentDetail;
    contentId : string;
    projectId : string;
    releaseId : string;
    sprintId : string;
    contentKey : string; 

    @ViewChild('contentDetail') contentDetail: ElementRef;
    
    constructor(protected router:Router,
                private route: ActivatedRoute,
//                private sanitizer: DomSanitizer,
                private contentService: ContentService) 
    {
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.contentId = params['contentId'];
            this.contentKey = params['contentKey'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.content = undefined;
            this.contentId = params['contentId'];
            this.contentKey = params['contentKey'];

            this.route.queryParams.forEach((params: Params) => {
                this.projectId = params['projectId'];
                this.releaseId = params['releaseId'];
                this.sprintId = params['sprintId'];
            });
            
            this.initFields();
        });        
    }

    public ngOnInit() : void
    {
    }

    public ngAfterViewInit() : void
    {
        setTimeout(() => { this.initFields() } , 100);
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        if (this.contentId != undefined)
        {
            this.contentService.retrieveDetail(this.contentId, this.projectId, this.releaseId, this.sprintId)
                .subscribe(result=>this.processContent(result), 
                           error => this.handleError(error) );
        }

        if (this.contentKey != undefined)
        {
            this.contentService.retrieveDetailByKey(this.contentKey, this.projectId, this.releaseId, this.sprintId)
                .subscribe(result=>this.processContent(result),
                           error => this.handleError(error) );
        }
    }

    processContent(result: ContentDetail) : void
    {
        this.content = result;

        if (this.contentDetail != undefined)
            this.contentDetail.nativeElement.innerHTML = this.content.contentDetail;
    }

    protected handleError(error : any) : void
    {
        super.addErrorMessage("Error occurred.  Please try again");
    }
/*
    provideContentDetail() : SafeHtml
    {
        return this.sanitizer.bypassSecurityTrustHtml(this.content.contentDetail);
    }
*/
}
