import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

import { Project } from './project';
import { BaseHttpService } from '../base/service/base-http.service';

@Injectable()
export class ProjectService extends BaseHttpService
{
    projectUrl : string;

    public constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.projectUrl = this.config.baseUrl + '/rest/project';
    }

    public retrieveAll() : Observable<Project[]>
    {
        let url = this.projectUrl + "/list";
        return super.baseRetrieveList(Project, url);
    }

    public retrieve(projectId : string) : Observable<Project>
    {
        let url = this.projectUrl + "?projectId=" + projectId;
        return super.baseRetrieveObject(Project, url);
    }

    public retrieveAvailable(userId? : string) : Observable<Array<Project>>
    {
        let url = this.projectUrl + "/available/list";

        if (userId != undefined)
            url = url + "/" + userId;

        return super.baseRetrieveList(Project, url);
    }
}