import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { UserAlertPreferenceService } from './user-alert-preference.service';
import { UserAlertPreference } from './user-alert-preference';

import { UserAccount } from '../user-account/user-account';
import { UserAccountService } from '../user-account/user-account.service';
import { Preference } from '../preference/preference';
import { BaseComponent } from '../base/component/base-component.component';

@Component({
  selector: 'user-alert-preference',
  templateUrl: './user-alert-preference.component.html',
  styleUrls: ['./user-alert-preference.component.scss'],
  providers: [ UserAlertPreferenceService, ConfirmationService ]
})
export class UserAlertPreferenceComponent extends BaseComponent implements OnInit
{ 
    userAlertPreferenceList : UserAlertPreference[];
    userAccount : UserAccount;
    
    selectedUserAlertPreference : UserAlertPreference;
    dailySummaryPreference: boolean = false;
    toggleAlerts : boolean = false;

    userAccountUpdateSucceeded : boolean = undefined;
    userAlertUpdateSucceeded : boolean = undefined;

    constructor(protected router:Router,
                private confirmationService : ConfirmationService,
                private userAccountService: UserAccountService,
                private userAlertPreferenceService: UserAlertPreferenceService ) 
    {
      super(router);
    }

    ngOnInit() : void
    {
        super.clearErrorMessages();
        this.loadData();
    }

    public loadData() : void
    {
        this.userAlertPreferenceService.retrieve()
          .subscribe(result =>this.processUserAlertPreferenceList(result),
                     error => this.handleError(error) ); 
          
        this.userAccountService.retrieve()
          .subscribe(result => this.processUserAccount(result),
                     error => this.handleError(error) ); 
          
    }

    protected processUserAlertPreferenceList(result : UserAlertPreference[]): void
    {
      this.userAlertPreferenceList = result
      this.selectedUserAlertPreference = undefined;

      this.toggleAlerts = false;

      // If any of the alerts are on, toggle alerts is on.
      for (let userAlertPreference of this.userAlertPreferenceList)
      {
        if (userAlertPreference.enabled == true)
        {
          this.toggleAlerts = true;
          break;
        }
      }

    }

    private processUserAccount(userAccount : UserAccount)
    {
        this.userAccount = userAccount;

        if (this.userAccount.hasPreference(Preference.DAILY_SUMMARY) )
          this.dailySummaryPreference = true;
        else
          this.dailySummaryPreference = false;


    }


    public onToggleAlerts() : void
    {
      for (let userAlertPreference of this.userAlertPreferenceList)
      {
          userAlertPreference.enabled = this.toggleAlerts;
      }
    }

    public onUpdate() : void
    {
      this.confirmationService.confirm(
        { message: 'Are you sure you wish to update your alert preferences?',
            accept: () => 
            {
              this.clearErrorMessages();
              this.updateUserAlertPreferenceList();
              this.updateUserAccount();
            }
        });
    }

    public onCancel() : void
    {
      this.router.navigate(['dashboard']);
    }

    private updateUserAlertPreferenceList() : void
    {
        this.userAlertUpdateSucceeded = undefined;

        this.userAlertPreferenceService.update(this.userAlertPreferenceList)
          .subscribe(result => this.handleUpdateUserAlertSuccessful(result),
                     error => this.handleUpdateUserAlertError(error) );
    }

    private updateUserAccount() : void
    {
        this.userAccountUpdateSucceeded = undefined;

        if (this.dailySummaryPreference == true)
          this.userAccount.addPreference(Preference.DAILY_SUMMARY);
        else
          this.userAccount.removePreference(Preference.DAILY_SUMMARY);

        this.userAccountService.update(this.userAccount)
          .subscribe(result => this.handleUpdateUserAccountSuccessful(result),
                     error => this.handleUpdateUserAccountError(error) );
    }

    private handleUpdateUserAlertSuccessful(result : UserAlertPreference[])
    {
        this.processUserAlertPreferenceList(result)
        this.userAlertUpdateSucceeded = true;
        this.displayMessage();
    }

    private handleUpdateUserAccountSuccessful(result : UserAccount) : void
    {
        this.processUserAccount(result);
        this.userAccountUpdateSucceeded = true;
        this.displayMessage();
    }

    private handleUpdateUserAlertError(error: any) : void
    {
      this.userAlertUpdateSucceeded = false;
      this.displayMessage();
    }

    private handleUpdateUserAccountError(error: any) : void
    {
      this.userAccountUpdateSucceeded = false;
      this.displayMessage();
    }

    private displayMessage() : void 
    {
      if (this.userAccountUpdateSucceeded != undefined && this.userAlertUpdateSucceeded != undefined)
      {
        if (this.userAccountUpdateSucceeded && this.userAccountUpdateSucceeded)
          this.addInfoMessage("Update succeeded!");
        else 
          this.addErrorMessage("Error Updating User Preferences.  Please try again.");
      }
    }

}
