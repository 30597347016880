import { Injectable }    from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { ProjectTeamCost } from './project-team-cost';
import { Project } from '../project/project';

@Injectable()
export class ProjectTeamCostService extends BaseHttpService
{
    projectTeamCostUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.projectTeamCostUrl = this.config.baseUrl + '/rest/project-team-cost';
    }

    public retrieve(projectTeamCostId : string) : Observable<ProjectTeamCost>
    {
        let url : string = this.projectTeamCostUrl + "/" + projectTeamCostId;
        return super.baseRetrieveObject(ProjectTeamCost, url);
    }

    public retrieveByProjectId(projectId : string) : Observable<ProjectTeamCost[]>
    {
        let url : string = this.projectTeamCostUrl + "/project/" + projectId + "/list";
        return super.baseRetrieveList(ProjectTeamCost, url);
    }
    
    public create(projectTeamCost : ProjectTeamCost) : Observable<ProjectTeamCost>
    {
        return super.baseCreateObject(ProjectTeamCost, this.projectTeamCostUrl, projectTeamCost);    
    }

    public update(projectTeamCost : ProjectTeamCost) : Observable<ProjectTeamCost>
    {
        return super.baseUpdateObject(ProjectTeamCost, this.projectTeamCostUrl, projectTeamCost);    
    }

    public delete(projectTeamCost : ProjectTeamCost) : Observable<any>
    {
        let deleteUrl : string = this.projectTeamCostUrl + "/" + projectTeamCost.projectTeamCostId;
        return super.baseDeleteObject(deleteUrl);
    }
}