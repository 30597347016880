import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { BaseOrgTimeSeriesComponent } from '../../base/component/base-org-time-series-component.component';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { Project } from '../../project/project';

import * as _ from "lodash"; 

// Internal class for this component.
class TeamPercentage {
    sprintDescription : string;
    percentage : number;
    index : number;
}

@Component({
  selector: 'team-attainment-timeline',
  templateUrl: './team-attainment-timeline.component.html',
  styleUrls: ['./team-attainment-timeline.component.scss' ],
  encapsulation : ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})
export class TeamAttainmentTimelineComponent extends BaseOrgTimeSeriesComponent implements OnInit, OnChanges 
{    
    constructor( protected router : Router,
                 protected timeSeriesService: TimeSeriesService) 
    { 
        super(router, timeSeriesService);
    }

    public ngOnInit() : void
    {
        super.ngOnInit();
        this.options['plugins']['title'].text = `Sprint Attainment %`;
    }

    public loadData() : void 
    {
        let deleteOccurred : boolean = false;

        // Clean up map - deselect projects if removed.
        for (let projectId of this.timeSeriesMap.keys() )
        {
            let found : boolean = false;

            for (let project of this.projectList)
            {
                if (projectId == project.projectId)
                {
                    found = true;
                    break;
                }
            }

            if (found == false)
            {
                this.timeSeriesMap.delete(projectId);
                deleteOccurred = true;
            }
        }

        // If a delete occurred, be sure to rebuild graph.
        if (deleteOccurred)
            this.buildGraph();

        // Retrieve projects
        for (let project of this.projectList)
            this.retrieveTimeSeriesByProject(project);
    }

    public retrieveTimeSeriesByProject(project : Project) : void
    {
        this.timeSeriesService.retrieve(project, project.projectId, TimeSeries.COMMITMENTATTAINMENT, 10)
            .subscribe(result =>this.processTimeSeries(project.projectId, result),
                       error => this.handleError404Okay(error) );
    }

    protected processTimeSeries(projectId : string, result: TimeSeries)
    {
        // Re-sort the time series for use later.  Make the most recent
        // items first in the list.
        result.sortDescending();

        if (_.isEqual(this.timeSeriesMap.get(projectId), result) == true)
            return;
    
        this.timeSeriesMap.set(projectId, result);
        this.buildGraph();
    }

    public buildGraph() : void
    {
        let percentageList : TeamPercentage[] = this.buildTeamAttainmentPercentage();

        this.data = {
            labels : percentageList.map( function(a) { return a.sprintDescription; } ),
            datasets: [
                {
                    type:'line',
                    label: 'Sprint Attainment %',
                    backgroundColor: this.BLUETRANSPARENT,
                    hoverBackgroundColor: this.BLUE,
                    borderColor: this.BLUE,
                    borderWidth: 2,
                    tension: .5,
                    data: percentageList.map( function(a) { return a.percentage; } )
                }
            ]
        }
    }

    public buildTeamAttainmentPercentage() : TeamPercentage[]
    {
        let result : TeamPercentage[] = [];

        // if no teams then just return.
        if (this.projectList.length <= 0)
            return result;

        // Accommodate up to 10 sprints.
        // This code assumes the time series is sorted in descending order
        // (most recent sprints first)
        for (let i = 0; i < 10; i++)
        {
            let teamCount : number = 0;
            let totalAttainment : number = 0;

            for (let projectId of this.timeSeriesMap.keys() )
            {
                // If this project doesn't have a sprint at the index we're 
                // checking, continue...
                if (this.timeSeriesMap.get(projectId).timeSeriesList.length <= i)
                    continue;

                teamCount += 1;

                let teamAttainment = this.timeSeriesMap.get(projectId).timeSeriesList[i].getFieldNumber("commitmentAttainment");
                totalAttainment = totalAttainment + teamAttainment;
            }

            if (teamCount > 0)
            {
                let percentage : number = Math.round((totalAttainment / teamCount) * 100);
                let sprintDescription : string = `${i + 1} sprints ago`;
                
                if (i == 0)
                    sprintDescription = "Last Sprint";

                let teamPercentage : TeamPercentage = new TeamPercentage();
                teamPercentage.percentage = percentage;
                teamPercentage.sprintDescription = sprintDescription;
                teamPercentage.index = i;

                result.push(teamPercentage);
            }
        }

        // Now, resort the result list so the most recent sprint is 
        // last and the oldest sprint is first.
        result = result.sort((teamPercentage1 : TeamPercentage, teamPercentage2 : TeamPercentage) => {

            // Sort descending by index so the oldest sprint is first and the
            // newest sprint is last.
            return teamPercentage2.index - teamPercentage1.index;
        });

        return result;
    }
}