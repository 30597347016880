<div class="grid col-12 card card-w-title">
    <h3 class="col-12">User Alert Preferences</h3>
        
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-4 sm:col-4 md:col-4" for="dailySummaryPreference">Daily Summary Message:</label>
            <span class="col-8 sm:col-8 md:col-8">
                <p-inputSwitch id="dailySummaryPreference" #dailySummaryPreferenceTag [(ngModel)]="dailySummaryPreference" name="dailySummaryPreference" pTooltip="{{dailySummaryPreference}}"></p-inputSwitch>
            </span>
        </div>
        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-4 sm:col-4 md:col-4" for="toggleAlerts">Receive Detail Alerts:</label>
            <span class="col-8 sm:col-8 md:col-8">
                <p-inputSwitch id="toggleAlerts" #toggleAlertsTag [(ngModel)]="toggleAlerts" name="toggleAlerts" pTooltip="{{toggleAlerts}}" (click)="onToggleAlerts()"></p-inputSwitch>
            </span>
        </div>
    </div>

    <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="userAlertPreferenceId"
        [rowHover]="true" [value]="userAlertPreferenceList" responsiveLayout="scroll">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- alert event name -->
                    <col style="width:150px"><!-- enabled -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Event
                    </th>
                    <th>
                        Email Notification
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-userAlertPreference>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ userAlertPreference.alertText.eventName }}
                    </td>
                    <td>
                        <p-inputSwitch [(ngModel)]="userAlertPreference.enabled" pTooltip="{{userAlertPreference.enabled}}"></p-inputSwitch>                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="2">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>
    
    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button #updateButtonTag pButton type="button" class="col-12" label="Update" (click)="onUpdate()"></button>
        </div>
        
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton type="button" class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

     <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
