<div *ngIf="backlogItem" class="grid col-12">
    <span class="col-12 sm:col-12 md:col-2">
        <a *ngIf="backlogItem.isUserStory()" [routerLink]="['/dashboard/user-story-detail', backlogItem.objectId]">
            <span [innerHTML]="backlogItem.formattedId | highlight: searchText"></span>
        </a>
        <a *ngIf="backlogItem.isDefect()" [routerLink]="['/dashboard/defect-detail', backlogItem.objectId]">
            <span [innerHTML]="backlogItem.formattedId | highlight: searchText"></span>
        </a>
        <a *ngIf="backlogItem.isChore()" [routerLink]="['/dashboard/chore-detail', backlogItem.objectId]">
            <span [innerHTML]="backlogItem.formattedId | highlight: searchText"></span>
        </a>
        <a *ngIf="backlogItem.isSpike()" [routerLink]="['/dashboard/spike-detail', backlogItem.objectId]">
            <span [innerHTML]="backlogItem.formattedId | highlight: searchText"></span>
        </a>
        <a *ngIf="backlogItem.isDependency()" [routerLink]="['/dashboard/dependency-detail', backlogItem.objectId]">
            <span [innerHTML]="backlogItem.formattedId | highlight: searchText"></span>
        </a>
    </span>
    <span *ngIf="backlogItem.title" class="col-12 sm:col-12 md:col-10" [innerHTML]="backlogItem.title | highlight: searchText"></span>
    <span *ngIf="backlogItem.description" class="col-12 sm:col-12 md:col-12" [innerHTML]="backlogItem.description | highlight: searchText"></span>
</div>
