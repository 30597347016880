import { BaseValueObject } from '../base/value-object/base-value-object';

export class OrgImage extends BaseValueObject {

    public orgImageId : string;
	public orgId : string;
	public orgImage : string;
    public orgImageType : string;

    public initialize(orgImage : OrgImage) : void
    {
        super.initialize(orgImage);

        this.orgImageId = orgImage.orgImageId;
        this.orgId = orgImage.orgId;
        this.orgImage = orgImage.orgImage;
        this.orgImageType = orgImage.orgImageType;
    }

}