import { BaseAgileProjectObject } from '../base/value-object/base-agile-project-object';
import * as  moment from 'moment';

export class Release extends BaseAgileProjectObject
{
    releaseId : string;
    name : string
    description : string;
    startDate : Date;
    dueDate : Date;
    released : boolean;
    parentReleaseId : string;
    parentFlag: boolean;

    initialize(release : Release) : void
    {
        super.initialize(release);
                
        this.releaseId = release.releaseId;
        this.name = release.name;
        this.description = release.description;
        this.startDate = this.fromUTC(release.startDate);
        this.dueDate = this.fromUTC(release.dueDate);
        this.parentReleaseId = release.parentReleaseId;
        this.parentFlag = release.parentFlag;
        this.released = release.released;
    }

    public hasDueDate() : boolean {

        let result : boolean = false;

        if (moment(this.dueDate).isValid() && this.dueDate != undefined)
            result = true;
            
        return result;
    }


    public get shortName() : string
    {
        let result : string = this.name;

        if (this.name != undefined && this.name.length > 40)
            result = this.name.substr(0, 40);

        return result;
    }

    public get shortDescription() : string
    {
        let result : string = this.description;

        if (this.description != undefined && this.description.length > 40)
            result = this.description.substr(0, 40);

        return result;
    }

    public get childRelease() : boolean
    {
        let result = false;

        if (this.parentReleaseId != undefined)
            result = true;

        return result;
    }
    
}
