import { BaseComponent } from './base-component.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseBacklogItemObject } from '../value-object/base-backlog-item-object';
import { BaseFactObject } from '../value-object/base-fact-object';
import { Sprint } from '../../sprint/sprint';
import { Directive } from '@angular/core';

@Directive()
export class BaseBacklogItemDetailComponent extends BaseComponent 
{ 
    sprintsWorkedOnFact : BaseFactObject;
    blockersFact : BaseFactObject;
    sprint : Sprint;

    constructor(protected router: Router) 
    { 
        super(router);
    }

    public unfinished(backlogItem : BaseBacklogItemObject) : boolean
    {
        let result : boolean = false;

		if (backlogItem.unfinished == true && backlogItem.storyPoints == null && backlogItem.sourceSystemStoryPoints != null)
            result = true;

        return result;
    }

    protected processSprintFound(sprint : Sprint ) : void
    {
        this.sprint = sprint;
    }

    protected processSprintNotFound(error : Error ) : void
    {
        this.sprint = undefined;
    }
    
}
