<p-card class="goal-progress-card">
    <ng-template pTemplate="header">
        <div class="goal-header col-12 flex grid align-content-center">
            <span class="col-6 flex justify-content-start">
                <span class="goal-title-text">Goal</span>
                <span *ngIf="goal.percentComplete >= 100.00" class="goal-title-text">&nbsp;ACHIEVED!</span>
            </span>
            <span class="col-6 flex justify-content-end">
                <span *ngIf="goal" pTooltip="Percent Complete" tooltipPosition="left">
                    {{goal.percentComplete | round }}%
                </span>
                <span *ngIf="content">
                    <a (click)="onContent()">
                        <i pTooltip="{{ content.contentTitle }}" tooltipPosition="left" class="content-icon material-icons">description</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex">
            <i class="material-icons goal-icon flex justify-content-start">flare</i>
            <span class="flex justify-content-center">{{ goal.goalText.goalTitle }}</span>
        </span>
    </ng-template>

    <span class="content">
        <span>{{ goal.goalText.goalDetail }}</span>  
    </span>

    <ng-template pTemplate="footer">
    </ng-template>
</p-card>
