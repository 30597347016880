import { BaseValueObject } from '../base/value-object/base-value-object';
import { Organization } from '../organization/organization';
import { Role } from '../role/role';
import { Preference } from '../preference/preference';
import { Project } from '../project/project';

import * as moment from 'moment';

export class UserAccount extends BaseValueObject
{
    userId : string;
    organization : Organization;
    emailAddress : string;
//    password : string;
    firstName : string;
    lastName : string;
    addressLine1 : string;
    addressLine2 : string;
    city : string;
    stateId : string;
    zipcode : string;
    isActive : boolean;
    emailSentDate : Date;
    loginActivatedDate : Date;
    loginDate : Date;
    lastLoginDate : Date;
    attemptsFailed : number;
    userRoles : Role[];
    userPreferences : Preference[];
    userProjects : Project[];

    constructor()
    {
        super();
        this.userRoles = [];
        this.userPreferences = [];
        this.userProjects = [];
    }

    public initialize(userAccount : UserAccount) : void
    {
        super.initialize(userAccount);

        this.userId = userAccount.userId;
        this.emailAddress = userAccount.emailAddress;
//        this.password = userAccount.password;
        this.firstName = userAccount.firstName;
        this.lastName = userAccount.lastName;
        this.addressLine1 = userAccount.addressLine1;
        this.addressLine2 = userAccount.addressLine2;
        this.city = userAccount.city;
        this.stateId = userAccount.stateId;
        this.zipcode = userAccount.zipcode;
        this.isActive = userAccount.isActive;
        this.emailSentDate = moment(userAccount.emailSentDate).toDate();
        this.loginActivatedDate = moment(userAccount.loginActivatedDate).toDate();
        this.loginDate = this.fromUTC(userAccount.loginDate);
        this.lastLoginDate = this.fromUTC(userAccount.lastLoginDate);
        this.attemptsFailed = userAccount.attemptsFailed;
        
        this.organization = new Organization();
        this.organization.initialize(userAccount.organization);

        this.userRoles = this.initializeList(Role, userAccount.userRoles);
        this.userPreferences = this.initializeList(Preference, userAccount.userPreferences);
        this.userProjects = this.initializeList(Project, userAccount.userProjects);
    }

    public static isStrongPassword(password : string) : boolean
    {
        let result : boolean = false;
        let regex : RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");

        if (regex.test(password) == true)
            result = true;

        return result;
    }

    public isUser() : boolean
    {
        let result : boolean = this.hasRole(Role.USER);
        return result;
    }


    public isAdmin() : boolean
    {
        let result : boolean = this.hasRole(Role.ADMIN);
        return result;
    }

    public makeUser() : void
    {
        if (this.isUser() == false)
        {
            let role : Role = Role.createRole(Role.USER);
            this.userRoles.push(role)
        }
    }

    public makeAdmin() : void
    {
        if (this.isAdmin() == false)
        {
            let role : Role = Role.createRole(Role.ADMIN);
            this.userRoles.push(role)
        }
    }

    public removeAdmin() : void
    {
        if (this.isAdmin() == true)
        {
            for (let role of this.userRoles)
            {
                if (role.roleId == Role.ADMIN)
                {
                    let index : number = this.userRoles.indexOf(role, 0);
                    if (index > -1) 
                        this.userRoles.splice(index, 1);
                }
            }

        }
    }

    public isContentAdmin() : boolean
    {
        let result : boolean = this.hasRole(Role.CONTENT);
        return result;
    }

    public isFinAdmin() : boolean
    {
        let result : boolean = this.hasRole(Role.FINADMIN);
        return result;
    }

    public isFinUser() : boolean
    {
        let result : boolean = this.hasRole(Role.FINUSER);
        return result;
    }

    public hasRole(roleId : number ) : boolean
    {
        let result : boolean = false;

        for (let role of this.userRoles)
        {
            if (role.roleId == roleId)
            {
                result = true;
                break;         
            }
        }

        return result;
    }

    public addRole(roleId : number) : void
    {
        if (this.hasRole(roleId) ==false)
        {
            let role : Role = Role.createRole(roleId);
            this.userRoles.push(role)
        }
   }

   public removeRole(roleId : number) : void
   {
        if (this.hasRole(roleId) == true)
        {
            for (let role of this.userRoles)
            {
                if (role.roleId == roleId)
                {
                    let index : number = this.userRoles.indexOf(role, 0);

                    if (index > -1) 
                        this.userRoles.splice(index, 1);
                }
            }
        }
   }

   public hasPreference(preferenceId : number ) : boolean
   {
       let result : boolean = false;

       for (let preference of this.userPreferences)
       {
           if (preference.preferenceId == preferenceId)
           {
               result = true;
               break;         
           }
       }

       return result;
   }

   public addPreference(preferenceId : number) : void
   {
       if (this.hasPreference(preferenceId) == false)
       {
           let preference : Preference = Preference.createPreference(preferenceId);
           this.userPreferences.push(preference);
       }
  }

  public removePreference(preferenceId : number) : void
  {
       if (this.hasPreference(preferenceId) == true)
       {
           for (let preference of this.userPreferences)
           {
               if (preference.preferenceId == preferenceId)
               {
                   let index : number = this.userPreferences.indexOf(preference, 0);

                   if (index > -1) 
                       this.userPreferences.splice(index, 1);
               }
           }
       }
  }
  
   
    public isValidEmailAddress() : boolean
    {
        let result : boolean = false;

        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = regex.test(this.emailAddress);

        return result;
    }
}