<div *ngIf="sprintDetail" class="grid col-12 card card-w-title">

    <p-blockUI [blocked]="blockedDocument"></p-blockUI>

    <sprint-heading class="col-12" [sprint]="sprintDetail"></sprint-heading>

    <div *ngIf="sprintDetail.isFutureSprint() == false" class="grid col-12">
        <sprint-points-carried-over class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-points-carried-over>
        <sprint-scope class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-scope>
        <sprint-commitment class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-commitment>
        <sprint-points-injected class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-points-injected>
        <sprint-agile-story class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-agile-story>
        <sprint-burndown-timeline class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-burndown-timeline>
        <sprint-velocity class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-velocity>
        <sprint-acceptance-criteria class="col-12 sm:col-12 md:col-3" [sprint]="sprintDetail"></sprint-acceptance-criteria>
    </div>

    <p-message class="col-12 hover-class" *ngIf="sprintDurationChangedFact && sprintStartDayChangedFact && (sprintDurationChangedFact.getFactBoolean() || sprintStartDayChangedFact.getFactBoolean() )" severity="error" [text]="sprintCadenceChangedMessage()" [closable]="false" (click)="onSprintCadenceChangedClicked()"></p-message>
    <p-message class="col-12 hover-class" *ngIf="beginningSprintFact && beginningSprintFact.getFactBoolean() == true" severity="info" [text]="beginningSprintMessage()" [closable]="false" (click)="onBeginningSprintClicked()"></p-message>
    <p-message class="col-12 hover-class" *ngIf="sprintEndReport" severity="info" [text]="sprintEndReportMessage()" [closable]="false" (click)="onSprintEndReportClicked()"></p-message>

    <div *ngIf="isFinUser() && teamCostsExistFact && costPerPointFact" class="grid col-12 sm:col-12 md:col-6">
        <label class="col-9 sm:col-9 md:col-5" for="displayCost">Display Estimated Costs:</label>
        <span class="col-3 sm:col-3 md:col-7">
            <p-inputSwitch id="displayCosts" #displayCostsTag [disabled]="teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false" [(ngModel)]="displayCosts" name="displayCosts" 
                pTooltip="{{ teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false ? 'Team costs do not exist for this backlog.' : '' }}">
            </p-inputSwitch>
        </span>
    </div>

    <div *ngIf="sprintDetail.isFutureSprint() == false" class="grid col-12 sm:col-12 md:col-6">
        <label class="col-9 sm:col-9 md:col-5" for="closedItems">Show Ghost Stories:</label>
        <span class="col-3 sm:col-3 md:col-7">
            <p-inputSwitch id="ghostStories" #ghostStoriesTag [(ngModel)]="ghostStories" name="ghostStories" (click)="onGhostStoriesClicked()"
                [disabled]="beginningSprintFact == null || beginningSprintFact.getFactBoolean() == true"
                pTooltip="{{ beginningSprintFact == undefined || beginningSprintFact.getFactBoolean() == true ? 'Ghost stories not available until after grace period ends.' : '' }}">
            </p-inputSwitch>
        </span>
    </div>

    <backlog-item-list class="col-12" [orgSourceSystem]="orgSourceSystem" [backlogItemList]="sprintDetail.backlogItemList" [ghostStoryList]="ghostStoryList" 
        [completed]="true" [rowStyleClass]="sprintRowStyleClass" [showFacts]="sprintDetail.isFutureSprint() ? false : true"
        [costPerPointFact]="costPerPointFact" [displayCosts]="displayCosts">
    </backlog-item-list>
</div>
