<div *ngIf="epic" class="grid card card-w-title">
    <epic-heading class="col-12" [epic]="epic" [displayCosts]="displayCosts"></epic-heading>

    <h3 class="col-12">Epic Backlog</h3>
    <div class="grid col-12 sm:col-12 md:col-6">
        <label class="col-9 sm:col-9 md:col-5" for="closedItems">Show Closed Items:</label>
        <span class="col-3 sm:col-3 md:col-7">
            <p-inputSwitch id="closedItems" #closedItemsTag [(ngModel)]="closedItems" name="closeItems"></p-inputSwitch>
        </span>
    </div>

    <div *ngIf="isFinUser() && teamCostsExistFact && costPerPointFact" class="grid col-12 sm:col-12 md:col-6">
        <label class="col-9 sm:col-9 md:col-5" for="displayCost">Display Estimated Costs:</label>
        <span class="col-3 sm:col-3 md:col-7">
            <p-inputSwitch id="displayCosts" #displayCostsTag [disabled]="teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false" [(ngModel)]="displayCosts" name="displayCosts" 
                pTooltip="{{ teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false ? 'Team costs do not exist for this backlog.' : '' }}">
            </p-inputSwitch>
        </span>
    </div>
    
    <backlog-item-list *ngIf="epicBacklog" class="col-12" [orgSourceSystem]="orgSourceSystem" [backlogItemList]="epicBacklog" [completed]="closedItems"
        [lazy]="true" [rows]="rows" [total] = "epicBacklog.total"
        (onLazyLoad)="onLazyLoad($event)" [paginator]="true"
        [costPerPointFact]="costPerPointFact" [displayCosts]="displayCosts">
    </backlog-item-list>
</div>
