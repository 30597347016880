import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel'

import { BacklogItem } from '../backlog-item/backlog-item';
import { UserStoryFact } from '../user-story-fact/user-story-fact';

@Component({
  selector: 'point-change-detail',
  templateUrl: './point-change-detail.component.html',
  styleUrls: ['./point-change-detail.component.scss' ],
  providers: [ ]
})

export class PointChangeDetailComponent implements OnInit, OnChanges 
{ 
    @ViewChild("pointChangeDetailTag")
    pointChangeDetailOverlay : OverlayPanel; 

    visible : boolean = false;
    pointChangeDetailList : any[];
    
    constructor() 
    { 
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void 
    {
    }

    public openOverlay(event : any, backlogItem : BacklogItem) : void
    {
        // Do not open this overlay if not a user story.
        if (backlogItem.isUserStory() == false)
            return;

        this.pointChangeDetailList = this.buildPointChangeDetail(backlogItem);
        this.pointChangeDetailOverlay.show(event);
    }

    public closeOverlay() : void
    {
        this.pointChangeDetailOverlay.hide();
    }

    public buildPointChangeDetail(backlogItem : BacklogItem) : any[]
    {
        let pointChangeDetailList : any[] = [];

        if (backlogItem.isUserStory() == true)
        {
            if (backlogItem.backlogItemFactList != null)
            {
                if (backlogItem.getFact(UserStoryFact.POINTCHANGEDETAIL) != null)
                {
                    for (let detail of backlogItem.getFact(UserStoryFact.POINTCHANGEDETAIL).getFactStringList() )
                        pointChangeDetailList.push(JSON.parse(detail) );
                }
            }
        }

        return pointChangeDetailList;
    }

}