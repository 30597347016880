<p-card class="release-point-status">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-7 flex justify-content-start">
                <span class="title-text">Predicted Completion</span>
            </span>
            <span class="col-5 flex justify-content-end">
                <span *ngIf="averageVelocity" pTooltip="Average Velocity" tooltipPosition="left">
                    {{averageVelocity.getFactNumber() | round }} pts/sprint
                </span>
                <span *ngIf="releaseOnTarget">
                    <a *ngIf="releaseOnTarget.getFactBoolean() == false" [routerLink]="['/dashboard/content-detail/key', RELEASEDATEATRISK]" [queryParams]="{releaseId: release.releaseId}">
                        <i *ngIf="release.hasDueDate()" pTooltip="Release Date {{release.dueDate | dateFormat }} at risk!" tooltipPosition="left" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="displayCosts == false && predictedCompletion && predictedCompletion.getFactTimestamp() != undefined">
                {{ predictedCompletion.getFactTimestamp() | dateFormat }}
            </span>
            <span *ngIf="displayCosts == true && costPerPoint && scope">
                {{ totalCost | round | currency:'USD':'symbol' }} total
            </span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="numberOfSprints && sprintsRemaining" class="chart-style">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
