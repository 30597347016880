<p-card class="defect-removal-rate-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Defect Removal Rate</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span class="content">
                    <a [routerLink]="['/dashboard/content-detail/key', DEFECTREMOVALRATE]">
                        <i pTooltip="What is defect removal rate?" tooltipPosition="left" class="content-icon material-icons">description</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="projectDefectRemovalRate" class="overview-detail">{{projectDefectRemovalRate.getFactNumber() | round }} % </span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
