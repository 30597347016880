<div *ngIf="project" class="grid card card-w-title">
    <project-summary class="col-12 sm:col-12 md:col-12 lg:col-12 ll-nogutter-top-bottom" [card]="false" [project]="project" [details]="false"></project-summary>

    <div *ngIf="projectStatus && projectStatus.initialLoad == false" class="grid col-12 sm:col-12 md:col-12 lg:col-12 ll-nogutter-top-bottom">
        <quality-stories-timeline class="col-12 sm:col-12 md:col-4" [project]="project"></quality-stories-timeline>
        <points-injected-timeline class="col-12 sm:col-12 md:col-4" [project]="project"></points-injected-timeline>
        <points-carried-over-timeline class="col-12 sm:col-12 md:col-4" [project]="project"></points-carried-over-timeline>

        <commitment-attainment-timeline *ngIf="numberOfSprints > 0" class="col-12 sm:col-12 md:col-4" [project]="project"></commitment-attainment-timeline>

        <div *ngFor="let release of releaseList" class="col-12 sm:col-12 md:col-4">
            <release-point-burnup [release]="release"></release-point-burnup>
        </div>

        <mini-app-alert-count-overview class="col-12 sm:col-12 md:col-4" [project]="project"></mini-app-alert-count-overview>
        <cost-per-point-timeline *ngIf="teamCostsExistFact && teamCostsExistFact.getFactBoolean() == true && userHasPermission" class="col-12 sm:col-12 md:col-4" [project]="project"></cost-per-point-timeline>
    </div>
</div>