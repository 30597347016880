import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { OrgImage } from './org-image';

@Injectable()
export class OrgImageService extends BaseHttpService
{
    orgImageUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.orgImageUrl = this.config.baseUrl + '/rest/org-image';
    }

    public retrieve(pOrgImageId : string) : Observable<OrgImage>
    {
        let url = this.orgImageUrl + `/${pOrgImageId}`;
        return super.baseRetrieveObject(OrgImage, url);
    }

    public retrieveByOrgId(pOrgId : string) : Observable<OrgImage>
    {
        let url = this.orgImageUrl + `/org-id/${pOrgId}`;
        return super.baseRetrieveObject(OrgImage, url);
    }

    public create(orgImage: OrgImage) : Observable<OrgImage>
    {
        let url = this.orgImageUrl;
        return super.baseCreateObject(OrgImage, url, orgImage);   
    }

    public upsert(orgImage: OrgImage) : Observable<OrgImage>
    {
        let url = this.orgImageUrl + "/upsert";
        return super.baseCreateObject(OrgImage, url, orgImage);   
    }

    public update(orgImage: OrgImage) : Observable<OrgImage>
    {
        let url = this.orgImageUrl;
        return super.baseUpdateObject(OrgImage, url, orgImage);   
    }

    public delete(orgImage: OrgImage) : Observable<any>
    {
        let url = this.orgImageUrl + `/${orgImage.orgImageId}`;
        return super.baseDeleteObject(url);
    }
}
