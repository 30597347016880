import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

@Component({
  selector: 'mini-defect-priority',
  templateUrl: './mini-defect-priority.component.html',
  styleUrls: ['./mini-defect-priority.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService ]
})

export class MiniDefectPriorityComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    project : Project;
    defectPriorityFact : ProjectFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private projectFactService : ProjectFactService) 
    { 
        super(router);

        this.options = {
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Defect Priority'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            animations: false
        }
    }

    public ngOnInit() : void
    {
        this.projectFactService.retrieve(this.project, ProjectFact.DEFECTPRIORITYTOTALS)
            .subscribe(result => this.processDefectPriorityTotals(result),
                       error => this.handleError(error) );
    }

    public processDefectPriorityTotals(result: ProjectFact)
    {
        this.defectPriorityFact = result;       

        this.data = {
            labels : ['Unknown', 'Highest', 'High', 'Medium', 'Low', 'Lowest'],
            datasets: [
                {
                    type:'pie',
                    label: 'Defect Priority',
                    data: [ this.defectPriorityFact.getFactMap()[0].value,
                            this.defectPriorityFact.getFactMap()[1].value,
                            this.defectPriorityFact.getFactMap()[2].value,
                            this.defectPriorityFact.getFactMap()[3].value,
                            this.defectPriorityFact.getFactMap()[4].value,
                            this.defectPriorityFact.getFactMap()[5].value,                
                    ],
                    backgroundColor: [
                        "rgba(66, 165, 245, .40)",
                        "rgba(255, 0, 0, .40)",
                        "rgba(255, 155, 0, .40)",
                        "rgba(255, 215, 0, .40)",
                        "rgba(173, 255, 47, .40)",
                        "rgba(0, 255, 0, .40)"
                    ],
                    hoverBackgroundColor: [
                        "rgba(66, 165, 245, 1)",
                        "rgba(255, 0, 0, 1)",
                        "rgba(255, 155, 0, 1)",
                        "rgba(255, 215, 0, 1)",
                        "rgba(173, 255, 47, 1)",
                        "rgba(0, 255, 0, 1)"
                    ],
                    borderColor: [
                        "rgba(66, 165, 245, 1)",
                        "rgba(255, 0, 0, 1)",
                        "rgba(255, 155, 0, 1)",
                        "rgba(255, 215, 0, 1)",
                        "rgba(173, 255, 47, 1)",
                        "rgba(0, 255, 0, 1)"
                    ],
                    borderWidth: [
                        2,
                        2,
                        2,
                        2,
                        2,
                        2
                    ]
                }
            ]
        }
    }

    public percentHighestHigh() : number
    {
        let totalDefectCount : number = 
                this.defectPriorityFact.getFactMap()[0].value +
                this.defectPriorityFact.getFactMap()[1].value +
                this.defectPriorityFact.getFactMap()[2].value +
                this.defectPriorityFact.getFactMap()[3].value +
                this.defectPriorityFact.getFactMap()[4].value +
                this.defectPriorityFact.getFactMap()[5].value;     

        let highestCount : number = 
                this.defectPriorityFact.getFactMap()[1].value +
                this.defectPriorityFact.getFactMap()[2].value;

        let result = 0;

        if (totalDefectCount != 0)
        {
            result = Math.ceil(highestCount / totalDefectCount * 100)
        }

        return result;
    }
}
