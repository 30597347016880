<div class="pages-body flex flex-column">
    <div class="align-self-center mt-auto mb-auto">
        <div class="input-panel flex flex-column card login-panel forgot-password-panel">

            <div class="col-12">
                <img class="logo" src="../../public/images/lucidlift-blue-alpha.png">
            </div>
            
            <p-messages class="loginError" [value]="errorMessages"></p-messages>

            <div class="col-12">&nbsp;<!-- filler --></div>

            <div class="grid" (keyup.enter)="onSubmit()">
                <div class="col-12">
                    <span class="p-float-label">
                        <input class="col-12" type="email" autocomplete="off" autocorrect="off" autocapitalize="none" name="emailAddress" #emailAddressTag pInputText [(ngModel)]="emailAddress">
                        <label>Email Address</label>
                    </span>
                </div>
            </div>

            <div class="col-12">&nbsp;<!-- filler --></div>

            <div class="grid col-12">
                <div class="col-12">
                    <button id="resetPasswordButton" pButton class="col-12" label="Reset Password" icon="fa-lg fa-user-circle" (click)="onSubmit()"></button>
                </div>

                <div class="col-12">
                    <button id="cancelButton" pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
                </div>
            </div>

        </div>
    </div>
</div>
