import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseBacklogItemDetailComponent } from '../base/component/base-backlog-item-detail.component';
import { Router, ActivatedRoute } from '@angular/router';

import { UserStory } from './user-story';
import { UserStoryFact } from '../user-story-fact/user-story-fact';
import { UserStoryService } from './user-story.service';

import { SprintService } from '../sprint/sprint.service';


@Component({
  selector: 'user-story-detail',
  templateUrl: './user-story-detail.component.html',
  styleUrls: ['./user-story-detail.component.scss' ],
  providers: [ UserStoryService, SprintService ]
})

export class UserStoryDetailComponent extends BaseBacklogItemDetailComponent implements OnInit, AfterViewInit 
{ 
    userStoryId : string;
    userStory : UserStory;
    
    constructor(private userStoryService : UserStoryService,
                private sprintService : SprintService,
                private route: ActivatedRoute,
                protected router: Router) 
    { 
        super(router);

        this.route.params.subscribe(params => {
            this.userStoryId = params['userStoryId'];
            this.initFields();
        });
        
    }

    public ngOnInit() : void
    {
    }

    public ngAfterViewInit() : void
    {
    }

    private initFields() : void
    {
        this.userStory = undefined;
        this.sprint = undefined;

        this.userStoryService.retrieve(this.userStoryId)
            .subscribe(result=>this.processUserStory(result),
                       error => this.handleError(error) );
    }

    private processUserStory(userStory : UserStory ) : void
    {
        this.userStory = userStory;

        if (this.userStory.getFact(UserStoryFact.BLOCKED) != undefined)
            this.blockersFact = this.userStory.getFact(UserStoryFact.BLOCKED);

        if (this.userStory.getFact(UserStoryFact.SPRINTSWORKEDON) != undefined)
            this.sprintsWorkedOnFact = this.userStory.getFact(UserStoryFact.SPRINTSWORKEDON);
    
        this.sprintService.retrieveByUserStory(this.userStory)
            .subscribe(result=>this.processSprintFound(result),
                       error => this.processSprintNotFound(error) );
    }

}
