import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LoggedInUser } from './logged-in-user';

@Injectable()
export class ContentGuard implements CanActivate 
{
  constructor(private router: Router) {}

  canActivate() 
  {
    let result : boolean = false;

    if (LoggedInUser.getLoggedInUser() != undefined)
    {
      if (LoggedInUser.getLoggedInUser().isContentAdmin() == false)
      {
          result = false;
          this.router.navigate(['dashboard']);
      }
      else
      {
          result = true;
      }
    }
    
    return result;
  }

}