export class ContextVariable
{
    contextKey : string;
	description : string;

    constructor(contextKey : string, description: string)
    {
        this.contextKey = contextKey;
        this.description = description;
    }
}