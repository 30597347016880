<p-table *ngIf="epicList" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="epicId"
    [rowHover]="true" [value]="epicList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
    (onLazyLoad)="loadData($event)" (onSort)="customSort($event)" [sortField]="sortField" [sortOrder]="sortOrder">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col><!-- name -->
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [pSortableColumn]="'name'">
                    Name
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-epic let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td>
                    <div class="epic-center">
                        <a [routerLink]="['/dashboard/epic-detail', epic.epicId]">{{epic.name}}</a>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="3">
                    No records found
                </td>
            </tr>
        </ng-template>
</p-table>
