import { Component, OnInit, Input } from '@angular/core';
import { BaseDetailComponent } from '../base/component/base-detail-component.component';

import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../org-source-system/org-source-system';

import { SprintService } from './sprint.service';
import { BacklogItemService } from '../backlog-item/backlog-item.service';

import { BacklogItem } from '../backlog-item/backlog-item';

import { SprintFactService } from '../sprint-fact/sprint-fact.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseFactObject } from '../base/value-object/base-fact-object';
import { ProjectFact } from '../project-fact/project-fact';

import { SprintDetail } from './sprint-detail';
import { SprintFact } from '../sprint-fact/sprint-fact';

import { SprintEndReport } from '../sprint-end-report/sprint-end-report';
import { SprintEndReportService } from '../sprint-end-report/sprint-end-report.service';

import { Message } from 'primeng/api';
import { Content } from '../content/content';

import { ProjectFactService } from '../project-fact/project-fact.service';

@Component({
  selector: 'sprint-detail',
  templateUrl: './sprint-detail.component.html',
  styleUrls: ['./sprint-detail.component.css' ],
  providers: [ OrgSourceSystemService, ProjectFactService, SprintService,  
               SprintFactService, BacklogItemService, SprintEndReportService ]
})

export class SprintDetailComponent extends BaseDetailComponent implements OnInit 
{ 
    sprintDetail : SprintDetail;
    ghostStoryList : BacklogItem[];

    sprintId : string;
    sprintEndReport : SprintEndReport;
    ghostStories : boolean;
    blockedDocument : boolean;

    orgSourceSystem : OrgSourceSystem;

    beginningSprintFact : SprintFact;
    sprintDurationChangedFact : SprintFact;
    sprintStartDayChangedFact : SprintFact;

    constructor( private orgSourceSystemService : OrgSourceSystemService,
                 private sprintService : SprintService,
                 private sprintFactService : SprintFactService,  
                 private backlogItemService : BacklogItemService,
                 private sprintEndReportService : SprintEndReportService,
                 private route: ActivatedRoute,
                 protected projectFactService : ProjectFactService,
                 protected router: Router) 

    { 
        super(router, projectFactService);
        
        this.route.params.forEach((params: Params) => {
            this.sprintId = params['sprintId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.sprintId = params['sprintId'];
            this.loadData();
        });

    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    protected loadData() : void 
    {
        this.sprintDetail = undefined;
        
        this.blockedDocument = false;
        this.ghostStories = false;
        this.ghostStoryList = [];

        // Build component via route.
        if (this.sprintId != undefined)
        {
            this.sprintService.retrieveById(this.sprintId)
                .subscribe(result=>this.processSprint(result),
                           error => this.handleError(error) );

            this.orgSourceSystemService.retrieveBySprintId(this.sprintId)
                .subscribe(result => this.processOrgSourceSystem(result), 
                           error => this.handleError(error) );

            this.sprintFactService.retrieveById(this.sprintId, SprintFact.BEGINNINGSPRINT)
                .subscribe(result => this.processBeginningSprintFact(result),
                           error => this.handleError404Okay(error) );

            this.sprintFactService.retrieveById(this.sprintId, SprintFact.SPRINTDURATIONCHANGED)
                .subscribe(result => this.processSprintDurationChangedFact(result),
                           error => this.handleError404Okay(error) );
           
            this.sprintFactService.retrieveById(this.sprintId, SprintFact.SPRINTSTARTDAYCHANGED)
                .subscribe(result => this.processSprintStartDayChangedFact(result),
                           error => this.handleError404Okay(error) );
           
            this.sprintEndReportService.retrieveBySprintId(this.sprintId)
                .subscribe(result => this.processSprintEndReport(result),
                           error => this.handleError404Okay(error) );
        }
    }

    // Override ancestor.
    protected retrieveCosts(projectId : string) : void 
    {
        this.projectFactService.retrieveById(projectId, ProjectFact.TEAMCOSTSEXIST)
            .subscribe(result => this.processTeamCostsExistFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieveById(this.sprintId, SprintFact.COSTPERPOINT)
            .subscribe(result => this.processCostPerPointFact(result),
                       error => this.handleError404Okay(error) );
    }

    // Override ancestor.
    protected processCostPerPointFact(result : BaseFactObject) : void
    {
        this.costPerPointFact = result;
    }

    protected processSprint(result:SprintDetail)
    {
        this.sprintDetail = result;
        this.retrieveCosts(this.sprintDetail.project.projectId);
    }

    protected processOrgSourceSystem(result : OrgSourceSystem)
    {
        this.orgSourceSystem = result;
    }

    protected processBeginningSprintFact(result : SprintFact)
    {
        this.beginningSprintFact = result;
    }

    protected processSprintDurationChangedFact(result : SprintFact)
    {
        this.sprintDurationChangedFact = result;
    }

    public processSprintStartDayChangedFact(result : SprintFact)
    {
        this.sprintStartDayChangedFact = result;
    }

    private processSprintEndReport(result : SprintEndReport) : void
    {
        this.sprintEndReport = result;
    }

    protected beginningSprintMessage() : string
    {
        let message : string = 'Sprint Grace Period is active.  Click on this message for more information.';
        return message;
    }
    
    public sprintCadenceChangedMessage() : string
    {
        let message: string = "";

        if (this.sprintDurationChangedFact.getFactBoolean() && this.sprintStartDayChangedFact.getFactBoolean() )
            message = "Sprint Start Day and Sprint Duration changed.  For more information on sprint cadence, please click here.";

        if (this.sprintDurationChangedFact.getFactBoolean() && this.sprintStartDayChangedFact.getFactBoolean() == false)
            message = "Sprint Duration changed.  For more information on sprint cadence, please click here.";
            
        if (this.sprintDurationChangedFact.getFactBoolean() == false && this.sprintStartDayChangedFact.getFactBoolean() )
            message = "Sprint Start Day changed.  For more information on sprint cadence, please click here.";

        return message;
    }
    
    protected sprintEndReportMessage() : string
    {
        let message : string = 'Sprint End Report Available.  Click on this message for report.';
        return message;
    }
    
    public onBeginningSprintClicked() : void 
    {
        this.router.navigate(['/dashboard/content-detail/key', Content.SPRINTGRACEPERIOD]);        
    }

    public onSprintEndReportClicked() : void 
    {
        this.router.navigate(['/dashboard/sprint-end-report', this.sprintEndReport.sprintEndReportId]);        
    }

    public onSprintCadenceChangedClicked() : void 
    {
        this.router.navigate(['/dashboard/content-detail/key', Content.SPRINTCADENCE]);        
    }

    private onAlertClick() : void
    {
        this.router.navigate(['/dashboard/app-alert-list', this.sprintDetail.project.projectId]);
    }

    private onGhostStoriesClicked()
    {
        if (this.ghostStories == true)
        {
            this.blockedDocument = true;

            this.backlogItemService.retrieveGhostStories(this.sprintDetail)
                .subscribe(result => {
                                    this.blockedDocument = false;
                                    this.processGhostStories(result)
                                },
                            error => {
                                    this.blockedDocument = false; 
                                    this.handleError (error) 
                                 } );
        }
        else
        {
            this.ghostStoryList = [];
        }
    }

    private processGhostStories(result : BacklogItem[] ) : void
    {
        this.ghostStoryList = result;
    }

    public sprintRowStyleClass(backlogItem : BacklogItem, index : number) : string
    {
        let result : string = null;
        
        if (backlogItem.isCompleted() == true)
        {
            if (index % 2 == 0)
                result = 'row-style-class-green-even';
            else
                result = 'row-style-class-green-odd';
        }

        return result;
    }
}
