<div class="grid card card-w-title">
    <h3 class="col-12">Update Content</h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div *ngIf="active" class="col-12">
        <div class="grid col-12 flex align-content-center align-items-center">
            <label class="col-12 sm:col-12 md:col-4" for="organization">Organization:</label>
            <p-dropdown class="col-12 sm:col-12 md:col-8" [options]="organizationList" name="organization" [(ngModel)]="content.orgId"></p-dropdown>                
        </div>

        <div class="grid col-12 flex align-content-center align-items-center">
            <label class="col-12 sm:col-12 md:col-4" for="contentKey">Content Key:</label>
            <p-inputMask class="col-12 sm:col-12 md:col-8" mask="*?******************" slotChar=" " placeholder="content key" maxlength="20" size="22" id="contentKey" #contentKeyTag [(ngModel)]="content.contentKey" name="contentKey"></p-inputMask>
        </div>

        <div class="grid col-12 flex align-content-center align-items-center">
            <label class="col-12 sm:col-12 md:col-4" for="contentTitle">Title:</label>
            <input pInputText maxlength="50" class="col-12 sm:col-12 md:col-8" id="contentTitle" #contentTitleTag [(ngModel)]="content.contentTitle" name="contentTitle" />
        </div>

        <div class="grid col-12">
            <label class="col-12 sm:col-12 md:col-4" for="contentSummary">Summary:</label>
            <textarea pInputTextarea maxlength="1500" rows="50" cols="30" class="col-12 sm:col-12 md:col-8 textarea-class" id="contentSummary" #contentSummaryTag [(ngModel)]="content.contentSummary" name="contentSummary"></textarea>
        </div>

        <p-editor [style]="{'height':'350px', 'font-size':'16px'}" [(ngModel)]="content.contentDetail" class="col-12 sm:col-12 md:col-12" id="contentDetail" #contentDetailTag name="contentDetail"></p-editor>

        <context-variable-list class="col-12" 
                [header]="'Content Context Variables'" 
                [contextVariableList]="contextVariableList">
        </context-variable-list> 
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button class="col-12" pButton label="Update" (click)="onUpdate()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <button class="col-12 secondary" pButton label="Cancel" (click)="onCancel()" ></button>
        </div>
    </div>
</div>
