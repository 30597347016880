import { Component, OnInit, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import * as moment from 'moment';

@Component({
  selector: 'mini-velocity-overview',
  templateUrl: './mini-velocity-overview.component.html',
  styleUrls: ['./mini-velocity-overview.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class MiniVelocityOverviewComponent extends BaseSprintTimeSeriesComponent implements OnInit 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    @Output() click = new EventEmitter<any>();

    averageVelocityFact : ProjectFact;
    velocityTrendFact : ProjectFact;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Velocity By Sprint`;

        this.projectFactService.retrieve(this.project, ProjectFact.AVERAGEVELOCITY)
            .subscribe(result => this.processAverageVelocityFact(result) );

        this.projectFactService.retrieve(this.project, ProjectFact.VELOCITYTREND)
            .subscribe(result => this.processVelocityTrendFact(result) );

        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTVELOCITY, 10)
            .subscribe(result =>this.processTimeSeries(result) );
    }

    public processTimeSeries(result: TimeSeries)
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Velocity',
                    backgroundColor: this.GREENTRANSPARENT,
                    borderColor: this.GREEN,
                    hoverBackgroundColor: this.GREEN,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("velocity"); } )
                }
            ]
        }
    }

    public processAverageVelocityFact(result : ProjectFact )
    {
        this.averageVelocityFact = result;
    }

    public processVelocityTrendFact(result : ProjectFact )
    {
        this.velocityTrendFact = result;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }   
}