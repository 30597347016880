import { BaseValueObject } from '../base/value-object/base-value-object';

export class Role extends BaseValueObject
{
    roleId : number;
	roleDescription : string;

    public static readonly USER : number = 1;
    public static readonly ADMIN: number = 2;
    public static readonly CONTENT: number = 3;
    public static readonly FINUSER: number = 4;
    public static readonly FINADMIN: number = 5;
    
    static createRole(roleId : number) : Role
    {
        let role : Role = new Role();

        switch (roleId)
        {
            case Role.USER:
                role.roleId = Role.USER;
                role.roleDescription = "User";

                break;

            case Role.ADMIN:
                role.roleId = Role.ADMIN;
                role.roleDescription = "Admin";

                break;

            case Role.CONTENT:
                role.roleId = Role.CONTENT;
                role.roleDescription = "Content Admin";

                break;

            case Role.FINUSER:
                role.roleId = Role.FINUSER;
                role.roleDescription = "Financial User";

                break;

            case Role.FINADMIN:
                role.roleId = Role.FINADMIN;
                role.roleDescription = "Financial Admin";

                break;                                
        }

        return role;
    }

    public initialize(role : Role): void
    {
        super.initialize(role);

        this.roleId = role.roleId;
        this.roleDescription = role.roleDescription;
    }
}