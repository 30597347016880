import { BaseValueObject } from '../base/value-object/base-value-object';

export class Content extends BaseValueObject
{
    contentId : string;
	contentKey : string;
	orgId : string;
	contentTitle : string;
	contentSummary : string;

	public static readonly CARRYOVERKEY : string = "storyCarryover";
	public static readonly GOODSTORIES : string = "goodStories";
	public static readonly STORYPOINTS : string = "storyPoints";
	public static readonly OVERLOADEDSPRINT : string = "overloadedSprint";
	public static readonly POINTSONDEFECTS : string = "pointsOnDefects";
	public static readonly POINTSONCHORES : string = "pointsOnChores";
	public static readonly ACCEPTANCECRITERIA : string = "acceptanceCriteria";
	public static readonly SPRINTCOMMITMENT : string = "sprintCommitment";
	public static readonly SPRINTCADENCE : string = "sprintCadence";
	public static readonly ZEROPOINTSPRINT : string = "zeroPointSprint";
	public static readonly ADDEDTOSPRINT : string = "addedToSprint";
	public static readonly POINTSCHANGED : string = "pointsChanged";
	public static readonly BLOCKERS : string = "blockers";
	public static readonly RELEASEESTIMATE : string = "releaseEstimate";
	public static readonly BACKLOGDEPTH : string = "backlogDepth";
	public static readonly RELEASEDATEATRISK : string = "releaseDateAtRisk";
	public static readonly SPRINTGRACEPERIOD : string = "sprintGracePeriod";
	public static readonly WELCOME : string = "welcome";
	public static readonly SCRUMMATURITY : string = "scrumMaturity";
	public static readonly TEAMSCRUMMATURITY : string = "teamScrumMaturity";
	public static readonly PRIVACYPOLICY : string = "privacyPolicy";
	public static readonly TERMSOFUSE : string = "termsOfUse";
	public static readonly JIRAAGILE : string = "jiraAgile";
	public static readonly MICROSOFTTFS : string = "microsoftTfs";
	public static readonly CAAGILECENTRAL : string = "caAgileCentral";
	public static readonly DEFINITIONOFREADY : string = "definitionOfReady";
	public static readonly DEFECTDENSITY : string = "defectDensity";
	public static readonly DEFECTREMOVALRATE : string = "defectRemovalRate";
	public static readonly DEFECTMTTR : string = "defectMTTR";
	public static readonly STORYCARRYOVER : string = "storyCarryover";
	public static readonly UNFINISHEDVELOCITY : string = "unfinishedVelocity";
	public static readonly QUALITYSTORIESTREND : string = "qualityStoriesTrend";
	public static readonly VELOCITYUNRELIABLE : string = "velocityUnreliable";
	
	public initialize(content : Content) : void
	{
		super.initialize(content);

        this.contentId = content.contentId;
        this.contentKey = content.contentKey;
		this.orgId = content.orgId;
        this.contentTitle = content.contentTitle;
        this.contentSummary = content.contentSummary;
	}
}
