<div class="grid col-12 card card-w-title">
    <h3>Add Team Cost Information </h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="effectiveDate">Effective Date:</label>
            <p-calendar class="col-12" id="effectiveDate" dateFormat="mm/dd/yy" [showIcon]="true" [showOnFocus]="false" #effectiveDateTag [(ngModel)]="effectiveDate" name="effectiveDate"></p-calendar>                        
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12">End Date:</label>
            <div class="grid col-12 flex align-content-center align-items-center">
                <label class="col-12 sm:col-12 md:col-3">Current Date:</label>
                <p-inputSwitch class="col-12 sm:col-12 md:col-2" [(ngModel)]="currentDateEndDate" name="currentDateEndDate"></p-inputSwitch>
                <p-calendar *ngIf="currentDateEndDate == false" class="col-12 sm:col-12 md:col-7" id="endDate" dateFormat="mm/dd/yy" [showIcon]="true" [showOnFocus]="false" #endDateTag [(ngModel)]="endDate" name="endDate"></p-calendar>                        
            </div>
        </div>

        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-12" for="costPerMonth">Monthly Cost:</label>
            <!--
            <input currencyMask class="currency-class" id="costPerMonth" [options]="{ allowNegative: 'false', precision : 0 }" #costPerMonthTag [(ngModel)]="costPerMonth" name="costPerMonth"/>              
            -->
            <p-inputNumber id="costPerMonth" #costPerMonthTag [(ngModel)]="costPerMonth" mode="currency" currency="USD" locale="en-US" name="costPerMonth"></p-inputNumber>
        </div>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12" label="Add" (click)="onAdd()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
    
</div>
