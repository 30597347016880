<div class="grid">
    <div class="grid col-12 sm:col-12 md:col-12 flex align-content-center">
        <span *ngIf="releaseProgramIncrementFact && releaseProgramIncrementFact.getFactBoolean() == true && releaseProgramIncrementQuarterFact" style="margin-right:30px">
            <h3 style="margin: auto 0px" pBadge [value]="releaseProgramIncrementQuarterFact.getFactString()" severity="success"><a [routerLink]="['/dashboard/release-detail', release.releaseId]">Release - {{release.name}}</a></h3>
        </span>
        <span *ngIf="!releaseProgramIncrementFact || releaseProgramIncrementFact.getFactBoolean() == false || !releaseProgramIncrementQuarterFact" style="margin-right:5px">
            <h3 style="margin: auto 0px"><a [routerLink]="['/dashboard/release-detail', release.releaseId]">Release - {{release.name}}</a></h3>
        </span>
        <button *ngIf="showButton == true" pButton label="Details" class="detail secondary" (click)="onClick(release)"></button>
    </div>

    <div *ngIf="release.hasDueDate()" class="grid col-12 sm:col-12 md:col-12 lg:col-12">
        <colorbox *ngIf="release.hasDueDate()" class="col-12 sm:col-12 md:col-12 lg:col-4" [name]="'Planned Date'" [detail]="release.dueDate | dateFormat" [color]="'blue'" [icon]="'event'"></colorbox>                
        <colorbox *ngIf="releaseScopeFact" class="col-12 sm:col-12 md:col-6 lg:col-4" [name]="'Scope'" [detail]="releaseScopeFact.getFactNumber() | plural:'point'" [color]="'blue'" [icon]="'timeline'"></colorbox>                
        <colorbox *ngIf="releasePointsCompletedFact" class="col-12 sm:col-12 md:col-6 lg:col-4" [name]="'Points Completed'" [detail]="releasePointsCompletedFact.getFactNumber() | plural:'point'" [color]="'green'" [icon]="'done'"></colorbox>                
    </div>

    <div *ngIf="!release.hasDueDate()" class="grid col-12 sm:col-12 md:col-12">
        <colorbox *ngIf="releaseScopeFact" class="col-12 sm:col-12 md:col-6" [name]="'Scope'" [detail]="releaseScopeFact.getFactNumber() | plural:'point'" [color]="'blue'" [icon]="'timeline'"></colorbox>                
        <colorbox *ngIf="releasePointsCompletedFact" class="col-12 sm:col-12 md:col-6" [name]="'Points Completed'" [detail]="releasePointsCompletedFact.getFactNumber() | plural:'point'" [color]="'blue'" [icon]="'done'"></colorbox>                
    </div>

    <p-messages class="col-12 sm:col-12 md:col-12 hover-over" *ngIf="releaseOnTargetFact && releaseOnTargetFact.getFactBoolean() == false" [value]="releaseAtRiskMessage" [closable]="false" (click)="onReleaseAtRiskClicked()"></p-messages>

    <release-point-burnup *ngIf="displayCosts == false" class="col-12 sm:col-12 md:col-4" [release]="release" [displayPoints]="true"></release-point-burnup>
    <release-cost-burnup *ngIf="displayCosts == true" class="col-12 sm:col-12 md:col-4" [release]="release"></release-cost-burnup>
    <release-defect-potential-timeline class="col-12 sm:col-12 md:col-4" [release]="release" (click)="onDefectClick()"></release-defect-potential-timeline>
    <release-status-selector class="col-12 sm:col-12 md:col-4" [release]="release" [displayCosts]="displayCosts"></release-status-selector>

    <p-panel *ngIf="release.description" class="col-12 sm:col-12 md:col-12" header="Description">
        <div style="overflow-y:scroll; height:100px;" class="preserveWhitespace" *ngIf="release.description" [innerHTML]="release.description">
        </div>            
    </p-panel>

    <p-message id="beginningProgramIncrement" class="col-12 sm:col-12 md:col-12 hover-over" *ngIf="releaseProgramIncrementFact && releaseProgramIncrementFact.getFactBoolean() == true && beginningReleaseFact && beginningReleaseFact.getFactBoolean() == true" severity="info" [text]="beginningReleaseMessage()" [closable]="false"></p-message>
    
</div>