import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ProjectService } from '../../project/project.service';
import { Project } from '../../project/project';

import { BaseComponent } from '../../base/component/base-component.component';

@Component({
  selector: 'project-config-list',
  templateUrl: './project-config-list.component.html',
  styleUrls: ['./project-config-list.component.scss'],
  providers: [ ProjectService, ConfirmationService ]
})
export class ProjectConfigListComponent extends BaseComponent implements OnInit
{ 
    projectList : Project[];
    selectedProject : Project;

    constructor(protected router:Router,
                private projectService : ProjectService, 
                private confirmationService : ConfirmationService) 
    {
      super(router);

      this.projectList = [];
      this.selectedProject = undefined;
    }

    public ngOnInit() : void
    {
        super.clearErrorMessages();
        this.retrieveProjectList();
    }

    public retrieveProjectList() : void
    {
        this.projectService.retrieveAvailable()
          .subscribe(result =>this.processProjectList(result),
                     error => this.handleError(error) );
    }

    private processProjectList(projectList : Project[]): void
    {
      this.projectList = projectList
      this.selectedProject = undefined;
    }

    public projectSelect(event : Event) : void
    {
    }

    public projectUnselect(event : Event) : void
    {
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public onUpdate() : void
    {
        if (this.selectedProject != undefined)
            this.router.navigate(['dashboard/admin/project-config-detail', this.selectedProject.projectId]);
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
     }
}
