import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Config } from './config/config';
import { BaseAppComponent } from './base-app.component';

import { PrimeNGConfig } from 'primeng/api';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

// import * as Chart from 'chart.js';

// declare var Chart : any;
declare var ga: Function; 
/*
Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      
      //Get ctx from string
      var ctx = chart.chart.ctx;

      //Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
      //Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight);

      //Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse+"px " + fontStyle;
      ctx.fillStyle = color;

      //Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  }
});
*/

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent extends BaseAppComponent
{
    constructor(private router: Router, private config : Config, protected primeNgConfig: PrimeNGConfig)
    {
      super(primeNgConfig);

      // Add the annotations plug-in
      Chart.register(annotationPlugin);        


      // If the google analytics function is defined, hook in.
      if (typeof ga != 'undefined')
      {
        ga('create', this.config.googleAnalyticsTag, 'auto');
        
        // Hook for google analytics.
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) 
          {
              ga('set', 'page', event.urlAfterRedirects);
              ga('send', 'pageview');
          }
        });
      }
      
      this.initApp();
    }

    public initApp() : void
    {
      this.inlineMenuPosition = "top";
    }
}
