import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base/service/base-http.service';
import { State } from './state';
import { Config } from '../config/config';


@Injectable()
export class StateService extends BaseHttpService
{
    stateUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.stateUrl = config.baseUrl + "/rest/state";
    }

    public retrieveAll() : Observable<State []>
    {
        let stateListUrl = this.stateUrl + "/list";
        return super.baseRetrieveList(State, stateListUrl);
    }
}