import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { EpicFactService } from '../../epic-fact/epic-fact.service';
import { EpicFact } from '../../epic-fact/epic-fact';
import { Epic } from '../../epic/epic';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'epic-status',
  templateUrl: './epic-status.component.html',
  styleUrls: ['./epic-status.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ EpicFactService ]
})

export class EpicStatusComponent extends BaseUIComponent implements OnInit { 

    @Input()
    epic : Epic;

    @Input()
    displayCosts : boolean = false;

    epicScope : EpicFact;
    epicPointsCompleted : EpicFact;
    costPerPoint : EpicFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private epicFactService: EpicFactService) 
    { 
        super(router);

        this.options = {
            indexAxis : 'y',
            plugins: {
                title: {
                    display: false,
                    text: 'Epic Progress'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
            },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    public ngOnInit() : void
    {
        this.epicFactService.retrieve(this.epic, EpicFact.EPICSCOPE)
            .subscribe(result => this.processEpicScope(result),
                       error => this.handleError(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICPOINTSCOMPLETED)
            .subscribe(result => this.processEpicPointsCompleted(result),      
                       error => this.handleError(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICCOSTPERPOINT)
            .subscribe(result =>this.processCostPerPoint(result),
                       error => this.handleError404Okay(error) );                                  
    }

    public processEpicScope(result: EpicFact) : void
    {
        this.epicScope = result;       
        this.buildGraph();
    }

    public processEpicPointsCompleted(result : EpicFact) : void
    {
        this.epicPointsCompleted = result;
        this.buildGraph();
    }

    public processCostPerPoint(result: EpicFact) : void
    {
        if (_.isEqual(this.costPerPoint, result) == false)
            this.costPerPoint = result;
    }

    public buildGraph() : void
    {
        if (this.epicScope == undefined || this.epicPointsCompleted == undefined)
            return;

        this.data = {
            labels : ['Epic'],
            datasets: [
                {
                    type:'bar',
                    label: 'Scope',
                    backgroundColor: this.BLUETRANSPARENT,
                    borderColor: this.BLUE,
                    hoverBackgroundColor: this.BLUE,
                    borderWidth: 2,
                    data: [ this.epicScope.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Points Completed',
                    backgroundColor: this.ORANGETRANSPARENT,
                    borderColor: this.ORANGE,
                    hoverBackgroundColor: this.ORANGE,
                    borderWidth: 2,
                    data: [ this.epicPointsCompleted.getFactNumber() ]
                }
            ]
        }
    }

    public percentComplete() : number
    {
        let result : number = 0.00;

        if (this.epicScope != undefined && this.epicPointsCompleted != undefined)
        {
            if (this.epicScope.getFactNumber() != 0)
            {
                result = (this.epicPointsCompleted.getFactNumber() / this.epicScope.getFactNumber() ) * 100;
            }
        }

        return result;
    }

    public get totalCost() : number
    {
        let result : number = 0;

        if (this.costPerPoint && this.epicScope)
            result = this.costPerPoint.getFactNumber() * this.epicScope.getFactNumber();

        return result;
    }
    
}
