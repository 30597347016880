import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { AppAlertService } from '../../app-alert/app-alert.service';
import { AppAlertSeverity } from '../../app-alert/app-alert-severity';
import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { Project } from '../../project/project';

@Component({
  selector: 'mini-app-alert-severity-overview',
  templateUrl: './mini-app-alert-severity-overview.component.html',
  styleUrls: ['./mini-app-alert-severity-overview.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ AppAlertService ]
})

export class MiniAppAlertSeverityOverviewComponent extends BaseUIComponent implements OnInit 
{ 
    @Input()
    project : Project;
    appAlertSeverity : AppAlertSeverity;

    data: any;
    options: any;

    constructor(protected router : Router, 
                private appAlertService : AppAlertService) 
    { 
        super(router);

        this.options = {
            responsive : true,
            maintainAspectRatio : false,
            plugins : {
                title: {
                    display: false,
                    text: 'App Alert Severity'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    ngOnInit() : void
    {
        this.appAlertService.retrieveAppAlertSeverity(this.project.projectId)
            .subscribe(result => this.processAppAlertSeverity(result) );
    }

    private processAppAlertSeverity(result: AppAlertSeverity)
    {
        this.appAlertSeverity = result;       

        this.data = {
            labels : ['Critical', 'Warning', 'Informational'],
            datasets: [
                {
                    type:'pie',
                    animations: false,
                    label: 'App Alerts',
                    data: [ this.appAlertSeverity.critical, this.appAlertSeverity.warning, this.appAlertSeverity.informational],
                    backgroundColor: [
                        this.REDTRANSPARENT,
                        this.YELLOWTRANSPARENT,
                        this.GREENTRANSPARENT
                    ],
                    hoverBackgroundColor: [
                        this.RED,
                        this.YELLOW,
                        this.GREEN
                    ],
                    borderColor: [
                        this.RED,
                        this.YELLOW,
                        this.GREEN
                    ],
                    borderWidth: [
                        2,
                        2,
                        2
                    ]
                }
            ]
        }
    }

}
