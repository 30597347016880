import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';

import { SprintFact } from '../../sprint-fact/sprint-fact'; 

import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import * as moment from 'moment';
import * as _ from "lodash"; 

@Component({
  selector: 'sprint-backlog-count-timeline',
  templateUrl: './sprint-backlog-count-timeline.component.html',
  styleUrls: ['./sprint-backlog-count-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class SprintBacklogCountTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges { 

    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;
    percentStoriesAverageFact : ProjectFact;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private projectFactService : ProjectFactService) 
    { 
        super(router);
        this.options['scales'] = {
                                    x: { stacked: true, display: false},
                                    y: { stacked: true, beginAtZero:true }
                                } 
        this.options['animations'] = false;                        
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Backlog Items Types By Sprint`;
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTSPRINTBACKLOGITEMCOUNT, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    
        this.projectFactService.retrieve(this.project, ProjectFact.PERCENTSTORIESAVERAGE)
            .subscribe(result =>this.processPercentStoriesAverageFact(result),
                       error => this.handleError404Okay(error) );
    }

    private processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        stack: 'dataset-1',
                        type:'bar',
                        label: 'Stories',
                        backgroundColor: "rgba(0, 256, 0, .40)",
                        hoverBackgroundColor: "rgba(0, 256, 0, 1)",
                        borderColor: "rgba(0, 256, 0, 1)",
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SPRINTSTORYCOUNT); } )
                    },
                    {
                        stack: 'dataset-1',
                        type:'bar',
                        label: 'Defects',
                        backgroundColor: "rgba(256, 0, 0, .40)",
                        hoverBackgroundColor: "rgba(256, 0, 0, 1)",
                        borderColor: "rgba(256, 0, 0, 1)",
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SPRINTDEFECTCOUNT); } )
                    },
                    {
                        stack: 'dataset-1',
                        type:'bar',
                        label: 'Chores',
                        backgroundColor: "rgba(0, 0, 256, .40)",
                        hoverBackgroundColor: "rgba(0, 0, 256, 1)",
                        borderColor: "rgba(0, 0, 256, 1)",
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SPRINTCHORECOUNT); } )
                    },
                    {
                        stack: 'dataset-1',
                        type:'bar',
                        label: 'Spikes',
                        backgroundColor: "rgba(256, 256, 0, .40)",
                        hoverBackgroundColor: "rgba(256, 256, 0, 1)",
                        borderColor: "rgba(256, 256, 0, 1)",
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SPRINTSPIKECOUNT); } )
                    },
                    {
                        stack: 'dataset-1',
                        type:'bar',
                        label: 'Dependendices',
                        backgroundColor: "rgba(0, 0, 0, .40)",
                        hoverBackgroundColor: "rgba(0, 0, 0, 1)",
                        borderColor: "rgba(0, 0, 0, 1)",
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SPRINTDEPENDENCYCOUNT); } )
                    },

                ]
            }
        }
    }

    private processPercentStoriesAverageFact(result : ProjectFact) : void
    {
        if (_.isEqual(this.percentStoriesAverageFact, result) == false)
            this.percentStoriesAverageFact = result;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
}