import { Directive} from '@angular/core';
import { Component, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from './base-component.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Directive()
export abstract class BaseFilledDoughnutComponent extends BaseComponent 
{ 
    data: any;
    options: any;
    plugins: any;

    constructor( protected router : Router) 
    { 
        super(router);
        
        this.options = {};
        
        this.plugins = [ {
            id: 'centerFill',
            beforeDraw: function (chart) {

                if (chart.config.options.elements.center) {
                  
                  //Get ctx from string
                  var ctx = chart.ctx;
            
                  //Get options from the center object in options
                  var centerConfig = chart.config.options.elements.center;
                  var fontStyle = centerConfig.fontStyle || 'Arial';
                  var txt = centerConfig.text;
                  var color = centerConfig.color || '#000';
                  var sidePadding = centerConfig.sidePadding || 20;
                  var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
                  //Start with a base font of 30px
                  ctx.font = "30px " + fontStyle;
            
                  //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                  var stringWidth = ctx.measureText(txt).width;
                  var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            
                  // Find out how much the font can grow in width.
                  var widthRatio = elementWidth / stringWidth;
                  var newFontSize = Math.floor(30 * widthRatio);
                  var elementHeight = (chart.innerRadius * 2);
            
                  // Pick a new font size so it will not be larger than the height of label.
                  var fontSizeToUse = Math.min(newFontSize, elementHeight);
            
                  //Set font settings to draw it correctly.
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                  var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                  ctx.font = fontSizeToUse+"px " + fontStyle;
                  ctx.fillStyle = color;
            
                  //Draw text in center
                  ctx.fillText(txt, centerX, centerY);
                }
            } 
        } ]

    }

    public configureOptions() : void
    {
        this.options = {
            animation: false,
            elements: {
                center: {
                    text: this.buildDescription(),
                    fontStyle: 'Helvetica', //Default Arial
                    sidePadding: 15 //Default 20 (as a percentage)
                },
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'bottom'
                },
            },
            cutout : '75%',
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    protected abstract buildDescription() : string;
}
