import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';

@Injectable()
export class LoginGuard implements CanActivate 
{
  constructor(private router: Router, 
              private loginService: LoginService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
  {
    let result = this.loginService.isLoggedIn();

    if (result === false)
    {
      this.router.navigate(['logout'], { queryParams: { returnUrl: state.url }});
      return false;   
    }

    return result;
  }
}