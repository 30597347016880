import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { Project } from '../project/project';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';
import { Sprint } from '../sprint/sprint';

import { DependencyFact } from '../dependency-fact/dependency-fact';

export class Dependency extends BaseBacklogItemObject
{
    public dependencyId : string;
    public dependencyFactList : DependencyFact[];

    public initialize(dependency : Dependency) : void
    {
        if (dependency == null)
            return;
        
        super.initialize(dependency);

        this.dependencyId = dependency.dependencyId;
        this.objectType = Dependency.DEPENDENCY;
        this.dependencyFactList = this.initializeList(DependencyFact, dependency.dependencyFactList);

        this.backlogItemFactList = this.dependencyFactList;
    }

    public getFact(factName : string) : DependencyFact
    {
        let result : DependencyFact = null;

        if (this.dependencyFactList != null)
        {
            for (let dependencyFact of this.dependencyFactList)
            {
                if (dependencyFact.name == factName)
                {
                    result = dependencyFact;
                    break;
                }
            }
        }

        return result;
    }
}