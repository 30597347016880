import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class Config {
    
    private static host : string = undefined;

    baseUrl : string;
    googleAnalyticsTag : string;

    constructor() 
    {
        this.setHostName();

        if (environment.production == true) 
        {   
            // Set environment up for production.
            this.baseUrl = "https://app.lucidlift.com/lucidlift";
            this.googleAnalyticsTag = "UA-108412691-1";
            return;
        }
        
        if (environment.alpha == true) 
        {   
            // Set environment up for alpha.
            this.baseUrl = "https://alpha.lucidlift.com/lucidlift";
//            this.baseUrl = `${Config.host}/lucidlift`;
            this.googleAnalyticsTag = "UA-108412691-3";
            return;
        }

        if (environment.standalone == true) 
        {   
            // Set environment up for standalone install
            this.baseUrl = `${Config.host}/lucidlift`;
            this.googleAnalyticsTag = "UA-108412691-2";
            return;
        }


        if (environment.abbott == true) 
        {   
            // Set environment up for Abbott install
            this.baseUrl = `${Config.host}/lucidlift`;
            this.googleAnalyticsTag = "UA-108412691-1";
            return;
        }
        
        if (environment.server == true) 
        {   
            // Set environment up for running as a server
            this.baseUrl = `http://10.0.0.4:8080/lucidlift.ddt`;
            this.googleAnalyticsTag = "UA-108412691-2";
            return;
        }

        // Set environment up for development.
        this.baseUrl = "http://localhost:8080/lucidlift.ddt";
        this.googleAnalyticsTag = "UA-108412691-2";
    }

    protected setHostName() 
    {
        if (Config.host == undefined)
        {
            var parser = document.createElement('a');
            parser.href = window.location.href;
                    
            Config.host = `${parser.protocol}//${parser.host}`;
        }
    }
}