import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base/service/base-http.service';
import { Epic } from './epic';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class EpicService extends BaseHttpService
{
    epicUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.epicUrl = this.config.baseUrl + '/rest/epic';
    }

    public retrieve(epicId : string ) : Observable<Epic>
    {
        let epicDetailUrl : string = this.epicUrl + "/" + epicId;

        return super.baseRetrieveObject(Epic, epicDetailUrl);        
    }

    public retrieveActiveEpics(projectId : string) : Observable<Epic[]>
    {
        let activeEpicsUrl = this.epicUrl + "/active/" + projectId; 

        return super.baseRetrieveList(Epic, activeEpicsUrl);
    }

    public retrieveFuture(projectId : string, startAt : number, maxResult : number, sortBy : string, direction : number) : Observable<ValueObjectList<Epic>>
    {
        let futureEpicsUrl = `${this.epicUrl}/future/${projectId}/subset?sortBy=${sortBy}&direction=${direction}`; 
        return super.baseRetrieveSubset(Epic, futureEpicsUrl, startAt, maxResult);
    }

    public retrieveCompleted(projectId : string, startAt: number, maxResult : number, sortBy : string, direction : number) : Observable<ValueObjectList<Epic>>
    {
        let completedEpicsUrl = `${this.epicUrl}/completed/${projectId}/subset?sortBy=${sortBy}&direction=${direction}`;
        return super.baseRetrieveSubset(Epic, completedEpicsUrl, startAt, maxResult);
    }

    public retrieveInactive(projectId : string, startAt: number, maxResult : number, sortBy : string, direction : number) : Observable<ValueObjectList<Epic>>
    {
        let inactiveEpicsUrl = `${this.epicUrl}/inactive/${projectId}/subset?sortBy=${sortBy}&direction=${direction}`;
        return super.baseRetrieveSubset(Epic, inactiveEpicsUrl, startAt, maxResult);
    }

    public retrieveOrganizationDashboard() : Observable<Epic[]>
    {
        let dashboardUrl = `${this.epicUrl}/dashboard`;
        return super.baseRetrieveList(Epic, dashboardUrl);
    }

}