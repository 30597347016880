import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';
import { SourceSystem } from '../source-system/source-system';
import { OrgSourceSystem } from '../org-source-system/org-source-system';
import { BacklogItem } from './backlog-item';

import { ContentDetail } from '../content/content-detail';

import { UserStoryFact } from '../user-story-fact/user-story-fact';
import { DefectFact } from '../defect-fact/defect-fact';
import { ChoreFact } from '../chore-fact/chore-fact';
import { SpikeFact } from '../spike-fact/spike-fact';
import { DependencyFact } from '../dependency-fact/dependency-fact';
import { ProjectFact } from '../project-fact/project-fact';

import { Table } from 'primeng/table';

@Component({
  selector: 'backlog-item-list',
  templateUrl: './backlog-item-list.component.html',
  styleUrls: ['./backlog-item-list.component.scss' ],
  providers: [  ]
})

export class BacklogItemListComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    selectedBacklogItem : BacklogItem;

    @Input()
    displaySprint : boolean = false;

    @Input()
    rows : number = 0;

    @Input()
    total : number = 0;

    @Input()
    paginator : boolean = false;

    @Input()
    lazy : boolean = false;

    @Input()
    rowStyleClass : Function = null;

    @Input()
    showFacts : boolean = false;

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    @Input()
    orgSourceSystem : OrgSourceSystem;

    @Input()
    backlogItemList : BacklogItem[];

    @Input()
    ghostStoryList : BacklogItem[];

    @Input()
    costPerPointFact : ProjectFact;

    @Input()
    displayCosts : boolean;

    viewBacklogItemList : BacklogItem[];

    @Input()
    completed: boolean
    lastCompleted : boolean;

    @ViewChild(Table) tableComponent: Table;

    constructor(protected router : Router) 
    { 
        super(router);
        this.completed = true;
        this.lastCompleted = true;
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void
    {
        this.initViewStoryList();
    }

    public invokeCallback(backlogItem : BacklogItem, index : number) : string
    {
        let result : string = undefined;

        if (this.rowStyleClass != null)
            result = this.rowStyleClass(backlogItem, index);

        return result;
    }

    public reloadList()
    {
        this.reset();
    }

    public initViewStoryList() : void
    {
        if (this.backlogItemList != undefined)
        {
            if (this.completed != this.lastCompleted)
            {
                this.reset();
            }
            
            this.viewBacklogItemList = new Array<BacklogItem>();

            if (this.completed == true)
            {
                for (let backlogItem of this.backlogItemList)
                {
                    if (backlogItem.isCompleted() == true)
                        this.addViewBacklogItemList(backlogItem);
                }
            }

            // Put all the open stories after the completed stories (when displayed)
            for (let backlogItem of this.backlogItemList)
            {
                if (backlogItem.isCompleted() == false)
                    this.addViewBacklogItemList(backlogItem);
            }

            // Finally, put all the ghost stories at the bottom
            if (this.ghostStoryList != undefined)
            {
                for (let ghostStory of this.ghostStoryList)
                {
                    ghostStory.ghostStory = true;
                    this.addViewBacklogItemList(ghostStory);
                }
            }
            
            this.lastCompleted = this.completed;
        }
    }

    private addViewBacklogItemList(backlogItem : BacklogItem) : void 
    {
        if (this.showFacts == true)
            backlogItem.showFacts = true;

        this.viewBacklogItemList.push(backlogItem);
    }

    public loadData(event : any) 
    {
        //event.first = First row offset
        //event.rows = Number of rows per page
        //add more records to the cars array
        this.onLazyLoad.emit(event);
    }

    public reset() : void
    {
        if (this.tableComponent != undefined)
            this.tableComponent.reset();
    }

    public calculateCost(backlogItem : BacklogItem) : number
    {
        let result : number = undefined;

        if (this.displayCosts != undefined && this.displayCosts == true)
        {
            if (backlogItem.storyPoints != undefined && this.costPerPointFact != undefined)
            {
                result = backlogItem.storyPoints * this.costPerPointFact.getFactNumber();
            }
        }

        return result;
    }

    public sprintsWorkedOn(backlogItem : BacklogItem) : number
    {
        let result : number = 0;

        if (backlogItem.backlogItemFactList != null)
        {
            if (backlogItem.getFact(UserStoryFact.SPRINTSWORKEDON) != null && backlogItem.isUserStory() )
            {
                let sprintsWorkedOnFact : Array<string> = backlogItem.getFact(UserStoryFact.SPRINTSWORKEDON).getFactStringList();
                result = sprintsWorkedOnFact.length;
            }

            if (backlogItem.getFact(DefectFact.SPRINTSWORKEDON) != null && backlogItem.isDefect() )
            {
                let sprintsWorkedOnFact : Array<string> = backlogItem.getFact(DefectFact.SPRINTSWORKEDON).getFactStringList();
                result = sprintsWorkedOnFact.length;
            }

            if (backlogItem.getFact(ChoreFact.SPRINTSWORKEDON) != null && backlogItem.isChore() )
            {
                let sprintsWorkedOnFact : Array<string> = backlogItem.getFact(ChoreFact.SPRINTSWORKEDON).getFactStringList();
                result = sprintsWorkedOnFact.length;
            }

            if (backlogItem.getFact(SpikeFact.SPRINTSWORKEDON) != null && backlogItem.isSpike() )
            {
                let sprintsWorkedOnFact : Array<string> = backlogItem.getFact(SpikeFact.SPRINTSWORKEDON).getFactStringList();
                result = sprintsWorkedOnFact.length;
            }

            if (backlogItem.getFact(DependencyFact.SPRINTSWORKEDON) != null && backlogItem.isDependency() )
            {
                let sprintsWorkedOnFact : Array<string> = backlogItem.getFact(DependencyFact.SPRINTSWORKEDON).getFactStringList();
                result = sprintsWorkedOnFact.length;
            }
            
        }

        return result;
    }

    public missingDescription(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.isUserStory() == true)
        {
            if (backlogItem.backlogItemFactList != null)
            {
                if (backlogItem.getFact(UserStoryFact.MISSINGDESCRIPTION) != null)
                    if (backlogItem.getFact(UserStoryFact.MISSINGDESCRIPTION).getFactBoolean() == true)
                        result = true;
            }
        }

        return result;
    }

    public poorAgileStory(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.isUserStory() == true)
        {
            // If the story is missing a description, do not show agile
            // form or acceptance criteria.
            if (backlogItem.backlogItemFactList != null && this.missingDescription(backlogItem) == false)
            {
                if (backlogItem.getFact(UserStoryFact.POORAGILESTORY) != null)
                    if (backlogItem.getFact(UserStoryFact.POORAGILESTORY).getFactBoolean() == true)
                        result = true;
            }
        }

        return result;
    }

    public poorAcceptanceCriteria(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.isUserStory() == true)
        {
            // If the story is missing a description, do not show agile
            // form or acceptance criteria.
            if (backlogItem.backlogItemFactList != null && this.missingDescription(backlogItem) == false)
            {
                if (backlogItem.getFact(UserStoryFact.POORACCEPTANCECRITERIA) != null)
                    if (backlogItem.getFact(UserStoryFact.POORACCEPTANCECRITERIA).getFactBoolean() == true)
                        result = true;
            }
        }

        return result;
    }

    public addedToCurrentSprint(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;

        if (backlogItem.isUserStory() == true)
        {
            if (backlogItem.backlogItemFactList != null)
            {
                if (backlogItem.getFact(UserStoryFact.ADDEDTOCURRENTSPRINT) != null)
                    if (backlogItem.getFact(UserStoryFact.ADDEDTOCURRENTSPRINT).getFactNumber() > 0)
                        result = true;
            }
        }

        return result;
    }

    public changedMidSprint(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.isUserStory() == true)
        {
            if (backlogItem.backlogItemFactList != null)
            {
                if (backlogItem.getFact(UserStoryFact.CHANGEDMIDSPRINT) != null)
                    if (backlogItem.getFact(UserStoryFact.CHANGEDMIDSPRINT).getFactNumber() > 0)
                        result = true;
            }
        }

        return result;
    }

    public hasPointChangeDetail(backlogItem : BacklogItem) : boolean
    {
        let result = false;

        if (backlogItem.isUserStory() == true)
        {
            if (backlogItem.backlogItemFactList != null)
            {
                if (backlogItem.getFact(UserStoryFact.POINTCHANGEDETAIL) != null)
                {
                    result = true;
                }
            }
        }

        return result;
    }


    public blocked(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.backlogItemFactList != null)
        {
            if (backlogItem.getFact(UserStoryFact.BLOCKED) != null)
                if (backlogItem.getFact(UserStoryFact.BLOCKED).getFactStringList().length > 0)
                    result = true;

            if (backlogItem.getFact(DefectFact.BLOCKED) != null)
                if (backlogItem.getFact(DefectFact.BLOCKED).getFactStringList().length > 0)
                    result = true;

            if (backlogItem.getFact(ChoreFact.BLOCKED) != null)
                if (backlogItem.getFact(ChoreFact.BLOCKED).getFactStringList().length > 0)
                    result = true;

            if (backlogItem.getFact(SpikeFact.BLOCKED) != null)
                if (backlogItem.getFact(SpikeFact.BLOCKED).getFactStringList().length > 0)
                    result = true;

            if (backlogItem.getFact(DependencyFact.BLOCKED) != null)
                if (backlogItem.getFact(DependencyFact.BLOCKED).getFactStringList().length > 0)
                    result = true;
                        
        }

        return result;
    }

    public openBacklogItemClosedSprint(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.backlogItemFactList != null)
        {
            if (backlogItem.getFact(UserStoryFact.OPENSTORYCLOSEDSPRINT) != null)
                if (backlogItem.getFact(UserStoryFact.OPENSTORYCLOSEDSPRINT).getFactBoolean() == true)
                    result = true;

            if (backlogItem.getFact(DefectFact.OPENDEFECTCLOSEDSPRINT) != null)
                if (backlogItem.getFact(DefectFact.OPENDEFECTCLOSEDSPRINT).getFactBoolean() == true)
                    result = true;

            if (backlogItem.getFact(ChoreFact.OPENCHORECLOSEDSPRINT) != null)
                if (backlogItem.getFact(ChoreFact.OPENCHORECLOSEDSPRINT).getFactBoolean() == true)
                    result = true;

            if (backlogItem.getFact(SpikeFact.OPENSPIKECLOSEDSPRINT) != null)
                if (backlogItem.getFact(SpikeFact.OPENSPIKECLOSEDSPRINT).getFactBoolean() == true)
                    result = true;

            if (backlogItem.getFact(DependencyFact.OPENDEPENDENCYCLOSEDSPRINT) != null)
                if (backlogItem.getFact(DependencyFact.OPENDEPENDENCYCLOSEDSPRINT).getFactBoolean() == true)
                    result = true;
        }

        return result;
    }


    public pointsInRange(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = true;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.backlogItemFactList != null)
        {
            if (backlogItem.getFact(UserStoryFact.POINTSINRANGE) != null)
                if (backlogItem.getFact(UserStoryFact.POINTSINRANGE).getFactBoolean() == false)
                    result = false;

            if (backlogItem.getFact(DefectFact.POINTSINRANGE) != null)
                if (backlogItem.getFact(DefectFact.POINTSINRANGE).getFactBoolean() == false)
                    result = false;

            if (backlogItem.getFact(ChoreFact.POINTSINRANGE) != null)
                if (backlogItem.getFact(ChoreFact.POINTSINRANGE).getFactBoolean() == false)
                    result = false;

            if (backlogItem.getFact(SpikeFact.POINTSINRANGE) != null)
                if (backlogItem.getFact(SpikeFact.POINTSINRANGE).getFactBoolean() == false)
                    result = false;

            if (backlogItem.getFact(DependencyFact.POINTSINRANGE) != null)
                if (backlogItem.getFact(DependencyFact.POINTSINRANGE).getFactBoolean() == false)
                    result = false;
        }

        return result;
    }

    public pointsOnStory(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = true;

        if (backlogItem.showFacts == false)
            return result;
    
        if (backlogItem.backlogItemFactList != null)
        {
            if (backlogItem.getFact(UserStoryFact.POINTSONSTORY) != null)
                if (backlogItem.getFact(UserStoryFact.POINTSONSTORY).getFactBoolean() == false)
                    result = false;
        }

        return result;
    }


    public hasCarriedOver(backlogItem : BacklogItem) : boolean
    {
        let result : boolean = false;

        if (this.sprintsWorkedOn(backlogItem) > 1)
            result = true;

        return result;
    }

    public buildSourceSystemLink(backlogItem : BacklogItem) : string
    {
        let result = undefined;

        if (this.orgSourceSystem != undefined)
            result = this.orgSourceSystem.buildSourceSystemLink(backlogItem);

        return result;
    }

    public get CARRYOVERKEY() : string
    {
        return ContentDetail.CARRYOVERKEY;
    }

    public get GOODSTORIES() : string
    {
        return ContentDetail.GOODSTORIES;
    }

    public get POINTSONDEFECTS() : string
    {
        return ContentDetail.POINTSONDEFECTS;
    }

    public get POINTSONCHORES() : string
    {
        return ContentDetail.POINTSONCHORES;
    }

    public get ACCEPTANCECRITERIA() : string
    {
        return ContentDetail.ACCEPTANCECRITERIA;
    }

    public get ADDEDTOSPRINT() : string
    {
        return ContentDetail.ADDEDTOSPRINT;
    }

    public get POINTSCHANGED() : string
    {
        return ContentDetail.POINTSCHANGED;
    }

    public get BLOCKERS() : string
    {
        return ContentDetail.BLOCKERS;
    }

    public get STORYPOINTS() : string 
    {
        return ContentDetail.STORYPOINTS;
    }

}
