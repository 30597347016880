import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { Project } from '../project/project';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';
import { Sprint } from '../sprint/sprint';

import { SpikeFact } from '../spike-fact/spike-fact';

export class Spike extends BaseBacklogItemObject
{
    public spikeId : string;
    public spikeFactList : SpikeFact[];

    public initialize(spike : Spike) : void
    {
        if (spike == null)
            return;
        
        super.initialize(spike);

        this.spikeId = spike.spikeId;
        this.objectType = Spike.SPIKE;
        this.spikeFactList = this.initializeList(SpikeFact, spike.spikeFactList);

        this.backlogItemFactList = this.spikeFactList;
    }

    public getFact(factName : string) : SpikeFact
    {
        let result : SpikeFact = null;

        if (this.spikeFactList != null)
        {
            for (let spikeFact of this.spikeFactList)
            {
                if (spikeFact.name == factName)
                {
                    result = spikeFact;
                    break;
                }
            }
        }

        return result;
    }
}