import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
//  styleUrls: [ ]
})

export class HomeComponent { 

  constructor(router : Router )
  {
    router.navigate(['dashboard']);
  }
}
