<div class="card overview project-sprint-history">
    <div class="overview-content clearfix">
        <span class="overview-title">Sprint History</span>
        <!-- Put average sprint commitment attainment percentage here
        <span *ngIf="appAlertCountTrend" class="overview-badge">
            <i *ngIf="appAlertCountTrend == 'up'" class="material-icons" pTooltip="Trending Up">trending_up</i>
            <i *ngIf="appAlertCountTrend == 'down'" class="material-icons" pTooltip="Trending Down">trending_down</i>
            <i *ngIf="appAlertCountTrend == 'neutral'" class="material-icons" pTooltip="Trending Flat">trending_flat</i>
        </span>
        -->
        <span *ngIf="numberOfSprintsFact" class="overview-detail">{{numberOfSprintsFact.getFactNumber()}} sprints completed</span>
    </div>
    <div class="overview-footer">
        <div *ngIf="timeSeries">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </div>
</div>