import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesItem } from '../../time-series/time-series-item';

import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { BaseSprint } from '../../base/value-object/base-sprint';

import * as moment from 'moment';
import * as _ from "lodash"; 

@Component({
  selector: 'sprint-burndown-timeline',
  templateUrl: './sprint-burndown-timeline.component.html',
  styleUrls: ['./sprint-burndown-timeline.component.scss' ],
  providers: [ TimeSeriesService, SprintFactService ],
  encapsulation: ViewEncapsulation.None,
})

export class SprintBurndownTimelineComponent extends BaseUIComponent implements OnInit, OnChanges { 

    @Input()
    sprint : BaseSprint;
    
    timeSeries : TimeSeries;
    pointsRemainingFact : SprintFact;
    beginningSprintFact : SprintFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private sprintFactService : SprintFactService) 
    { 
        super(router);

        this.options = {
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Sprint Burndown'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.sprint.project, this.sprint.sprintId, TimeSeries.SPRINTBURNDOWN, 100)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    
        this.sprintFactService.retrieve(this.sprint, SprintFact.POINTSREMAINING)
            .subscribe(result => this.processPointsRemainingFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.BEGINNINGSPRINT)
            .subscribe(result => this.processBeginningSprintFact(result),
                       error => this.handleError404Okay(error) );
            
    }

    private processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       
            let displayTimeSeries : TimeSeries = new TimeSeries();

            if (this.timeSeries.timeSeriesList.length == 0)
            {
                let startTimeSeriesItem : TimeSeriesItem = new TimeSeriesItem();
                let endTimeSeriesItem : TimeSeriesItem = new TimeSeriesItem();
                startTimeSeriesItem.timestamp = this.sprint.sprintBeginDate;
                startTimeSeriesItem.setFieldNumber(SprintFact.POINTSREMAINING, 0);
                endTimeSeriesItem.timestamp = this.sprint.sprintEndDate;
                endTimeSeriesItem.setFieldNumber(SprintFact.POINTSREMAINING, 0);
                displayTimeSeries.timeSeriesList.push(startTimeSeriesItem);
                displayTimeSeries.timeSeriesList.push(endTimeSeriesItem);
            }

            if (this.timeSeries.timeSeriesList.length == 1)
            {
                let startTimeSeriesItem : TimeSeriesItem = new TimeSeriesItem();
                let endTimeSeriesItem : TimeSeriesItem = new TimeSeriesItem();
                startTimeSeriesItem.timestamp = this.sprint.sprintBeginDate;
                startTimeSeriesItem.setFieldNumber(SprintFact.POINTSREMAINING, this.timeSeries.timeSeriesList[0].getFieldNumber(SprintFact.POINTSREMAINING) );
                endTimeSeriesItem.timestamp = this.sprint.sprintEndDate;
                endTimeSeriesItem.setFieldNumber(SprintFact.POINTSREMAINING, this.timeSeries.timeSeriesList[0].getFieldNumber(SprintFact.POINTSREMAINING) );
                displayTimeSeries.timeSeriesList.push(startTimeSeriesItem);
                displayTimeSeries.timeSeriesList.push(endTimeSeriesItem);
            }

            if (this.timeSeries.timeSeriesList.length > 1)
                displayTimeSeries = this.timeSeries;

            this.data = {
                labels : displayTimeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'line',
                        fill: true,
                        tension: .5,
                        label: 'Points Remaining',
                        backgroundColor: this.GREENTRANSPARENT,
                        hoverBackgroundColor: this.GREEN,
                        borderColor: this.GREEN,
                        borderWidth: 2,
                        data: displayTimeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.POINTSREMAINING); } )
                    }
                ]
            }
        }
    }

    private processPointsRemainingFact(result : SprintFact) : void
    {
        if (_.isEqual(this.pointsRemainingFact, result) == false)
            this.pointsRemainingFact = result;
    }

    private processBeginningSprintFact(result : SprintFact) : void
    {
        this.beginningSprintFact = result;
    }

}