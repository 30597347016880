import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { OrgSourceSystemService } from '../../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../../org-source-system/org-source-system';

import { BaseComponent } from '../../base/component/base-component.component';

@Injectable()
@Component({
  selector: 'org-source-system-list',
  templateUrl: './org-source-system-list.component.html',
  styleUrls: ['./org-source-system-list.component.css'],
  providers: [ OrgSourceSystemService, ConfirmationService ]
})
export class OrgSourceSystemListComponent extends BaseComponent implements OnInit
{ 
    orgSourceSystemList : OrgSourceSystem[];
    selectedOrgSourceSystem : OrgSourceSystem;

    blockedDocument : boolean = false;

    constructor(protected router:Router,
                private confirmationService : ConfirmationService,
                private orgSourceSystemService: OrgSourceSystemService ) 
    {
      super(router);
    }

    ngOnInit() : void
    {
        this.blockedDocument = false;

        super.clearErrorMessages();
        this.retrieveList();
    }

    public retrieveList() : void
    {
        this.blockedDocument = false;
        
        this.orgSourceSystemService.retrieveAll()
          .subscribe(result =>this.processOrgSourceSystemList(result) );
    }

    private processOrgSourceSystemList(orgSourceSystemList : OrgSourceSystem[]): void
    {
      this.orgSourceSystemList = orgSourceSystemList
      this.selectedOrgSourceSystem = null;
    }

    public onDelete() : void
    {
        super.clearErrorMessages();
        
        this.confirmationService.confirm(
            { message: 'Are you sure you wish to delete the selected row?',
                accept: () => 
                {
                this.deleteOrgSourceSystem(this.selectedOrgSourceSystem);
                }
            });
    }

    public onAdd() : void
    {
        this.router.navigate(['/dashboard/admin/org-source-system-add']);
    }

    public onUpdate() : void
    {
        this.router.navigate(['/dashboard/admin/org-source-system-update', this.selectedOrgSourceSystem.orgSourceSystemId]);
    }

    public onCancel() : void
    {
       this.router.navigate(['/dashboard']);
    }


    private deleteOrgSourceSystem(orgSourceSystem : OrgSourceSystem ) : void
    {
        this.blockedDocument = true;

        this.orgSourceSystemService.delete(orgSourceSystem)
          .subscribe(result => this.deleteSuccessful(),
                     error => this.handleError(error) );
    }

    protected deleteSuccessful() : void
    {
        this.blockedDocument = false;
        super.clearErrorMessages();
        super.addInfoMessage("Delete successful!");
        this.retrieveList();
    }

    protected handleError(error : any) :  void
    {
        this.blockedDocument = false;
        super.clearErrorMessages();
        super.addErrorMessage("Error occurred.  Please try again");
     }

}
