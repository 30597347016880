<div class="main-component">
    <div *ngIf="projectList != undefined && projectList.length == 0">
            <div *ngIf="content" class="grid card card-w-title">
                    <h3 class="col-12">{{content.contentTitle}}</h3>
                    <h4 class="col-12">{{content.contentSummary}}</h4>
                    <div class="col-12 content-detail">
                        <div [innerHTML]="provideContentDetail()">
                        </div>
                    </div>
            </div>
    </div>
    <!--
    <div *ngIf="enableOrganizationSummary != undefined && enableOrganizationSummary == true">
        <organization-summary *ngIf="projectList != undefined && projectList.length > 1 && userAccount != undefined" [userAccount]="userAccount"></organization-summary>
    </div>
    -->

    <!--
    <div *ngIf="enableOrganizationSummary != undefined && enableOrganizationSummary == false">
    -->
        <div *ngIf="projectList != undefined && projectList.length > 0">
            <div *ngFor="let project of projectList">
                <project-summary [project]="project"></project-summary>
            </div>
        </div>
    <!---
    </div>
    --->
</div>
