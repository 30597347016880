import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { PasswordReset } from './password-reset';
import { Config } from '../config/config';

@Injectable()
export class ResetPasswordService extends BaseHttpService
{
    resetPasswordUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.resetPasswordUrl = config.baseUrl + "/rest/reset-password";
    }

    public retrieve(resetTokenId : string) : Observable<PasswordReset>
    {
        let url = this.resetPasswordUrl + "/" + resetTokenId;
        return super.baseRetrieveObject(PasswordReset, url);
    }

    public resetPassword(passwordReset: PasswordReset): Observable<string> 
    {
        let requestUrl = this.resetPasswordUrl;
        let data = JSON.stringify(passwordReset);

        return this.http.put(requestUrl, data, { headers: this.createHeaders(), responseType: 'text'} );
    }

    protected createHeaders() : HttpHeaders
    {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return headers;
    }

}