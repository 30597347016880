import { Directive, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';
import { ConfirmationService } from 'primeng/api';

import { Project } from '../project/project';
import { ProjectService } from '../project/project.service';

import { ProjectTeamCostService } from './project-team-cost.service';
import { DateUtils } from '../utilities/date-utils';

import { LLUtils } from '../utilities/ll-utils';

@Directive()
export abstract class BaseProjectTeamCostComponent extends BaseComponent implements OnInit 
{ 
    projectId : string;
    project : Project;

    constructor( protected router: Router) 
    { 
        super(router);        
    }

    public abstract loadData() : void;

    public ngOnInit() : void
    {
        this.clearErrorMessages();
    }

    public onCancel() : void
    {
        this.router.navigate(['/dashboard/project-team-cost-list', this.projectId] );
    }

    public validatePage(pEffectiveDate : Date, pEndDate : Date, pCurrentDateEndDate : boolean, pCostPerMonth : number) : boolean 
    {
        let result = true;

        if (pEffectiveDate == undefined)
        {
            this.addErrorMessage("Effective Date cannot be empty.");
            return(false);
        }

        if (pEndDate == undefined && pCurrentDateEndDate == false)
        {
            this.addErrorMessage("End Date cannot be empty.");
            return(false);
        }

        if (LLUtils.isNumeric(pCostPerMonth) == false || pCostPerMonth <= 0)
        {
            this.addErrorMessage("Cost Per Month must be provided");
            return(false);
        }

        if (pCurrentDateEndDate == false)
        {
            if (DateUtils.isDateAfter(pEffectiveDate, pEndDate) == false)
            {
                this.addErrorMessage("Effective Date must come before the End Date.");
                result = false;
            }
        }
        
        return result;
    }
}
