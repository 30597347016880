<p-card class="quality-stories-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Quality Stories&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="qualityStoriesTrend" class="overview-badge">
                    <i *ngIf="qualityStoriesTrend == 'up'" class="material-icons" pTooltip="Trending Up" tooltipPosition="left">trending_up</i>
                    <i *ngIf="qualityStoriesTrend == 'down'" class="material-icons" pTooltip="Trending Down" tooltipPosition="left">trending_down</i>
                    <i *ngIf="qualityStoriesTrend == 'neutral'" class="material-icons" pTooltip="Trending Flat" tooltipPosition="left">trending_flat</i>
                </span>
        
                <span>
                    <a [routerLink]="['/dashboard/content-detail/key', QUALITYSTORIESTREND]" [queryParams]="{ projectId: project.projectId }">
                        <i pTooltip="More Information - Quality Story Trend" tooltipPosition="left" class="content-icon material-icons">description</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="qualityStoriesAverage" tooltipPosition="bottom" pTooltip="Three Sprint Average">{{ (qualityStoriesAverage.getFactNumber() * 100.00) | round:2 | number:'1.0-0'}}%</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>

<zoom-graph #zoomGraph type="line" [sourceData]="data" [sourceOptions]="options"></zoom-graph>