<div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
        <div *ngIf="displayDefaultImage == true" class="layout-topbar-logo">
            <div class="logo"></div>
        </div>
        <div *ngIf="orgImage != undefined" class="layout-topbar-logo">
            <div class="custom-logo">
                <img *ngIf="orgImage.orgImage != undefined" [src]="getImage()" width="180" height="40" />
            </div>
        </div>

        <a class="layout-menu-button shadow-6" (click)="dashboard.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>

        <a class="layout-topbar-mobile-button" (click)="dashboard.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': dashboard.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">
            <!-- filler -->
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item layout-search-item">
                    <a class="layout-topbar-action rounded-circle" (click)="dashboard.onTopbarItemClick($event, 'search')" pRipple>
                        <i class="pi pi-search fs-large"></i>
                    </a>

                    <div class="layout-search-panel p-inputgroup" [@topbarActionPanelAnimation]="'visible'" *ngIf="dashboard.search" (@topbarActionPanelAnimation.done)="onSearchAnimationEnd($event)">
                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                        <input #searchInput type="text" pInputText placeholder="Search" [(ngModel)]="searchText" (click)="dashboard.searchClick = true;" (keyup.enter)="onSearch($event)">
                        <span class="p-inputgroup-addon">
                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" (click)="dashboard.searchClick = false;"></button>
                        </span>
                    </div>
                </li>
                <li class="layout-topbar-item notifications">
                    <a class="layout-topbar-action rounded-circle" (click)="dashboard.onTopbarItemClick($event, 'notifications')" pRipple>
                        <span class="p-overlay-badge">
                            <i class="material-icons">inbox</i>
                            <span class="p-badge p-badge-warning">{{ unreadInboxItemCount }}</span>
                        </span>
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="dashboard.activeTopbarItem === 'notifications'">
                        <li class="layout-topbar-action-item" *ngFor="let inboxItem of inboxItemList">
                            <div class="flex flex-row align-items-center">
                                <a [routerLink]="['inbox-item-detail', inboxItem.inboxItemId]">
                                    <div class="topbar-menuitem flex flex-row">
                                        <i *ngIf="inboxItem.read == true" class="material-icons">drafts</i>
                                        <i *ngIf="inboxItem.read == false" class="material-icons">mail</i>
                                        <div>{{inboxItem.subject}}</div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row justify-content-center align-content-center">
                                <span>Click&nbsp;<a [routerLink]="['inbox-item-list']">here</a>&nbsp;for Inbox</span>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="layout-topbar-item app">
                    <a class="layout-topbar-action rounded-circle" (click)="dashboard.onTopbarItemClick($event, 'settings')" pRipple>
                        <i class="material-icons">settings</i>
                    </a>
                    <div class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="dashboard.activeTopbarItem === 'settings'">
                        <div class="grid grid-nogutter">
                            <div *ngIf="userAccount.isAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/org-source-system-list']" class="flex align-items-center flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">verified_user</i>
                                        <span>Sources</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/project-filter']" class="flex align-items-center flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">input</i>
                                        <span>Select Projects</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/org-config']" class="flex align-items-center flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">subject</i>
                                        <span>Configure Organization</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/project-config-list']" class="flex align-items-center flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">group</i>
                                        <span>Configure Projects</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/org-job-schedule']" class="flex align-items-center flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">timer</i>
                                        <span>Job Schedule</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/user-account-list']" class="flex align-items-center flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">supervisor_account</i>
                                        <span>Users</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin() && userAccount.isContentAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/goal-text']" class="flex flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">check_circle</i>
                                        <span>Manage Goal Text</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin() && userAccount.isContentAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/alert-text']" class="flex flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">add_alert</i>
                                        <span>Manage Alert Text</span>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="userAccount.isAdmin() && userAccount.isContentAdmin()" class="layout-topbar-action-item col-12">
                                <a [routerLink]="['/dashboard/admin/content-list']" class="flex flex-column text-color" pRipple>
                                    <span class="topbar-menuitem col-12 flex align-content-center">
                                        <i class="material-icons">description</i>
                                        <span>Manage Content</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
