import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { InboxItemService } from '../inbox-item/inbox-item.service';
import { InboxItem } from '../inbox-item/inbox-item';
import { ValueObjectList } from '../base/value-object/value-object-list';

import { ConfirmationService } from 'primeng/api';
// import { DataTable } from 'primeng/primeng';

@Component({
  selector: 'inbox-item-list',
  templateUrl: './inbox-item-list.component.html',
  styleUrls: ['./inbox-item-list.component.css' ],
  providers: [ ConfirmationService, InboxItemService ]
})

export class InboxItemListComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    rows : number = 10;

    @Input()
    total : number = 0;

    @Input()
    paginator : boolean = true;

    @Input()
    lazy : boolean = true;

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    inboxItemList : ValueObjectList<InboxItem>;
    itemToDeleteList : Array<string>;

//    @ViewChild(DataTable) dataTableComponent: DataTable;

    constructor( protected router : Router,
                 private confirmationService : ConfirmationService,
                 private inboxItemService : InboxItemService)
    { 
        super(router);
    }

    public ngOnInit() : void
    {
    }

    public onDeleteChecked(inboxItem : InboxItem, checkedEvent : boolean)
    {
        if (checkedEvent)
        {
            this.itemToDeleteList.push(inboxItem.inboxItemId);
        }
        else
        {
            let i : number = this.itemToDeleteList.indexOf(inboxItem.inboxItemId);

            if (i != -1)
                this.itemToDeleteList.splice(i, 1);
        }
    }

    private processInboxItems(result : ValueObjectList<InboxItem>) : void
    {
        this.inboxItemList = result;
        this.total = this.inboxItemList.total;
        this.itemToDeleteList = new Array<string>();
    }

    public loadData(event : any) 
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.inboxItemService.retrieveList(startAt, maxResult)
            .subscribe(result => this.processInboxItems(result),
                       error => this.handleError(error) ); 
    }

    public onDelete() : void
    {
        this.confirmationService.confirm(
            { message: 'Are you sure you wish to delete the selected inbox items?',
                accept: () => 
                {
                    super.clearErrorMessages();
                    this.inboxItemService.deleteList(this.itemToDeleteList)
                        .subscribe(result => this.deleteInboxItemSuccessful(result),
                                   error => this.handleError(error) );
                }
            });
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public deleteInboxItemSuccessful(result : any) : void
    {
        this.addInfoMessage("Delete Successful!");
//        this.dataTableComponent.reset();
    }
    
}
