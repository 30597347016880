import { BaseValueObject } from '../base/value-object/base-value-object';

export class Zipcode extends BaseValueObject
{
    zipcode : string;
    stateId : string;
    cityName : string;

    public initialize(zipcode : Zipcode) : void
    {
        if (zipcode == null)
            return;
        
        super.initialize(zipcode);

        this.zipcode = zipcode.zipcode;
        this.stateId = zipcode.stateId;
        this.cityName = zipcode.cityName;
    }
}
