import { PipeTransform, Pipe } from '@angular/core';
import { LLUtils } from '../utilities/ll-utils';

@Pipe({ name: 'round' })
export class RoundPipe implements PipeTransform 
{
    transform(value: number, precision? : number): number 
    {
        let result : number = LLUtils.round(value, precision);
        return result;
  }
}