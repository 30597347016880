import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';
import { ConfirmationService } from 'primeng/api';

import { Project } from '../../project/project';
import { ProjectService } from '../../project/project.service';

import { ProjectConfig } from './project-config';
import { ProjectConfigService } from './project-config.service';

@Component({
  selector: 'project-config-detail',
  templateUrl: './project-config-detail.component.html',
  styleUrls: ['./project-config-detail.component.scss' ],
  providers: [ ProjectService, ProjectConfigService, ConfirmationService ]
})

export class ProjectConfigDetailComponent extends BaseComponent implements OnInit 
{ 
    projectId : string;

    freezeFrame : boolean;
    freezeFrameConfig : ProjectConfig;

    project : Project;
    projectConfigList : ProjectConfig[];

    constructor( private projectService : ProjectService,
                 private projectConfigService : ProjectConfigService,
                 private confirmationService : ConfirmationService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        
        this.projectId = "";

        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.project = undefined;
            this.projectId = params['projectId'];
            this.loadData();
        });

    }

    public ngOnInit() : void
    {
        this.clearErrorMessages();
        this.freezeFrame = false;
    }

    private loadData() : void
    {
        this.project = undefined;

        this.projectService.retrieve(this.projectId)
                .subscribe(result => this.processProjectDetail(result),
                           error => this.handleError(error) );
    }

    private processProjectDetail(result : Project) : void
    {
        this.project = result;

        // Once we have our project, grab the configuration.
        this.projectConfigService.retrieveList(this.projectId, ProjectConfig.STATUSCATEGORIES)
                .subscribe(result => this.processProjectConfigList(result),
                           error => this.handleError(error) );

        this.projectConfigService.retrieve(this.projectId, ProjectConfig.FREEZEFRAME)
                .subscribe(result => this.processFreezeFrame(result),
                           error => this.handleFreezeFrameError(error) );
    }

    private processProjectConfigList(result : ProjectConfig[]) : void
    {
        this.projectConfigList = result;
    }

    private processFreezeFrame(result : ProjectConfig) : void
    {
        this.freezeFrameConfig = result;

        if (this.freezeFrameConfig.configValue == "true")
            this.freezeFrame = true;
    }

    private handleFreezeFrameError (error : Response) : void
    {
        if (error.status == 404)
        {
            this.freezeFrameConfig = ProjectConfig.createProjectConfig(this.project, ProjectConfig.FREEZEFRAME, "false");
            this.freezeFrame = false;
        }
        
        super.handleError404Okay(error);        
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard/admin/project-config-list']);
    }

    public onUpdate() : void
    {
        this.clearErrorMessages();

        this.confirmationService.confirm(
            { message: 'Are you sure you wish to update this definition of done configuration?',
                accept: () => 
                {
                    this.updateProjectConfig();
                }
            });
    }

    public onUpdateFreezeFrame() : void
    {
        this.clearErrorMessages();

        this.confirmationService.confirm(
            { message: 'Are you sure you wish to update freeze frame configuration?',
                accept: () => 
                {
                    this.updateFreezeFrame();
                }
            });
    }


    public updateProjectConfig() : void 
    {
        this.projectConfigService.updateList(this.projectConfigList)
            .subscribe(result => this.updateListSuccessful(result),
                       error => this.handleUpdateError(error) );
    }

    public updateFreezeFrame() : void
    {
        this.freezeFrameConfig.configValue = String(this.freezeFrame);
        this.projectConfigService.update(this.freezeFrameConfig)
            .subscribe(result => this.updateFreezeFrameSuccessful(result),
                       error => this.handleUpdateError(error) );
    }

    private updateListSuccessful(result : ProjectConfig[] ) : void
    {
        this.addInfoMessage("Update Definition of Done Succeeded!");
        this.projectConfigList = result;
    }

    private updateFreezeFrameSuccessful(result : ProjectConfig ) : void
    {
        this.addInfoMessage("Update Freeze Frame Succeeded!");
        this.processFreezeFrame(result);
    }

    private handleUpdateError(error : any) : void
    {
        this.addErrorMessage("Problem updating project configuration.  Please try again!");
    }
}
