import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { OrgJobScheduleService } from './org-job-schedule.service';
import { OrgJobSchedule } from './org-job-schedule';

import { ProjectService } from '../../project/project.service';
import { Project } from '../../project/project';

import { BaseComponent } from '../../base/component/base-component.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'org-job-schedule',
  templateUrl: './org-job-schedule.component.html',
  styleUrls: ['./org-job-schedule.component.css'],
  providers: [ OrgJobScheduleService, ProjectService, ConfirmationService ]
})
export class OrgJobScheduleComponent extends BaseComponent implements OnInit
{ 
    orgJobScheduleList : OrgJobSchedule[];
    selectedOrgJobSchedule : OrgJobSchedule;
    intervalList : SelectItem[];

    projectList : Project[];

    constructor(protected router:Router,
                private confirmationService : ConfirmationService,
                private orgJobScheduleService: OrgJobScheduleService,
                private projectService : ProjectService ) 
    {
      super(router);
    }

    ngOnInit() : void
    {
        super.clearErrorMessages();
        this.buildIntervalList();
        this.retrieveLists();
    }

    protected buildIntervalList() : void
    {
        this.intervalList = [];
        this.intervalList.push( {label:'1 minute', value:60 } );
        this.intervalList.push( {label:'2 minutes', value:120 } );
        this.intervalList.push( {label:'5 minutes', value:300 } );
        this.intervalList.push( {label:'10 minutes', value:600 } );
        this.intervalList.push( {label:'20 minutes', value:1200 } );
        this.intervalList.push( {label:'40 minutes', value:2400 } );
        this.intervalList.push( {label:'1 hour', value:3600 } );
    }

    public retrieveLists() : void
    {
      forkJoin( [
          this.orgJobScheduleService.retrieveAll(),
          this.projectService.retrieveAll() ] )
        .subscribe(([result1, result2]) => 
        {
          this.processOrgJobScheduleList(result1);
          this.processProjectList(result2);
          this.sortOrgJobScheduleList();
        }, 
        error => this.handleError(error)
      );
    }

    protected processOrgJobScheduleList(result : OrgJobSchedule[]): void
    {
      this.orgJobScheduleList = result
      this.selectedOrgJobSchedule = undefined;
    }

    protected processProjectList(result : Project[] )
    {
      this.projectList = result;
    }

    public onUpdate() : void
    {
      this.confirmationService.confirm(
        { message: 'Are you sure you wish to update all job schedules?',
            accept: () => 
            {
              this.updateOrgJobSchedule();
            }
        });
    }

    public onCancel() : void
    {
       this.router.navigate(['/dashboard']);
    }

    private updateOrgJobSchedule() : void
    {
        this.clearErrorMessages();
        
        this.orgJobScheduleService.update(this.orgJobScheduleList)
          .subscribe(result => this.updateSucceeded(result),
                     error => this.handleUpdateError(error) );
    }

    private updateSucceeded(result : OrgJobSchedule[] ) : void
    {
        this.addInfoMessage("Update Successful.");
        this.processOrgJobScheduleList(result);
        this.sortOrgJobScheduleList();
    }

    private handleUpdateError(error: any) : void
    {
      this.addErrorMessage("Error Updating Job Schedules.  Please try again.");
    }

    public getProjectDescription(orgJobSchedule : OrgJobSchedule) : string
    {
      let result : string = "";
      let orgSourceSystemId = orgJobSchedule.orgSourceSystem.orgSourceSystemId;
      let sourceSystemKey = orgJobSchedule.projectSourceSystemKey

      for (let project of this.projectList )
      {

        if (project.orgSourceSystemId == orgSourceSystemId && project.sourceSystemKey == sourceSystemKey)
        {
            result = project.description;
            break;
        }
      }

      return result;
    }

    public sortOrgJobScheduleList() : void {
      this.orgJobScheduleList.sort(
        (a, b) => {
            return (this.getProjectDescription(a) ).localeCompare(this.getProjectDescription(b) );
        });

    }
}
