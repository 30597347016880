import { Injectable }    from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { InboxItem } from './inbox-item';
import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class InboxItemService extends BaseHttpService
{
    inboxItemUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.inboxItemUrl = this.config.baseUrl + '/rest/inbox-item';
    }

    public retrieve(inboxItemId : string) : Observable<InboxItem>
    {
        let url = this.inboxItemUrl + "/" + inboxItemId;
        return super.baseRetrieveObject(InboxItem, url);
    }

    public retrieveList(startAt : number, maxResult : number) : Observable<ValueObjectList<InboxItem>>
    {
        let url = this.inboxItemUrl + "/subset";
        return super.baseRetrieveSubset(InboxItem, url, startAt, maxResult);
    }

    public retrieveCount(read : boolean) : Observable<number>
    {
        let url = this.inboxItemUrl + "/count?read=" + read;
        return super.baseRetrieve(url, this.processInboxItemCount, this.handleError);
    }

    private processInboxItemCount(response : any) : number
    {
        let result : number = Number.parseInt(response);
        return result;
    }

    public update(inboxItem : InboxItem) : Observable<InboxItem>
    {
        return super.baseUpdateObject(InboxItem, this.inboxItemUrl, inboxItem );
    }

    public delete(inboxItem : InboxItem) : Observable<string>
    {
        let url : string = `${this.inboxItemUrl}/${inboxItem.inboxItemId}`;
        return super.baseDeleteObject(url);
    }

    public deleteList(itemToDeleteList : Array<string>) : Observable<string>
    {
        let url : string = `${this.inboxItemUrl}?list=${JSON.stringify(itemToDeleteList)}`;
        return super.baseDeleteObject(url);
    }
    
    private handleError(error: any): any
    {
        return error.message || error;
    }
}