import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesItem } from '../../time-series/time-series-item';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { Project } from '../../project/project';

import * as moment from 'moment';

@Component({
  selector: 'mini-app-alert-count-severity-overview',
  templateUrl: './mini-app-alert-count-severity-overview.component.html',
  styleUrls: ['./mini-app-alert-count-severity-overview.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})

export class MiniAppAlertCountSeverityOverviewComponent extends BaseUIComponent implements OnInit { 

    @Input()
    project : Project;
    
    timeSeries : TimeSeries;
    appAlertCountNumber : number;    
    appAlertCountTrend: string;

    data: any;
    options: any;

    constructor(protected router : Router, 
                private timeSeriesService : TimeSeriesService) 
    { 
        super(router);

        this.options = {
            scales: { 
                y: { beginAtZero:true, stacked:true }, 
                x: { display: false, stacked:true }
            },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'App Alert Count'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
        
    }

    public ngOnInit() : void
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTALERTCOUNT, 10)
            .subscribe(result =>this.processAppAlertCount(result),
                       error => this.handleError404Okay(error) );
    }

    private processAppAlertCount(result: TimeSeries)
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Critical',
//                    backgroundColor: '#FF0000',
                    backgroundColor: this.REDTRANSPARENT,
                    hoverBackgroundColor: this.RED,
                    borderColor: this.RED,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("critical"); } )
                },
                {
                    type:'bar',
                    label: 'Warning',
//                    backgroundColor: '#FFD700',
                    backgroundColor: this.YELLOWTRANSPARENT,
                    hoverBackgroundColor: this.YELLOW,
                    borderColor: this.YELLOW,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("warning"); } )
                },
                {
                    type:'bar',
                    label: 'Informational',
//                    backgroundColor: '#42A5F5',
                    backgroundColor: this.GREENTRANSPARENT,
                    hoverBackgroundColor: this.GREEN,
                    borderColor: this.GREEN,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("informational"); } )
                }
            ]
        }

    }

}
