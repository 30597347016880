import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { AppAlertService } from './app-alert.service';
import { AppAlert } from './app-alert';
import { ValueObjectList } from '../base/value-object/value-object-list';

import { Project } from '../project/project';
import { ProjectService } from '../project/project.service';

@Component({
  selector: 'app-alert-list',
  templateUrl: './app-alert-list.component.html',
  styleUrls: ['./app-alert-list.component.css' ],
  providers: [ ProjectService, AppAlertService ]
})

export class AppAlertListComponent extends BaseComponent implements OnInit, AfterViewInit
{ 
    rows : number = 10;
    paginator : boolean = true;
    lazy : boolean = true;
    totalRecords : number = 0;

    appAlertList : ValueObjectList<AppAlert>;

    projectId : string;
    project : Project;

    constructor(private appAlertService : AppAlertService,
                private projectService : ProjectService,
                private route: ActivatedRoute,
                protected router: Router) 

    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

    }

    public ngAfterViewInit() : void 
    {
        setTimeout( () => { this.projectService.retrieve(this.projectId)
            .subscribe(result => { this.processProject(result) } ) }, 100);
    }

    private processProject(result : Project)
    {
        this.project = result;
    }

    private processAppAlerts(result : ValueObjectList<AppAlert>) : void
    {
        // if (this.appAlertList == undefined)
        //     this.appAlertList = result;
        // else
        //    this.appAlertList = ValueObjectList.concat(this.appAlertList, result);
        this.appAlertList = result;
        this.totalRecords = this.appAlertList.total;
    }

    public loadData(event : any) 
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;
        let total : number = 0;

        if (this.appAlertList != undefined)
            total = this.appAlertList.total;
        else
            total = this.rows;

        if (startAt < total)
        {
            this.appAlertService.retrieveByProject(this.projectId, startAt, maxResult)
                .subscribe(result => this.processAppAlerts(result),
                           error => this.handleError(error) ); 
        }
    }
}
