import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base/service/base-http.service';
import { BacklogItem } from './backlog-item';
import { Project } from '../project/project';
import { Config } from '../config/config';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';

import { BaseSprint } from '../base/value-object/base-sprint';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class BacklogItemService extends BaseHttpService
{
    protected backlogItemUrl : string

    constructor(http: HttpClient, config: Config) 
    {
        super(http);
        this.backlogItemUrl = config.baseUrl + "/rest/backlog-item";
    }

    public retrieve(objectId : string) : Observable<BacklogItem>
    {
        let requestUrl = this.backlogItemUrl + "/" + objectId;
        return super.baseRetrieveObject(BacklogItem, requestUrl);
    }

    public retrieveBySourceSystemKey(projectId : string, orgSourceSystemId : string, sourceSystemKey : string) : Observable<BacklogItem>
    {
        let requestUrl = this.backlogItemUrl + "/" + projectId + "/" + orgSourceSystemId + "/" + sourceSystemKey;
        return super.baseRetrieveObject(BacklogItem, requestUrl);
    }
    
    public retrieveList(project: Project, objectIdList : string[]) : Observable<Array<BacklogItem>>
    {
        let requestUrl = this.backlogItemUrl + "/" + project.projectId + "/list/" + JSON.stringify(objectIdList);
        return super.baseRetrieveList(BacklogItem, requestUrl);
    }

    public retrieveProductBacklog(project : Project, closedItems : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<BacklogItem>>
    {
        let requestUrl = this.backlogItemUrl + "/project/" + project.projectId + "/subset?closed=" + closedItems;
        return super.baseRetrieveSubset(BacklogItem, requestUrl, startAt, maxResult);
    }

    public retrieveProductBacklogByObjectType(project : Project, objectType : string, closedItems : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<BacklogItem>>
    {
        let requestUrl = this.backlogItemUrl + "/project/" + project.projectId + "/" + objectType + "/subset?closed=" + closedItems;
        return super.baseRetrieveSubset(BacklogItem, requestUrl, startAt, maxResult);
    }

    public retrieveReleaseBacklog(release : Release, closedItems : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<BacklogItem>>
    {
        let requestUrl = this.backlogItemUrl + "/release/" + release.releaseId + "/subset?closed=" + closedItems;
        return super.baseRetrieveSubset(BacklogItem, requestUrl, startAt, maxResult);
    }

    public retrieveEpicBacklog(epic : Epic, closedItems : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<BacklogItem>>
    {
        let requestUrl = this.backlogItemUrl + "/epic/" + epic.epicId + "/subset?closed=" + closedItems;
        return super.baseRetrieveSubset(BacklogItem, requestUrl, startAt, maxResult);
    }

    public retrieveGhostStories(sprint : BaseSprint) : Observable<BacklogItem[]>
    {
        let requestUrl = this.backlogItemUrl + "/ghost-stories/" + sprint.sprintId;
        return super.baseRetrieveList(BacklogItem, requestUrl);
    }

}
