<div class="grid card card-w-title">
    <h3 class="col-12">Inbox Item List</h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <p-table class="col-12 inbox-item-list" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="inboxItemId"
        [rowHover]="true" [value]="inboxItemList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
        (onLazyLoad)="loadData($event)">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="width:50px">    <!-- delete -->
                    <col style="width:50px">    <!-- open -->
                    <col>                       <!-- subject -->
                    <col style="width:250px">   <!-- date received -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Subject</th>
                    <th>Date Received</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-inboxItem let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td>
                        <div class="flex align-content-center justify-content-center">
                            <p-checkbox binary="true" (onChange)="onDeleteChecked(inboxItem, $event)"></p-checkbox>                
                        </div>
                    </td>
                    <td>
                        <div class="flex align-content-center justify-content-center">
                            <a [routerLink]="['/dashboard/inbox-item-detail', inboxItem.inboxItemId]">
                                <i *ngIf="inboxItem.read == true" class="material-icons">drafts</i>
                                <i *ngIf="inboxItem.read == false" class="material-icons">mail</i>
                            </a>
                        </div>
                    </td>
                    <td>
                        <span style="float:left">{{inboxItem.subject}}</span>
                    </td>
                    <td>
                        {{ inboxItem.dateCreated | dateFormat }}        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="4">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>
    
    <div class="col-12 sm:col-12 md:col-6">
        <button pButton class="col-12" [disabled]="itemToDeleteList == undefined || itemToDeleteList.length ==0" label="Delete Selected" (click)="onDelete()"></button>
    </div>
    <div class="col-12 sm:col-12 md:col-6">
        <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>

