import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { Project } from '../project/project';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';
import { Sprint } from '../sprint/sprint';

import { DefectFact } from '../defect-fact/defect-fact';

export class Defect extends BaseBacklogItemObject
{
    public defectId : string;
    public defectFactList : DefectFact[];

    public initialize(defect : Defect) : void
    {
        if (defect == null)
            return;
        
        super.initialize(defect);

        this.defectId = defect.defectId;
        this.objectType = Defect.DEFECT;
        this.defectFactList = this.initializeList(DefectFact, defect.defectFactList);

        this.backlogItemFactList = this.defectFactList;
    }

    public getFact(factName : string) : DefectFact
    {
        let result : DefectFact = null;

        if (this.defectFactList != null)
        {
            for (let defectFact of this.defectFactList)
            {
                if (defectFact.name == factName)
                {
                    result = defectFact;
                    break;
                }
            }
        }

        return result;
    }
}