import { BaseValueObject } from '../../base/value-object/base-value-object';
import { OrgSourceSystem } from '../../org-source-system/org-source-system';

import * as moment from 'moment';

export class OrgJobSchedule extends BaseValueObject {

    public orgJobScheduleId : string;
	public orgSourceSystem : OrgSourceSystem;
	public projectSourceSystemKey : string;
	public interval : number;
	public active : boolean;

    public initialize(orgJobSchedule : OrgJobSchedule) : void
    {
        super.initialize(orgJobSchedule);

        this.orgJobScheduleId = orgJobSchedule.orgJobScheduleId;
        this.projectSourceSystemKey = orgJobSchedule.projectSourceSystemKey;
        this.interval = orgJobSchedule.interval;
        this.active = orgJobSchedule.active;

        this.orgSourceSystem = this.initializeObject(OrgSourceSystem, orgJobSchedule.orgSourceSystem);
    }

}