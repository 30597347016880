<p-card class="team-attainment-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Team Sprint Attainment %&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
        </div>
    </ng-template>

    <!--
    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="averageVelocityFact" pTooltip="Three Sprint Average" tooltipPosition="bottom">{{averageVelocityFact.getFactNumber() | round }} points / sprint</span>
        </span>
    </ng-template>
    -->

    <span class="content">
        <div>
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>

<zoom-graph #zoomGraph type="line" [sourceData]="data" [sourceOptions]="options"></zoom-graph>