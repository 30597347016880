<div class="layout-footer flex align-items-center p-4 shadow-2 grid-nogutter">
    <div class="grid flex col-12 align-items-center grid-nogutter">
        <div class="col-12 sm:col-12 md:col-3">
            <img class="small-logo sm:col-12 md:col-12" src="../../public/images/lucidlift-blue-alpha.png"><br>
            <div class="patent-text sm:col-12 md:col-12">Patent pending, Serial #62/398.472</div>
        </div>
        <div class="col-12 sm:col-12 md:col-6 link grid flex justify-content-between align-content-center">
            <a class="col-4 sm:col-4 md:col-4" href="mailto:support@lucidlift.com"><i class="fa fa-life-ring"></i>&nbsp;Support</a>
            <a class="col-4 sm:col-4 md:col-4" [routerLink]="['/dashboard/content-detail/key', TERMSOFUSE]" [routerLinkActive]="['active-menulink']"><i class="material-icons termsOfUseIcon">list</i>&nbsp;Terms Of Use</a>
            <a class="col-4 sm:col-4 md:col-4" [routerLink]="['/dashboard/content-detail/key', PRIVACYPOLICY]" [routerLinkActive]="['active-menulink']"><i class="material-icons privacyPolicyIcon">lock</i>&nbsp;Privacy Policy</a>
        </div>            
        <div class="col-12 sm:col-12 md:col-3 flex justify-content-end copyright">
            <span class="material-icons">copyright</span>{{year}} lucidLift, LLC
        </div>
        <div class="col-12 flex justify-content-center">
            <span *ngIf="COMPANYNAME" class="made-with-love">made with <span class="hearts">&hearts;</span> in Minnesota for {{ COMPANYNAME }} </span>
        </div>
    </div>
</div>