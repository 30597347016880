import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';
import { ConfirmationService } from 'primeng/api';

import { Project } from '../project/project';
import { ProjectService } from '../project/project.service';

import { ProjectTeamCost } from './project-team-cost';
import { ProjectTeamCostService } from './project-team-cost.service';

import { DateUtils } from '../utilities/date-utils';
import * as moment from 'moment';

@Component({
  selector: 'project-team-cost-list',
  templateUrl: './project-team-cost-list.component.html',
  styleUrls: ['./project-team-cost-list.component.scss' ],
  providers: [ ProjectService, ProjectTeamCostService, ConfirmationService ]
})

export class ProjectTeamCostListComponent extends BaseComponent implements OnInit 
{ 
    projectId : string;

    project : Project;
    projectTeamCostList : ProjectTeamCost[];
    selectedProjectTeamCost : ProjectTeamCost;

    constructor( private projectService : ProjectService,
                 private projectTeamCostService : ProjectTeamCostService,
                 private confirmationService : ConfirmationService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        
        this.projectId = "";

        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.project = undefined;
            this.projectId = params['projectId'];
            this.loadData();
        });

    }

    public ngOnInit() : void
    {
        this.clearErrorMessages();
    }

    private loadData() : void
    {
        this.project = undefined;

        this.projectService.retrieve(this.projectId)
                .subscribe(result => this.processProjectDetail(result),
                           error => this.handleError(error) );

        this.projectTeamCostService.retrieveByProjectId(this.projectId)
                .subscribe(result => this.processProjectTeamCostList(result),
                           error => this.handleError404Okay(error) );
    }

    private processProjectDetail(result : Project) : void
    {
        this.project = result;
    }

    private processProjectTeamCostList(result : ProjectTeamCost[]) : void
    {
        this.projectTeamCostList = result;

        if (this.gapsIdentified() )
        {
            this.addInfoMessage("WARNING - Gaps identified in costs time series");
        }
    }

    public onAdd() : void
    {
        this.router.navigate(['/dashboard/project-team-cost-add', this.projectId]);
    }

    public onDelete() : void
    {
        this.clearErrorMessages();

        this.confirmationService.confirm(
            { message: 'Are you sure you wish to delete the selected row?',
                accept: () => 
                {
                    this.deleteProjectTeamCost();
                }
            });

    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public onUpdate() : void
    {
        this.router.navigate(['/dashboard/project-team-cost-update', this.selectedProjectTeamCost.projectTeamCostId]);
    }

    public deleteProjectTeamCost() : void 
    {
        this.projectTeamCostService.delete(this.selectedProjectTeamCost)
            .subscribe(result => this.deleteProjectTeamCostSuccessful(result),
                       error => this.handleDeleteError(error) );
    }

    private deleteProjectTeamCostSuccessful(result : string) : void
    {
        this.addInfoMessage("Team costs row successfully deleted.");
        this.loadData();
    }

    private handleDeleteError(error : any) : void
    {
        this.addErrorMessage("Problem deleting team cost row.  Please try again!");
    }

    private gapsIdentified() : boolean
    {
        let result : boolean = false;
        let costList = this.projectTeamCostList.slice().reverse();

        for (let i : number = 0; i < costList.length - 1; i++)
        {
            if (DateUtils.calcBusinessDays(costList[i].endDate, costList[i+1].effectiveDate) > 1)
            {
                result = true;
                break;
            }
        }

        return result;
    }

}
