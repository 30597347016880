<p-card class="cost-per-point-timeline" (click)="onclick($event)">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-12 flex justify-content-start">
                <span class="title-text">Cost Per Point<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="costPerPointFact">$ {{costPerPointFact.getFactNumber() | round }}/point</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>

<zoom-graph #zoomGraph type="bar" [sourceData]="data" [sourceOptions]="options"></zoom-graph>
