import { BaseAgileObject } from '../base/value-object/base-agile-object';

export class Project extends BaseAgileObject
{
    projectId : string;
    orgId : string;
    description : string;

    public initialize (object: Project) : void
    {
        super.initialize(object);

        this.projectId = object.projectId;
        this.orgId = object.orgId;
        this.description = object.description;
    }
}