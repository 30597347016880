import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { ReleaseFactService } from '../../release-fact/release-fact.service';
import { Release } from '../../release/release';
import { ReleaseFact } from '../../release-fact/release-fact';
import { ContentDetail } from '../../content/content-detail';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'release-point-burnup',
  templateUrl: './release-point-burnup.component.html',
  styleUrls: ['./release-point-burnup.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [  TimeSeriesService, ReleaseFactService ]
})

export class ReleasePointBurnupComponent extends BaseUIComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    release : Release;

    @Input()
    displayPoints : boolean = false;

    timeSeries : TimeSeries;

    scope : ReleaseFact;
    pointsCompleted : ReleaseFact;
    activeReleaseUnsized : ReleaseFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private releaseFactService: ReleaseFactService) 
    { 
        super(router);

        this.options = {
            plugins: {
                title: {
                    display: false,
                    text: 'Release Burnup - Points'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }    
            },
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } 
                    },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.release.project.description} : ${this.release.name} Point Burnup`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void
    {
        this.timeSeriesService.retrieve(this.release.project, this.release.releaseId, TimeSeries.RELEASEBURNUP, 10)
            .subscribe(result => this.processReleaseBurnup(result),
                       error => this.handleError404Okay(error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASESCOPE)
            .subscribe(result =>this.processScope(result),
                       error => this.handleError (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASEPOINTSCOMPLETED)
            .subscribe(result =>this.processPointsCompleted(result),
                       error => this.handleError (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.ACTIVERELEASEUNSIZED)
            .subscribe(result =>this.processActiveReleaseUnsized(result),
                       error => this.handleError404Okay(error) );
    }

    public processReleaseBurnup(result: TimeSeries) : void
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'bar',
                        label: 'Points Completed',
                        backgroundColor: this.GREENTRANSPARENT,
                        hoverBackgroundColor: this.GREEN,
                        borderColor: this.GREEN,
                        borderWidth: 2,
                        
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintPointsComplete"); } )
                    },
                    {
                        type:'bar',
                        label: 'Total Completed',
                        backgroundColor: this.ORANGETRANSPARENT,
                        hoverBackgroundColor: this.ORANGE,
                        borderColor: this.ORANGE,
                        borderWidth: 2,
                        
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("totalPointsComplete"); } )
                    },
                    {
                        type:'line',
                        label: 'Release Scope',
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintScope"); } )
                    }
                ]
            }
        }
    }

    private processScope(result : ReleaseFact) : void
    {
        if (_.isEqual(this.scope, result) == false)
            this.scope = result;
    }

    private processPointsCompleted(result : ReleaseFact) : void
    {
        if (_.isEqual(this.pointsCompleted, result) == false)
            this.pointsCompleted = result;
    }

    private processActiveReleaseUnsized(result : ReleaseFact) : void
    {
        if (_.isEqual(this.activeReleaseUnsized, result) == false)
            this.activeReleaseUnsized = result;
    }

    private percentComplete() : number
    {
        let result : number = 0.00;
        
        if (this.scope && this.pointsCompleted)
        {
            if (this.scope.getFactNumber() != 0)
                result = (this.pointsCompleted.getFactNumber() / this.scope.getFactNumber()) * 100;
        }

        return result;
    }

    private pointsRemaining() : number
    {
        let result : number = 0.00;
        
        if (this.scope && this.pointsCompleted)
            result = this.scope.getFactNumber() - this.pointsCompleted.getFactNumber();

        return result;
    }

    private get RELEASEESTIMATE() : string
    {
        return ContentDetail.RELEASEESTIMATE;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

}
