<div *ngIf="backlogItem" class="col-12 grid">
    <h3 class="col-12">{{ objectTypeTitle }} {{ backlogItem.formattedId }} - {{ backlogItem.title }} </h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>
    <backlog-item-deleted-message class="col-12" [baseBacklogItemObject]="backlogItem"></backlog-item-deleted-message>

    <p-panel class="col-12" header="Description">
        <div style="overflow-y:scroll; height:200px;" class="preserveWhitespace" *ngIf="backlogItem.description" [innerHTML]="backlogItem.description">
        </div>            
    </p-panel>

    <p-panel *ngIf="backlogItem.acceptanceCriteria" class="col-12" header="Acceptance Criteria">
        <div style="overflow-y:scroll; height:200px;" class="preserveWhitespace" [innerHTML]="backlogItem.acceptanceCriteria">
        </div>
    </p-panel>

    <p-panel *ngIf="blockers" header="Blockers" class="col-12">
        <ul *ngFor="let blockerBacklogItem of blockers">
            <li *ngIf="blockerBacklogItem.isUserStory()">
                <a *ngIf="blockerBacklogItem.objectId != objectId" [routerLink]="['/dashboard/user-story-detail', blockerBacklogItem.objectId]" class="blocker">
                    {{ blockerBacklogItem.formattedId }} - {{ blockerBacklogItem.title }}
                </a>
                <span *ngIf="blockerBacklogItem.objectId == objectId" class="blocker">
                    This story is blocked!
                </span>
            </li>
            <li *ngIf="blockerBacklogItem.isDefect()">
                <a [routerLink]="['/dashboard/defect-detail', blockerBacklogItem.objectId]" class="blocker">
                    {{ blockerBacklogItem.formattedId }} - {{ blockerBacklogItem.title }}
                </a>
                <span *ngIf="blockerBacklogItem.objectId == objectId" class="blocker">
                    This defect is blocked!
                </span>
            </li>
            <li *ngIf="blockerBacklogItem.isChore()">
                <a [routerLink]="['/dashboard/chore-detail', blockerBacklogItem.objectId]" class="blocker">
                    {{ blockerBacklogItem.formattedId }} - {{ blockerBacklogItem.title }}
                </a>
                <span *ngIf="blockerBacklogItem.objectId == objectId" class="blocker">
                    This chore is blocked!
                </span>
            </li>
            <li *ngIf="blockerBacklogItem.isSpike()">
                <a [routerLink]="['/dashboard/spike-detail', blockerBacklogItem.objectId]" class="blocker">
                    {{ blockerBacklogItem.formattedId }} - {{ blockerBacklogItem.title }}
                </a>
                <span *ngIf="blockerBacklogItem.objectId == objectId" class="blocker">
                    This spike is blocked!
                </span>
            </li>
            <li *ngIf="blockerBacklogItem.isDependency()">
                <a [routerLink]="['/dashboard/dependency-detail', blockerBacklogItem.objectId]" class="blocker">
                    {{ blockerBacklogItem.formattedId }} - {{ blockerBacklogItem.title }}
                </a>
                <span *ngIf="blockerBacklogItem.objectId == objectId" class="blocker">
                    This dependency is blocked!
                </span>
            </li>
        </ul>
    </p-panel>

    <div class="grid col-12 grid-nogutter">
        <div class="col-12 sm:col-12 md:col-6 grid-nogutter">
            <p-panel [style]="{width:'100%'}" class="grid col-12 grid-nogutter" header="Details">

                <div class="flex align-content-center align-fields-center">
                    <label class="col-4 flex align-content-center align-fields-center">Id:</label>
                    <div class="col-8 flex align-content-center align-fields-center" style="padding-left:0px">
                        <span class="flex align-content-center align-fields-center" style="padding-right:5px">{{ backlogItem.formattedId }}</span>
                        <a *ngIf="buildSourceSystemLink(backlogItem) && backlogItem.deleted == false" href="{{ buildSourceSystemLink(backlogItem) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                    </div>
                </div>
                <div *ngIf="backlogItem.project" class="grid col-12">
                    <label class="col-4" for="project">Project:</label>
                    <a class="grid col-8" [routerLink]="['/dashboard/project-detail', backlogItem.project.projectId]">
                        <span class="col-12 backlog-item-project" id="project">
                            {{ backlogItem.project.description }}
                        </span>
                    </a>
                </div>

                <release-link-list  *ngIf="backlogItem.releases" class="grid col-12" [displayVertical]="true" [releases]="backlogItem.releases"></release-link-list>

                <div *ngIf="backlogItem.epic" class="grid col-12">
                    <label class="col-4" for="epic">Epic:</label>
                    <a class="grid col-8" [routerLink]="['/dashboard/epic-detail', backlogItem.epic.epicId]">
                        <span class="col-12 backlog-item-epic" id="epic">
                            {{ backlogItem.epic.name }}
                        </span>
                    </a>
                </div>
                <div *ngIf="sprint" class="grid col-12">
                    <label class="col-4" for="sprint">Sprint:</label>
                    <a class="grid col-8" [routerLink]="['/dashboard/sprint-detail', sprint.sprintId]">
                        <span class="col-12 backlog-item-sprint" id="sprint">
                            {{sprint.title}}
                        </span>
                    </a>
                </div>
                <div *ngIf="sprintHistory && sprintHistory.length > 0" class="grid col-12">
                    <label class="col-4">Sprint History:</label>
                    <div class="grid col-8">
                        <div *ngFor="let sprint of sprintHistory" class="col-12 grid-nogutter">
                            <a class="grid col-12" [routerLink]="['/dashboard/sprint-detail', sprint.sprintId]">
                                <span class="col-12 backlog-item-sprint" >
                                    {{sprint.title}}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="grid col-12">
                    <label class="col-4" for="status">Status:</label>
                    <span class="col-8" id="status">
                        {{backlogItem.status}}
                    </span>
                </div>
                <div class="grid col-12">
                    <label class="col-4" for="priorityRank">Priority:</label>
                    <priority-rank class="col-8" id="priorityRank" [priorityRank]="backlogItem.priorityRank"></priority-rank>
                </div>
                <div class="grid col-12">
                    <label class="col-4" for="storyPoints">Story Points:</label>
                    <span class="col-8" id="storyPoints">
                        <story-point [backlogItem]="backlogItem" [tooltipPosition]="right"></story-point>
                    </span>
                </div>
            </p-panel>
        </div>
        <div class="col-12 sm:col-12 md:col-1">&nbsp;<!-- filler --></div>
        <div class="col-12 sm:col-12 md:col-5 grid-gutter">
            <app-alert [backlogItem]="backlogItem" [objectId]="objectId"></app-alert>
        </div>
    </div>
</div>
