import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BaseBacklogItemDetailComponent } from '../base/component/base-backlog-item-detail.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Spike } from './spike';
import { SpikeService } from './spike.service';
import { SpikeFact } from '../spike-fact/spike-fact';

import { SprintService } from '../sprint/sprint.service';

@Component({
  selector: 'spike-detail',
  templateUrl: './spike-detail.component.html',
  styleUrls: ['./spike-detail.component.scss' ],
  providers: [ SpikeService, SprintService ]
})

export class SpikeDetailComponent extends BaseBacklogItemDetailComponent implements OnInit, AfterViewInit 
{ 
    spikeId : string;
    spike : Spike;

    constructor(private spikeService : SpikeService,
                private sprintService : SprintService,
                private route: ActivatedRoute,
                protected router: Router) 
    { 
        super(router);

        this.route.params.subscribe(params => {
            this.spikeId = params['spikeId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public ngAfterViewInit() : void
    {
    }

    private initFields() : void
    {
        this.spike = undefined;
        this.sprint = undefined;

        this.spikeService.retrieve(this.spikeId)
            .subscribe(result=>this.processSpike(result) );
    }

    private processSpike(spike : Spike ) : void
    {
        this.spike = spike;
                
        if (this.spike.getFact(SpikeFact.BLOCKED) != undefined)
            this.blockersFact = this.spike.getFact(SpikeFact.BLOCKED);
        
        if (this.spike.getFact(SpikeFact.SPRINTSWORKEDON) != undefined)
            this.sprintsWorkedOnFact = this.spike.getFact(SpikeFact.SPRINTSWORKEDON);

        this.sprintService.retrieveBySpike(this.spike)
            .subscribe(result=>this.processSprintFound(result),
                error=>this.processSprintNotFound(error) );
    }

}
