import * as moment from 'moment';

export abstract class BaseValueObject 
{
    createdByUserId : string;
    dateCreated : Date;
    modifiedByUserId : string;
    dateModified : Date;

    constructor()
    {
    }

    public initialize(object : BaseValueObject) : void
    {
        if (object != undefined)
        {
            this.createdByUserId = object.createdByUserId;
            this.dateCreated = moment(object.dateCreated).toDate();
            this.modifiedByUserId = object.modifiedByUserId;
            this.dateModified = moment(object.dateModified).toDate();
        }
    }

    public initializeObject <T extends BaseValueObject>(c: {new(): T; }, data : T) : T
    {
        let object = new c();

        if (data != undefined)
            object.initialize(data);

        return object;
    }

    public initializeList <T extends BaseValueObject> (c: {new(): T; }, dataList: T[] ) : T[]
    {
        let resultList : Array<T> = new Array<T>();

        if (dataList != undefined)
        {
            for (let data of dataList)
            {
                let object = new c();
                object.initialize(data);

                resultList.push(object);
            }
        }
        
        return resultList;
    }

    // This method was created to handle UTC dates coming
    // back from our REST services as Javascript wants to parse
    // everything into a local date.
    public fromUTC(utcDate : Date) : Date
    {
        if (utcDate != undefined )
        {
            // Make sure we have a real date object.
            let tempDate : Date = moment(utcDate).toDate();

            // Create the epoch in GMT.
            let epoch : number = Date.UTC (tempDate.getFullYear(), 
                            tempDate.getMonth(),
                            tempDate.getDate(),
                            tempDate.getHours(),
                            tempDate.getMinutes(),
                            tempDate.getSeconds(),
                            tempDate.getMilliseconds() );

            let result : Date = new Date(0);

            // Set UTC.
            result.setUTCMilliseconds(epoch);
            return result;
        }
        else
        {
            return undefined;
        }
    }

 }