import { BaseFactObject } from '../base/value-object/base-fact-object';

export class UserStoryFact extends BaseFactObject
{
	// USER STORY FACT CONSTANTS
	public static readonly CHANGEDMIDSPRINT : string = "changedMidsprint";
	public static readonly LARGEUSERSTORY : string = "largeUserStory";
	public static readonly ADDEDTOCURRENTSPRINT : string = "addedToCurrentSprint";
	public static readonly SPRINTSWORKEDON : string = "sprintsWorkedOn";
	public static readonly POORAGILESTORY : string = "poorAgileStory";
	public static readonly POORACCEPTANCECRITERIA : string = "poorAcceptanceCriteria";
	public static readonly BLOCKED : string	= "userStoryBlocked";
	public static readonly PROJECTINITIALLOAD : string = "userStoryProjectInitialLoad";
	public static readonly POINTSONSTORY : string = "pointsOnStory";
	public static readonly POINTSINRANGE : string = "storyPointsInRange";
	public static readonly OPENSTORYCLOSEDSPRINT : string = "openStoryClosedSprint";
	public static readonly STORYCLOSEDOUTSIDESPRINT : string = "storyClosedOutsideSprint";
	public static readonly UNFINISHEDVELOCITY : string = "storyUnfinishedVelocity";
	public static readonly TOPOFBACKLOG : string = "topOfBacklog";
	public static readonly QUALITYSTORY : string = "qualityStory";
	public static readonly MISSINGDESCRIPTION : string = "userStoryMissingDescription";
	public static readonly POINTCHANGEDETAIL : string = "userStoryPointChangeDetail";

    userStoryFactId : string;
	userStoryId : string;
	projectId : string;

    public initialize(userStoryFact : UserStoryFact) : void
    {
        super.initialize(userStoryFact);
        this.userStoryFactId = userStoryFact.userStoryFactId;
        this.userStoryId = userStoryFact.userStoryId;
		this.projectId = userStoryFact.projectId;
    }
}