import * as _ from 'lodash';
import * as moment from 'moment';

export class LLUtils 
{
    public static pad(num : number, size : number) : string
    {
        let s : string = num.toString();
        while (s.length < size) 
            s = "0" + s;

        return s;
    }
    
    public static left(pValue : string, pLength : number ) : string 
    {
        if (pValue == undefined)
            return pValue;

        return pValue.slice(0, pLength);
    }

    public static isStringEmpty(pString : string ) : boolean
    {
        let result : boolean = false;

        if (pString == undefined || pString.trim() == "")
            result = true;

        return result;
    }

    public static isNumeric(pNumber : number) : boolean
    {
        let result : boolean = false;
        let numberString : string = `${pNumber}`;
        
        if (isNaN(pNumber) == false && isNaN(parseFloat(numberString)) == false)
            result = true;
        else
            result = false;

        return result;
    }

    public static round(value: number, precision? : number): number 
    {
        let result : number = undefined;

        if (precision != undefined)
        {
            result = _.round(value, precision);
        }
        else
        {
            result = Math.round(value);
        }
        
        return result;
    }

    public static hexToRgb(hex : string, opacity? : number) : string 
    {
        let hexValue = hex.replace("#", "");    // Filter out #

        var bigint = parseInt(hexValue, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        let result = "";

        if (opacity)
            result = `rgba(${r},${g},${b},${opacity})`;
        else
            result = `rgb(${r},${g},${b})`

        return result; 
    }

    public static equalsIgnoreCase(pString1 : string, pString2 : string): boolean
    {
        let result : boolean = false;

        let string1 : string = (pString1 != undefined ? pString1.toLowerCase() : "");
        let string2 : string = (pString2 != undefined ? pString2.toLowerCase() : "");

        result = (string1 == string2);

        return result;
    }

    public static uuidToColor(pString : string) : string 
    {
        let hash : number = 0;

        for (let i = 0; i < pString.length; i++)
            hash = pString.charCodeAt(i) + ((hash << 5) - hash);

        let color : string = '#';
        for (let i = 0; i < 3; i++) 
        {
          var value = (hash >> (i * 8)) & 0xFF;
          let temp = ('00' + value.toString(16)); 
          color += temp.substring(temp.length-2);
        }

        return color;
    }

    public static safeStringify(obj : any, indent : number = 2) : string
    {
        let cache = [];
        const retVal = JSON.stringify(
            obj,
            (key, value) =>
            typeof value === "object" && value !== null
                ? cache.includes(value)
                ? undefined // Duplicate reference found, discard key
                : cache.push(value) && value // Store value in our collection
                : value,
            indent
        );

        cache = null;
        
        return retVal;
    }
}