<div class="ui-fluid card card-w-title">
    <h3>Profile</h3>

    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <label class="col-12" for="firstName">First Name:</label>
            <input class="col-12" pInputText maxlength="50" #firstNameTag="ngModel" id="firstName" [(ngModel)]="userAccount.firstName"  name="firstName" required/>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <label class="col-12" for="lastName">Last Name:</label>
            <input class="col-12" pInputText maxlength="50" #lastNameTag="ngModel" id="lastName" [(ngModel)]="userAccount.lastName"  name="lastName" required/>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <label class="col-12" for="address1">Address Line 1:</label>
            <input class="col-12" pInputText maxlength="100" #address1Tag="ngModel" id="address1" [(ngModel)]="userAccount.addressLine1"  name="address1" />
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <label class="col-12" for="address2">Address Line 2:</label>
            <input class="col-12" pInputText maxlength="100" #address2Tag="ngModel" id="address2" [(ngModel)]="userAccount.addressLine2"  name="address2"/>
        </div>

        <div class="grid col-12 flex align-content-center align-items-center">
            <div class="col-12 sm:col-12 md:col-6">
                <label class="col-12" for="city">City:</label>
                <input class="col-12" pInputText maxlength="100" #cityTag="ngModel" id="city" [(ngModel)]="userAccount.city"  name="city" />
            </div>
    
            <div class="col-12 sm:col-12 md:col-6 grid-nogutter">
                <label class="col-12" for="stateId">State:</label>
                <div class="col-12">
                    <p-dropdown [style]="{'width':'300px'}" id="stateId" #stateIdTag name="stateId" [options]="stateItems" [(ngModel)]="selectedStateId"></p-dropdown>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <label class="col-12" for="zipcode">Zipcode:</label>
            <input class="col-12" pInputText maxlength="5" #zipcodeTag="ngModel" id="zipcode" [(ngModel)]="userAccount.zipcode"  name="zipcode" />
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <label class="col-12" for="emailAddress">Email Address:</label>
            <input class="col-12" pInputText maxlength="100" #emailAddressTag="ngModel" id="emailAddress" [(ngModel)]="userAccount.emailAddress"  name="emailAddress" required/>
        </div>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-4">
            <button pButton id="updateButton" class="col-12" label="Update" (click)="onUpdate()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-4">
            <button pButton id="resetPasswordButton" class="col-12" label="Reset Password" (click)="onResetPassword()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-4">
            <button pButton id="cancelButton" class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
