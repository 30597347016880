import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseBacklogItemDetailComponent } from '../base/component/base-backlog-item-detail.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Chore } from './chore';
import { ChoreService } from './chore.service';
import { ChoreFact } from '../chore-fact/chore-fact';

import { SprintService } from '../sprint/sprint.service';

@Component({
  selector: 'chore-detail',
  templateUrl: './chore-detail.component.html',
  styleUrls: ['./chore-detail.component.scss' ],
  providers: [ ChoreService, SprintService ]
})

export class ChoreDetailComponent extends BaseBacklogItemDetailComponent implements OnInit, AfterViewInit 
{ 
    choreId : string;
    chore : Chore;

    constructor(private choreService : ChoreService,
                private sprintService : SprintService,
                private route: ActivatedRoute,
                protected router: Router) 
    { 
        super(router);

        this.route.params.subscribe(params => {
            this.choreId = params['choreId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public ngAfterViewInit() : void
    {
    }

    private initFields() : void
    {
        this.chore = undefined;
        this.sprint = undefined;

        this.choreService.retrieve(this.choreId)
            .subscribe(result=>this.processChore(result) );
    }

    private processChore(chore : Chore ) : void
    {
        this.chore = chore;
                
        if (this.chore.getFact(ChoreFact.BLOCKED) != undefined)
            this.blockersFact = this.chore.getFact(ChoreFact.BLOCKED);
        
        if (this.chore.getFact(ChoreFact.SPRINTSWORKEDON) != undefined)
            this.sprintsWorkedOnFact = this.chore.getFact(ChoreFact.SPRINTSWORKEDON);

        this.sprintService.retrieveByChore(this.chore)
            .subscribe(result=>this.processSprintFound(result),
                        error=>this.processSprintNotFound(error) );
    }
    
}
