<div class="card overview scrum-maturity-timeline">
    <div class="overview-content clearfix">
        <span class="overview-title">Scrum Maturity&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
        <span *ngIf="topScrumMaturityTrend" class="overview-badge">
            <i *ngIf="topScrumMaturityTrend == 'up'" class="material-icons" pTooltip="Trending Up" tooltipPosition="left">trending_up</i>
            <i *ngIf="topScrumMaturityTrend == 'down'" class="material-icons" pTooltip="Trending Down" tooltipPosition="left">trending_down</i>
            <i *ngIf="topScrumMaturityTrend == 'neutral'" class="material-icons" pTooltip="Trending Flat" tooltipPosition="left">trending_flat</i>
        </span>
        <span *ngIf="topScrumMaturityAverage" class="overview-detail" tooltipPosition="bottom" pTooltip="Three Sprint Average">{{ topScrumMaturityAverage.getFactNumber() | round:2 | number:'1.2-2' }}</span>
    </div>
    <div class="overview-footer">
        <div *ngIf="timeSeries">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </div>
</div>

<zoom-graph #zoomGraph type="line" [sourceData]="data" [sourceOptions]="options"></zoom-graph>