import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { SprintFact } from '../../sprint-fact/sprint-fact';

import { ContentDetail } from '../../content/content-detail';

import { BaseComponent } from '../../base/component/base-component.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'mini-sprint-commitment',
  templateUrl: './mini-sprint-commitment.component.html',
  styleUrls: ['./mini-sprint-commitment.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class MiniSprintCommitmentComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    sprint : BaseSprint;

    sprintOverloaded : SprintFact;
    sprintCommitment : SprintFact;
    sprintVelocity : SprintFact;
    data: any;
    options: any;

    constructor(protected router : Router,
                private sprintFactService : SprintFactService) 
    { 
        super(router);

        this.options = {
            indexAxis: 'y',
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins : {
                title: {
                    display: false,
                    text: 'Sprint Commitment'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    public ngOnInit() : void
    {
        this.retrieveData();
    }

    public ngOnChanges() : void
    {
        this.retrieveData();
    }

    public retrieveData() : void
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTOVERLOADED)
            .subscribe(result => this.processSprintOverloaded(result),        
                       error => super.handleError(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTCOMMITMENT)
            .subscribe(result => this.processSprintCommitment(result),       
                       error => super.handleError(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTVELOCITY)
            .subscribe(result => this.processSprintVelocity(result),       
                       error => super.handleError(error) );
            
    }

    protected processSprintOverloaded(result : SprintFact)
    {
        if (_.isEqual(this.sprintOverloaded, result) == false)
            this.sprintOverloaded = result;
    }
    
    protected processSprintCommitment(result : SprintFact)
    {
        if (_.isEqual(this.sprintCommitment, result) == false)
        {
            this.sprintCommitment = result; 
            this.drawGraph();
        }
    }

    protected processSprintVelocity(result : SprintFact)
    {
        if (_.isEqual(this.sprintVelocity, result) == false)
        {
            this.sprintVelocity = result; 
            this.drawGraph();      
        }
    }

    protected drawGraph()
    {
        if (this.sprintCommitment == undefined || this.sprintVelocity == undefined)
            return;

        this.data = {
            labels : [ moment(this.sprint.sprintEndDate).format("MMM Do YYYY") ],
            datasets: [
                {
                    type:'bar',
                    label: 'Points Completed',
//                    backgroundColor: '#42A5F5',
                    backgroundColor: 'rgba(66, 165, 245, .40)',
                    hoverBackgroundColor: 'rgba(66, 165, 245, 1)',
                    borderColor: 'rgba(66, 165, 245, 1)',
                    borderWidth: 2,
                    data: [ this.sprintVelocity.getFactNumber().toFixed(0) ]
                },
                {
                    type:'bar',
                    label: 'Commitment',
//                    backgroundColor: '#FF9B00',
                    backgroundColor: 'rgba(255, 155, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 155, 0, 1)',
                    borderColor: 'rgba(255, 155, 0, 1)',
                    borderWidth: 2,
                    data: [ this.sprintCommitment.getFactNumber().toFixed(0) ]
                }
                              
            ]
        }
    }

    private percentComplete() : number
    {
        let result : number = 0.00;

        if (this.sprintCommitment == undefined || this.sprintVelocity == undefined)
            return(result);

        let sprintCommitment : number = this.sprintCommitment.getFactNumber();
        let velocity : number = this.sprintVelocity.getFactNumber();

        if (sprintCommitment != 0)
        {
            result = (velocity / sprintCommitment) * 100;
        }
        else
        {
            // If sprint commitment is zero, the commitment is met.
            result = 100;
        }

        return result;
    }

    private get OVERLOADEDSPRINT() : string
    {
        return ContentDetail.OVERLOADEDSPRINT;
    }
}
