import { Component, OnInit, OnChanges } from '@angular/core';
import { Input } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'zoom-bubble-graph',
  templateUrl: './zoom-bubble-graph.component.html',
  styleUrls: ['./zoom-bubble-graph.component.scss' ],
  providers: [ ]
})

export class ZoomBubbleGraphComponent implements OnInit, OnChanges 
{ 
    visible : boolean = false;

    @Input()
    sourceData : any = undefined;
    
    @Input()
    sourceOptions : any = undefined;

    dialogTitle : string;

    @Input()
    type : string;

    data : any = undefined;
    options : any = undefined;

    constructor() 
    { 
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void 
    {
        if (this.sourceData != undefined)
            this.data = this.sourceData;
    }

    public openDialog() : void
    {
        if (this.sourceOptions != undefined)
            this.options = this.sourceOptions;

        if (this.options != undefined)
        {
            this.options['responsive'] = false;
            this.options['animiations'] = false;
            this.options.onClick = (event, array) => { };
            this.options['plugins']['legend'].position = 'bottom';
            this.options['plugins']['legend'].display = false;
            this.options['plugins']['title'].display = true;
            this.options['plugins']['title'].font = { size : 20 };
        }

        if (this.options != undefined &&
            this.options['plugins'] != undefined &&
            this.options['plugins']['title'] != undefined &&
            this.options['plugins']['title'].text != undefined)
        {
            this.dialogTitle = this.options['plugins']['title'].text;
        }

        // Display
        this.visible = true;
    }

    public closeDialog() : void
    {
        this.visible = false;
    }
}