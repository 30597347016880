import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { Content } from '../../content/content';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'points-injected-timeline',
  templateUrl: './points-injected-timeline.component.html',
  styleUrls: ['./points-injected-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class PointsInjectedTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    pointsInjectedAverageFact : ProjectFact;
    pointsInjectedTrendFact : ProjectFact;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router); 
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Points Injected By Sprint`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTPOINTSINJECTED, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.POINTSINJECTEDAVERAGE)
            .subscribe(result => this.processPointsInjectedAverage(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.POINTSINJECTEDTREND)
            .subscribe(result => this.processPointsInjectedTrend(result),
                       error => this.handleError404Okay(error) );

    }

    private processPointsInjectedAverage(result : ProjectFact) : void
    {
        if (_.isEqual(this.pointsInjectedAverageFact, result) == false)
            this.pointsInjectedAverageFact = result;
    }

    private processPointsInjectedTrend(result : ProjectFact) : void
    {
        if (_.isEqual(this.pointsInjectedTrendFact, result) == false)
            this.pointsInjectedTrendFact = result;
    }

    public processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       
            
            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type: 'line',
                        label: 'Points',
                        backgroundColor: this.REDTRANSPARENT,
                        pointHoverBackgroundColor: this.RED,
                        borderColor: this.RED,
                        borderWidth: 2,
                        fill: true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("pointsInjected"); } )
                    }
                ]
            }
        }
    }

    public get ADDEDTOSPRINT() : string 
    {
        return Content.ADDEDTOSPRINT;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
 
}