import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { UserDashboardPreference } from './user-dashboard-preference';

@Injectable()
export class UserDashboardPreferenceService extends BaseHttpService
{
    userDashboardPreferenceUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.userDashboardPreferenceUrl = this.config.baseUrl + '/rest/user-dashboard-preference';
    }

    public retrieve() : Observable<UserDashboardPreference>
    {
        let url = this.userDashboardPreferenceUrl;
        return super.baseRetrieveObject(UserDashboardPreference, url);
    }

    public update(userDashboardPreference: UserDashboardPreference) : Observable<UserDashboardPreference>
    {
        let url = this.userDashboardPreferenceUrl;
        return super.baseUpdateObject(UserDashboardPreference, url, userDashboardPreference);   
    }
}
