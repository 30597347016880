import { Injectable }    from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';

import { Content } from './content';
import { ContentDetail } from './content-detail';

@Injectable()
export class ContentService extends BaseHttpService
{
    contentUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.contentUrl = this.config.baseUrl + '/rest/content';
    }

    public retrieve(contentId : string) : Observable<Content>
    {
        let url = this.contentUrl + "/" + contentId;
        return super.baseRetrieveObject(Content, url);
    }

    public retrieveDetail(contentId : string, projectId?: string, releaseId? : string, sprintId? : string) : Observable<ContentDetail>
    {
        if( projectId != null && releaseId != null && sprintId != null)
            throw new Error("projectId and releaseId and sprintId cannot all be used");

        let url = this.contentUrl + "/detail/" + contentId;

        if (projectId != null)
            url = url + "?projectId=" + projectId;
   
        if (releaseId != null)
            url = url + "?releaseId=" + releaseId;

        if (sprintId != null)
            url = url + "?sprintId=" + sprintId;
            
        return super.baseRetrieveObject(ContentDetail, url);
    }

    public retrieveDetailNoTransform(contentId : string) : Observable<ContentDetail>
    {
        let url = this.contentUrl + "/detail/no-transform/" + contentId;
        return super.baseRetrieveObject(ContentDetail, url);
    }
    
    public retrieveByKey(contentKey : string) : Observable<Content>
    {
        let url = this.contentUrl + "/key/" + contentKey;
        return super.baseRetrieveObject(Content, url);
    }

    public retrieveDetailByKey(contentKey : string, projectId? : string, releaseId? : string, sprintId? : string) : Observable<ContentDetail>
    {
        if( projectId != null && releaseId != null && sprintId != null)
            throw new Error("projectId and releaseId and sprintId cannot all be used");

        let url = this.contentUrl + "/detail/key/" + contentKey;

        if (projectId != null)
            url = url + "?projectId=" + projectId;
   
        if (releaseId != null)
            url = url + "?releaseId=" + releaseId;

        if (sprintId != null)
            url = url + "?sprintId=" + sprintId;

        return super.baseRetrieveObject(ContentDetail, url);
    }

    public retrieveAll() : Observable<Content[]>
    {
        let url = this.contentUrl + "/list";
        return super.baseRetrieveList(Content, url);
    }

    public create(content : ContentDetail) : Observable<ContentDetail>
    {
        return super.baseCreateObject(ContentDetail, this.contentUrl, content);    
    }

    public update(content : ContentDetail) : Observable<ContentDetail>
    {
        return super.baseUpdateObject(ContentDetail, this.contentUrl, content);    
    }

    public delete(content : Content) : Observable<string>
    {
        let deleteUrl = this.contentUrl + "/" + content.contentId;
        return super.baseDelete(deleteUrl, this.processDeleteResponse, this.handleError);
    }

    private processDeleteResponse(response: any) : string
    {
        return response;
    }

    private handleError(error: any): any 
    {
        return error.message || error;
    }
}