import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/component/base-component.component';
import { Project } from '../project/project';

import { UserAccount } from '../user-account/user-account';
import { UserAccountService } from '../user-account/user-account.service';

import { OrgConfig } from '../org-config/org-config';
import { OrgConfigService } from '../org-config/org-config.service';

import { MessagingService } from '../event/messaging.service';
import { RefreshProjectListEvent } from '../event/refresh-project-list-event';

import { LoggedInUser } from '../authentication/logged-in-user';
import { ContentDetail } from '../content/content-detail';
import { ContentService } from '../content/content.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Subscription, Observable } from "rxjs";
import { LLUtils } from '../utilities/ll-utils';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: [ './main.component.css' ],
  providers : [ ContentService, OrgConfigService ]
})

export class MainComponent extends BaseComponent implements OnInit, OnDestroy
{ 
    projectList : Project[];
    refreshProjectListSubscription : Subscription;
    userAccount : UserAccount;

    content : ContentDetail;
    enableOrganizationSummary : boolean = undefined;

    constructor(protected router:Router,
                private userAccountService : UserAccountService,
                private messagingService : MessagingService,
                private contentService : ContentService,
                private orgConfigService : OrgConfigService,
                private sanitizer: DomSanitizer) 
    { 
        super(router);
              
        // Set-up subscriptions
        this.refreshProjectListSubscription = this.messagingService.of(RefreshProjectListEvent)
            .subscribe(message => {
                if (message.userAccount != undefined)
                    this.setupUserAccountAndProjectList(message.userAccount);
        }); 
  
    }

    public ngOnInit() : void
    {        
        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            this.userAccount = LoggedInUser.getLoggedInUser();
            this.projectList = LoggedInUser.getLoggedInUser().userProjects;
            this.checkRedirect();
            this.retrieveOrgConfig();
        }
        else
        {
            this.userAccountService.retrieve()
                .subscribe(result => this.processUserAccount(result),
                            error => { this.handleError(error) } ); 
        }

        this.contentService.retrieveDetailByKey(ContentDetail.WELCOME)
            .subscribe(result=>this.processContent(result),
                       error => this.handleError(error) );

    }

    private processUserAccount(userAccount : UserAccount) : void
    {
        this.userAccount = LoggedInUser.getLoggedInUser();
        this.setupUserAccountAndProjectList(userAccount);
        this.checkRedirect();
        this.retrieveOrgConfig();
    }
    
    public ngOnDestroy() : void
    {
        this.refreshProjectListSubscription.unsubscribe();
    }

    protected checkRedirect() : void 
    {
        if (this.projectList != undefined && this.projectList.length == 1)
        {
            this.router.navigate(['/dashboard/project-detail', this.projectList[0].projectId]);
        }
    }

    public processContent(result: ContentDetail) : void
    {
        this.content = result;
    }
    
    private setupUserAccountAndProjectList(userAccount : UserAccount) : void
    {
        if (userAccount != undefined)
        {
            LoggedInUser.setLoggedInUser(userAccount);  
            this.userAccount = LoggedInUser.getLoggedInUser();
            this.projectList = LoggedInUser.getLoggedInUser().userProjects;
        }
    }
    
    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
            
        return error.message || error;
     }

    protected provideContentDetail() : SafeHtml
    {
        return this.sanitizer.bypassSecurityTrustHtml(this.content.contentDetail);
    }    

    public retrieveOrgConfig() : void
    {
        // Make sure we have a logged in user
        if (LoggedInUser.getLoggedInUser() == undefined)
            return;

        // Retrieve the org config.
        this.orgConfigService.retrieveByKey(LoggedInUser.getLoggedInUser().organization.orgId, OrgConfig.ENABLEORGANIZATIONSUMMARY)
            .subscribe(result => this.processOrgConfig(result), 
                       error => this.handleOrgConfigNotFound(error) );
    }

    public processOrgConfig(result : OrgConfig) : void
    {
        if (LLUtils.isStringEmpty(result.configValue) == false)
        {
            let configValue = result.configValue;

            if (configValue.toLowerCase() == 'true')
                this.enableOrganizationSummary = true;
            else
                this.enableOrganizationSummary = false;
        }
    }

    public handleOrgConfigNotFound(error : any) : void
    {
        this.enableOrganizationSummary = false;
        super.handleError404Okay(error);
    }
}
