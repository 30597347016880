import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { BaseComponent } from '../base/component/base-component.component';

import { Release } from './release';
import { ReleaseFact } from '../release-fact/release-fact';
import { ReleaseFactService } from '../release-fact/release-fact.service';
import { Content } from '../content/content';

@Component({
  selector: 'release-heading',
  templateUrl: './release-heading.component.html',
  styleUrls: ['./release-heading.component.scss' ],
  encapsulation : ViewEncapsulation.None,
  providers: [ ReleaseFactService ]
})
export class ReleaseHeadingComponent extends BaseComponent implements OnInit 
{
    @Input() 
    release : Release;

    @Input()
    showButton : boolean = false;

    @Input()
    displayCosts : boolean = false;

    releaseOnTargetFact : ReleaseFact;
    releaseScopeFact : ReleaseFact;
    releasePointsCompletedFact : ReleaseFact;
    releaseProgramIncrementFact : ReleaseFact;
    releaseProgramIncrementQuarterFact : ReleaseFact;
    releaseGracePeriodEndFact : ReleaseFact;

    beginningReleaseFact : ReleaseFact;

    releaseAtRiskMessage : any = [ { severity:'error', summary:'', detail: 'This release is at risk of missing its due date.  Click on this message for more information.' } ];

    constructor( private releaseFactService : ReleaseFactService,
                 protected router: Router) 
     { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.RELEASEONTARGET)
            .subscribe(result => this.processReleaseOnTargetFact(result),
                        error => this.handleError404Okay(error) );

        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.RELEASESCOPE)
            .subscribe(result => this.processReleaseScopeFact(result),
                    error => this.handleError404Okay(error) );

        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.RELEASEPOINTSCOMPLETED)
            .subscribe(result => this.processReleasePointsCompletedFact(result),
                    error => this.handleError404Okay(error) );

        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.PROGRAMINCREMENTRELEASE)
            .subscribe(result => this.processReleaseProgramIncrementFact(result),
                    error => this.handleError404Okay(error) );
                   
        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.BEGINNINGRELEASE)
            .subscribe(result => this.processBeginningReleaseFact(result),
                    error => this.handleError404Okay(error) );                                    

        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.PROGRAMINCREMENTQUARTER)
            .subscribe(result => this.processReleaseProgramIncrementQuarterFact(result),
                error => this.handleError404Okay(error) );                                    

        this.releaseFactService.retrieveById(this.release.releaseId, ReleaseFact.RELEASEGRACEPERIODEND)
            .subscribe(result => this.processReleaseGracePeriodEndFact(result),
                error => this.handleError404Okay(error) );                                    
    }

    private processReleaseOnTargetFact(result : ReleaseFact)
    {
        this.releaseOnTargetFact = result;
    }

    private processReleaseScopeFact(result : ReleaseFact)
    {
        this.releaseScopeFact = result;
    }

    private processReleasePointsCompletedFact(result : ReleaseFact)
    {
        this.releasePointsCompletedFact = result;
    }

    private processReleaseProgramIncrementFact(result : ReleaseFact)
    {
        this.releaseProgramIncrementFact = result;
    }

    private processBeginningReleaseFact(result : ReleaseFact)
    {
        this.beginningReleaseFact = result;
    }

    private processReleaseProgramIncrementQuarterFact(result : ReleaseFact)
    {
        this.releaseProgramIncrementQuarterFact = result;
    }

    private processReleaseGracePeriodEndFact(result : ReleaseFact)
    {
        this.releaseGracePeriodEndFact = result;
    }
    
    public onDefectClick() : void
    {
        this.router.navigate(['/dashboard/defect-backlog', this.release.project.projectId]);
    }

    public onReleaseAtRiskClicked() : void 
    {
        this.router.navigate(['/dashboard/content-detail/key', Content.RELEASEDATEATRISK], { queryParams : { releaseId: this.release.releaseId} } );        
    }    

    public onClick(release : Release) : void
    {
        this.router.navigate(['/dashboard/release-detail', release.releaseId]);
    }
    
    public beginningReleaseMessage() : string
    {
        let message : string = 'Release Grace Period is active.  Release scope is still open for planning changes.';

        if (this.releaseGracePeriodEndFact != undefined)
        {
            let pipe : DatePipe = new DatePipe('en-US');
            let gracePeriodEnd : Date = this.releaseGracePeriodEndFact.getFactTimestamp();
            let gracePeriodEndString : string = pipe.transform(gracePeriodEnd);

            message = `${message} Grace Period ends on ${gracePeriodEndString}`
        }

        return message;
    }

}
