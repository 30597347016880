<p-card class="sprint-acceptance-criteria">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-10 flex justify-content-start">
                <span class="title-text">Acceptance Criteria</span>
            </span>
            <span class="col-2 flex justify-content-end">
                <span *ngIf="acceptanceCriteriaCount && totalStoryCount">
                    <a *ngIf="percentGoodAcceptanceCriteria() < 100 && totalStoryCount != 0" [routerLink]="['/dashboard/content-detail/key', ACCEPTANCECRITERIA]">
                        <i tooltipPosition="left" pTooltip="Sparse Acceptance Criteria" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div *ngIf="acceptanceCriteriaCount && totalStoryCount">
            <p-chart type="pie" [data]="data" [options]="options" [height]="'150px'"></p-chart>
        </div>    
    </span>
</p-card>