<div class="grid card card-w-title">
    <h3 class="col-12">Alert Text Configuration</h3>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-4">
            <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="eventClass" [rowHover]="true" 
                [value]="alertTextList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedAlertText" (onRowSelect)="alertTextSelect()"
                (onRowUnselect)="alertTextUnselect()">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:300px"><!-- description -->
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            Events
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-alertText>
                    <tr [pSelectableRow]="rowData">
                        <td>
                            {{ alertText.eventName }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="1">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div *ngIf="selectedAlertText" class="col-12 sm:col-12 md:col-8">
            <div class="grid col-12">
                <label class="col-4" for="eventType">Event Type:</label>
                <span class="col-8" id="eventType">{{eventTypeText(selectedAlertText.eventType)}}</span>
            </div>
            <div class="grid col-12 flex align-content-center align-items-center">
                <label class="col-4" for="severity">Severity:</label>
                <p-dropdown [options]="severityList" name="severity" [(ngModel)]="selectedAlertText.severity"></p-dropdown>                
            </div>
            <div class="grid col-12">
                <label class="col-4" for="alertTitle">Title:</label>
                <input pInputText maxlength="200" class="col-8" id="alertTitle" #alertTitleTag [(ngModel)]="selectedAlertText.alertTitle" name="alertTitle" />
            </div>
            <div class="grid col-12">
                <label class="col-4" for="alertSummary">Summary:</label>
                <textarea pInputTextarea maxlength="600" rows="5" cols="30" class="col-8 textarea-class" id="alertSummary" #alertSummaryTag [(ngModel)]="selectedAlertText.alertSummary" name="alertSummary"></textarea>
            </div>
            <div class="grid col-12">
                <label class="col-4" for="alertDetail">Detail:</label>
                <textarea pInputTextarea maxlength="1000" rows="10" cols="30" class="col-8 textarea-class" id="alertDetail" #alertDetailTag [(ngModel)]="selectedAlertText.alertDetail" name="alertDetail"></textarea>
            </div>
            <div class="grid col-12 flex align-content-center align-items-center">
                <label class="col-4" for="content">Content:</label>
                <p-dropdown [options]="contentList" name="content" [(ngModel)]="selectedAlertText.contentId"></p-dropdown>                
            </div>

            <div *ngIf="selectedAlertText" class="col-12">
                <div *ngIf="(selectedAlertText.eventType == 1) || (selectedAlertText.eventType == 3) || (selectedAlertText.eventType == 4) || (selectedAlertText.eventType == 7)" class="col-12">
                    <context-variable-list [header]="'Backlog Item Context Variables'" [contextVariableList]="backlogItemContextList"></context-variable-list> 
                </div>
                <div *ngIf="selectedAlertText.eventType == 2" class="col-12">
                    <context-variable-list [header]="'Sprint Context Variables'" [contextVariableList]="sprintContextList"></context-variable-list> 
                </div>
                <div *ngIf="selectedAlertText.eventType == 5" class="col-12">
                    <context-variable-list [header]="'Release Context Variables'" [contextVariableList]="releaseContextList"></context-variable-list> 
                </div>
                <div *ngIf="selectedAlertText.eventType == 6" class="col-12">
                    <context-variable-list [header]="'Project Context Variables'" [contextVariableList]="projectContextList"></context-variable-list> 
                </div>
            </div>
        </div>
    </div>

    <div class="grid col-12 sm:col-12 md:col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12" [disabled]="selectedAlertText == undefined" label="Update" (click)="onUpdate()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>
</div>
