<div class="grid card card-w-title">
    <div *ngIf="project" class="col-12">
        <h3 class="col-12 sm:col-12 md:col-9">Project Configuration - {{project.description}}</h3>
    </div>

    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <h4 class="col-12">Definition of Done Configuration</h4>

    <p-table *ngIf="projectConfigList" class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
        dataKey="projectConfigId" [rowHover]="true" [value]="projectConfigList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedProjectConfig">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- description -->
                    <col><!-- definition of done -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Description
                    </th>
                    <th>
                        Definition of Done
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-projectConfig>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ projectConfig.configDescription }}
                    </td>
                    <td>
                        {{ projectConfig.definitionOfDone }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="2">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <div class="col-12 sm:col-12 md:col-6">
        <button #updateButtonTag pButton class="col-12" label="Update" (click)="onUpdate()"></button>
    </div>

    <div class="col-12 sm:col-12 md:col-6">
        <button #cancelButton1Tag pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
    </div>

    <h4 class="col-12 padding-left-no-margin">Freeze Frame</h4>
    <h5 class="col-12 padding-left-no-margin">
    Freeze Frame will "freeze" the sprint a story is closed in.  This is useful for 
    source systems that will not allow their definition of an open item to remain in a 
    sprint so they force the user to put the story back on the backlog or move to the next
    sprint.  Either situation will result in invalid velocity calculations.
    </h5>

    <div class="col-12">
        <label class="col-9 sm:col-9 md:col-3" for="closedItems">Enable Freeze Frame:</label>
        <span class="col-3 sm:col-3 md:col-9">
            <p-inputSwitch id="freezeFrame" #freezeFrameTag [(ngModel)]="freezeFrame" name="freezeFrame"></p-inputSwitch>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6">
        <button #updateButtonTag pButton class="col-12" label="Update" (click)="onUpdateFreezeFrame()"></button>
    </div>

    <div class="col-12 sm:col-12 md:col-6">
        <button #cancelButton2Tag pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
