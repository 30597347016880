import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { EpicService } from './epic.service';
import { Epic } from './epic';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Component({
  selector: 'epic-detail-summary',
  templateUrl: './epic-detail-summary.component.html',
  styleUrls: ['./epic-detail-summary.component.css' ],
  providers: [ EpicService ]
})

export class EpicDetailSummaryComponent extends BaseComponent implements OnInit 
{ 
    projectId : string;
    epicList : Epic[];
    inactiveEpicList : ValueObjectList<Epic>;
    futureEpicList : ValueObjectList<Epic>;
    completedEpicList : ValueObjectList<Epic>;

    inactiveSortOrder : number = 1;
    futureSortOrder : number = 1;
    completedSortOrder : number = 1;
    maxRows : number = 5;

    constructor( private epicService : EpicService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        this.inactiveEpicList = new ValueObjectList<Epic>();
        this.futureEpicList = new ValueObjectList<Epic>();
        this.completedEpicList = new ValueObjectList<Epic>();
    }

    ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        this.epicService.retrieveActiveEpics(this.projectId)
               .subscribe(result => this.processActiveEpics(result));
    }

    processActiveEpics(result : Epic[]) : void
    {
        this.epicList = result;
    }

    public processCompletedEpics(result : ValueObjectList<Epic>) : void
    {
        this.completedEpicList = result;
    }

    public processFutureEpics(result : ValueObjectList<Epic>) : void
    {
        this.futureEpicList = result;
    }

    public processInactiveEpics(result : ValueObjectList<Epic>) : void
    {
        this.inactiveEpicList = result;
    }
    
    private onClick(epic : Epic) : void
    {
        this.router.navigate(['/dashboard/epic-detail', epic.epicId]);
    }
    
    public onLazyLoadFuture(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.epicService.retrieveFuture(this.projectId, startAt, maxResult, "name", this.futureSortOrder)
            .subscribe(result => this.processFutureEpics(result),
                       error => this.handleError404Okay(error) );
    }

    public onSortClickedFuture(event:any)
    {
        this.futureSortOrder = event.order;

        setTimeout( () => 
            this.epicService.retrieveFuture(this.projectId, 0, this.maxRows, "name", this.futureSortOrder)
                .subscribe(result => this.processFutureEpics(result),
                        error => this.handleError404Okay(error) ), 500 );

    }

    public onLazyLoadCompleted(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.epicService.retrieveCompleted(this.projectId, startAt, maxResult, "name", this.completedSortOrder)
            .subscribe(result => this.processCompletedEpics(result),
                       error => this.handleError404Okay(error) );
    }

    public onSortClickedCompleted(event:any)
    {
        this.completedSortOrder = event.order;

        setTimeout( () => 
            this.epicService.retrieveCompleted(this.projectId, 0, this.maxRows, "name", this.completedSortOrder)
                .subscribe(result => this.processCompletedEpics(result),
                       error => this.handleError404Okay(error) ), 500 );

    }

    public onLazyLoadInactive(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.epicService.retrieveInactive(this.projectId, startAt, maxResult, "name", this.inactiveSortOrder)
            .subscribe(result => this.processInactiveEpics(result),
                       error => this.handleError404Okay(error) );
    }

    public onSortClickedInactive(event:any)
    {
        this.inactiveSortOrder = event.order;

        // The timeout makes up for some weirdo eventing with data table sorting - 
        // revisit after upgrading primeng / angular.
        setTimeout( () => 
            this.epicService.retrieveInactive(this.projectId, 0, this.maxRows, "name", this.inactiveSortOrder)
                .subscribe(result => this.processInactiveEpics(result),
                        error => this.handleError404Okay(error) ), 500 );
    }

}
