<p-card class="sprint-burn-down-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-9 flex justify-content-start">
                <span class="title-text">Sprint Burndown</span>
            </span>
            <span class="col-3 flex justify-content-end">
                <span *ngIf="velocityTrendFact">
                    <i *ngIf="velocityTrendFact.getFactString() == 'up'" class="material-icons" pTooltip="Trending Up" tooltipPosition="left">trending_up</i>
                    <i *ngIf="velocityTrendFact.getFactString() == 'down'" class="material-icons" pTooltip="Trending Down" tooltipPosition="left">trending_down</i>
                    <i *ngIf="velocityTrendFact.getFactString() == 'neutral'" class="material-icons" pTooltip="Trending Flat" tooltipPosition="left">trending_flat</i>
                </span>
                <span *ngIf="velocityUnreliableFact">
                    <a *ngIf="velocityUnreliableFact.getFactBoolean()" [routerLink]="['/dashboard/content-detail/key', VELOCITYUNRELIABLE]" [queryParams]="{projectId: project.projectId}">
                        <i pTooltip="Velocity may be unreliable for planning" tooltipPosition="left" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="pointsRemainingFact">{{ pointsRemainingFact.getFactNumber() | round | plural:'pt' }} remaining</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries && beginningSprintFact && beginningSprintFact.getFactBoolean() == false">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>
        <div *ngIf="beginningSprintFact && beginningSprintFact.getFactBoolean() == true" class="grace-period-text">
            <span *ngIf="beginningSprintFact.getFactBoolean() == true">Graph will appear when grace period is over.</span>
        </div>
    </span>
</p-card>
