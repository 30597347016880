import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';

import { UserAccount } from '../user-account/user-account';
import { UserAccountService } from '../user-account/user-account.service';

import { LoginService } from './login.service';
import { BaseComponent } from '../base/component/base-component.component';

import { LoggedInUser } from '../authentication/logged-in-user';

@Component({
  selector: 'login-app',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
  providers: [ LoginService, UserAccountService ]
})

export class LoginComponent extends BaseComponent implements OnInit
{ 
  emailAddressModel : string;
  passwordModel : string;

  @ViewChild('emailAddressTag') emailAddressTag : ElementRef;
  @ViewChild('passwordTag') passwordTag : ElementRef;
  
  returnUrl : string;

  constructor(private loginService : LoginService, 
              private userAccountService : UserAccountService,
              protected router:Router,
              private route:ActivatedRoute) 
  {              
    super(router);
  }

  public ngOnInit() : void
  {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (this.returnUrl == undefined)
      this.returnUrl = 'dashboard';
  }

  public onSubmit() : void
  {
    this.clearErrorMessages(); 

    let emailAddress = this.emailAddressTag.nativeElement.value;
    let password = this.passwordTag.nativeElement.value;

    if (emailAddress.length == 0)
    {
      this.addErrorMessage("Email address cannot be empty.");
      return;
    }

    if (password.length == 0)
    {
      this.addErrorMessage("Password cannot be empty.");
      return;
    }
    
    this.loginService.login(emailAddress, password)
      .subscribe(result => { this.processLogin(result) }, 
                 error => { this.loginError() } );
  }

  public onForgotPassword() : void
  {
    this.router.navigate(['forgot-password']);
  }

  public processLogin(result: string)
  {
    localStorage.setItem('auth-token', result);

    // Logging in was successful - get the user account information.
    this.userAccountService.retrieve()
      .subscribe(result => this.processUserAccount(result),
               error => { this.loginError() } ); 
  }

  private processUserAccount(userAccount : UserAccount) : void
  {
    LoggedInUser.setLoggedInUser(userAccount);  
      
    // Navigate to whereever we need to go.
    this.router.navigate([this.returnUrl]);
  }

  public loginError() 
  {
    localStorage.removeItem('auth-token');
    this.clearErrorMessages();
    this.addErrorMessage("Email Addresss or Password incorrect");
  }

}
