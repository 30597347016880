<p-card class="org-scrum-maturity">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Last Three Sprint Average Maturity&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div *ngIf="projectList" class="chart-style">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'200px'"></p-chart>
        </div>    
    </span>
</p-card>

<zoom-graph #zoomGraph type="bar" [sourceData]="data" [sourceOptions]="options"></zoom-graph>
