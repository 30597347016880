export class TFSProjectKey {

  projectId: string = "";
  teamId: string = "";
  projectName: string = "";
  teamName: string = "";

  private constructor() {}

  public static of(pKey: string): TFSProjectKey {
    const result = new TFSProjectKey();
    const keys = result.parse(pKey);

    if (keys.length === 4) {
      result.setProjectId(keys[0]);
      result.setTeamId(keys[1]);
      result.setProjectName(keys[2]);
      result.setTeamName(keys[3]);
    }

    if (keys.length === 1) {
      result.setProjectId(keys[0]);
      result.setTeamId("");
      result.setProjectName("");
      result.setTeamName("");
    }

    if (keys.length === 0) {
      result.setProjectId("");
      result.setTeamId("");
      result.setProjectName("");
      result.setTeamName("");
    }

    return result;
  }

  public static from(pProjectId: string, pTeamId: string, pProjectName: string, pTeamName: string): TFSProjectKey {
    const result = new TFSProjectKey();

    result.setProjectId(pProjectId);
    result.setTeamId(pTeamId);
    result.setProjectName(pProjectName);
    result.setTeamName(pTeamName);

    return result;
  }

  public getProjectId(): string {
    return this.projectId;
  }

  public setProjectId(pProjectId: string): void {
    this.projectId = pProjectId;
  }

  public getProjectName(): string {
    return this.projectName;
  }

  public setProjectName(pProjectName: string): void {
    this.projectName = pProjectName;
  }

  public getTeamId(): string {
    return this.teamId;
  }

  public setTeamId(pTeamId: string): void {
    this.teamId = pTeamId;
  }

  public getTeamName(): string {
    return this.teamName;
  }

  public setTeamName(pTeamName: string): void {
    this.teamName = pTeamName;
  }

  protected parse(pCompositeKey: string): string[] {
    const result: string[] = pCompositeKey.split('/').map(token => token.trim()).filter(token => token.length > 0);

    if (result.length !== 4 && result.length !== 1) {
      throw new Error(`Error parsing TFS composite key: ${pCompositeKey}`);
    }

    return result;
  }

  public getAreaPath(): string {
    if (!this.isComposite()) {
      return "";
    } 
    else {
      return `${this.getProjectName()}\\\\${this.getTeamName()}`;
    }
  }

  public getUrlFragment(): string {
    if (!this.isComposite()) {
      return this.getProjectId();
    } 
    else {
      return `${this.getProjectId()}/${this.getTeamId()}`;
    }
  }

  public toString(): string {
    if (!this.isComposite()) {
      return this.getProjectId();
    } 
    else {
      return `${this.getProjectId()}/${this.getTeamId()}/${this.getProjectName()}/${this.getTeamName()}`;
    }
  }

  public isComposite(): boolean {
    return this.projectId !== "" && this.teamId !== "" && this.projectName !== "" && this.teamName !== "";
  }
}