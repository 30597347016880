import { UserAccount } from '../user-account/user-account';

export class RefreshProjectListEvent 
{
    userAccount : UserAccount;  
    
    constructor(userAccount : UserAccount)
    {
        this.userAccount = userAccount;
    }          
}