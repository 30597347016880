import { BaseAgileObject } from './base-agile-object';
import { Project } from '../../project/project';

export abstract class BaseAgileProjectObject extends BaseAgileObject
{
    project : Project;

    public initialize(object: BaseAgileProjectObject)
    {
        super.initialize(object);

        if (object.project != undefined)
            this.project = this.initializeObject(Project, object.project);
        else
            this.project = undefined;
    }
}