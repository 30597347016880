import { Directive, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Directive()
export class BaseAppComponent implements OnInit
{
    topbarTheme = 'blue';

    menuTheme = 'light';

    layoutMode = 'light';

    menuMode = 'static';

    inlineMenuPosition = 'bottom';

    inputStyle = 'filled';

    ripple = true;

    isRTL = false;

    constructor(protected primengConfig: PrimeNGConfig) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
