<div *ngIf="project" class="grid card card-w-title">
    <h3 class="col-12">Alert List for {{project.description}}</h3>

    <div class="col-12 sm:col-12 md:col-4">
        <mini-app-alert-count-overview [project]="project"></mini-app-alert-count-overview>
    </div>

    <div class="col-12 sm:col-12 md:col-4">
        <mini-app-alert-severity-overview [project]="project"></mini-app-alert-severity-overview>
    </div>

    <div class="col-12 sm:col-12 md:col-4">
        <mini-app-alert-count-severity-overview [project]="project"></mini-app-alert-count-severity-overview>
    </div>

    <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="appAlertId"
        [rowHover]="true" [value]="appAlertList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="totalRecords" responsiveLayout="scroll"
        (onLazyLoad)="loadData($event)">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- name -->
                    <col style="width:100px"><!-- severity -->
                    <col style="width:150px"><!-- date received -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Alert Summary
                    </th>
                    <th>
                        Severity
                    </th>
                    <th>
                        Date Received
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-appAlert let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td>
                        <app-alert-detail [appAlert]="appAlert"></app-alert-detail>
                    </td>
                    <td>
                        {{appAlert.severityDescription }}
                    </td>
                    <td>
                        {{ appAlert.dateCreated | dateFormat }}        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="3">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>
</div>

