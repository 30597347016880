import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform 
{
    public transform(dateObject: any): string 
    {
        let result : string = "unsupported date";

        if (dateObject instanceof Date)
            result = moment(dateObject).format("MMM Do YYYY");

        if (typeof(dateObject) == "string")
            result = moment(dateObject).format("MMM Do YYYY");
        
        return result;
    }
}