import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ContentService } from '../../content/content.service';
import { ContentDetail } from '../../content/content-detail';

import { BaseComponent } from '../../base/component/base-component.component';

@Component({
  selector: 'content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.css'],
  providers: [ ContentService ]
})
export class ContentViewComponent extends BaseComponent implements OnInit
{ 
    content : ContentDetail;
    contentId : string;
    contentKey : string; 

    constructor(protected router:Router,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private contentService: ContentService) 
    {
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.contentId = params['contentId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.content = undefined;
            this.contentId = params['contentId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        if (this.contentId != undefined)
        {
            this.contentService.retrieveDetail(this.contentId)
                .subscribe(result=>this.processContent(result),
                           error => this.handleError(error) );
        }

        if (this.contentKey != undefined)
        {
            this.contentService.retrieveDetailByKey(this.contentKey)
                .subscribe(result=>this.processContent(result),
                           error => this.handleError(error) );
        }
    }

    protected processContent(result: ContentDetail) : void
    {
        this.content = result;
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
     }

    public provideContentDetail() : SafeHtml
    {
        return this.sanitizer.bypassSecurityTrustHtml(this.content.contentDetail);
    }

    public onReturn() : void
    {
        this.router.navigate(['/dashboard/admin/content-list']);
    }

}
