import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from './base-component.component';

import { BaseFactObject } from '../value-object/base-fact-object';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';

import { LoggedInUser } from '../../authentication/logged-in-user';

@Directive()
export abstract class BaseDetailComponent extends BaseComponent
{
    teamCostsExistFact : ProjectFact;
    costPerPointFact : BaseFactObject;
    displayCosts : boolean = false;

    constructor(protected router : Router, 
                protected projectFactService : ProjectFactService)
    {
        super(router);
    }

    protected retrieveCosts(projectId : string) : void 
    {
        this.projectFactService.retrieveById(projectId, ProjectFact.TEAMCOSTSEXIST)
            .subscribe(result => this.processTeamCostsExistFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieveById(projectId, ProjectFact.COSTPERPOINT)
            .subscribe(result => this.processCostPerPointFact(result),
                       error => this.handleError404Okay(error) );
    }

    protected processCostPerPointFact(result : ProjectFact) : void
    {
        this.costPerPointFact = result;
    }

    protected processTeamCostsExistFact(result : ProjectFact) : void
    {
        this.teamCostsExistFact = result;
    }

    public isFinUser() : boolean 
    {
        let result : boolean = false;

        if (LoggedInUser.getLoggedInUser() != undefined)
            result = LoggedInUser.getLoggedInUser().isFinUser();

        return result;
    }
    
}