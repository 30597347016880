import { ModuleWithProviders }   from '@angular/core';
import { Routes, RouterModule }  from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { LoginGuard } from '../authentication/login-guard';
import { AdminGuard } from '../authentication/admin-guard';
import { ContentGuard } from '../authentication/content-guard';
import { MainComponent } from '../main/main.component';
import { OrgConfigComponent } from '../admin/org-config/org-config.component';
import { OrgSourceSystemListComponent } from '../admin/org-source-system/org-source-system-list.component';
import { OrgSourceSystemAddComponent } from '../admin/org-source-system/org-source-system-add.component';
import { OrgSourceSystemUpdateComponent } from '../admin/org-source-system/org-source-system-update.component';
import { ProjectFilterComponent } from '../admin/project-filter/project-filter.component';
import { ProfileComponent } from '../user-account/profile.component';
import { UserAlertPreferenceComponent } from '../user-alert-preference/user-alert-preference.component';
import { UserAccountListComponent } from '../admin/user-account/user-account-list.component';
import { UserAccountUpdateComponent } from '../admin/user-account/user-account-update.component';
import { UserAccountAddComponent } from '../admin/user-account/user-account-add.component';
import { ProjectDetailComponent } from '../project/project-detail.component';
import { ReleaseDetailComponent } from '../release/release-detail.component';
import { ReleaseDetailSummaryComponent } from '../release/release-detail-summary.component';
import { EpicDetailSummaryComponent } from '../epic/epic-detail-summary.component';
import { EpicDetailComponent } from '../epic/epic-detail.component';
import { SprintDetailSummaryComponent } from '../sprint/sprint-detail-summary.component';
import { SprintDetailReloadComponent } from '../sprint/sprint-detail-reload.component';
import { SprintDetailComponent } from '../sprint/sprint-detail.component';
import { UserStoryDetailComponent } from '../user-story/user-story-detail.component';
import { ProductBacklogComponent } from '../product-backlog/product-backlog.component';
import { ProjectSummaryComponent } from '../project/project-summary.component';
import { SearchResultsListComponent } from '../search/search-results-list.component';
import { AlertTextComponent } from '../admin/alert-text/alert-text.component';
import { GoalTextComponent } from '../admin/goal-text/goal-text.component';
import { ContentListComponent } from '../admin/content/content-list.component';
import { ContentAddComponent } from '../admin/content/content-add.component';
import { ContentUpdateComponent } from '../admin/content/content-update.component';
import { ContentViewComponent } from '../admin/content/content-view.component';
import { ContentDetailComponent } from '../content/content-detail.component';
import { DefectBacklogComponent } from '../defect/defect-backlog.component';
import { DefectDetailComponent } from '../defect/defect-detail.component';
import { ChoreDetailComponent } from '../chore/chore-detail.component';
import { SpikeDetailComponent } from '../spike/spike-detail.component';
import { DependencyDetailComponent } from '../dependency/dependency-detail.component';
import { AppAlertListComponent } from '../app-alert/app-alert-list.component';
import { InboxItemListComponent } from '../inbox-item/inbox-item-list.component';
import { InboxItemDetailComponent } from '../inbox-item/inbox-item-detail.component';
import { SprintEndReportComponent } from '../sprint-end-report/sprint-end-report.component';
import { OrgJobScheduleComponent } from '../admin/org-job-schedule/org-job-schedule.component';
import { ProjectConfigListComponent } from '../admin/project-config/project-config-list.component';
import { ProjectConfigDetailComponent } from '../admin/project-config/project-config-detail.component';
import { ProjectTeamCostListComponent } from '../project-team-cost/project-team-cost-list.component';
import { ProjectTeamCostAddComponent } from '../project-team-cost/project-team-cost-add.component';
import { ProjectTeamCostUpdateComponent } from '../project-team-cost/project-team-cost-update.component';

import { UserStoryTestComponent } from '../user-story/user-story-test.component'

import { ApolloComponent } from '../apollo/apollo.component';

const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [ LoginGuard ], 
    children: [
      { path: '', component: MainComponent },
      { path: 'inbox-item-list', component: InboxItemListComponent },
      { path: 'inbox-item-detail/:inboxItemId', component: InboxItemDetailComponent },
      { path: 'sprint-end-report/:sprintEndReportId', component: SprintEndReportComponent },
      { path: 'search-results-list/:searchText', component: SearchResultsListComponent },
      { path: 'user-story-detail/:userStoryId', component: UserStoryDetailComponent },
      { path: 'project-summary/:projectId', component: ProjectSummaryComponent },
      { path: 'project-detail/:projectId', component: ProjectDetailComponent },
      { path: 'release-detail-summary/:projectId', component: ReleaseDetailSummaryComponent },
      { path: 'release-detail/:releaseId', component: ReleaseDetailComponent },
      { path: 'epic-detail-summary/:projectId', component: EpicDetailSummaryComponent },
      { path: 'epic-detail/:epicId', component: EpicDetailComponent },
      { path: 'sprint-detail-summary/:projectId', component: SprintDetailSummaryComponent },
      { path: 'sprint-detail/:sprintId', component: SprintDetailComponent },
      { path: 'sprint-detail-reload/:sprintId', component: SprintDetailReloadComponent },
      { path: 'product-backlog/:projectId', component: ProductBacklogComponent },
      { path: 'defect-backlog/:projectId', component: DefectBacklogComponent },
      { path: 'defect-detail/:defectId', component: DefectDetailComponent },
      { path: 'chore-detail/:choreId', component: ChoreDetailComponent },
      { path: 'spike-detail/:spikeId', component: SpikeDetailComponent },
      { path: 'dependency-detail/:dependencyId', component: DependencyDetailComponent },
      { path: 'app-alert-list/:projectId', component: AppAlertListComponent },
      { path: 'project-team-cost-list/:projectId', component:ProjectTeamCostListComponent },
      { path: 'project-team-cost-add/:projectId', component:ProjectTeamCostAddComponent },
      { path: 'project-team-cost-update/:projectTeamCostId', component:ProjectTeamCostUpdateComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'user-alert-preference', component: UserAlertPreferenceComponent },
      { path: 'content-detail/:contentId', component: ContentDetailComponent },
      { path: 'content-detail/key/:contentKey', component: ContentDetailComponent },
      { path: 'admin/org-config', component: OrgConfigComponent, canActivate: [ AdminGuard] },
      { path: 'admin/org-job-schedule', component: OrgJobScheduleComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/org-source-system-list', component: OrgSourceSystemListComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/org-source-system-add', component: OrgSourceSystemAddComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/org-source-system-update/:orgSourceSystemId', component: OrgSourceSystemUpdateComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/project-filter', component: ProjectFilterComponent, canActivate: [ AdminGuard ]  },
      { path: 'admin/project-config-list', component: ProjectConfigListComponent, canActivate: [ AdminGuard ]  },
      { path: 'admin/project-config-detail/:projectId', component: ProjectConfigDetailComponent, canActivate: [ AdminGuard ]  },
      { path: 'admin/content-list', component: ContentListComponent, canActivate: [ AdminGuard, ContentGuard ]  },
      { path: 'admin/content-add', component: ContentAddComponent, canActivate: [ AdminGuard, ContentGuard ]  },
      { path: 'admin/content-update/:contentId', component: ContentUpdateComponent, canActivate: [ AdminGuard, ContentGuard ]  },
      { path: 'admin/content-view/:contentId', component: ContentViewComponent, canActivate: [ AdminGuard, ContentGuard ]  },
      { path: 'admin/user-account-list', component: UserAccountListComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/user-account-add', component: UserAccountAddComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/user-account-update/:userId', component: UserAccountUpdateComponent, canActivate: [ AdminGuard ] },
      { path: 'admin/alert-text', component: AlertTextComponent, canActivate: [ AdminGuard, ContentGuard ] },
      { path: 'admin/goal-text', component: GoalTextComponent, canActivate: [ AdminGuard, ContentGuard ] }

      // Temp code for Torqata testing.
      , { path: 'apollo', component: ApolloComponent, canActivate: [ ] }

    ]
  }
];

export const dashboardRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(dashboardRoutes);
