<p-overlayPanel #pointChangeDetailTag [appendTo]="'body'" [showCloseIcon]="true" [dismissable]="false" [style]="{'width':'325px','height':'125px'}">
    <p-table [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
        dataKey="dateChanged" [style]="{'width':'290px','height':'110px','overflow':'scroll' }"
        [rowHover]="true" [value]="pointChangeDetailList" responsiveLayout="scroll">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="width:150px"><!-- date changed -->
                    <col style="width:150px"><!-- from -->
                    <col style="width:150px"><!-- to -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Date Changed     
                    </th>
                    <th>
                        From
                    </th>
                    <th>
                        To
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-pointChangeDetail>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ pointChangeDetail.dateChanged | dateFormat }}
                    </td>
                    <td>
                        {{ pointChangeDetail.from }}
                    </td>
                    <td>
                        {{ pointChangeDetail.to }}
                    </td>
                </tr>
            </ng-template>
    </p-table>
</p-overlayPanel>
