import { Component, OnInit, OnChanges, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { Project } from '../../project/project';

import { SprintFact } from '../../sprint-fact/sprint-fact';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service'; 

import { Content } from '../../content/content';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'defect-mttr-timeline',
  templateUrl: './defect-mttr-timeline.component.html',
  styleUrls: ['./defect-mttr-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})
export class DefectMTTRTimelineComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    project : Project;

    @Output() click = new EventEmitter<any>();

    timeSeries : TimeSeries;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService) 
    {
        super(router);

        this.options = {
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Defect MTTR'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void
    {
        if (this.project != undefined)
        {
            this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTAVERAGEDEFECTMTTR, 10)
                .subscribe(result =>this.processTimeSeries(result),
                           error => this.handleError404Okay(error) ); 
        }
    }

    private processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'line',
                        label: 'Defect Mean Time To Repair',
                        backgroundColor: 'rgba(0, 255, 0, .40)',
                        pointHoverBackgroundColor: 'rgba(0, 255, 0, 1)',
                        borderColor: 'rgba(0, 255, 0, 1)',
                        borderWidth: 2,
                        fill: true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) 
                                { 
                                    return a.getFieldNumber(SprintFact.AVERAGEDEFECTMTTR);
                                })
                    }
                ]
            }
        }
    }

    public get projectMTTR() : number
    {
        let result : number = 0

        if (this.timeSeries != null && this.timeSeries.timeSeriesList.length >0)
        {
            let index : number = this.timeSeries.timeSeriesList.length - 1;
            result = this.timeSeries.timeSeriesList[index].getFieldNumber(SprintFact.AVERAGEDEFECTMTTR);            
        }

        return result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    public get DEFECTMTTR() : string
    {
        return Content.DEFECTMTTR;
    }
}
