import { BaseValueObject } from './base-value-object';
import { BaseTimeSeriesObject } from './base-time-series-object';
import { SimpleMap } from './simple-map';
import { KeyValue } from './key-value';

import * as moment from 'moment';

// export abstract class BaseFactObject extends BaseValueObject
export class BaseFactObject extends BaseValueObject
{
    name : string;
	fact : string;
	factEvaluated : string;

    public static readonly RESULT : string = "result";

    public initialize(object: BaseFactObject) : void
    {
        super.initialize(object);

        this.name = object.name;
        this.fact = object.fact;
        this.factEvaluated = object.factEvaluated;
    }

    public getFactString() : string
    {
        var data = JSON.parse(this.fact);
        return data[this.name]; 
    }

    public getFactBoolean() : boolean
    {
        var data = JSON.parse(this.fact);
        let result:boolean = data[this.name];

        return result;
    }

    public getFactStringList() : Array<string>
    {
        let list = JSON.parse(this.fact)[this.name] as string[];
        return list;
    }

    public getFactNumber() : number
    {
        var data = JSON.parse(this.fact);
        return data[this.name];
    }

    public getFactTimestamp() : Date
    {
        var data = JSON.parse(this.fact);
        let dateString : string = data[this.name];

        if (dateString != "null")
            return moment(dateString).toDate();
        else   
            return undefined;
    }

    public setFactNumber(fact : number) : void
    {
        this.fact = '{"' + this.name + '" : ' + fact + '} ';
    }

    public setFactBoolean(fact : boolean) : void
    {
        this.fact = '{"' + this.name + '" : ' + fact + '} ';
    }
    
    public getFactMap () : SimpleMap
    {
        let dataList = JSON.parse(this.fact)[this.name];

        let resultList : SimpleMap = new SimpleMap();

        for (let data of dataList)
        {
            let keyValue = new KeyValue();
            keyValue.key = Object.keys(data)[0];
            keyValue.value = data[keyValue.key]; 
            resultList.push(keyValue);
        }

        return resultList;
    }

    public getFactTimeSeries <T extends BaseTimeSeriesObject> (c: {new(): T; } ) : Array<T>
    {
        let timeSeriesList = JSON.parse(this.fact)[this.name][BaseFactObject.RESULT] as T[];
        let resultList : Array<T> = new Array<T>();

        for (let timeData of timeSeriesList)
        {
            let object = new c();
            object.initialize(timeData);
            resultList.push(object);
        }

        return resultList;
    }

}
