import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { GoalText } from './goal-text';
import { GoalTextService } from './goal-text.service';

import { Content } from '../../content/content';
import { ContentService } from '../../content/content.service';

import { ContextVariable } from '../context-variable/context-variable';
import { BaseComponent } from '../../base/component/base-component.component';

import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'goal-text',
  templateUrl: './goal-text.component.html',
  styleUrls: ['./goal-text.component.css'],
  providers: [ GoalTextService, ContentService, ConfirmationService ]
})
export class GoalTextComponent extends BaseComponent implements OnInit
{
    backlogItemContextList : ContextVariable[];
    sprintContextList : ContextVariable[];
    releaseContextList : ContextVariable[];
    projectContextList : ContextVariable[];

    goalTextList : GoalText[];
    selectedGoalText : GoalText;

    contentList: SelectItem[];

    constructor(protected router:Router,
                private goalTextService : GoalTextService, 
                private contentService : ContentService,
                private confirmationService : ConfirmationService) 
    {
      super(router);
    }

    ngOnInit() : void
    {
        super.clearErrorMessages();

        this.contentService.retrieveAll()
            .subscribe(result => this.processContentList(result),
                       error => this.handleError(error) );        

        this.retrieveGoalTextList();
    }

    public retrieveGoalTextList() : void
    {
        this.selectedGoalText = undefined;

        this.goalTextService.retrieveAll()
          .subscribe(result => this.processGoalTextList(result) );
    }

    private processGoalTextList(goalTextList : GoalText[]): void
    {
      this.goalTextList = goalTextList;
      this.selectedGoalText = undefined;
    }

    private processContentList(result : Array<Content>) : void
    {
        this.contentList = [];
        this.contentList.push( { label:"(none)", value: undefined } );

        for (let content of result)
        {
            this.contentList.push( { label:content.contentKey, value: content.contentKey } );
        }
    }

    public goalTextSelect() : void
    {
    }

    public goalTextUnselect() : void
    {
        this.selectedGoalText = undefined;
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public onUpdate() : void
    {
        this.clearErrorMessages();

        if (LLUtils.isStringEmpty(this.selectedGoalText.goalTitle) == true)
        {
            this.addErrorMessage("Goal Title must be specified");
            return;
        }

        if (LLUtils.isStringEmpty(this.selectedGoalText.goalDetail) == true)
        {
            this.addErrorMessage("Goal Detail must be specified");
            return;
        }

        if (LLUtils.isNumeric(this.selectedGoalText.sequence) == false)
        {
            this.addErrorMessage("Sequence must be specified");
            return;
        }

        this.goalTextService.update(this.selectedGoalText)
            .subscribe(result=>this.updateSuccessful(result),
                       error => this.handleError(error) ); 
    }

    private updateSuccessful(result : GoalText) : void
    {
        this.addInfoMessage("Update successful!");
        this.retrieveGoalTextList();
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
     }

}
