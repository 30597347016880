import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseFilledDoughnutComponent } from '../../base/component/base-filled-doughnut.component';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';

import { PluralPipe } from '../../pipes/plural.pipe';

import { ContentDetail } from '../../content/content-detail';

import * as moment from 'moment';

@Component({
  selector: 'sprint-commitment',
  templateUrl: './sprint-commitment.component.html',
  styleUrls: ['./sprint-commitment.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class SprintCommitmentComponent extends BaseFilledDoughnutComponent implements OnInit 
{ 
    @Input()
    sprint : BaseSprint;

    @Output() click = new EventEmitter<any>();

    sprintCommitmentFact : SprintFact;
    pointsCompletedFact : SprintFact;
    sprintOverloadedFact : SprintFact;
    
    constructor( protected router : Router,
                 private sprintFactService : SprintFactService)
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTCOMMITMENT)
            .subscribe(result => this.processSprintCommitmentFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.POINTSCOMPLETED)
            .subscribe(result => this.processPointsCompletedFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTOVERLOADED)
            .subscribe(result => this.processSprintOverloadedFact(result),       
                       error => super.handleError(error) );

        }

    public processSprintCommitmentFact(result : SprintFact) : void
    {
        this.sprintCommitmentFact = result;
        this.drawGraph();
    }

    public processPointsCompletedFact(result : SprintFact) : void
    {
        this.pointsCompletedFact = result;
        this.drawGraph();
    }
    
    public processSprintOverloadedFact(result : SprintFact)
    {
        this.sprintOverloadedFact = result;
    }
    
    public drawGraph() : void
    {
        if (this.sprintCommitmentFact == undefined || this.pointsCompletedFact == undefined)
            return;

        this.data = {
            labels : ['Points Remaining', 'Points Completed'],
            datasets: [
                {
                    type:'doughnut',
                    label: '',
                    data: [ this.getPointsRemaining(),
                            this.pointsCompletedFact.getFactNumber()
                     ],

                    backgroundColor: [
                        "rgba(0, 0, 256, .40)",
                        "rgba(0, 256, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(0, 256, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2
                    ],
                    hoverBackgroundColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(0, 256, 0, 1)"
                    ],
                    hoverBorderColor: [
                        "rgba(0, 0, 256, 1)",
                        "rgba(0, 256, 0, 1)"
                    ]
                }
            ]
        };

        this.configureOptions();
    }

    public percentComplete() : number 
    {
        let result : number = 0;

        if (this.sprintCommitmentFact != undefined && this.pointsCompletedFact != undefined)
        {
            if (this.sprintCommitmentFact.getFactNumber() > 0)
            {
                result = (this.pointsCompletedFact.getFactNumber() / this.sprintCommitmentFact.getFactNumber() ) * 100;
            }
        }

        return result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    private get OVERLOADEDSPRINT() : string
    {
        return ContentDetail.OVERLOADEDSPRINT;
    }

    protected buildDescription() : string
    {
        let result : string = "";

        if (this.sprintCommitmentFact != undefined)
        {
            let pluralPipe = new PluralPipe();
            result = pluralPipe.transform(this.sprintCommitmentFact.getFactNumber(), "pt");
        }

        return result;
    }

    protected getPointsRemaining() : number
    {
        let result : number = 0;

        if (this.sprintCommitmentFact.getFactNumber() - this.pointsCompletedFact.getFactNumber() < 0)
            result = 0;
        else    
            result = this.sprintCommitmentFact.getFactNumber() - this.pointsCompletedFact.getFactNumber();

        return result;
    }
}