import * as moment from 'moment';

export class DefectCount
{
    sprintEndDate: Date;
    openDefects: number;
    closedDefects: number;
    totalDefects : number;

    public initialize(defectCount : DefectCount) : void
    {
        this.sprintEndDate = moment(defectCount.sprintEndDate).toDate();
        this.openDefects = defectCount.openDefects;
        this.closedDefects = defectCount.closedDefects;
        this.totalDefects = defectCount.totalDefects;
    }
}
