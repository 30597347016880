import { Component, OnInit, OnChanges, Inject, forwardRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ProjectSummaryComponent } from './project-summary.component';
import { BaseComponent } from '../base/component/base-component.component';

import { Project } from './project';
import { ProjectFact } from '../project-fact/project-fact';
import { ProjectService } from './project.service';
import { ProjectFactService } from '../project-fact/project-fact.service';

import { ProjectStatus } from '../project-status/project-status';
import { ProjectStatusService } from '../project-status/project-status.service';

import { Sprint } from '../sprint/sprint';
import { SprintService } from '../sprint/sprint.service';

import { Release } from '../release/release';
import { ReleaseService } from '../release/release.service';

import { LoggedInUser } from '../authentication/logged-in-user';

@Component({
  selector: 'project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectService, ProjectStatusService, ProjectFactService, SprintService, ReleaseService ]
})

export class ProjectDetailComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    projectId : string;

    project : Project;
    projectStatus : ProjectStatus;
    releaseList : Release[];

    numberOfSprints : number = 0;
    teamCostsExistFact : ProjectFact;

    constructor( private projectService : ProjectService,
                 private projectStatusService : ProjectStatusService,
                 private projectFactService : ProjectFactService,
                 private releaseService : ReleaseService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        
        this.projectId = "";

        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
            this.loadData();
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.project = undefined;
            this.projectId = params['projectId'];
            this.loadData();
        });

    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    private loadData() : void
    {
        this.project = undefined;
        this.releaseList = undefined;
        this.projectStatus = undefined;

        this.projectService.retrieve(this.projectId)
            .subscribe(result => this.processProjectDetail(result),
                       error => this.handleError(error) );

        this.releaseService.retrieveActive(this.projectId)
            .subscribe(result => this.processReleaseList(result),
                       error => this.handleError(error) );

        this.projectFactService.retrieveById(this.projectId, ProjectFact.NUMBEROFSPRINTS)
            .subscribe(result => this.processNumberOfSprints(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieveById(this.projectId, ProjectFact.TEAMCOSTSEXIST)
            .subscribe(result => this.processTeamCostsExistFact(result),
                       error => this.handleError404Okay(error) );

        this.projectStatusService.retrieve(this.projectId)
            .subscribe(result => this.processProjectStatus(result),
                       error => this.handleError404Okay(error) );
                                  
    }

    processProjectDetail(result : Project) : void
    {
        this.project = result;
    }

    private processProjectStatus(result : ProjectStatus)
    {
        this.projectStatus = result;
    }

    protected processReleaseList(releaseList : Release[]) : void
    {
        this.releaseList = releaseList;
    }

    private processNumberOfSprints(result : ProjectFact)
    {
        this.numberOfSprints = result.getFactNumber();
    }

    private processTeamCostsExistFact(result : ProjectFact)
    {
        this.teamCostsExistFact = result;
    }
    
    private onAlertClick() : void
    {
        this.router.navigate(['/dashboard/app-alert-list', this.project.projectId]);
    }

    private onVelocityClick() : void
    {
        this.router.navigate(['/dashboard/sprint-detail-summary', this.project.projectId]);
    }

    private onSprintCommitmentClick() : void
    {
        this.router.navigate(['/dashboard/sprint-detail-summary', this.project.projectId]);
    }

    private onReleaseStatusClick() : void
    {
        this.router.navigate(['/dashboard/release-detail-summary', this.project.projectId]);
    }

    protected get userHasPermission() : boolean
    {
        let result : boolean = false;

        if (LoggedInUser.getLoggedInUser() != undefined)
            result = LoggedInUser.getLoggedInUser().isFinUser();

        return result;
    }

}
