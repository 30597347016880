import { Directive, Input } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { AppComponent } from '../app.component';

@Directive()
export class BaseDashboardProfileComponent {

    @Input() key = 'inline-menu';

    @Input() style: any;

    @Input() styleClass: string;

    active: boolean;

    constructor(public dashboard: DashboardComponent, public app: AppComponent) { }

    onClick(event) {
        this.dashboard.onInlineMenuClick(event, this.key);
        event.preventDefault();
    }

    get isTooltipDisabled() {
        return !(this.dashboard.isSlim() && !this.dashboard.isMobile());
    }

    get tabIndex() {
        return !this.dashboard.inlineMenuActive  ? '-1' : null;
    }

    isHorizontalActive() {
       return this.dashboard.isHorizontal() && !this.dashboard.isMobile();
    }
}
