<div class="grid card card-w-title">
    <h3 class="col-12">Product Backlog</h3>

    <a *ngIf="upcomingBacklogItemsNotReadyFact && upcomingBacklogItemsNotReadyFact.getFactStringList().length > 0" style="width:100%; margin-bottom:10px" [routerLink]="['/dashboard/content-detail/key', DEFINITIONOFREADY]" [routerLinkActive]="['active-menulink']" [queryParams]="{ projectId: project.projectId }">
        <p-message class="col-12 hover-over" severity="info" [text]="upcomingBacklogItemsNotReadyMessage" [closable]="false"></p-message>
    </a>

    <mini-backlog-count *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></mini-backlog-count> 
    <mini-backlog-depth *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></mini-backlog-depth> 
    <mini-velocity-overview *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></mini-velocity-overview>    

    <sprint-backlog-count-timeline *ngIf="project && numberOfSprintsFact && numberOfSprintsFact.getFactNumber() > 0" class="col-12 sm:col-12 md:col-4" [project]="project"></sprint-backlog-count-timeline>
    <points-per-story-timeline *ngIf="project && numberOfSprintsFact && numberOfSprintsFact.getFactNumber() > 0" class="col-12 sm:col-12 md:col-4" [project]="project"></points-per-story-timeline>
    <point-distribution-timeline *ngIf="project && numberOfSprintsFact && numberOfSprintsFact.getFactNumber() > 0" class="col-12 sm:col-12 md:col-4" [project]="project"></point-distribution-timeline>

    <div *ngIf="productBacklog && project && teamCostsExistFact && costPerPointFact" class="grid col-12 sm:col-12 md:col-12">
        <div *ngIf="isFinUser()" class="grid col-12 sm:col-12 md:col-12">
            <label class="col-9 sm:col-9 md:col-4" for="displayCost">Display Estimated Costs:</label>
            <span class="col-3 sm:col-3 md:col-8">
                <p-inputSwitch id="displayCosts" #displayCostsTag [disabled]="teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false" [(ngModel)]="displayCosts" name="displayCosts" 
                    pTooltip="{{ teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false ? 'Team costs do not exist for this backlog.' : '' }}">
                </p-inputSwitch>
            </span>
        </div>

        <div class="col-12" *ngFor="let sprint of sprintDetailList">
            <h4>{{sprint.title}}</h4>
            <backlog-item-list class="col-12" [orgSourceSystem]="orgSourceSystem" [backlogItemList]="sprint.backlogItemList" [completed]="true" [showFacts]="true" 
                [costPerPointFact]="costPerPointFact" [displayCosts]="displayCosts">
            </backlog-item-list>
        </div>
    </div>

    <h4 class="col-12">Backlog</h4>
    <div *ngIf="productBacklog && project && teamCostsExistFact && costPerPointFact" class="product-backlog grid col-12">
        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-9 sm:col-9 md:col-4" for="closedItems">Show Closed Items:</label>
            <span class="col-3 sm:col-3 md:col-8">
                <p-inputSwitch id="closedItems" #closedItemsTag [(ngModel)]="closedItems" name="closeItems" (click)="onClosedClicked()"></p-inputSwitch>
            </span>
        </div>
    
        <div class="grid col-12 sm:col-12 md:col-6">
            <label class="col-9 sm:col-9 md:col-4" for="sprintPlanner">Sprint Planner:</label>
            <span class="col-3 sm:col-3 md:col-8">
                <p-inputSwitch id="sprintPlanner" #sprintPlannerTag [disabled]="upcomingBacklogItemsFact == undefined || disableSprintPlanner()" 
                    [(ngModel)]="sprintPlanner" name="sprintPlanner" (click)="onSprintPlannerClicked(); backlogItemListTag.reloadList();" tooltipPosition="left"
                    pTooltip="{{ disableSprintPlanner() == true ? 'Sprint Planner requires two sprints of backlog depth.' : '' }}">
                </p-inputSwitch>
            </span>
        </div>

        <backlog-item-list class="col-12" #backlogItemListTag [orgSourceSystem]="orgSourceSystem" [backlogItemList]="productBacklog" [completed]="closedItems"
            [lazy]="true" [rows]="rows" [total]="productBacklog.total" [rowStyleClass]="productBacklogRowStyleClass"
            [costPerPointFact]="costPerPointFact" [displayCosts]="displayCosts" [displaySprint]="true"
            (onLazyLoad)="onLazyLoad($event)" [paginator]="true">
        </backlog-item-list>
    </div> 
</div>
