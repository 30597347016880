import { Component, OnInit, Injectable, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';

import { OrgSourceSystemService } from '../../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../../org-source-system/org-source-system';

import { ProjectFilterService } from './project-filter.service';
import { ProjectFilter } from './project-filter';
import { Project } from '../../project/project';

import { BaseComponent } from '../../base/component/base-component.component';

@Component({
  selector: 'project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFilterService, OrgSourceSystemService, ConfirmationService ]
})
export class ProjectFilterComponent extends BaseComponent implements OnInit
{ 
    orgSourceSystemList : OrgSourceSystem[];
    selectedOrgSourceSystem : OrgSourceSystem;
    blockedDocument : boolean = false;

    sourceProjectList : Project[];
    filteredProjectList : Project[];

    constructor(protected router:Router,
                private projectFilterService : ProjectFilterService, 
                private confirmationService : ConfirmationService,
                private orgSourceSystemService: OrgSourceSystemService ) 
    {
      super(router);

      this.sourceProjectList = [];
      this.filteredProjectList = [];
    }

    public ngOnInit() : void
    {
        super.clearErrorMessages();
        this.retrieveOrgSourceSystemList();
    }

    public retrieveOrgSourceSystemList() : void
    {
        this.orgSourceSystemService.retrieveAll()
          .subscribe(result =>this.processOrgSourceSystemList(result) );
    }

    private processOrgSourceSystemList(orgSourceSystemList : OrgSourceSystem[]): void
    {
      this.orgSourceSystemList = orgSourceSystemList
      this.selectedOrgSourceSystem = null;
    }

    public orgSourceSystemSelect(event : Event) : void
    {
        this.retrieveProjectLists();
    }

    private retrieveProjectLists()
    {
        this.sourceProjectList = undefined;
        this.filteredProjectList = undefined;
        this.blockedDocument = true;

        forkJoin( [
            this.projectFilterService.retrieveProjectList(this.selectedOrgSourceSystem),
            this.projectFilterService.retrieveFilteredProjectList(this.selectedOrgSourceSystem) ]
        ).subscribe(([result1, result2]) => 
            {
                this.processSourceProjectList(result1);
                this.processFilteredProjectList(result2);
            }, 
            error => this.handleError(error)
        );
    }

    private processSourceProjectList(result : Project[])
    {
        this.sourceProjectList = result;
        this.unblockDocument();
    }

    private processFilteredProjectList(result : Project[])
    {
        this.filteredProjectList = result;
        this.unblockDocument();
    }

    private unblockDocument() : void 
    {
        if (this.filteredProjectList != undefined && this.sourceProjectList != undefined)
            this.blockedDocument = false;
    }

    public orgSourceSystemUnselect(event : Event) : void
    {
        this.sourceProjectList = [];
        this.filteredProjectList = [];
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public onUpdate() : void
    {
        this.clearErrorMessages();
        this.blockedDocument = true;
        this.projectFilterService.createProjectFilterList(this.filteredProjectList, this.selectedOrgSourceSystem)
            .subscribe(result=>this.updateSuccessful(result),
                       error => this.handleError(error) ); 
    }

    private updateSuccessful(result : ProjectFilter[]) : void
    {
        this.addInfoMessage("Update successful!");
        this.retrieveProjectLists();
    }

    protected handleError(error : any) : any
    {
        this.blockedDocument = false;
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
    }
}
