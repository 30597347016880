import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { BacklogItem } from '../backlog-item/backlog-item';
import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class SearchService extends BaseHttpService
{
    searchUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.searchUrl = this.config.baseUrl + '/rest/search';
    }

    public search(searchText : string) : Observable<BacklogItem[]>
    {
        let searchUrl = this.searchUrl + "?searchText=" + searchText; 
        return super.baseRetrieveList(BacklogItem, searchUrl); 
    }

    public searchSubset(searchText : string, startAt : number, maxResult : number) : Observable<ValueObjectList<BacklogItem>>
    {
        let searchUrl = this.searchUrl + "/subset?searchText=" + searchText; 
        return super.baseRetrieveSubset(BacklogItem, searchUrl, startAt, maxResult); 
    }
}