import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { Config } from '../config/config';

@Injectable()
export class DateService extends BaseHttpService
{
    dateServiceUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.dateServiceUrl = config.baseUrl + "/rest/date";
    }

    public retrieveList() : Observable<string[]>
    {
        let requestUrl = this.dateServiceUrl + "/quarter-list";
        return super.baseRetrieve(requestUrl, 
                                    (response) => { return response },
                                    (error) => { return error} );
    }
}