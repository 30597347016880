import { UserAccount } from '../user-account/user-account';

export class LoggedInUser
{
    private static loggedInUser : UserAccount = undefined;

    public static getLoggedInUser() : UserAccount
    {
        return LoggedInUser.loggedInUser;
    }

    public static setLoggedInUser(loggedInUser : UserAccount) : void
    {
        LoggedInUser.loggedInUser = loggedInUser;
    }
}
