<div [style]="styleWidth">

<p-table *ngIf="projectList && epicList" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="projectId"
    [rowHover]="true" [value]="projectList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
    (onLazyLoad)="loadData($event)" (sortFunction)="customSort($event)" [customSort]="true" [sortField]="sortField" [sortOrder]="sortOrder">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:5%"><!-- Column for row expander -->
                <col style="width:5%"><!-- % Complete -->
                <col>                   <!-- Project Name -->
                <col style="width:15%"><!-- To Do -->
                <col style="width:15%"><!-- In Progress -->
                <col style="width:15%"><!-- Completed -->
                <col style="width:15%"><!-- Total -->
            </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>

                </th>
                <th class="percent-complete-header" [pSortableColumn]="'percentComplete'">
                    %
                </th>
                <th [pSortableColumn]="'description'">
                    Project
                </th>
                <th [pSortableColumn]="'todo'">
                    Epics To Do
                </th>
                <th [pSortableColumn]="'inprogress'">
                    Epics In Progress
                </th>
                <th [pSortableColumn]="'completed'">
                    Epics Completed
                </th>
                <th [pSortableColumn]="'total'">
                    Total Epics
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-project let-columns="columns" let-index="rowIndex" let-expanded="expanded">
            <tr [pSelectableRow]="rowData" [ngClass]="projectRowStyleClass(project, index)">
                <td>
                    <button [pRowToggler]="rowData" class="shortcut-button-small secondary pi pi-fw" pButton id="expandRowButton" #expandRowButtonTag [icon]="expanded ? 'pi-chevron-circle-down' : 'pi-chevron-circle-right'" pTooltip="Detail"></button>
                </td>
                <td style="min-width:30px">
                    <p-progressBar [value]="projectEpicsPercentComplete(project)" [showValue]="false" [pTooltip]="projectEpicsPercentComplete(project) + '%'"></p-progressBar>                    
                </td>
                <td>
                    <div>
                        <a [routerLink]="['/dashboard/project-detail', project.projectId]">{{project.description}}</a>
                    </div>
                </td>
                <td>
                    {{ projectEpicsToDo(project) }}
                </td>
                <td>
                    {{ projectEpicsInProgress(project) }}
                </td>
                <td>
                    {{ projectEpicsCompleted(project) }}
                </td>
                <td>
                    {{ projectEpicsTotal(project) }}
                </td>                
            </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-project let-rowData let-columns="columns">
            <tr>
                <td [attr.colspan]="7">
                    <epic-dashboard-list class="col-12" [project]="project" [epicList]="getEpicList(project)" [lazy]="false" 
                        [rows]="5" [paginator]="true">
                    </epic-dashboard-list>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
            <tr>
                <td colspan="3">
                    Total Percent Complete : {{ totalEpicsPercentComplete()}}%
                </td>
                <td>
                    {{ totalEpicsToDo() }}
                </td>
                <td>
                    {{ totalEpicsInProgress() }}
                </td>
                <td>
                    {{ totalEpicsCompleted() }}
                </td>
                <td>
                    {{ totalEpicsTotal() }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="7">
                    No records found
                </td>
            </tr>
        </ng-template>
</p-table>


</div>
