import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DashboardMenuService } from './dashboard-menu.service';
import { DashboardComponent } from './dashboard.component';
import { BaseDashboardSubmenuComponent } from './base-dashboard-submenu.component';

@Component({
    /* tslint:disable:component-selector */
    selector: '[dashboard-submenu]',
    /* tslint:enable:component-selector */
    templateUrl: './dashboard-submenu.component.html',
    styleUrls: ['./dashboard-submenu.component.scss'],
    encapsulation : ViewEncapsulation.None,
    host: {
        '[class.layout-root-menuitem]': 'root || active',
        '[class.active-menuitem]': '(active)'
    },
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px',
                padding: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px',
                padding: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px',
                padding: '0px'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class DashboardSubmenuComponent extends BaseDashboardSubmenuComponent
{
    constructor(public dashboard : DashboardComponent, 
        public router : Router, 
        protected cd: ChangeDetectorRef, 
        protected menuService: DashboardMenuService) 
    {   
        super(dashboard, router, cd, menuService);

        // Reset the subscription established in base class.
        if (this.menuSourceSubscription != undefined)
            this.menuSourceSubscription.unsubscribe();

        // New subscription.
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(currentKey => {
            
            // activate/deactivate current active menu
            if (currentKey != undefined && (this.key == currentKey || currentKey.indexOf(this.key) >= 0) ) 
            {
                this.active = true;
            }
            else
            {
                this.active = false;
            }
        });
    }        

    public ngOnInit() : void
    {
        super.ngOnInit();
    }

    // Override base class.
    public itemClick(event: Event) : void
    {
        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return;
        }

        // navigate with hover in horizontal mode
        if (this.root) {
            this.dashboard.menuHoverActive = !this.dashboard.menuHoverActive;
        }

        // notify other items
        this.menuService.onMenuStateChange(this.key);

        // execute command
        if (this.item.command) {
            this.item.command({originalEvent: event, item: this.item});
        }

        // toggle active state
        if (this.item.items) 
        {
            this.active = true;

            //execute routerlink - ADDED PDC
            if (this.item.routerLink) {
                this.router.navigate(this.item.routerLink);
            }            
        } 
        else 
        {
            // activate item
            this.active = true;

            // reset horizontal and slim menu
            if (this.dashboard.isHorizontal() || this.dashboard.isSlim()) {
                this.menuService.reset();
                this.dashboard.menuHoverActive = false;
            }

            if (!this.dashboard.isStatic()) {
                this.dashboard.menuActive = false;
            }

            this.dashboard.mobileMenuActive = false;
        }

        this.removeActiveInk(event);
    }

    // Override base class.  This will need to be updated based on 
    // route changes.
    public updateActiveStateFromRoute() : void
    {
        let url : string = "";

        if (this.item.routerLink != undefined && this.item.routerLink.length > 0)
            url = this.item.routerLink[0];

        for (let i = 1; i < this.item.routerLink.length; i++)
            url = `${url}/${this.item.routerLink[i]}`;

        let active : boolean = this.router.isActive(url, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' } );

        if (active == true && this.key != undefined)
        {
            this.menuService.onMenuStateChange(this.key);
        }
    }

}
