import { BaseValueObject } from './base-value-object';

// Note that this is really not an object but rather
// an interface.
export class ValueObjectList<T> extends Array<T>
{
    public static readonly STARTAT : string = "startAt";
	public static readonly MAXRESULT : string = "maxResult";
	public static readonly RESULT : string = "result";
	public static readonly TOTAL : string = "total";

    public startAt : number;
    public maxResult : number;
    public total : number;

    public static build <T extends BaseValueObject> (c: {new(): T; }, data : T[]) : ValueObjectList<T>
    {
        let resultList : ValueObjectList<T> = new ValueObjectList<T>();

        resultList.startAt = 0;
        resultList.maxResult = data.length;
        resultList.total = data.length;
        let responseList  = data as T[];

        for (let responseData of responseList)
        {
            let object = new c();
            object.initialize(responseData);

            resultList.push(object);
        }

        return resultList;
    }
    
    public static concat <T> (list1: ValueObjectList<T>, list2:ValueObjectList<T>) : ValueObjectList<T>
    {
        let result : ValueObjectList<T> = new ValueObjectList<T>();

        for (let element of list1)
            result.push(element);

        for (let element of list2)
            result.push(element);

        result.startAt = list2.startAt;
        result.maxResult = list2.maxResult;
        result.total = list2.total;

        return result;
    }

    // Primarily for unit testing.
    public static toJson <T> (list : ValueObjectList<T>) : string
    {
        let elements : Array<T> = new Array<T>();

        for (let element of list)
            elements.push(element);

        var result = {
            "startAt" : list.startAt,
            "maxResult" : list.maxResult,
            "total"  : list.total,
            "result" : elements
        }

        return JSON.stringify(result);
    }
}
