import { Injectable } from '@angular/core';
import { BaseDashboardMenuService } from './base-dashboard-menu.service';

// Make sure this is a singleton.
@Injectable({
    providedIn: 'root',
})
export class DashboardMenuService extends BaseDashboardMenuService
{
    // Put lucidLIFT specific overrides here.
}
