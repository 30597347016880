<p-card class="sprint-agile-story">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Story Writing</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="agileStoryCount && totalStoryCount">
                    <span tooltipPosition="left" pTooltip="% Good Stories">{{ percentGoodStories() }}%</span>
                </span>
                <span *ngIf="agileStoryCount && totalStoryCount">
                    <a *ngIf="percentGoodStories() < 100 && totalStoryCount != 0" [routerLink]="['/dashboard/content-detail/key', GOODSTORIES]">
                        <i tooltipPosition="left" pTooltip="Check Story Form" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div *ngIf="agileStoryCount && totalStoryCount">
            <p-chart type="pie" [data]="data" [options]="options" [height]="'150px'"></p-chart>
        </div>    
    </span>
</p-card>
