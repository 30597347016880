import { Component } from '@angular/core';
import { Content } from '../content/content';
import { LoggedInUser } from '../authentication/logged-in-user';

import * as moment from 'moment';

@Component({
    selector: 'dashboard-footer',
    templateUrl: 'dashboard-footer.component.html',
    styleUrls: ['./dashboard-footer.component.scss']

})

export class DashboardFooterComponent {

    readonly year : string = moment().format('YYYY');

    public get TERMSOFUSE() : string
    {
        return Content.TERMSOFUSE;
    }

    public get PRIVACYPOLICY() : string
    {
        return Content.PRIVACYPOLICY;
    }
    
    public get COMPANYNAME() : string 
    {
        let result : string = undefined;

        if (LoggedInUser.getLoggedInUser() != undefined)
            result = LoggedInUser.getLoggedInUser().organization.name;

        return result;
    }
}
