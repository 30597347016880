import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { BaseOrgTimeSeriesComponent } from '../../base/component/base-org-time-series-component.component';

import { Project } from '../../project/project';

import { SprintFact } from '../../sprint-fact/sprint-fact';
import { LLUtils } from '../../utilities/ll-utils';

import * as _ from 'lodash';

// Internal class for this component.
class DefectDensityAverage {
    average : number;
    index : number;
}

@Component({
  selector: 'org-defect-density-timeline',
  templateUrl: './org-defect-density-timeline.component.html',
  styleUrls: ['./org-defect-density-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})
export class OrgDefectDensityTimelineComponent extends BaseOrgTimeSeriesComponent implements OnInit, OnChanges 
{ 
    constructor( protected router : Router,
                 protected timeSeriesService : TimeSeriesService) 
    { 
        super(router, timeSeriesService);
        this.options.onClick = (event, array) => { this.graphClickEvent(event, array) }
    }

    public ngOnInit() : void
    {
        super.ngOnInit();
        this.options['plugins']['title'].text = `Defect Density Trend By Project`;
    }

    public retrieveTimeSeriesByProject(project : Project) : void
    {
        this.timeSeriesService.retrieve(project, project.projectId, TimeSeries.PROJECTDEFECTDENSITY, 10)
            .subscribe(result => this.processTimeSeries(project.projectId, result),
                       error => this.handleError404Okay(error) );
    }

    protected processTimeSeries(projectId : string, result: TimeSeries)
    {
        if (_.isEqual(this.timeSeriesMap.get(projectId), result) == true)
            return;
    
        this.timeSeriesMap.set(projectId, result);
        this.buildGraph();
    }

    protected buildGraph() : void
    {
        // First, build all the labels.
        let labelArray : string[] = [];

        for (let i = 10; i > 0; i-- )
        {
            switch (i)
            {
                case 1:
                    labelArray.push("Current Sprint");
                    break;

                case 2:
                    labelArray.push("Last Sprint");
                    break;
        
                default:
                    labelArray.push(`${i} sprints ago`);
                    break;
            }
        }

        this.data = {
            labels: labelArray
        }

        // Next, plot the projects.
        // Put in logic to check if data changed.
        this.data.datasets = [];

        for (let project of this.projectList)
        {
            let timeSeries = this.timeSeriesMap.get(project.projectId);

            // If we didn't have our project loaded yet, continue.
            if (timeSeries == undefined)
                continue;

            let hexColor : string = LLUtils.uuidToColor(timeSeries.objectId);
            let color = LLUtils.hexToRgb(hexColor, 1.0);
            let transparentColor = LLUtils.hexToRgb(hexColor, .40);

            let paddedValues : number[] = this.padTimeSeries(timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.DEFECTDENSITY); } ), 10);

            this.data.datasets.push({
                    type: 'line',
                    label: this.getProjectName(timeSeries.objectId),
                    backgroundColor: transparentColor,
                    hoverBackgroundColor: color,
                    borderColor: color,
                    borderWidth: 2,
                    fill: false,
                    tension: .5,
                    data: paddedValues
            });
        }

        // If we have more than one team selected, include an average of the teams.
        if (this.projectList.length > 1)
        {
            let defectDensityAverageList : DefectDensityAverage[] = this.buildTeamDefectDensityAverage();

            let paddedValues : number[] = this.padTimeSeries(defectDensityAverageList.map( function(a : DefectDensityAverage) { return a.average; } ), 10);

            this.data.datasets.push({
                type: 'line',
                label: 'Average',
                backgroundColor: this.BLACKTRANSPARENT,
                hoverBackgroundColor: this.BLACK,
                borderColor: this.BLACK,
                borderWidth: 5,
                fill: false,
                tension: .5,
                data: paddedValues
            });
        }

    }
    
    public buildTeamDefectDensityAverage() : DefectDensityAverage[]
    {
        let result : DefectDensityAverage[] = [];

        // if no teams then just return.
        if (this.projectList.length <= 0)
            return result;

        // Create a deep copy of our timeseries map.  We do this because
        // we need to resort the elements of the map.
        let timeSeriesMap : Map<string, TimeSeries> = _.cloneDeep(this.timeSeriesMap);

        // Accommodate up to 10 sprints.
        for (let i = 0; i < 10; i++)
        {
            let teamCount : number = 0;
            let totalDefectDensity : number = 0;

            for (let projectId of timeSeriesMap.keys() )
            {
                // If this project doesn't have a sprint at the index we're 
                // checking, continue...
                if (timeSeriesMap.get(projectId).timeSeriesList.length <= i)
                    continue;

                let timeSeriesDescending = timeSeriesMap.get(projectId);
                timeSeriesDescending.sortDescending();

                teamCount += 1;

                let teamDefectDensity = timeSeriesDescending.timeSeriesList[i].getFieldNumber(SprintFact.DEFECTDENSITY);
                totalDefectDensity = totalDefectDensity + teamDefectDensity;
            }

            if (teamCount > 0)
            {
                let average : number = LLUtils.round((totalDefectDensity / teamCount), 2);

                let defectDensityAverage : DefectDensityAverage = new DefectDensityAverage();
                defectDensityAverage.average = average;
                defectDensityAverage.index = i;

                result.push(defectDensityAverage);
            }
        }

        // Now, resort the result list so the most recent sprint is 
        // last and the oldest sprint is first.
        result = result.sort((defectDensityAverage1 : DefectDensityAverage, defectDensityAverage2 : DefectDensityAverage) => {

            // Sort descending by index so the oldest sprint is first and the
            // newest sprint is last.
            return defectDensityAverage2.index - defectDensityAverage1.index;
        });

        return result;
    }

}
