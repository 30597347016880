import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseValueObject } from '../../base/value-object/base-value-object';
import { ValueObjectList } from '../../base/value-object/value-object-list';
import { map, retry } from 'rxjs/operators';

export abstract class BaseHttpService 
{
    public static readonly NOTFOUND = "notFound";

    protected http: HttpClient;
    protected static readonly RESULT : string = "result";
    protected static readonly NUMRETRIES : number = 3;

    constructor(http: HttpClient) 
    { 
        this.http = http;
    }

    public baseRetrieveList<T extends BaseValueObject>(c: {new(): T; }, url: string) : Observable<T[]>
    {
        let headers = this.createHeaders();

        return this.http.get(url, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map( (response : any) => this.processListResponse(c, response)), 
                
                error => this.baseHandleError(error) );
        
    }

    public baseRetrieveSubset<T extends BaseValueObject>(c: {new(): T; }, url: string, startAt: number, maxResult:number) : Observable<ValueObjectList<T>>
    {
        let headers = this.createHeaders();
        let fullUrl : string;

        if (url.indexOf("?") == -1)
            fullUrl = url + "?";
        else
            fullUrl = url + "&";

        fullUrl = fullUrl + ValueObjectList.STARTAT + "=" + startAt + "&" + ValueObjectList.MAXRESULT + "=" + maxResult;

        return this.http.get(fullUrl, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map( (response : T) => this.processSubsetResponse(c, response) ), 
                
                error => this.baseHandleError(error) );
    }

    public baseRetrieveObject<T extends BaseValueObject>(c: {new(): T; }, url: string) : Observable<T>
    {
        let headers = this.createHeaders();

        return this.http.get(url, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map( (response : T) => this.processObjectResponse(c, response) ),
                
                error => this.baseHandleError(error) );
    }

    public baseRetrieve(url: string, responseHandler: any, errorHandler: any) : Observable<any>
    {
        let headers = this.createHeaders();

        return this.http.get(url, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map(response => responseHandler(response) ),
                
                error => errorHandler(error) );
    }

    public baseRetrieveAll(url: string, responseHandler: any, 
        errorHandler: any) : Observable<any>
    {
        let headers = this.createHeaders();

        return this.http.get(url, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map(response => responseHandler(response) ),
                
                error => errorHandler(error) );
    }

    public baseCreate(url : string, data : string, responseHandler: any, 
        errorHandler: any) : Observable<any>
    {
        let headers = this.createHeaders();

        return this.http.post(url, data, { headers: headers } ).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map(response => responseHandler(response) ),
        
                error => errorHandler(error) );
    }

    public baseCreateObject<T extends BaseValueObject> (c: {new(): T; }, url : string, object : T) : Observable<T>
    {
        let headers = this.createHeaders();
        let data = JSON.stringify(object);

        return this.http.post(url, data, { headers: headers } ).pipe(
                retry(BaseHttpService.NUMRETRIES),  
                map( (response : T) => this.processObjectResponse(c, response) ),
            
                error => this.baseHandleError(error) );
    }

    public basePostList<T extends BaseValueObject>(c: {new(): T; }, url: string, data : string) : Observable<T[]>
    {
        return this.baseCreateList(c, url, data);
    }

    public baseCreateList<T extends BaseValueObject>(c: {new(): T; }, url: string, data : string) : Observable<T[]>
    {
        let headers = this.createHeaders();

        return this.http.post(url, data, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES), 
                map( (response : T) => this.processListResponse(c, response) ),
                    
                error => this.baseHandleError(error) );
    }

    public baseUpdate(url: string, data: string, responseHandler: any, 
        errorHandler: any) : Observable<any>
    {
        let headers = this.createHeaders();

        return this.http.put(url, data, { headers: headers } ).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map( (response : any) => responseHandler(response) ), 

                error => errorHandler(error) );    
    }

    public baseUpdateObject <T extends BaseValueObject>(c: {new(): T; }, url: string, object : T) : Observable<T>
    {
        let headers = this.createHeaders();
        let data = JSON.stringify(object);
                
        return this.http.put(url, data, { headers: headers }).pipe(
                retry(BaseHttpService.NUMRETRIES),
                map( (response : T) => this.processObjectResponse(c, response) ),
                
                error => this.baseHandleError(error) );
    }

    public baseUpdateList <T extends BaseValueObject>(c: {new(): T; }, url: string, object : T[]) : Observable<T[]>
    {
        let headers = this.createHeaders();
        let data = JSON.stringify(object);
                
        return this.http.put(url, data, { headers: headers } ).pipe(
                retry(BaseHttpService.NUMRETRIES), 
                map( (response : T) => this.processListResponse(c, response) ),

                error => this.baseHandleError(error) );
    }

    public baseDelete(url: string, responseHandler: any, errorHandler: any) : Observable<any>
    {
        let headers = this.createHeaders();

        return this.http.delete(url, { headers: headers } ).pipe(
                retry(BaseHttpService.NUMRETRIES), 
                map( response   => responseHandler(response) ),
                    
                error => errorHandler(error) );
    }

    public baseDeleteObject(url: string) : Observable<any>
    {
        let headers = this.createHeaders();

        return this.http.delete(url, { headers: headers }).pipe(
            retry(BaseHttpService.NUMRETRIES),   
            map( response => this.processDeleteObjectResponse(response) ),
            
            error => this.baseHandleError(error) );
    }

    private processDeleteObjectResponse(response: any) : string
    {
        let result = response;
        return result;
    }

    protected processListResponse <T extends BaseValueObject> (c: {new(): T; }, response: T) : T[]
    {
        return this.buildList(c, response);
    }

    protected processSubsetResponse <T extends BaseValueObject> (c: {new(): T; }, response: T) : ValueObjectList<T>
    {
        return this.buildSubset(c, response);
    }

    protected processObjectResponse <T extends BaseValueObject> (c: {new(): T; }, responseData: T) : T
    {
        return this.buildObject(c, responseData);
    }

    protected buildList <T extends BaseValueObject> (c: {new(): T; }, response : T) : Array<T>
    {
        let resultList : Array<T> = new Array<T>();

        let responseList  = response[BaseHttpService.RESULT] as T[];
        
        for (let responseData of responseList)
        {
            let object = new c();
            object.initialize(responseData);

            resultList.push(object);
        }
                    
        return resultList;
    }

    protected buildSubset <T extends BaseValueObject> (c: {new(): T; }, response : T) : ValueObjectList<T>
    {
        let resultList : ValueObjectList<T> = new ValueObjectList<T>();

        resultList.startAt = response[ValueObjectList.STARTAT];
        resultList.maxResult = response[ValueObjectList.MAXRESULT];
        resultList.total = response[ValueObjectList.TOTAL];
        let responseList  = response[ValueObjectList.RESULT] as T[];

        for (let responseData of responseList)
        {
            let object = new c();
            object.initialize(responseData);

            resultList.push(object);
        }
        
        return resultList;
    }

    protected buildObject <T extends BaseValueObject> (c: {new(): T; }, responseData : T) : T
    {
        let object = new c();
        
        object.initialize(responseData);
        
        return object;
    }

    protected baseHandleError(error: any): any 
    {
        return error.message || error;
    }

    protected createHeaders() : HttpHeaders
    {
        // Note HttpHeaders is immutable
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        let authToken = localStorage.getItem('auth-token');
        headers = headers.append('Authorization', `Bearer ${authToken}`);

        return headers;
    }
}