import { BaseValueObject } from '../../base/value-object/base-value-object';
import { Project } from '../../project/project';

import * as random from 'random-utility';

export class ProjectConfig extends BaseValueObject
{
	public projectConfigId : string;
	public project : Project;
	public configCategory : string;
	public configKey : string;
	public configValue : string;
	public configDescription : string;
	public override : boolean;
	public overrideValue : string;

	// Common keys.
	public static readonly STORYPOINTS : string = "storyPoints";
	public static readonly RANK : string = "rank";
	public static readonly FREEZEFRAME : string = "freezeFrame";

	// Common Categories
	public static readonly STATUSCATEGORIES : string = "statusCategories";

    public initialize (object: ProjectConfig) : void
    {
        super.initialize(object);
	
        this.projectConfigId = object.projectConfigId;
	    this.configCategory = object.configCategory;
	    this.configKey = object.configKey;
	    this.configValue = object.configValue;
	    this.configDescription = object.configDescription;
	    this.override = object.override;
		this.overrideValue = object.overrideValue;

		this.project = this.initializeObject(Project, object.project)
    }

	public set definitionOfDone(isDone : boolean)
	{
		if (isDone == true)
		{
			this.overrideValue = "done";
			this.override = true;
		}			
		else
		{
			this.overrideValue = undefined;
			this.override = false;
		}
	}

	public get definitionOfDone() : boolean
	{
		let result : boolean = false;
		let configValue : string = this.configValue;

		if (this.override == true)
			configValue = this.overrideValue;

		if (configValue.toLowerCase() == "done")
			result = true;

		return result;
	}

	public static createProjectConfig(project : Project, configKey : string, configValue : string) : ProjectConfig
	{
		let result : ProjectConfig = new ProjectConfig();

		result.projectConfigId = random.uuid();
		result.project = project;
		result.configKey = configKey;
		result.configValue = configValue;
		result.configCategory = null;
		result.configDescription = null;
		result.override = false;
		result.overrideValue = null;

		return result;
	}
}