import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router } from '@angular/router';

import { BaseSprint } from '../base/value-object/base-sprint';
import { SprintFact } from '../sprint-fact/sprint-fact';
import { SprintFactService } from '../sprint-fact/sprint-fact.service';

@Component({
  selector: 'sprint-heading',
  templateUrl: './sprint-heading.component.html',
  styleUrls: ['./sprint-heading.component.scss' ],
  providers: [ SprintFactService ]
})

export class SprintHeadingComponent extends BaseComponent implements OnInit
{ 
    @Input()
    sprint : BaseSprint;

    @Input()
    showButton : boolean;

    darkSprintFact : SprintFact = undefined;

    constructor(protected router: Router,
                protected sprintFactService : SprintFactService) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTDARKSPRINT)
            .subscribe(result => this.processDarkSprintFact(result), 
                       error => this.handleError404Okay(error) );
    }

    public onClick(sprint : BaseSprint) : void
    {
        this.router.navigate(['/dashboard/sprint-detail', sprint.sprintId]);
    }

    public processDarkSprintFact(result : SprintFact)
    {
        this.darkSprintFact = result;
    }

    public isDarkSprint() : boolean
    {
        let result : boolean = false;

        if (this.darkSprintFact != undefined && this.darkSprintFact.getFactStringList().length > 0)
            result = true;

        return result;
    }
    
}
