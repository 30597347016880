// Very basic map implementation.
import { KeyValue } from './key-value';

export class SimpleMap extends Array<KeyValue>
{
    constructor()
    {
        super();
    }
    
    // NOTE : this functon doesn't seem to work for some reason.
    public getByKey(key : string) : any
    {
        let result : any = null;

        for (let keyValue of this)
        {
            if (keyValue.key == key)
            {
                result = keyValue.value;
                break;
            }
        }

        return result;
    }
}