<p-card class="epic-point-burnup">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Epic Scope</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="epicScope && epicPointsCompleted" tooltipPosition="left" pTooltip="Percent Complete">{{ percentComplete() | round }} %</span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="displayPoints == false">{{ epic.description }}</span>
            <span *ngIf="epicScope && epicPointsCompleted && displayPoints == true">{{ pointsRemaining() | round }} pts remaining</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>