import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseBacklogItemDetailComponent } from '../base/component/base-backlog-item-detail.component';
import { Router, ActivatedRoute } from '@angular/router';

import { Defect } from './defect';
import { DefectService } from './defect.service';
import { DefectFact } from '../defect-fact/defect-fact';

import { SprintService } from '../sprint/sprint.service';


@Component({
  selector: 'defect-detail',
  templateUrl: './defect-detail.component.html',
  styleUrls: ['./defect-detail.component.scss' ],
  providers: [ DefectService, SprintService ]
})

export class DefectDetailComponent extends BaseBacklogItemDetailComponent implements OnInit, AfterViewInit 
{ 
    defectId : string;
    defect : Defect;
    
    constructor(private defectService : DefectService,
                private sprintService : SprintService,
                private route: ActivatedRoute,
                protected router: Router) 
    { 
        super(router);

        this.route.params.subscribe(params => {
            this.defectId = params['defectId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public ngAfterViewInit() : void
    {
    }

    private initFields() : void
    {
        this.defect = undefined;
        this.sprint = undefined;

        this.defectService.retrieve(this.defectId)
            .subscribe(result=>this.processDefect(result) );
    }

    private processDefect(defect : Defect ) : void
    {
        this.defect = defect;
        
        if (this.defect.getFact(DefectFact.BLOCKED) != undefined)
            this.blockersFact = this.defect.getFact(DefectFact.BLOCKED);

        if (this.defect.getFact(DefectFact.SPRINTSWORKEDON) != null)
            this.sprintsWorkedOnFact = this.defect.getFact(DefectFact.SPRINTSWORKEDON);

        this.sprintService.retrieveByDefect(this.defect)
            .subscribe(result=>this.processSprintFound(result),
                       error=>this.processSprintNotFound(error) );
    }
}
