import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base/service/base-http.service';
import { ReleaseFact } from './release-fact';
import { Release } from '../release/release';

@Injectable()
export class ReleaseFactService extends BaseHttpService
{
    private releaseFactUrl: string;

    constructor(http: HttpClient, private config: Config)
    {
        super(http);
        this.releaseFactUrl = this.config.baseUrl + '/rest/releasefact';
    }

    public retrieve(release: Release, factName : string) : Observable<ReleaseFact>
    {
        let url = this.releaseFactUrl + `?releaseId=${release.releaseId}&name=${factName}`;
        return super.baseRetrieveObject(ReleaseFact, url);
    }

    public retrieveById(releaseId: string, factName : string) : Observable<ReleaseFact>
    {
        let url = this.releaseFactUrl + `?releaseId=${releaseId}&name=${factName}`;
        return super.baseRetrieveObject(ReleaseFact, url);
    }
}