import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseBacklogItemDetailComponent } from '../base/component/base-backlog-item-detail.component';
import { Router, ActivatedRoute } from '@angular/router';

import { Dependency } from './dependency';
import { DependencyService } from './dependency.service';
import { DependencyFact } from '../dependency-fact/dependency-fact';

import { SprintService } from '../sprint/sprint.service';

@Component({
  selector: 'dependency-detail',
  templateUrl: './dependency-detail.component.html',
  styleUrls: ['./dependency-detail.component.scss' ],
  providers: [ DependencyService, SprintService ]
})

export class DependencyDetailComponent extends BaseBacklogItemDetailComponent implements OnInit, AfterViewInit
{ 
    dependencyId : string;
    dependency : Dependency;
    
    constructor(private dependencyService : DependencyService,
                private sprintService : SprintService,
                private route: ActivatedRoute,
                protected router: Router) 
    { 
        super(router);

        this.route.params.subscribe(params => {
            this.dependencyId = params['dependencyId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public ngAfterViewInit() : void
    {
    }
    
    private initFields() : void
    {
        this.dependency = undefined;
        this.sprint = undefined;

        this.dependencyService.retrieve(this.dependencyId)
            .subscribe(result=>this.processDependency(result) );
    }

    private processDependency(dependency : Dependency ) : void
    {
        this.dependency = dependency;
        
        if (this.dependency.getFact(DependencyFact.BLOCKED) != null)
            this.blockersFact = this.dependency.getFact(DependencyFact.BLOCKED);
        
        if (this.dependency.getFact(DependencyFact.SPRINTSWORKEDON) != null)
            this.sprintsWorkedOnFact = this.dependency.getFact(DependencyFact.SPRINTSWORKEDON);

        this.sprintService.retrieveByDependency(this.dependency)
            .subscribe(result=>this.processSprintFound(result),
                error=>this.processSprintNotFound(error) );
    }
}
