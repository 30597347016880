import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ViewChild } from '@angular/core';

import { BaseComponent } from '../base/component/base-component.component';
import { SearchService } from './search.service';
import { BacklogItem } from '../backlog-item/backlog-item';
import { ValueObjectList } from '../base/value-object/value-object-list';

@Component({
  selector: 'search-results-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.css' ],
  providers: [ SearchService ]
})

export class SearchResultsListComponent extends BaseComponent implements OnInit 
{ 
    backlogItemList : ValueObjectList<BacklogItem> = undefined;
    searchText : string;

    rows : number = 20;

    @ViewChild(DataView) dataViewComponent: DataView;

    constructor( private searchService : SearchService, 
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.route.params.subscribe(params => {
            this.backlogItemList = undefined;
            this.searchText = params['searchText']; 
        });

        // Kick off loadData.
        let event = { first: 0, rows : this.rows }
        this.loadData(event);
    }

    public processSearchResults(backlogItemList : ValueObjectList<BacklogItem>)
    {    
        if (this.backlogItemList == undefined)
            this.backlogItemList = backlogItemList;
        else
            this.backlogItemList = ValueObjectList.concat(this.backlogItemList, backlogItemList);
    }

    public loadData(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;
        let total : number = 0;

        if (this.backlogItemList != undefined)
            total = this.backlogItemList.total;
        else
            total = this.rows;

        if (startAt < total)
        {
            this.searchService.searchSubset(this.searchText, startAt, maxResult)
                .subscribe(result => this.processSearchResults(result));
        }
    }
}
