import { BaseFactObject } from '../base/value-object/base-fact-object';

export class ReleaseFact extends BaseFactObject
{
	// RELEASE FACT CONSTANTS
	public static readonly RELEASESCOPE : string = "releaseScope";
	public static readonly RELEASEPOINTSCOMPLETED : string = "releasePointsCompleted";
	public static readonly OPENDEFECTCOUNT : string = "releaseOpenDefectCount";
	public static readonly CLOSEDDEFECTCOUNT : string = "releaseClosedDefectCount";
	public static readonly TOTALDEFECTCOUNT : string = "releaseTotalDefectCount";
	public static readonly DEFECTCOUNTTIMESERIES : string = "releaseDefectCountTimeSeries";
	public static readonly RELEASENUMBEROFSPRINTS : string = "releaseNumberOfSprints";
	public static readonly RELEASESTORIESSIZED : string = "releaseStoriesSized";
	public static readonly RELEASESTORIESUNSIZED : string = "releaseStoriesUnsized";
	public static readonly ACTIVERELEASE : string = "releaseActiveRelease";
	public static readonly COMPLETEDRELEASE : string = "releaseCompletedRelease";
	public static readonly FUTURERELEASE : string = "releaseFutureRelease";
	public static readonly ACTIVERELEASEUNSIZED : string = "activeReleaseUnsized";
	public static readonly SPRINTSREMAINING : string = "releaseSprintsRemaining";
	public static readonly PREDICTEDCOMPLETION : string	= "releasePredictedCompletion";
	public static readonly RELEASEONTARGET : string = "releaseOnTarget";
	public static readonly RELEASEINCURRENTSPRINT : string = "releaseInCurrentSprint";
	public static readonly PLACEHOLDERCOMPLETION : string = "releasePlaceholderCompletion";
	public static readonly PLACEHOLDERSPRINTSREMAINING : string = "releasePlaceholderSprintsRemaining";
	public static readonly POINTSBYDUEDATE : string = "releasePointsByDueDate";
	public static readonly BACKLOGITEMSBYDUEDATE : string = "releaseBacklogItemsByDueDate";
	public static readonly RELEASECOSTPERPOINT : string = "releaseCostPerPoint";
	public static readonly PROGRAMINCREMENTRELEASE : string	= "releaseProgramIncrement";
	public static readonly PROGRAMINCREMENTQUARTER : string = "releaseProgramIncrementQuarter";
	public static readonly PROGRAMINCREMENTCURRENTQUARTER : string = "releaseProgramIncrementCurrentQuarter";
	public static readonly PROGRAMINCREMENTLASTQUARTER : string = "releaseProgramIncrementLastQuarter";
	public static readonly RELEASEGRACEPERIODEND : string = "releaseGracePeriodEnd";
	public static readonly BEGINNINGRELEASE : string = "beginningRelease";
	public static readonly ENDINGRELEASE : string = "endingRelease";
	public static readonly SCOPEPERCENTCHANGE : string = "releaseScopePercentChange";

	// Not audited.
	public static readonly DEFECTPOTENTIAL : string = "releaseDefectPotential";
	
    releaseFactId : string;
	releaseId : string;
	projectId : string;

    public initialize(object : ReleaseFact) : void
    {
        super.initialize(object);
        this.releaseFactId = object.releaseFactId;
		this.releaseId = object.releaseId;
		this.projectId = object.projectId;
    }
}