import { Component, OnInit, OnChanges, EventEmitter, Directive } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from './base-component.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Directive()
export class BaseUIComponent extends BaseComponent 
{ 
    data: any;
    options: any;

    @Output() click = new EventEmitter<any>();

    /*
        Color scheme :

        RED - Defect or something bad has happened - rgba(255,0,0,1)
        GREEN - Velocity or points completed in a sprint - rgba(0,255,0,1)
        ORANGE - Completed across multiple sprints (cumulative) - rgba(255, 155, 0, 1)
        BLUE - Commitment / Scope - rgba(0,0,255, 1)  
        GREY - Trend line - (default)
        YELLOW - Caution - rgba(255, 215, 0, 1)
    */

    public readonly GREEN : string = "rgba(0,255,0, 1)";
    public readonly GREENTRANSPARENT : string = "rgba(0,255,0, .40)";

    public readonly YELLOW : string = "rgba(255, 215, 0, 1)";
    public readonly YELLOWTRANSPARENT : string = "rgba(255, 215, 0, .40)";

    public readonly RED : string = "rgba(255,0,0, 1)";
    public readonly REDTRANSPARENT : string = "rgba(255, 0, 0, .40)";

    public readonly ORANGE : string = "rgba(255, 155, 0, 1)";
    public readonly ORANGETRANSPARENT : string = "rgba(255, 155, 0, .40)";

    public readonly BLUE : string = "rgba(0,0,255,1)";
    public readonly BLUETRANSPARENT : string = "rgba(0, 0, 255, .40)";

    public readonly BLACK : string = "rgba(0,0,0,1)";
    public readonly BLACKTRANSPARENT : string = "rgba(0, 0, 0, .40)";

    constructor( protected router : Router) 
    { 
        super(router); 

        this.options = {
            plugins: {
                legend: {
                    display: false,
                    position: 'bottom'
                },
                title: {
                    display: false,
                    text: 'Title'
                } 
            },
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } 
                    },
            responsive : true,
            maintainAspectRatio : true,
            layout: {
                padding: {
                    left: 10,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        }
    }
}