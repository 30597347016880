<p-card class="cost-per-point-average-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Average Cost Per Point Trend&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div>
            <p-chart type="line" [data]="data" [options]="options" [height]="'200px'"></p-chart>
        </div>    
    </span>
</p-card>

<!--
<p-card *ngIf="this.hasData != undefined && this.hasData == false" class="scrum-maturity-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="overview-title">Scrum Maturity&nbsp;</span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
    </ng-template>

    <span class="content">
        <div class="grace-period-text">
            <span >Graph will appear when scrum maturity trends are available (typically, after first sprint is completed).</span>
        </div>
    </span>
</p-card>
-->

<zoom-graph #zoomGraph type="bar" [sourceData]="data" [sourceOptions]="options"></zoom-graph>