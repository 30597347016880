export class AppAlertSeverity
{
    public critical : number;
    public warning : number;
    public informational : number;

    public initialize(appAlertSeverity : AppAlertSeverity) : void
    {
        this.critical = appAlertSeverity.critical;
        this.warning = appAlertSeverity.warning;
        this.informational = appAlertSeverity.informational;
    }
}
