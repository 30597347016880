import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { Project } from '../project/project';

import { GoalService } from './goal.service';
import { Goal } from './goal';

import * as _ from 'lodash'; 

@Component({
  selector: 'goal-summary',
  templateUrl: './goal-summary.component.html',
  styleUrls: ['./goal-summary.component.scss' ],
  providers: [ GoalService ]
})

export class GoalSummaryComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    project : Project;

    goalList : Goal[];

    constructor( private goalService : GoalService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void
    {
        this.goalService.retrieveByProject(this.project.projectId, 3)
            .subscribe(result => this.processGoalList(result));
    }

    processGoalList(result : Goal[]) : void
    {
        if (_.isEqual(this.goalList, result) == false)
            this.goalList = result;
    }
}
