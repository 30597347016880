import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { AppAlertService } from './app-alert.service';
import { AppAlert } from './app-alert';

import { Project } from '../project/project';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';
import { Sprint } from '../sprint/sprint';

import { ValueObjectList } from '../base/value-object/value-object-list';
import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';

@Component({
  selector: 'app-alert',
  templateUrl: './app-alert.component.html',
  styleUrls: ['./app-alert.component.css' ],
  providers: [ AppAlertService ]
})

export class AppAlertComponent extends BaseComponent implements OnInit 
{ 

    @Input()
    project : Project;

    @Input()
    release: Release;

    @Input()
    epic: Epic;

    @Input()
    sprint: Sprint;

    @Input()
    backlogItem : BaseBacklogItemObject = undefined;

    @Input()
    objectId : string = undefined;

    
    appAlertList : ValueObjectList<AppAlert>;

    constructor(protected router : Router,
                private appAlertService : AppAlertService) 
    { 
        super(router);
        this.appAlertList = new ValueObjectList<AppAlert>();
    }

    ngOnInit() : void
    {
        if (this.project != null)
            this.appAlertService.retrieveByProject(this.project.projectId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.release != null)
            this.appAlertService.retrieveByRelease(this.release.releaseId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.epic != null)
            this.appAlertService.retrieveByEpic(this.epic.epicId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );
 
        if (this.sprint != null)
            this.appAlertService.retrieveBySprint(this.sprint.sprintId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.backlogItem.isUserStory() == true)
            this.appAlertService.retrieveByUserStory(this.objectId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.backlogItem.isDefect() != null)
            this.appAlertService.retrieveByDefect(this.objectId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.backlogItem.isChore() != null)
            this.appAlertService.retrieveByChore(this.objectId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.backlogItem.isSpike() != null)
            this.appAlertService.retrieveBySpike(this.objectId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );

        if (this.backlogItem.isDependency() != null)
            this.appAlertService.retrieveByDependency(this.objectId, 0, 5)
                .subscribe(result=>this.processAppAlerts(result),
                           error => this.handleError404Okay(error) );
    }

    processAppAlerts(result: ValueObjectList<AppAlert>) : void
    {
        this.appAlertList = result;
    }
}
