<div *ngIf="release" class="grid col-12 card card-w-title">
    <div class="col-12" *ngIf="futureReleaseFact && futureReleaseFact.getFactBoolean() == false">
        <release-heading [release]="release" [displayCosts]="displayCosts"></release-heading>
    </div>
    <div class="grid col-12" *ngIf="futureReleaseFact && futureReleaseFact.getFactBoolean() == true">
        <h3 class="col-12">Release - {{release.name}}</h3>
        <span *ngIf="release.hasDueDate()" class="col-12">
            <colorbox *ngIf="release.hasDueDate()" class="col-12 sm:col-12 md:col-6" [name]="'Planned Date'" [detail]="release.dueDate | dateFormat" [color]="'blue'" [icon]="'event'"></colorbox>                
            <colorbox *ngIf="releaseScopeFact" class="col-12 sm:col-12 md:col-6" [name]="'Scope'" [detail]="releaseScopeFact.getFactNumber() | plural:'point'" [color]="'blue'" [icon]="'timeline'"></colorbox>                
        </span>
        <span *ngIf="!release.hasDueDate()" class="col-12">
            <colorbox *ngIf="releaseScopeFact" class="col-12 sm:col-12" [name]="'Scope'" [detail]="releaseScopeFact.getFactNumber() | plural:'point'" [color]="'blue'" [icon]="'timeline'"></colorbox>                
        </span>
    </div>

    <h4 class="col-12">Release Backlog</h4>
    
    <div *ngIf="releaseBacklog" class="release-backlog grid col-12">

        <div class="grid col-12 sm:col-12 md:col-4">
            <label class="col-9 sm:col-9 md:col-7" for="closedItems">Show Closed Items:</label>
            <span class="col-3 sm:col-3 md:col-5">
                <p-inputSwitch id="closedItems" #closedItemsTag [(ngModel)]="closedItems" name="closeItems"></p-inputSwitch>
            </span>
        </div>

        <div class="grid col-12 sm:col-12 md:col-4" *ngIf="futureReleaseFact && futureReleaseFact.getFactBoolean() == false">
            <label class="col-9 sm:col-9 md:col-7" for="releaseInOut">Show Release In/Out:</label>
            <span class="col-3 sm:col-3 md:col-5">
                <p-inputSwitch id="releaseInOut" #releaseInOutTag [disabled]="release.hasDueDate() == false" 
                    [(ngModel)]="releaseInOut" name="releaseInOut" (click)="onReleaseInOutClicked(); backlogItemListTag.reloadList();"
                    pTooltip="{{ release.hasDueDate() == false ? 'Only enabled when release has a due date' : '' }}">
                </p-inputSwitch>
            </span>
        </div>

        <div *ngIf="isFinUser() && teamCostsExistFact && costPerPointFact" class="grid col-12 sm:col-12 md:col-4">
            <label class="col-9 sm:col-9 md:col-7" for="displayCost">Display Estimated Costs:</label>
            <span class="col-3 sm:col-3 md:col-5">
                <p-inputSwitch id="displayCosts" #displayCostsTag [disabled]="teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false" [(ngModel)]="displayCosts" name="displayCosts" 
                    pTooltip="{{ teamCostsExistFact == undefined || teamCostsExistFact.getFactBoolean() == false ? 'Team costs do not exist for this backlog.' : '' }}">
                </p-inputSwitch>
            </span>
        </div>

        <backlog-item-list class="col-12" #backlogItemListTag [orgSourceSystem]="orgSourceSystem" [backlogItemList]="releaseBacklog" 
            [completed]="closedItems" [lazy]="true" [rows]="rows" [total]="releaseBacklog.total"
            (onLazyLoad)="onLazyLoad($event)" [paginator]="true" [rowStyleClass]="releaseRowStyleClass"
            [costPerPointFact]="costPerPointFact" [displayCosts]="displayCosts">
        </backlog-item-list>
    </div>
</div>
