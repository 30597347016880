import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { SprintFact } from '../../sprint-fact/sprint-fact';

import { Sprint } from '../../sprint/sprint';
import { ContentDetail } from '../../content/content-detail';

@Component({
  selector: 'sprint-acceptance-criteria',
  templateUrl: './sprint-acceptance-criteria.component.html',
  styleUrls: ['./sprint-acceptance-criteria.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class SprintAcceptanceCriteriaComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    sprint : Sprint;

    acceptanceCriteriaCount : SprintFact;
    totalStoryCount : SprintFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private sprintFactService : SprintFactService) 
    { 
        super(router); 

        this.options = {
            responsive : true,
            maintainAspectRatio : false,
            title: {
                display: false,
                text: 'Sprint Acceptance Criteria'
            }, 
            plugins : {
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    ngOnInit() : void
    {
        this.retrieveData();
    }

    public retrieveData()
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTSTORYCOUNT)
            .subscribe(result => this.processSprintStoryCount(result),
                       error => this.handleError(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.STORYACCEPTANCECRITERIACOUNT)
            .subscribe(result => this.processStoryAcceptanceCriteriaCount(result),
                       error => this.handleError(error) );

    }

    public processSprintStoryCount(result : SprintFact) : void
    {
        this.totalStoryCount = result;
        this.buildGraph();
    }

    public processStoryAcceptanceCriteriaCount(result : SprintFact) : void
    {
        this.acceptanceCriteriaCount = result;
        this.buildGraph();
    }

    public buildGraph() : void
    {
        if (this.totalStoryCount == undefined || this.acceptanceCriteriaCount == undefined)
            return;

        this.data = {
            labels : ['Good A/C', 'Sparse A/C'],
            datasets: [
                {
                    type:'pie',
                    animations: false,
                    label: '',
                    data: [ this.acceptanceCriteriaCount.getFactNumber(),
                            this.totalStoryCount.getFactNumber() - this.acceptanceCriteriaCount.getFactNumber()
                     ],

                    backgroundColor: [
                        "rgba(0, 256, 0, .40)",
                        "rgba(256, 0, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(0, 256, 0, 1)",
                        "rgba(256, 0, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2
                    ],
                    hoverBackgroundColor: [
                        "rgba(0, 256, 0, 1)",
                        "rgba(256, 0, 0, 1)"
                    ]
                }
            ]
        }
    }

    public percentGoodAcceptanceCriteria() : number
    {
        let result : number = 0;

        if (this.acceptanceCriteriaCount != undefined && this.totalStoryCount != undefined)
        {
            if (this.totalStoryCount.getFactNumber() != 0)
                result = Math.ceil(this.acceptanceCriteriaCount.getFactNumber() / this.totalStoryCount.getFactNumber() * 100);
        }

        return result;
    }

    public get ACCEPTANCECRITERIA() : string
    {
        return ContentDetail.ACCEPTANCECRITERIA
    }
}
