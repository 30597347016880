import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';

import { Epic } from '../../epic/epic';
import { EpicFact } from '../../epic-fact/epic-fact';
import { EpicFactService } from '../../epic-fact/epic-fact.service';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'epic-cost-burnup',
  templateUrl: './epic-cost-burnup.component.html',
  styleUrls: ['./epic-cost-burnup.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService, EpicFactService ]
})

export class EpicCostBurnupComponent extends BaseUIComponent implements OnInit 
{ 
    @Input()
    epic : Epic;

    timeSeries : TimeSeries;

    epicScope : EpicFact;
    epicPointsCompleted : EpicFact;

    costPerPoint : ProjectFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private projectFactService : ProjectFactService,
                 private epicFactService : EpicFactService) 
    { 
        super(router);

        this.options = {
            scales: { y: { beginAtZero:true }, 
                      x: { display: false }
                    },
            plugins: {
                title: {
                    display: false,
                    text: 'Epic Burnup'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    public ngOnInit() : void
    {
        this.timeSeriesService.retrieve(this.epic.project, this.epic.epicId, TimeSeries.EPICCOSTBURNUP, 10)
            .subscribe(result => this.processEpicBurnup(result),
                       error => this.handleError(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICSCOPE)
            .subscribe(result => this.processEpicScope(result),
                       error => this.handleError404Okay(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICPOINTSCOMPLETED)
            .subscribe(result => this.processEpicPointsCompleted(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.epic.project, ProjectFact.COSTPERPOINT)
            .subscribe(result =>this.processCostPerPoint(result),
                       error => this.handleError404Okay(error) );
                                  
    }

    public processEpicScope (result : EpicFact) : void
    {
        this.epicScope = result;
    }

    public processEpicPointsCompleted (result : EpicFact) : void
    {
        this.epicPointsCompleted = result;
    }

    public  processCostPerPoint(result: ProjectFact) : void
    {
        if (_.isEqual(this.costPerPoint, result) == false)
            this.costPerPoint = result;
    }

    public processEpicBurnup(result: TimeSeries) : void
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Cost/Sprint',
                    backgroundColor: this.GREENTRANSPARENT,
                    borderColor: this.GREEN,
                    hoverBackgroundColor: this.GREEN,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("sprintPointsComplete") * a.getFieldNumber("sprintCostPerPoint")); } )
                },
                {
                    type:'bar',
                    label: 'Cumulative Cost',
                    backgroundColor: this.ORANGETRANSPARENT,
                    borderColor: this.ORANGE,
                    hoverBackgroundColor: this.ORANGE,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("totalPointsComplete") * a.getFieldNumber("sprintCostPerPoint")); } )
                },
                {
                    type:'line',
                    label: 'Estimated Epic Cost',
                    data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("sprintScope") * a.getFieldNumber("sprintCostPerPoint")); } )
                }
            ]
        }
    }

    public percentComplete() : number
    {
        let result : number = 0.00;
        
        if (this.epicScope && this.epicPointsCompleted)
           if (this.epicScope.getFactNumber() != 0)
                result = (this.epicPointsCompleted.getFactNumber() / this.epicScope.getFactNumber() ) * 100;

        return result;
    }

    public pointsRemaining() : number
    {
        let result : number = 0.00;
        
        if (this.epicScope && this.epicPointsCompleted)
        {
            result = this.epicScope.getFactNumber() - this.epicPointsCompleted.getFactNumber();
            if (result < 0) result = 0;
        }

        return result;
    }

    public get costsRemaining() : number
    {
        let result : number = 0;

        if (this.costPerPoint)
            result = this.costPerPoint.getFactNumber() * this.pointsRemaining();

        return result;
    }
    
}
