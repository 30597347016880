import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { ProjectStatus } from './project-status';
import { BaseHttpService } from '../base/service/base-http.service';

@Injectable()
export class ProjectStatusService extends BaseHttpService
{
    projectStatusUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.projectStatusUrl = this.config.baseUrl + '/rest/project-status';
    }

    public retrieve(projectId : string) : Observable<ProjectStatus>
    {
        let url = this.projectStatusUrl + "/" + projectId;
        return super.baseRetrieveObject(ProjectStatus, url);
    }
}