import { BaseValueObject } from '../base/value-object/base-value-object';
import { Project } from '../project/project';

export class AppAlert extends BaseValueObject
{
    appAlertId : string;
	project : Project;
	objectId : string;
	objectFactId : string;
	objectType : string;
	source : string;
	title : string;
	summary : string;
	detail : string;
	link : string;
	severity : number;
	alarmActive : boolean;
	alarmBeginDate : Date;
	alarmEndDate : Date;

	public initialize(appAlert : AppAlert) : void
	{
		super.initialize(appAlert);

		this.appAlertId = appAlert.appAlertId;
		this.objectId = appAlert.objectId;
		this.objectFactId = appAlert.objectFactId;
		this.objectType = appAlert.objectType;
		this.source = appAlert.source;
		this.title = appAlert.title;
		this.summary = appAlert.summary;
		this.detail = appAlert.detail;
		this.link = appAlert.link;
		this.severity = appAlert.severity;
		this.alarmActive = appAlert.alarmActive;
        this.alarmBeginDate = this.fromUTC(appAlert.alarmBeginDate);
        this.alarmEndDate = this.fromUTC(appAlert.alarmEndDate);

		this.project = this.initializeObject(Project, appAlert.project);
	}

	public get severityDescription() : string
	{
		let result : string = "Warning";

		switch (this.severity)
		{
			case 1:
				result = "Critical";
				break;

			case 2:
				result = "Warning";
				break;

			case 3:
				result = "Informational";
				break;
		}

		return result;
	}
}