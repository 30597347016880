import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { OrganizationService } from '../../organization/organization.service';
import { Organization } from '../../organization/organization';

import { ContentService } from '../../content/content.service';
import { ContentDetail } from '../../content/content-detail';
import { ContextVariable } from '../context-variable/context-variable';

import { SelectItem } from 'primeng/api';

import { BaseComponent } from '../../base/component/base-component.component';
import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'content-add',
  templateUrl: './content-add.component.html',
  styleUrls: ['./content-add.component.css'],
  providers: [ ContentService, OrganizationService ]
})
export class ContentAddComponent extends BaseComponent implements OnInit
{ 
    content : ContentDetail;
    active : boolean;
    
    contextVariableList : ContextVariable[] = [];
    organizationList : SelectItem[] = [];

    constructor(protected router:Router,
                protected contentService: ContentService, 
                protected organizationService: OrganizationService) 
    {
        super(router);
        this.active = false;
        this.content = new ContentDetail(); 
    }

    public ngOnInit() : void
    {
        this.initFields();
        this.initializeContextVariableList();

        this.organizationService.retrieveAll()
            .subscribe(result => this.processOrganizationList(result), 
                       error => this.handleError(error) );  
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        // Kludge alert: trick angluar into form reset.
        this.active = false;
        setTimeout(() => this.active = true, 0);
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
            
        return error.message || error;
     }

     public onAdd() : void
     {
         super.clearErrorMessages();
         
        // Validate
        if (LLUtils.isStringEmpty(this.content.contentKey) == true )
        {
            this.addErrorMessage("Content Key must be supplied.");
            return;
        }

        if (LLUtils.isStringEmpty(this.content.contentTitle) == true )
        {
            this.addErrorMessage("Content Title must be supplied.");
            return;
        }

        if (LLUtils.isStringEmpty(this.content.contentSummary) == true )
        {
            this.addErrorMessage("Content Summary must be supplied.");
            return;
        }

        if (LLUtils.isStringEmpty(this.content.contentDetail) == true )
        {
            this.addErrorMessage("Content Detail must be supplied.");
            return;
        }

        this.contentService.create(this.content)
            .subscribe(result => this.onSuccess(result),
                    error => this.handleError(error) );
     }

     public onCancel() : void
     {
        this.router.navigate(['/dashboard/admin/content-list']);
     }

     protected onSuccess(result : ContentDetail) : void
     {
        this.content = result;
        this.addInfoMessage("Content Added!");
     }

     private initializeContextVariableList() : void
     {
        this.contextVariableList = new Array<ContextVariable>();
        this.contextVariableList.push(new ContextVariable("[@content key='contentKey' title='title' /]", "Link to application content") );
        this.contextVariableList.push(new ContextVariable("[@applink url='url' title='title' /]", "Link to internal pages") );
        this.contextVariableList.push(new ContextVariable("[@link url='url' title='title' /]", "Link to external pages") );
        this.contextVariableList.push(new ContextVariable("[@break/]", "Force line break.") );
    }

    public processOrganizationList(result : Organization[] )
    {
        this.organizationList = [];
        this.organizationList.push( { label:"(none)", value: undefined } );

        for (let organization of result)
            this.organizationList.push( { label:organization.name, value: organization.orgId } );
    }
}
