import { Component, Input, Inject, forwardRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { AnimationEvent } from '@angular/animations';

import { DashboardComponent } from './dashboard.component';
import { UserAccount } from '../user-account/user-account';
import { InboxItemService } from '../inbox-item/inbox-item.service';

import { MessagingService } from '../event/messaging.service';
import { InboxItemReadEvent } from '../event/inbox-item-read-event';
import { Subscription, Observable } from "rxjs";
import { ValueObjectList } from '../base/value-object/value-object-list';
import { InboxItem } from '../inbox-item/inbox-item';

import { OrgImage } from '../org-image/org-image';
import { OrgImageService } from '../org-image/org-image.service';

import { AppComponent } from '../app.component';
import { BaseComponent } from '../base/component/base-component.component';
import { BaseDashboardTopbarComponent } from './base-dashboard-topbar.component';
import { relativeTimeThreshold } from 'moment';
import { LoggedInUser } from '../authentication/logged-in-user';

@Component({
    selector: 'dashboard-topbar',
    templateUrl: './dashboard-topbar.component.html',
    styleUrls : ['./dashboard-topbar.component.scss'],
    encapsulation: ViewEncapsulation.None,

    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
              ]),
              transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
              ])
        ])
    ],
    providers : [ InboxItemService, OrgImageService ]
})
export class DashboardTopbarComponent extends BaseDashboardTopbarComponent {

    @Input()
    userAccount : UserAccount;

    inboxItemReadSubscription : Subscription;
    inboxItemList : ValueObjectList<InboxItem>;
    searchText : string;

    unreadInboxItemCount : number;
    orgImage : OrgImage = undefined;
    displayDefaultImage : boolean = false;

    constructor(protected router : Router,
                public dashboard : DashboardComponent,
                public app : AppComponent,        
                private inboxItemService : InboxItemService,
                private messagingService : MessagingService,
                protected orgImageService : OrgImageService) 
    {
        super(router, dashboard, app);
    }

    public ngOnInit() : void
    {
        this.inboxItemReadSubscription = this.messagingService.of(InboxItemReadEvent)
            .subscribe(message => { this.refreshInboxItems();
        }); 

        this.initFields();
        this.displayDefaultImage = false;
    }

    public ngOnDestroy() : void
    {
        this.inboxItemReadSubscription.unsubscribe();    
    }

    public initFields() : void
    {
        this.inboxItemList = new ValueObjectList<InboxItem>();
        super.clearErrorMessages();
        this.refreshInboxItems();
        this.retrieveOrgImage();
    }

    public refreshInboxItems()
    {
        this.inboxItemService.retrieveList(0, 5)
            .subscribe(result => this.processInboxItems(result),
                       error => this.handleError(error) ); 

        this.inboxItemService.retrieveCount(false)
            .subscribe(result=>this.processUnreadInboxItemCount(result),
                       error => this.handleError(error) );
    }

    private processInboxItems(result : ValueObjectList<InboxItem>) : void
    {
        this.inboxItemList = result;
    }

    private processUnreadInboxItemCount(result: number) : void
    {
        this.unreadInboxItemCount = result;
    }

    public retrieveOrgImage() : void
    {
        if (LoggedInUser.getLoggedInUser() != undefined && 
            LoggedInUser.getLoggedInUser().organization != undefined && 
            LoggedInUser.getLoggedInUser().organization.orgId != undefined)
        {
            this.orgImageService.retrieveByOrgId(LoggedInUser.getLoggedInUser().organization.orgId )
                .subscribe(result => this.processOrgImage(result), 
                           error => this.handleDisplayDefaultImage(error) );
        }
    }

    public processOrgImage(result : OrgImage) : void
    {
        this.orgImage = result;
    }

    public handleDisplayDefaultImage(error : any) : void
    {
        this.displayDefaultImage = true;
    }

    public getImage() : string
    {
      let image = `data:${this.orgImage.orgImageType};base64,${this.orgImage.orgImage}`;
      return image;
    }
  
    public onSearch(event : any) : void
    {
        if (this.searchText != undefined)
        {
            this.dashboard.search = false;
            this.router.navigate(['/dashboard/search-results-list', this.searchText]);
        }
        
        this.searchText = undefined;
    }

    public onSearchAnimationEnd(event: AnimationEvent) 
    {
        switch(event.toState) {
            case 'visible':
                if (this.searchInputViewChild != undefined) this.searchInputViewChild.nativeElement.focus();
            break;
        }
    }


}