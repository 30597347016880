import { BaseValueObject } from '../base/value-object/base-value-object';
import { Project } from '../project/project';

import * as random from 'random-utility';
import * as moment from 'moment';

export class ProjectTeamCost extends BaseValueObject
{
	public projectTeamCostId : string;
	public project : Project 
	public effectiveDate : Date;
	public endDate : Date;
    public costPerMonth : number;
    public currentDateEndDate : boolean;

    public constructor()
    {
        super();
        this.currentDateEndDate = false;
        this.projectTeamCostId = random.uuid();
    }   

    public initialize (object: ProjectTeamCost) : void
    {
        super.initialize(object);
	
        this.projectTeamCostId = object.projectTeamCostId;
	    this.project = object.project;
	    this.effectiveDate = moment(object.effectiveDate).toDate();
	    this.endDate = moment(object.endDate).toDate();
	    this.costPerMonth = object.costPerMonth;
        this.currentDateEndDate = object.currentDateEndDate;
        
		this.project = this.initializeObject(Project, object.project)
    }
}