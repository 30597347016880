import { BaseValueObject } from '../base/value-object/base-value-object';
import { UserAccount } from '../user-account/user-account';

export class InboxItem extends BaseValueObject
{
	public inboxItemId : string;				
	public userAccount : UserAccount;	
	public subject : string;
	public body : string;	
	public read : boolean;			

	public initialize(inboxItem : InboxItem) : void
	{
		super.initialize(inboxItem);

        this.inboxItemId = inboxItem.inboxItemId;
        this.subject = inboxItem.subject;
        this.body = inboxItem.body;
		this.read = inboxItem.read;
		
        this.userAccount = this.initializeObject(UserAccount, inboxItem.userAccount);
	}
}