<p-card class="points-injected">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Points Injected</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="sprintPointsInjected" pTooltip="Percent Injected" tooltipPosition="left">
                    {{percentInjected | round }}%
                </span>

                <span class="unplanned-work" *ngIf="sprintPointsInjected">
                    <a *ngIf="sprintPointsInjected.getFactNumber() > 0" [routerLink]="['/dashboard/content-detail/key', ADDEDTOSPRINT]">
                        <i pTooltip="Unplanned Work" tooltipPosition="left" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="sprintPointsInjected" class="overview-detail">{{ sprintPointsInjected.getFactNumber() }} points injected</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="sprintCommitment && sprintPointsInjected && sprintScope">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
