import { Component, OnInit, Input } from '@angular/core';
import { BaseDetailComponent } from '../base/component/base-detail-component.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../org-source-system/org-source-system';

import { EpicService } from './epic.service';
import { Epic } from './epic';

import { BacklogItemService } from '../backlog-item/backlog-item.service';
import { BacklogItem } from '../backlog-item/backlog-item';

import { ProjectFactService } from '../project-fact/project-fact.service';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Component({
  selector: 'epic-detail',
  templateUrl: './epic-detail.component.html',
  styleUrls: ['./epic-detail.component.css' ],
  providers: [ OrgSourceSystemService, ProjectFactService, EpicService, BacklogItemService ]
})

export class EpicDetailComponent extends BaseDetailComponent implements OnInit 
{ 
    epicId : string;
    epic : Epic;

    orgSourceSystem : OrgSourceSystem;
    
    closedItems : boolean;
    rows : number = 20;

    epicBacklog : ValueObjectList<BacklogItem>;

    constructor( private orgSourceSystemService : OrgSourceSystemService,
                 private epicService : EpicService,
                 private backlogItemService : BacklogItemService,
                 private route: ActivatedRoute,
                 protected projectFactService : ProjectFactService,
                 protected router: Router) 
 
    { 
        super(router, projectFactService);
        this.closedItems = false;
    }

    ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.epicId = params['epicId'];
        });

        this.epicService.retrieve(this.epicId)
            .subscribe(result => this.processEpic(result), 
                       error => this.handleError(error) );

        this.orgSourceSystemService.retrieveByEpicId(this.epicId)
            .subscribe(result => this.processOrgSourceSystem(result), 
                       error => this.handleError(error) );

        this.epicBacklog = new ValueObjectList<BacklogItem>();
    }

    public processEpic(epic : Epic) : void
    {
        this.epic = epic;
        this.retrieveCosts(this.epic.project.projectId);
    }

    public processOrgSourceSystem(orgSourceSystem : OrgSourceSystem) : void
    {
        this.orgSourceSystem = orgSourceSystem;
    }

    public processEpicBacklog(result : ValueObjectList<BacklogItem>)
    {
        this.epicBacklog = result;
    }

    private onLazyLoad(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.backlogItemService.retrieveEpicBacklog(this.epic, this.closedItems, startAt, maxResult)
            .subscribe(result=>this.processEpicBacklog(result) );
    }
}
