import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { Spike } from './spike';
import { Project } from '../project/project';
import { Config } from '../config/config';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class SpikeService extends BaseHttpService
{
    protected spikeUrl : string

    constructor(http: HttpClient, config: Config) 
    {
        super(http);
        this.spikeUrl = config.baseUrl + "/rest/spike";
    }

    public retrieve(spikeId : string) : Observable<Spike>
    {
        let requestUrl = this.spikeUrl + "/" + spikeId;
        return super.baseRetrieveObject(Spike, requestUrl);
    }

    public retrieveSpikeBacklog(project : Project, closed : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<Spike>>
    {
        let requestUrl = this.spikeUrl + "/" + project.projectId + "/subset?closed=" + closed;
        return super.baseRetrieveSubset(Spike, requestUrl, startAt, maxResult);
    }

    public retrieveReleaseBacklog(release : Release) : Observable<Spike[]>
    {
        let requestUrl = this.spikeUrl + "/releasebacklog/" + release.releaseId;
        return super.baseRetrieveList(Spike, requestUrl);
    }

    public retrieveEpicBacklog(epic : Epic) : Observable<Spike[]>
    {
        let requestUrl = this.spikeUrl + "/epicbacklog/" + epic.epicId;
        return super.baseRetrieveList(Spike, requestUrl);
    }
}
