import { TimeSeries } from './time-series';
import { BaseValueObject } from '../base/value-object/base-value-object';

import * as moment from 'moment';

export class TimeSeriesItem extends BaseValueObject {

	public timeSeriesItemId : string;
	public timestamp : Date; 
	public sprintId : string;	
	public data : string;

    public initialize (timeSeriesItem : TimeSeriesItem) : void
    {
        super.initialize(timeSeriesItem);
        
        this.timeSeriesItemId = timeSeriesItem.timeSeriesItemId;
        this.timestamp = moment(timeSeriesItem.timestamp).toDate();
        this.sprintId = timeSeriesItem.sprintId;
        this.data = timeSeriesItem.data;
    }

    public getFieldNumber(key : string) : number
    {
        var jsonData = JSON.parse(this.data);
        let result : number = jsonData[key];
        return result;
    }

    public setFieldNumber(key : string, value : number)
    {
        if (this.data != undefined)
        {
            let jsonObject = JSON.parse(this.data);
            jsonObject[key] = value;
            this.data = JSON.stringify(jsonObject);
        }
        else
        {
            this.data = `{ "${key}" : ${value} }`;
        }
    }

    public getDataKeys() : Array<string>
    {
        let result = new Array<string>();

        if (this.data.length > 0)
        {
            let jsonData = JSON.parse(this.data);
            
            for (let key of Object.keys(jsonData) )
                result.push(key);
        }

        return result;
    }
}

