import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';

import { Release } from '../../release/release';

import * as moment from 'moment';

@Component({
  selector: 'release-status-selector',
  templateUrl: './release-status-selector.component.html',
  styleUrls: ['./release-status-selector.component.scss' ],
  providers: [ ProjectFactService]
})

export class ReleaseStatusSelectorComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    release : Release;

    @Input()
    displayCosts: boolean = false;

    numberOfSprints : ProjectFact;

    constructor(protected router : Router,
                private projectFactService: ProjectFactService)
    { 
        super(router);
    }

    ngOnInit() : void
    {
        this.projectFactService.retrieve(this.release.project, ProjectFact.NUMBEROFSPRINTS)
            .subscribe(result => this.processProjectNumberOfSprints(result),
                       error => this.handleError404Okay(error) );
    }

    private processProjectNumberOfSprints(result : ProjectFact) : void
    {
        this.numberOfSprints = result;
    }
}
