import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { UserAccountService } from './user-account.service';
import { UserAccount } from './user-account';
import { BaseComponent } from '../base/component/base-component.component';
import { State } from '../state/state';
import { StateService } from '../state/state.service';
import { ZipcodeService } from '../zipcode/zipcode.service';

import { LLUtils } from '../utilities/ll-utils';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [ ConfirmationService, UserAccountService, StateService, ZipcodeService ]
})
export class ProfileComponent extends BaseComponent implements OnInit
{ 
    userAccount : UserAccount;
    stateList : State[];
    stateItems : SelectItem[];

    selectedStateId : string;

    constructor(protected router:Router,
                private confirmationService : ConfirmationService,
                private userAccountService: UserAccountService,
                private stateService: StateService,
                private zipcodeService: ZipcodeService)
    {
        super(router);
        this.userAccount = new UserAccount();
        this.stateList = [];
    }

    public ngOnInit() : void
    {
        this.initFields();
    }

    public initFields() : void
    {
        this.errorMessages = [];

        this.userAccountService.retrieve()
          .subscribe(result => this.processUserAccount(result),
                     error => this.handleError(error) ); 

        this.stateService.retrieveAll()
            .subscribe(result => this.processStates(result),
                       error => this.handleError(error) ); 
    }

    private processUserAccount(userAccount : UserAccount)
    {
        this.userAccount = userAccount;
        this.selectedStateId = userAccount.stateId;
    }

    private processStates(states : State[])
    {
        this.stateList = states;
        this.stateItems = [];

        this.stateItems.push({label: '[None Selected]', value:null } );
        
        for(var state of this.stateList)
        {
            this.stateItems.push({label: state.stateName.trim(), value:state.stateId })
        }
    }

    public onUpdate() : void
    {
        this.clearErrorMessages();

        // Validate the form
        if (LLUtils.isStringEmpty(this.userAccount.firstName) )
        {
            this.addErrorMessage("First name must be provided.");
            return;
        }

        if (LLUtils.isStringEmpty(this.userAccount.lastName) )
        {
            this.addErrorMessage("Last name must be provided.");
            return;
        }

        if (LLUtils.isStringEmpty(this.userAccount.emailAddress) )
        {
            this.addErrorMessage("Email address must be provided.");
            return;
        }

        if (this.userAccount.zipcode != null && this.userAccount.zipcode.length == 0)
            this.userAccount.zipcode = undefined;

        if (this.userAccount.zipcode != undefined)
        {
            this.zipcodeService.retrieve(this.userAccount.zipcode, this.selectedStateId)
                .subscribe(result => this.validateFormAndUpdate(),
                           error => this.handleZipcodeError(error) )  
        }
        else
        {
            this.validateFormAndUpdate();
        }
    }

    private handleUpdate(result : UserAccount) : void
    {
        this.processUserAccount(result);
        this.addInfoMessage("Update succeeded!");
    }

    private handleZipcodeError(result: any) : void
    {
        super.addErrorMessage("Invalid zipcode!");
    }

    public onCancel() : void
    {
        this.router.navigate(['dashboard']);
    }

    public onResetPassword() : void
    {
        this.confirmationService.confirm(
            { message: 'Are you sure you wish to reset your password?',
                accept: () => 
                {
                    super.clearErrorMessages();
                    this.userAccountService.resetPassword()
                        .subscribe(result => this.resetPasswordSuccessful(result),
                                   error => this.resetPasswordFailed(error) );
                }
            });
    }

    private resetPasswordSuccessful(response : any) : void
    {
        this.addInfoMessage("Reset password request succeeded.  Please check email for instructions.");
    }

    private resetPasswordFailed(error: any) : void
    {
        this.addErrorMessage("Reset password failed.  Please try again");
    }

    private validateFormAndUpdate() : void
     {
        super.clearErrorMessages();

        let valid = true;
        
        if (this.userAccount.isValidEmailAddress() == false )
        {
            valid = false;
            super.addErrorMessage("Invalid email address.");
        }

         // Update the database.
         if (valid)
         {
            this.userAccount.stateId = this.selectedStateId;

            this.userAccountService.update(this.userAccount)
                .subscribe(result => this.handleUpdate(result),
                           error => this.handleError(error) );
         }
     }
}
