import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';

import { UserAccountService } from '../../user-account/user-account.service';
import { UserAccount } from '../../user-account/user-account';
import { BaseComponent } from '../../base/component/base-component.component';
import { State } from '../../state/state';
import { Zipcode } from '../../zipcode/zipcode';
import { StateService } from '../../state/state.service';
import { ZipcodeService } from '../../zipcode/zipcode.service';
import { ProjectService } from '../../project/project.service';
import { Project } from '../../project/project';
import { Role } from '../../role/role';
import { Preference } from '../../preference/preference';

import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'user-account-add',
  templateUrl: './user-account-add.component.html',
  styleUrls: ['./user-account-add.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ UserAccountService, StateService, ZipcodeService, ProjectService ]
})
export class UserAccountAddComponent extends BaseComponent implements OnInit
{ 
    userAccount : UserAccount;
    stateList : State[];
    stateItems : SelectItem[];

    admin : boolean = false;
    displayMaturity : boolean = true;
    finuser : boolean = false;
    finadmin : boolean = false;

    selectedStateId : string;

    sourceProjectList : Project[];
    addDisabled : boolean = false;

    constructor(protected router:Router,
                private userAccountService: UserAccountService,
                private projectService: ProjectService,
                private stateService: StateService,
                private zipcodeService: ZipcodeService)
    {
        super(router);
        this.userAccount = new UserAccount();
        this.stateList = [];
        this.sourceProjectList = [];
    }

    public ngOnInit() : void
    {
        this.initFields();
    }

    public initFields() : void
    {
        this.errorMessages = [];

        this.userAccount = new UserAccount();
        this.userAccount.isActive = true;
        this.userAccount.makeUser();

       this.projectService.retrieveAll()
          .subscribe(result => this.processSourceProjectList(result),
                     error => this.handleError(error) ); 

        this.stateService.retrieveAll()
          .subscribe(result => this.processStates(result),
                     error => this.handleError(error) ); 

        this.addDisabled = false;
    }

    private processUserAccount(userAccount : UserAccount)
    {
        this.userAccount = userAccount;
        this.selectedStateId = userAccount.stateId;
    }

    private processSourceProjectList(projectList : Project[])
    {
        this.sourceProjectList = projectList;
    }

    private processStates(states : State[])
    {
        this.stateList = states;
        this.stateItems = [];
        
        this.stateItems.push({label: '[None Selected]', value:null } );

        for(var state of this.stateList)
        {
            this.stateItems.push({label: state.stateName.trim(), value:state.stateId })
        }
    }

     public onAdd() : void
     {
        this.clearErrorMessages();
        
        if (this.userAccount.zipcode != null && this.userAccount.zipcode.length == 0)
            this.userAccount.zipcode = undefined;
        
        if (this.userAccount.zipcode != undefined)
        {
            this.zipcodeService.retrieve(this.userAccount.zipcode, this.selectedStateId)
                    .subscribe(result => this.validateFormAndAdd(),
                               error => this.handleZipcodeError(error) );
        }
        else
        {
            this.validateFormAndAdd();
        }
     }

     private handleAdd(result : UserAccount) : void
     {
         this.addDisabled = true;
         this.processUserAccount(result);
         this.addInfoMessage("User created and welcome email sent!");
     }

     private handleZipcodeError(result: any) : void
     {
            super.addErrorMessage("Invalid zipcode!");
     }

     public onCancel() : void
     {
        this.router.navigate(['/dashboard/admin/user-account-list']);
     }

     private validateFormAndAdd() : void
     {
        super.clearErrorMessages();

        let valid = true;
        
        if (LLUtils.isStringEmpty(this.userAccount.firstName) )
        {
            valid = false;
            this.addErrorMessage("First name must be provided.");
        }

        if (LLUtils.isStringEmpty(this.userAccount.lastName) )
        {
            valid = false;
            this.addErrorMessage("Last name must be provided.");
        }

        if (this.userAccount.isValidEmailAddress() == false )
        {
            valid = false;
            super.addErrorMessage("Invalid email address.");
        }

         // Update the database.
         if (valid)
         {
            // Add our roles.
            if (this.admin == true)
                this.userAccount.makeAdmin();
            else
                this.userAccount.removeAdmin();

            if (this.finuser == true)
                this.userAccount.addRole(Role.FINUSER);
            else
                this.userAccount.removeRole(Role.FINUSER);

            if (this.finadmin == true)
                this.userAccount.addRole(Role.FINADMIN);
            else
                this.userAccount.removeRole(Role.FINADMIN);

            this.userAccount.stateId = this.selectedStateId;

            // By default, ensure the user gets the summary email.
            this.userAccount.addPreference(Preference.DAILY_SUMMARY);

            // Enable / display maturity preference.
            if (this.displayMaturity == true)
                this.userAccount.addPreference(Preference.DISPLAYMATURITYRATING);
            else
                this.userAccount.removePreference(Preference.DISPLAYMATURITYRATING);

            this.userAccountService.create(this.userAccount)
                .subscribe(result => this.handleAdd(result),
                           error => this.handleError(error) );
         }
     }
}
