import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router } from '@angular/router';

import { Sprint } from './sprint';
import { SprintFact } from '../sprint-fact/sprint-fact';
import { ContentDetail } from '../content/content-detail';

@Component({
  selector: 'sprint-list',
  templateUrl: './sprint-list.component.html',
  styleUrls: ['./sprint-list.component.css' ],
  providers: [  ]
})

export class SprintListComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    rows : number = 0;

    @Input()
    total : number = 0;

    @Input()
    paginator : boolean = false;

    @Input()
    lazy : boolean = false;

    selectedSprint : Sprint;

    @Input()
    sprintList : Sprint[];

    @Input()
    header : string;

    viewSprintList : Sprint[];

    @Input()
    futureSprints: boolean

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    constructor(protected router : Router) 
    { 
        super(router);
        this.futureSprints = false;
    }

    ngOnInit() : void
    {
    }

    ngOnChanges() : void
    {
        this.initViewStoryList();
    }

    public initViewStoryList() : void
    {
        if (this.sprintList != undefined)
        {
            this.viewSprintList = new Array<Sprint>();

            for (let sprint of this.sprintList)
            {
                if (sprint.isFutureSprint() == false)
                    this.viewSprintList.push(sprint);
            }

            if (this.futureSprints == true)
            {
                for (let sprint of this.sprintList)
                {
                    if (sprint.isFutureSprint() == true)
                        this.viewSprintList.push(sprint);
                }
            }
       }
    }

    loadData(event : any) 
    {
        //event.first = First row offset
        //event.rows = Number of rows per page
        //add more records to the cars array
        this.onLazyLoad.emit(event);
    }

    public sprintCommitmentMet(sprint : Sprint) : boolean
    {
        let result : boolean = false;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTCOMMITMENTMET) != null)
                if (sprint.getFact(SprintFact.SPRINTCOMMITMENTMET).getFactBoolean() == true)
                    result = true;
        }

        return result;
    }

    public sprintVelocity(sprint : Sprint) : number
    {
        let result : number = 0;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTVELOCITY) != null)
                result = sprint.getFact(SprintFact.SPRINTVELOCITY).getFactNumber();
        }

        return result;
    }


    public sprintScope(sprint : Sprint) : number
    {
        let result : number = 0;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTSCOPE) != null)
                result = sprint.getFact(SprintFact.SPRINTSCOPE).getFactNumber();
        }

        return result;
    }

    public sprintDuration(sprint : Sprint) : number
    {
        let result : number = 0;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTDURATION) != null)
                result = sprint.getFact(SprintFact.SPRINTDURATION).getFactNumber();
        }

        return result;
    }

    public sprintDurationChanged(sprint : Sprint) : boolean
    {
        let result : boolean = false;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTDURATIONCHANGED) != null)
                result = sprint.getFact(SprintFact.SPRINTDURATIONCHANGED).getFactBoolean();
        }

        return result;
    }

    public sprintStartDayChanged(sprint : Sprint) : boolean
    {
        let result : boolean = false;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTSTARTDAYCHANGED) != null)
                result = sprint.getFact(SprintFact.SPRINTSTARTDAYCHANGED).getFactBoolean();
        }

        return result;
    }

    public get SPRINTCADENCE() : string
    {
        return ContentDetail.SPRINTCADENCE;
    }

    public get SPRINTCOMMITMENT() : string
    {
        return ContentDetail.SPRINTCOMMITMENT;
    }

    public get ZEROPOINTSPRINT() : string
    {
        return ContentDetail.ZEROPOINTSPRINT;
    }
}
