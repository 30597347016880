import { Component, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';

import { SprintFact } from '../../sprint-fact/sprint-fact';

import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { RoundPipe } from '../../pipes/round.pipe';

import * as moment from 'moment';
import * as _ from "lodash"; 

@Component({
  selector: 'points-per-story-timeline',
  templateUrl: './points-per-story-timeline.component.html',
  styleUrls: ['./points-per-story-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class PointsPerStoryTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges { 

    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;
    
    averagePointsPerStoryAverageFact : ProjectFact;
    averagePointsPerStoryTrendFact : ProjectFact;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private projectFactService : ProjectFactService) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Average Points Per Story`;
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTAVERAGEPOINTSPERSTORY, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    
        this.projectFactService.retrieve(this.project, ProjectFact.AVERAGEPOINTSPERSTORYAVERAGE)
            .subscribe(result =>this.processAveragePointsPerStoryAverageFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.AVERAGEPOINTSPERSTORYTREND)
            .subscribe(result => this.processAveragePointsPerStoryTrendFact(result),
                       error => this.handleError404Okay(error) );
    }

    private processTimeSeries(result: TimeSeries)
    {
        let roundPipe : RoundPipe = new RoundPipe();

        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       

            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type:'bar',
                        label: 'Average Points Per Story',
                        backgroundColor: this.GREENTRANSPARENT,
                        hoverBackgroundColor: this.GREEN,
                        borderColor: this.GREEN,
                        borderWidth: 2,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return roundPipe.transform(a.getFieldNumber(SprintFact.AVERAGEPOINTSPERSTORY), 2); } )
                    },
                    {
                        type:'line',
                        label: 'Three Sprint Average',
                        data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber(SprintFact.AVERAGEPOINTSPERSTORYAVERAGE) ); } )
                    }
                ]
            }
        }
    }

    private processAveragePointsPerStoryAverageFact(result : ProjectFact) : void
    {
        if (_.isEqual(this.averagePointsPerStoryAverageFact, result) == false)
            this.averagePointsPerStoryAverageFact = result;
    }

    private processAveragePointsPerStoryTrendFact(result : ProjectFact ) : void
    {
        if (_.isEqual(this.averagePointsPerStoryTrendFact, result) == false)
            this.averagePointsPerStoryTrendFact = result;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
}