<p-card class="sprint-scope" (click)="onclick($event)">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Current Scope</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="sprintScopeFact && pointsCompletedFact">
                    <span tooltipPosition="left" pTooltip="% Completed">{{ percentComplete() | floor }}%</span>
                </span>
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div *ngIf="sprintScopeFact && pointsCompletedFact">
            <p-chart type="doughnut" [data]="data" [options]="options" [plugins]="plugins" [height]="'150px'"></p-chart>
        </div>    
    </span>
</p-card>