import { BaseValueObject } from '../base/value-object/base-value-object';

export class Organization extends BaseValueObject
{
    orgId: string;
    name: string;
    timeoutEnabled : boolean;

    public initialize(object : Organization) : void
    {
        super.initialize(object);

        this.orgId = object.orgId;
        this.name = object.name;
        this.timeoutEnabled = object.timeoutEnabled;
    }
}