import { Injectable }    from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';

import { Organization } from '../organization/organization';

@Injectable()
export class OrganizationService extends BaseHttpService
{
    organizationUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.organizationUrl = this.config.baseUrl + '/rest/organization';
    }

    public retrieve(orgId : string) : Observable<Organization>
    {
        let url = this.organizationUrl + "/" + orgId;
        return super.baseRetrieveObject(Organization, url);
    }

    public retrieveAll() : Observable<Organization[]>
    {
        let url = this.organizationUrl + "/list";
        return super.baseRetrieveList(Organization, url);
    }

    public create(organization : Organization) : Observable<Organization>
    {
        return super.baseCreateObject(Organization, this.organizationUrl, organization);    
    }

    public update(organization : Organization) : Observable<Organization>
    {
        return super.baseUpdateObject(Organization, this.organizationUrl, organization);    
    }

    public delete(organization : Organization) : Observable<string>
    {
        let deleteUrl = this.organizationUrl + "/" + organization.orgId;
        return super.baseDelete(deleteUrl, this.processDeleteResponse, this.handleError);
    }

    private processDeleteResponse(response: any) : string
    {
        return response;
    }

    private handleError(error: any): any 
    {
        return error.message || error;
    }
}