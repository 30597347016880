<p-card class="mini-defect-aging">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Defect Aging</span>
            </span>
            <span class="col-4 flex justify-content-end">
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span *ngIf="avgDefectDaysOutstanding" class="flex justify-content-center">
            {{ avgDefectDaysOutstanding.getFactNumber() | ceiling }} days average
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="defectDaysOutstanding" class="chart-style">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
