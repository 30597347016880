<p-card class="epic-status">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Epic Progress</span>
            </span>
            <span class="col-4 flex justify-content-end">
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="displayCosts == false && epicPointsCompleted">
                {{epicPointsCompleted.getFactNumber() }} pts completed 
             </span>
             <span *ngIf="displayCosts == true && costPerPoint && epicScope">
                {{ totalCost | round | currency:'USD':'symbol' }} total
             </span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="epicPointsCompleted && epicScope" class="chart-style">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px' "></p-chart>
        </div>    
    </span>
</p-card>