import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { OrgSourceSystemService } from '../../org-source-system/org-source-system.service';
import { SourceSystemService } from '../../source-system/source-system.service';
import { OrgSourceSystem } from '../../org-source-system/org-source-system';
import { SourceSystem } from '../../source-system/source-system';

import { SelectItem } from 'primeng/api';

import { BaseComponent } from '../../base/component/base-component.component';

import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'org-source-system-add',
  templateUrl: './org-source-system-add.component.html',
  styleUrls: ['./org-source-system-add.component.css'],
  providers: [ OrgSourceSystemService, SourceSystemService ]
})
export class OrgSourceSystemAddComponent extends BaseComponent implements OnInit
{ 
    sourceSystemList : SourceSystem[];
    sourceSystemItems : SelectItem[];
    sourceAuthTypeItems : SelectItem[];
    selectedSourceSystem : SourceSystem;
    selectedSourceAuthType : number;
    sourceAuthEndPoint : string;

    userId: string;
    password: string;
    confirmPassword: string;
    apiUrl : string;

    blockedDocument : boolean = false;

    private readonly JIRA_CLOUD_AUTH_END_POINT = 'https://id.atlassian.com/id/rest/login';

    constructor(protected router:Router,
                private orgSourceSystemService: OrgSourceSystemService,
                private sourceSystemService: SourceSystemService ) 
    {
        super(router);
        this.sourceSystemItems = [];
    }

    public ngOnInit() : void
    {
        this.blockedDocument = false;
        this.initFields();
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        this.sourceSystemItems = [];
        this.userId = "";
        this.password = "";
        this.confirmPassword = "";
        this.apiUrl = "";

        this.sourceSystemService.retrieveAll()
          .subscribe(result => this.processSourceSystemList(result),
                     error => this.handleError(error) ); 
    }

    private processSourceSystemList(sourceSystemList : SourceSystem[])
    {
        this.sourceSystemList = sourceSystemList;
        this.sourceSystemItems = [];

        for (let sourceSystem of sourceSystemList)
            this.sourceSystemItems.push({label: sourceSystem.description.trim(), value:sourceSystem });

        if (sourceSystemList.length > 0)
            this.selectedSourceSystem = sourceSystemList[0];

        this.buildSourceAuthTypeList();
    }

    public onSourceSystemChanged(event:any) : void
    {
        this.buildSourceAuthTypeList();
    }

    private buildSourceAuthTypeList() : void
    {
        this.sourceAuthTypeItems = [];
        this.sourceAuthEndPoint = undefined;

        if (this.selectedSourceSystem != undefined)
        {
            switch (this.selectedSourceSystem.sourceSystemId)
            {
                case SourceSystem.JIRA_AGILE:
                case SourceSystem.JIRA_API:
                    this.sourceAuthTypeItems.push({label: "Basic Auth", value:OrgSourceSystem.BASICAUTH });
                    this.sourceAuthTypeItems.push({label: "Cookie-Based Auth", value:OrgSourceSystem.COOKIEAUTH });
                    // this.sourceAuthTypeItems.push({label: "OAuth", value:OrgSourceSystem.OAUTH });

                    this.selectedSourceAuthType = OrgSourceSystem.BASICAUTH;
                    this.sourceAuthEndPoint = this.JIRA_CLOUD_AUTH_END_POINT;

                    break;

                case SourceSystem.TFS:
                    this.sourceAuthTypeItems.push({label: "Basic Auth", value:OrgSourceSystem.BASICAUTH });
                    // this.sourceAuthTypeItems.push({label: "OAuth", value:OrgSourceSystem.OAUTH });
                    this.selectedSourceAuthType = OrgSourceSystem.BASICAUTH;

                    break;

                case SourceSystem.AGILECENTRAL:
                    this.sourceAuthTypeItems.push({label: "Basic Auth", value:OrgSourceSystem.BASICAUTH });
                    // this.sourceAuthTypeItems.push({label: "OAuth", value:OrgSourceSystem.OAUTH });
                    this.selectedSourceAuthType = OrgSourceSystem.BASICAUTH;

                    break;
                    
            }
        }
    }

    protected handleError(error : any) : any
    {
        this.blockedDocument = false;

        if (error.status === 422)
            super.addErrorMessage("Invalid API URL or API credentials.");
        else
            super.addErrorMessage("Error occurred.  Please try again");
            
        return error.message || error;
     }

     public onAdd() : void
     {
         super.clearErrorMessages();

        if (LLUtils.isStringEmpty(this.userId) )
        {
            super.addErrorMessage("User Id is required.");
            return;
        }

        if (LLUtils.isStringEmpty(this.password) )
        {
            super.addErrorMessage("Password / API Token is required.");
            return;
        }

        if (LLUtils.isStringEmpty(this.confirmPassword) )
        {
            super.addErrorMessage("Confirm Password / API Token is required.");
            return;
        }

        if (LLUtils.isStringEmpty(this.apiUrl) )
        {
            super.addErrorMessage("API Url is required.");
            return;
        }

         if (this.password != this.confirmPassword)
         {
             super.addErrorMessage("Password and confirm password do not match.");
             return;
         }

         // Attempt to create.
         let orgSourceSystem = new OrgSourceSystem();
         
         orgSourceSystem.sourceSystem = this.selectedSourceSystem;
         orgSourceSystem.sourceSystemUserId = this.userId;
         orgSourceSystem.sourceSystemPassword = this.password;
         orgSourceSystem.sourceSystemApiUrl = this.apiUrl;
         orgSourceSystem.sourceAuthType = this.selectedSourceAuthType;

         if (orgSourceSystem.sourceAuthType == OrgSourceSystem.COOKIEAUTH)
            orgSourceSystem.sourceAuthEndPoint = this.sourceAuthEndPoint;
         else
            orgSourceSystem.sourceAuthEndPoint = null;

         orgSourceSystem.deleted = false;

         this.blockedDocument = true;

         this.orgSourceSystemService.create(orgSourceSystem)
            .subscribe(result => this.addSucceeded(result),
                       error => this.handleError(error) );
     }

    public addSucceeded(result : OrgSourceSystem) : void
    {
        this.blockedDocument = false;
        super.addInfoMessage("Source System Successfully Added!");
    }

    public onCancel() : void
    {
        this.router.navigate(['/dashboard/admin/org-source-system-list']);
    }

    public get COOKIEAUTH() : number 
    {
        return OrgSourceSystem.COOKIEAUTH;
    }
}
