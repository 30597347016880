import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LoggedInUser } from './logged-in-user';

@Injectable()
export class AdminGuard implements CanActivate 
{
  constructor(private router: Router) {}

  canActivate() 
  {
    let result : boolean = false;

    if (LoggedInUser.getLoggedInUser() == undefined)
    {
        result = false;
        this.router.navigate(['/logout']);
        return result;
    }

    if (LoggedInUser.getLoggedInUser().isAdmin() == false)
    {
        result = false;
        this.router.navigate(['/dashboard']);
    }
    else
    {
        result = true;
    }

    return result;
  }

}