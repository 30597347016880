import { Directive, OnInit, OnChanges, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { BaseUIComponent } from './base-ui-component.component';
import { ZoomGraphComponent } from '../../ui/zoom-graph/zoom-graph.component';

import { Project } from '../../project/project';

import { LLUtils } from '../../utilities/ll-utils';

@Directive()
export abstract class BaseOrgTimeSeriesComponent extends BaseUIComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    projectList : Project[] = [];
    timeSeriesMap : Map<string, TimeSeries> = new Map<string, TimeSeries>();

    hasData : boolean = undefined;

    data: any;
    options: any;

    constructor( protected router : Router,
                 protected timeSeriesService : TimeSeriesService) 
    { 
        super(router);
        this.options.onClick = (event, array) => { this.graphClickEvent(event, array) }
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        let deleteOccurred : boolean = false;

        // Clean up map - deselect projects if removed.
        for (let projectId of this.timeSeriesMap.keys() )
        {
            let found : boolean = false;

            for (let project of this.projectList)
            {
                if (projectId == project.projectId)
                {
                    found = true;
                    break;
                }
            }

            if (found == false)
            {
                this.timeSeriesMap.delete(projectId);
                deleteOccurred = true;
            }
        }

        // If a delete occurred, be sure to rebuild graph.
        if (deleteOccurred)
            this.buildGraph();

        for (let project of this.projectList)
            this.retrieveTimeSeriesByProject(project);
    }

    public abstract retrieveTimeSeriesByProject(project : Project) : void;
    protected abstract buildGraph() : void;

    public getProjectName(projectId : string) : string
    {
        let result : string = "";

        for (let project of this.projectList)
        {
            if (project.projectId == projectId)
            {
                result = project.description;
                break;
            }
        }

        return result;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

    public navigate(datasetIndex : number, timeSeriesListIndex : number) : void
    {
       let projectId : string = this.projectList[datasetIndex].projectId;
       this.router.navigate(['/dashboard/project-detail', projectId]);            
    }

    public graphClickEvent(event : MouseEvent, array: any[]) : void 
    {
        if (array.length > 0)
        {
            let datasetIndex : number = array[0].datasetIndex;
            let index : number = array[0].index;
            this.navigate(datasetIndex, index);            
        }
    }

    public padTimeSeries(values : number[], maxLength : number) : number[]
    {
        let result : number[] = [];

        for (let i = 0; i < (maxLength - values.length); i++)
            result[i] = 0;

        result = [ ...result, ...values];

        return result;
    }
}
