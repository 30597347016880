<p-table *ngIf="epicList" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="epicId"
    [rowHover]="true" [value]="epicList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
    (onLazyLoad)="loadData($event)" (onSort)="customSort($event)" [sortField]="sortField" [sortOrder]="sortOrder">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="percent-complete-header" [pSortableColumn]="'percentComplete'">
                    %
                </th>
                <th [pSortableColumn]="'formattedId'">
                    Id
                </th>
                <th [pSortableColumn]="'name'">
                    Name
                </th>
                <th [pSortableColumn]="'status'">
                    Status
                </th>
                <th [pSortableColumn]="'scope'">
                    Scope
                </th>
                <th [pSortableColumn]="'pointsCompleted'">
                    Points Completed
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-epic let-columns="columns" let-index="rowIndex">
            <tr [pSelectableRow]="rowData" [ngClass]="epicRowStyleClass(epic, index)">
                <td style="min-width:30px">
                    <p-progressBar [value]="epic.percentComplete" [showValue]="false" [pTooltip]="epic.percentComplete + '%'"></p-progressBar>                    
                </td>
                <td>
                    <div class="flex align-content-center align-items-center">
                        <a *ngIf="buildEpicSourceSystemLink(epic)" href="{{ buildEpicSourceSystemLink(epic) }}" target="_blank">
                            <i class="material-icons" [ngStyle]="{'vertical-align' : 'middle','padding-right':'5px','color':'blue'}" pTooltip="View in Source System">open_in_new</i>
                        </a>
                        <a [routerLink]="['/dashboard/epic-detail', epic.epicId]">{{epic.formattedId}}</a>
                    </div>
                </td>
                <td>
                    <div>
                        <a [routerLink]="['/dashboard/epic-detail', epic.epicId]">{{epic.name}}</a>
                        <span *ngIf="epic.epicUnsized == true" pTooltip="Epic Unsized!  Some stories are not estimated!">
                            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="epic-unsized">
                                <i class="material-icons">warning</i>
                            </a>
                        </span>
                    </div>
                </td>
                <td>
                    {{ epic.status }}
                </td>
                <td>
                    {{ epic.scope }}
                </td>
                <td>
                    {{ epic.pointsCompleted }}
                </td>                
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
            <tr>
                <td colspan="4">
                    Total Percent Complete : {{ totalPercentComplete()}}%
                </td>
                <td>
                    {{ totalScope() }}
                </td>
                <td>
                    {{ totalPointsCompleted() }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="6">
                    No records found
                </td>
            </tr>
        </ng-template>
</p-table>
