<p-card class="mini-sprint-commitment">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Sprint Commitment</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="sprintCommitment && sprintVelocity" tooltipPosition = "left" pTooltip="Percent Complete">
                    {{percentComplete() | round }}%
                </span>
        
                <span class="overview-badge sprint-overloaded" *ngIf="sprintOverloaded">
                    <a *ngIf="sprintOverloaded.getFactBoolean()" [routerLink]="['/dashboard/content-detail/key', OVERLOADEDSPRINT]">
                        <i tooltipPosition = "left" pTooltip="Sprint Overloaded" class="warning material-icons">warning</i>
                    </a>
                </span>
             </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="sprint">Sprint Ending {{ sprint.sprintEndDate | dateFormat }}</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="sprintCommitment && sprintVelocity" class="chart-style">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
