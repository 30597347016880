<div class="card card-w-title col-12 grid">
    <div *ngIf="organization != undefined" class="grid col-12 sm:col-12 md:col-12 align-content-end align-items-end">
        <h3 class="col-12 sm:col-12 md:col-6">{{ organization.name }}</h3>
        <div class="col-12 sm:col-12 md:col-6">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-filter"></i></span>  
                <p-multiSelect [style]="{'width':'90%'}" [options]="projectList" [(ngModel)]="filteredProjectList" defaultLabel="Filter Projects" optionLabel="description" display="chip" (onChange)="filterChange()" dataKey="projectId"></p-multiSelect>
            </div>    
        </div>
    </div>
    
    <!-- Display projects that might be initially loading -->
    <div *ngIf="areAnyProjectsLoading()" class="col-12 grid">
        <div *ngFor="let project of projectList" class="col-12">
            <div *ngIf="isProjectLoading(project)">
                <progress-bar [description]="getProjectDescription(project)"></progress-bar>
            </div>
        </div>
    </div>
    <!-- ================================================================================== -->
    <!-- Desktop -->
    <p-fieldset *ngIf="isMobile() == false" legend="Epic Summary" [toggleable]="false" class="col-12 sm:col-12 md:col-12">
        <!-- Selector for program increments -->
        <div class="col-12 sm:col-12 md:col-12">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-filter"></i></span>  
                <p-multiSelect [style]="getQuarterSelectorStyle()" [options]="quarterList" [(ngModel)]="filteredQuarterList" defaultLabel="Filter Quarters" display="chip" (onChange)="filterChange()"></p-multiSelect>
            </div>    
        </div>

        <!-- Display epics in progress for the organization -->
        <epic-dashboard-summary class="col-12 sm:col-12 md:col-12" [projectList]="filteredProjectList" [epicList]="filteredEpicList" [lazy]="false" 
            [rows]="maxRows" [total]="filteredProjectList.length" (onSortClicked)="onSortClicked($event)"
            [paginator]="true">
        </epic-dashboard-summary>
    </p-fieldset>

    <!-- Mobile -->
    <div *ngIf="isMobile() == true" [style]="getFieldSetStyle()">
        <!-- Selector for program increments -->
        <div class="col-12 sm:col-12 md:col-12">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-filter"></i></span>  
                <p-multiSelect [style]="getQuarterSelectorStyle()" [options]="quarterList" [(ngModel)]="filteredQuarterList" defaultLabel="Filter Quarters" display="chip" (onChange)="filterChange()"></p-multiSelect>
            </div>    
        </div>

        <!-- Display epics in progress for the organization -->
        <epic-dashboard-summary class="col-12 sm:col-12 md:col-12" [projectList]="filteredProjectList" [epicList]="filteredEpicList" [lazy]="false" 
            [rows]="maxRows" [total]="filteredProjectList.length" [paginator]="true" styleWidth="width:90%">
        </epic-dashboard-summary>
    </div>
    <!-- ================================================================================== -->
    <!-- Desktop Maturity Graphs-->
    <p-fieldset *ngIf="isMobile() == false" legend="Maturity" [toggleable]="false" class="col-12 sm:col-12 md:col-12">
        <div class="grid col-12 sm:col-12 md:col-12">
            <label class="col-3 sm:col-3 md:col-3" style="font-weight:bold">Maturity Goal: {{ selectedMaturity | number:'1.2-2' }}</label>
            <p-slider class="col-9 sm:col-9 md:col-9" [(ngModel)]="selectedMaturity" [min]="1" [max]="5" [step]=".05" (singleChange)="filterChange()"></p-slider>
        </div>

        <div style="width:100%" class="grid">
            <!-- Maturity Graphs -->
            <org-scrum-maturity class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList" [maturityGoal]="selectedMaturity">
            </org-scrum-maturity>
            <org-scrum-maturity-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList" [maturityGoal]="selectedMaturity">
            </org-scrum-maturity-timeline>
        </div>
    </p-fieldset>

    <!-- Mobile Maturity Graphs -->
    <div *ngIf="isMobile() == true" class="col-12 sm:col-12 md:col-12">
        <div class="grid col-12 sm:col-12 md:col-12">
            <label class="col-12 sm:col-12 md:col-12" style="font-weight:bold">Maturity Goal: {{ selectedMaturity | number:'1.2-2' }}</label>
            <p-slider class="col-12 sm:col-12 md:col-12" [(ngModel)]="selectedMaturity" [min]="1" [max]="5" [step]=".05" (singleChange)="filterChange()"></p-slider>
        </div>

        <!-- Maturity Graphs -->
        <org-scrum-maturity class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList" [maturityGoal]="selectedMaturity">
        </org-scrum-maturity>
        <org-scrum-maturity-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList" [maturityGoal]="selectedMaturity">
        </org-scrum-maturity-timeline>
    </div>
    <!-- ================================================================================== -->

    <!-- Sprint Commitment / Attainment Graphs -->
    <team-commitment-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </team-commitment-timeline>
    <team-attainment-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </team-attainment-timeline>
    <!-- Cost Per Point Graphs -->
    <org-cost-per-point-timeline *ngIf="hasFinanicialAccess()" class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </org-cost-per-point-timeline>
    <org-cost-per-point-average-timeline *ngIf="hasFinanicialAccess()" class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </org-cost-per-point-average-timeline>
    <org-defect-density-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </org-defect-density-timeline>
    <org-open-defect-count-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </org-open-defect-count-timeline>
    <org-velocity-timeline class="col-12 sm:col-12 md:col-6" [projectList]="filteredProjectList">
    </org-velocity-timeline>
</div>
<!--
<div *ngIf="project" [ngClass]="cssClassList">
    <div class="col-12 grid ll-nogutter-top-bottom">
        <div class="md:col-9 sm:col-12 flex align-content-center grid">
            <h3 style="margin: auto 0px"><a [routerLink]="['/dashboard/project-detail', project.projectId]">{{project.description}}</a></h3>
            <span style="margin-right:5px">&nbsp;</span>
            <button *ngIf="details" pButton type="button" label="Details" class="project-detail-button secondary" (click)="onDetailClick()"></button>
        </div>
        <div *ngIf="orgSourceSystem" class="md:col-3 sm:col-12 org-source-system">
            <a *ngIf="orgSourceSystem.sourceSystem.sourceSystemId == AGILECENTRAL" [routerLink]="['/dashboard/content-detail/key', CAAGILECENTRAL]">
                <img src='/assets/images/ca-agile-central-small.png'>
            </a>

            <a *ngIf="orgSourceSystem.sourceSystem.sourceSystemId == JIRA_AGILE" [routerLink]="['/dashboard/content-detail/key', JIRAAGILE]">
                <img  src='/assets/images/atlassian-jira-small.png'>
            </a>
            
            <a *ngIf="orgSourceSystem.sourceSystem.sourceSystemId == JIRA_API" [routerLink]="['/dashboard/content-detail/key', JIRAAGILE]">
                <img src='/assets/images/atlassian-jira-small.png'>
            </a>

            <a *ngIf="orgSourceSystem.sourceSystem.sourceSystemId == TFS" [routerLink]="['/dashboard/content-detail/key', MICROSOFTTFS]">
                <img  src='/assets/images/azure-dev-ops-small.png'>
            </a>
        </div>
    </div>
    
    <div *ngIf="notFound && projectLoading == undefined" class="col-12">
        <span>This project has not yet started to load.  If this message does not 
        disappear within the next minute or so, check to make sure the project refresh 
        job is active.           
        </span> 
        <br><br>
        <span *ngIf="getIsAdmin() == true">Since you are an administrator, you can review which
        project refresh jobs are active by clicking <a [routerLink]="['/dashboard/admin/org-job-schedule']">here</a>.
        </span>
    </div>

    <div *ngIf="projectLoading == true" class="col-12">
        <progress-bar [description]="'Initial Load in Progress...'"></progress-bar>
    </div>

    <div *ngIf="projectLoading == false" class="col-12 grid ll-nogutter-top-bottom">
        <div class="grid col-12 project-line-2">
            <div *ngIf="displayMaturityRating() == true" class="grid col-12 sm:col-12 md:col-12 lg:col-9 flex-margin-hack">
                <div class="col-12 sm:col-12 md:col-6 lg:col-2">Current Maturity:</div>
                <div *ngIf="scrumMaturityFact" class="col-12 sm:col-12 md:col-6 lg:col-3"> 
                    <project-star-rating class="hover-over" [rating]="scrumMaturityFact.getFactNumber()" (click)="onScrumMaturityClick()"></project-star-rating>
                </div>
                <div class="col-12 sm:col-12 md:col-6 lg:col-2">Top Maturity:</div>
                <div *ngIf="topScrumMaturityFact" class="col-12 sm:col-12 md:col-6 lg:col-3"> 
                    <project-star-rating class="hover-over" [rating]="topScrumMaturityFact.getFactNumber()" (click)="onTopScrumMaturityClick()"></project-star-rating>
                </div>
                <div class="col-12 sm:col-12 md:col-12 lg:col-2">
                </div>
            </div>
            <div *ngIf="displayMaturityRating() != true" class="col-9">&nbsp;</div>
            <div class="col-12 sm:col-12 md:col-12 lg:col-3">
                <div *ngIf="projectStatus && projectStatus.refreshCompleted" class="col-12 refresh-completed">Last refreshed: {{ projectStatus.refreshCompleted | relativeTime:projectStatus.currentTime }}</div>        
            </div>
        </div>
        <div class="col-12 ll-nogutter-top-bottom">
            <goal-summary class="ll-nogutter-top-bottom" [project]="project"></goal-summary>
        </div>
        <div class="col-12 ll-nogutter-top-bottom">
            <div class="grid ll-nogutter-top-bottom">
                <div class="col-12 md:col-12 lg:col-4 ll-nogutter-top-bottom">
                    <sprint-commitment-timeline class="col-12 ll-nogutter-top-bottom" [project]="project"></sprint-commitment-timeline>
                </div>
                <div class="col-12 md:col-12 lg:col-4 ll-nogutter-top-bottom">
                    <mini-defect-count-overview class="col-12 ll-nogutter-top-bottom" [project]="project"></mini-defect-count-overview>
                </div>
                <div class="col-12 md:col-12 lg:col-4 ll-nogutter-top-bottom">
                    <scrum-maturity-closed-sprints-timeline class="col-12 ll-nogutter-top-bottom" *ngIf="displayMaturityRating() == true" [project]="project"></scrum-maturity-closed-sprints-timeline>
                    <mini-backlog-depth *ngIf="displayMaturityRating() != true" class="col-12 ll-nogutter-top-bottom" [project]="project"></mini-backlog-depth> 
                </div>
            </div>
        </div>
    </div>
</div>
-->
