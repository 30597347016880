import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { BaseOrgTimeSeriesComponent } from '../../base/component/base-org-time-series-component.component';

import { Project } from '../../project/project';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { LLUtils } from '../..//utilities/ll-utils';

import * as _ from 'lodash';

// Internal class for this component.
class CostPerPointAverage {
    average : number;
    index : number;
}

@Component({
  selector: 'org-cost-per-point-average-timeline',
  templateUrl: './org-cost-per-point-average-timeline.component.html',
  styleUrls: ['./org-cost-per-point-average-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})

export class OrgCostPerPointAverageTimelineComponent extends BaseOrgTimeSeriesComponent implements OnInit, OnChanges 
{ 
    constructor( protected router : Router,
                 protected timeSeriesService : TimeSeriesService) 
    { 
        super(router, timeSeriesService);
    }

    public ngOnInit() : void
    {
        super.ngOnInit();
        this.options['plugins']['title'].text = `Cost Per Point Average`;
    }

    public retrieveTimeSeriesByProject(project : Project) : void
    {
        this.timeSeriesService.retrieve(project, project.projectId, TimeSeries.PROJECTCOSTPERPOINT, 10)
            .subscribe(result => this.processTimeSeries(project.projectId, result),
                       error => this.handleError404Okay(error) );
    }

    protected processTimeSeries(projectId : string, result: TimeSeries)
    {
        if (_.isEqual(this.timeSeriesMap.get(projectId), result) == true)
            return;
    
        this.timeSeriesMap.set(projectId, result);
        this.buildGraph();
    }

    protected buildGraph() : void
    {
        // If there are no projects, just return.
        if (this.projectList.length <= 0)
            return;
            
        // First, build all the labels.
        let labelArray : string[] = [];

        for (let i = 10; i > 0; i-- )
        {
            switch (i)
            {
                case 1:
                    labelArray.push("Current Sprint");
                    break;

                case 2:
                    labelArray.push("Last Sprint");
                    break;
        
                default:
                    labelArray.push(`${i} sprints ago`);
                    break;
            }
        }

        this.data = {
            labels: labelArray
        }

        // Next, plot the projects.
        // Put in logic to check if data changed.
        this.data.datasets = [];

        let costPerPointAverageList : CostPerPointAverage[] = this.buildCostPerPointAverage();
        let paddedValues : number[] = this.padTimeSeries(costPerPointAverageList.map( function(a : CostPerPointAverage) { return a.average } ), 10);
        
        this.data.datasets.push({
            type: 'line',
            label: 'Average',
            backgroundColor: this.BLACKTRANSPARENT,
            hoverBackgroundColor: this.BLACK,
            borderColor: this.BLACK,
            borderWidth: 5,
            fill: false,
            tension: .5,
            data: paddedValues
        });
    }

    public buildCostPerPointAverage() : CostPerPointAverage[]
    {
        let result : CostPerPointAverage[] = [];

        // if no teams then just return.
        if (this.projectList.length <= 0)
            return result;

        // Create a deep copy of our timeseries map.  We do this because
        // we need to resort the elements of the map.
        let timeSeriesMap : Map<string, TimeSeries> = _.cloneDeep(this.timeSeriesMap);

        // Accommodate up to 10 sprints.
        for (let i = 0; i < 10; i++)
        {
            let teamCount : number = 0;
            let totalCostPerPoint : number = 0;

            for (let projectId of timeSeriesMap.keys() )
            {
                // If this project doesn't have a sprint at the index we're 
                // checking, continue...
                if (timeSeriesMap.get(projectId).timeSeriesList.length <= i)
                    continue;

                let timeSeriesDescending = timeSeriesMap.get(projectId);
                timeSeriesDescending.sortDescending();

                teamCount += 1;

                let teamMaturity = timeSeriesDescending.timeSeriesList[i].getFieldNumber(SprintFact.COSTPERPOINT);
                totalCostPerPoint = totalCostPerPoint + teamMaturity;
            }

            if (teamCount > 0)
            {
                let average : number = LLUtils.round((totalCostPerPoint / teamCount), 2);

                let costPerPointAverage : CostPerPointAverage = new CostPerPointAverage();
                costPerPointAverage.average = average;
                costPerPointAverage.index = i;

                result.push(costPerPointAverage);
            }
        }

        // Now, resort the result list so the most recent sprint is 
        // last and the oldest sprint is first.
        result = result.sort((costPerPointAverage1 : CostPerPointAverage, costPerPointAverage2 : CostPerPointAverage) => {

            // Sort descending by index so the oldest sprint is first and the
            // newest sprint is last.
            return costPerPointAverage2.index - costPerPointAverage1.index;
        });

        return result;
    }

}
