import { BaseFactObject } from '../base/value-object/base-fact-object';

export class DependencyFact extends BaseFactObject
{
	// DEPENDENCY FACT CONSTANTS
	public static readonly SPRINTSWORKEDON : string = "dependencySprintsWorkedOn";
	public static readonly POINTSONDEPENDENCY : string = "pointsOnDependency";
	public static readonly BLOCKED : string = "dependencyBlocked";
	public static readonly POINTSINRANGE : string = "dependencyPointsInRange";
	public static readonly OPENDEPENDENCYCLOSEDSPRINT : string = "openDependencyClosedSprint";

    dependencyFactId : string;
	dependencyId : string;
    projectId : string;

    public initialize(dependencyFact : DependencyFact) : void
    {
        super.initialize(dependencyFact);
        this.dependencyFactId = dependencyFact.dependencyFactId;
        this.dependencyId = dependencyFact.dependencyId;
        this.projectId = dependencyFact.projectId;
    }
}