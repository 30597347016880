<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL}" [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme">

    <dashboard-topbar *ngIf="userAccount" [userAccount]="userAccount"></dashboard-topbar>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <dashboard-profile *ngIf="userAccount && app.inlineMenuPosition=='top' || app.inlineMenuPosition=='both'" key="top" [userAccount]="userAccount"></dashboard-profile>
            <dashboard-menu *ngIf="projectList" style="overflow:scroll" [reset]="resetMenu" [projectList]="projectList"></dashboard-menu>
            <dashboard-profile *ngIf="userAccount && app.inlineMenuPosition=='bottom' || app.inlineMenuPosition=='both'" key="bottom" [userAccount]="userAccount"></dashboard-profile>
        </div>
    </div>

    <div class="layout-main">
        <div class="layout-content">
            <p-messages class="col-12" [value]="errorMessages" [closable]="false" (click)="forceInterrupt()"></p-messages>
            <router-outlet></router-outlet>
        </div>

        <dashboard-footer></dashboard-footer>
    </div>

    <!--
    <app-config></app-config>
    -->
    
    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>

</div>
