import { Component, OnInit, OnChanges, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { DefectCount } from '../../defect/defect-count';

import { BaseFactObject } from '../../base/value-object/base-fact-object';

import { Release } from '../../release/release';
import { ReleaseFact } from '../../release-fact/release-fact';
import { ReleaseFactService } from '../../release-fact/release-fact.service';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service'; 

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'release-defect-potential-timeline',
  templateUrl: './release-defect-potential-timeline.component.html',
  styleUrls: ['./release-defect-potential-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ReleaseFactService, TimeSeriesService ]
})

export class ReleaseDefectPotentialTimelineComponent extends BaseUIComponent implements OnInit, OnChanges 
{ 
    @Input()
    release : Release;

    @Output() click = new EventEmitter<any>();

    defectCountList : DefectCount[];
    timeSeries : TimeSeries;

    defectPotentialTimeSeries : TimeSeries;

    openDefectCountFact : BaseFactObject;
    openDefectTrend : string;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private releaseFactService : ReleaseFactService,
                 private timeSeriesService : TimeSeriesService) 
    {
        super(router);

        this.defectCountList = undefined;

        this.options = {
            plugins: {
                title: {
                    display: false,
                    text: 'Defect Count'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            scales:  { y: { stacked : true, beginAtZero:true }, 
                       x: { stacked : true, display: false } },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void
    {
        if (this.release != undefined)
        {
            this.releaseFactService.retrieve(this.release, ReleaseFact.OPENDEFECTCOUNT)
                .subscribe(result => this.processOpenDefectCountFact(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.release.project, this.release.releaseId, TimeSeries.RELEASEDEFECTCOUNT, 10)
                .subscribe(result=>this.processDefectCountTimeSeries(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.release.project, this.release.releaseId, TimeSeries.RELEASEDEFECTPOTENTIAL, 10)
                .subscribe(result =>this.processDefectPotentialTimeSeries(result),
                           error => this.handleError404Okay(error) ); 
                                      
        }
    }

    private processDefectCount()
    {
        if (this.defectCountList == undefined || this.defectPotentialTimeSeries == undefined)
            return;

        this.data = {
            labels : this.defectCountList.map( function(a) { return moment(a.sprintEndDate).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Closed Defects',
                    backgroundColor: this.GREENTRANSPARENT,
                    pointHoverBackgroundColor: this.GREEN,
                    borderColor: this.GREEN,
                    borderWidth: 2,
                    data: this.defectCountList.map( function(a) { return a.closedDefects; } )
                },
                {
                    type:'bar',
                    label: 'Open Defects',
                    backgroundColor: this.REDTRANSPARENT,
                    pointHoverBackgroundColor: this.RED,
                    borderColor: this.RED,
                    borderWidth: 2,
                    data: this.defectCountList.map( function(a) { return a.openDefects; } )
                },
                {
                    type:'line',
                    label: 'Defect Potential',
                    data: this.defectPotentialTimeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber(ReleaseFact.DEFECTPOTENTIAL) ); } )
                }
            
            ]
        }
    }

    public processOpenDefectCountFact(result : BaseFactObject ) : void
    {
        if (_.isEqual(this.openDefectCountFact, result) == false)
            this.openDefectCountFact = result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    public processDefectCountTimeSeries(result: TimeSeries) : void
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;
            this.defectCountList = [];

            for (let timeSeriesItem of result.timeSeriesList)
            {
                let dc : DefectCount = new DefectCount();
                dc.sprintEndDate = moment(timeSeriesItem.timestamp).toDate();
                dc.openDefects = timeSeriesItem.getFieldNumber("openDefects");
                dc.closedDefects = timeSeriesItem.getFieldNumber("closedDefects");
                dc.totalDefects = timeSeriesItem.getFieldNumber("totalDefects");

                this.defectCountList.push(dc);
            }

            this.processDefectCount();
            this.setOpenDefectTrend();
        }
    }

    private setOpenDefectTrend() : void
    {
        let twoSprintsAgo : number = 0;
        let oneSprintAgo : number = 0;
        let lastSprint : number = 0;

        this.openDefectTrend = "neutral";

        if (this.defectCountList.length >= 3)
        {
            let length = this.defectCountList.length;

            twoSprintsAgo = this.defectCountList[length-3].openDefects;
            oneSprintAgo = this.defectCountList[length-2].openDefects;
            lastSprint = this.defectCountList[length-1].openDefects;

            if (twoSprintsAgo < oneSprintAgo && oneSprintAgo < lastSprint)
                this.openDefectTrend = "up";

            if (twoSprintsAgo > oneSprintAgo && oneSprintAgo > lastSprint)
                this.openDefectTrend = "down";
        }
    }

    public processDefectPotentialTimeSeries(result : TimeSeries) : void
    {
        this.defectPotentialTimeSeries = result;
        this.processDefectCount();
    }
}
