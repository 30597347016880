import { BaseValueObject } from '../base/value-object/base-value-object'; 

import * as moment from 'moment';

export class ProjectStatus extends BaseValueObject {

	public projectStatusId : string;
	public projectId : string;
	public updatesProcessed : Date;
	public deletesProcessed : Date;
	public factsProcessed : Date;
	public timeSeriesProcessed : Date;
	public rulesProcessed : Date;
	public refreshStarted : Date;
	public refreshCompleted : Date;
	public refreshDuration : number;
	public status : number;
	public currentTime : Date;
	public initialLoad : boolean;
	public updateFailureCount : number;
	public deleteFailureCount : number;
	

	public static readonly SUCCESS : number = 1;
	public static readonly FAILURE : number = 2;

    public initialize(projectStatus : ProjectStatus) : void
    {
        super.initialize(projectStatus);

		this.projectStatusId = projectStatus.projectStatusId;
		this.projectId = projectStatus.projectId;
		this.updatesProcessed = moment(projectStatus.updatesProcessed).toDate();
		this.deletesProcessed = moment(projectStatus.deletesProcessed).toDate();
		this.factsProcessed = moment(projectStatus.factsProcessed).toDate();
		this.timeSeriesProcessed = moment(projectStatus.timeSeriesProcessed).toDate();
		this.rulesProcessed = moment(projectStatus.rulesProcessed).toDate();
		this.refreshStarted = moment(projectStatus.refreshStarted).toDate();
		this.refreshCompleted = moment(projectStatus.refreshCompleted).toDate();
		this.refreshDuration = projectStatus.refreshDuration;
		this.status = projectStatus.status;
		this.initialLoad = projectStatus.initialLoad;
		this.updateFailureCount = projectStatus.updateFailureCount;
		this.deleteFailureCount = projectStatus.deleteFailureCount;
		this.currentTime = moment(projectStatus.currentTime).toDate();
    }

}