import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

@Component({
  selector: 'priority-rank',
  templateUrl: './priority-rank.component.html',
  styleUrls: ['./priority-rank.component.css' ],
  providers: [ ]
})

export class PriorityRankComponent extends BaseComponent implements OnInit { 

    active: boolean;

    @Input()
    priorityRank : string;
    
    constructor(protected router : Router) 
    { 
        super(router);
        
        this.active = false;
        this.priorityRank = "";
    }

    ngOnInit() : void
    {
        this.active = true;
    }

    public priorityRankClass() : string
    {
        let result : string = "material-icons ";

        if (this.priorityRank == "1")
            result = result + "priority-rank-highest";

        if (this.priorityRank == "2")
            result = result + "priority-rank-high";

        if (this.priorityRank == "3")
            result = result + "priority-rank-medium";

        if (this.priorityRank == "4")
            result = result + "priority-rank-low";

        if (this.priorityRank == "5")
            result = result + "priority-rank-lowest";

        return result;
    }

    public priorityRankIcon() : string
    {
        let result : string = "arrow_upward";

        if (this.priorityRank == "4")
            result = "arrow_downward";

        if (this.priorityRank == "5")
            result = "arrow_downward";

        return result;
    }

    public priorityRankText() : string
    {
        let result : string = "medium";

        if (this.priorityRank == "1")
            result = "highest";

        if (this.priorityRank == "2")
            result = "high";

        if (this.priorityRank == "3")
            result = "medium";

        if (this.priorityRank == "4")
            result = "low";

        if (this.priorityRank == "5")
            result = "lowest";

        return result;
    }
}
