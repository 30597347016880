import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { OrganizationService } from '../../organization/organization.service';
import { Organization } from '../../organization/organization';

import { ContentService } from '../../content/content.service';
import { ContentDetail } from '../../content/content-detail';
import { ContextVariable } from '../context-variable/context-variable';

import { SelectItem } from 'primeng/api';

import { BaseComponent } from '../../base/component/base-component.component';
import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'content-update',
  templateUrl: './content-update.component.html',
  styleUrls: ['./content-update.component.css'],
  providers: [ ContentService, OrganizationService ]
})
export class ContentUpdateComponent extends BaseComponent implements OnInit
{ 
    content : ContentDetail;
    active : boolean;
    
    contentId : string;

    contextVariableList : ContextVariable[];
    organizationList : SelectItem[] = [];

    constructor(protected router:Router,
                protected route: ActivatedRoute,
                protected contentService: ContentService, 
                protected organizationService : OrganizationService) 
    {
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.contentId = params['contentId'];
        });

        this.active = false;
    }

    public ngOnInit() : void
    {
        this.initFields();
        this.initializeContextVariableList();

        this.organizationService.retrieveAll()
            .subscribe(result => this.processOrganizationList(result), 
                        error => this.handleError(error) );  

    }

    protected initFields() : void
    {
        super.clearErrorMessages();

        this.contentService.retrieveDetailNoTransform(this.contentId)
            .subscribe(result=>this.processContent(result),
                       error => this.handleError(error) );
    }

    protected processContent(result: ContentDetail) : void
    {
        this.content = result;
        this.active = true;
    }

    protected handleError(error : any) : any
    {
        super.addErrorMessage("Error occurred.  Please try again");
        return error.message || error;
     }

     public onUpdate() : void
     {
         super.clearErrorMessages();
         
        // Validate
        if (LLUtils.isStringEmpty(this.content.contentKey) == true )
        {
            this.addErrorMessage("Content Key must be supplied.");
            return;
        }

        if (LLUtils.isStringEmpty(this.content.contentTitle) == true )
        {
            this.addErrorMessage("Content Title must be supplied.");
            return;
        }

        if (LLUtils.isStringEmpty(this.content.contentSummary) == true )
        {
            this.addErrorMessage("Content Summary must be supplied.");
            return;
        }

        if (LLUtils.isStringEmpty(this.content.contentDetail) == true )
        {
            this.addErrorMessage("Content Detail must be supplied.");
            return;
        }

        this.contentService.update(this.content)
            .subscribe(result => this.onSuccess(result),
                error => this.handleError(error) );
     }

     public onCancel() : void
     {
        this.router.navigate(['/dashboard/admin/content-list']);
     }

     protected onSuccess(result : ContentDetail) : void
     {
        this.addInfoMessage("Content Updated!");
        this.processContent(result);
     }

    private initializeContextVariableList() : void
    {
        this.contextVariableList = new Array<ContextVariable>();
        this.contextVariableList.push(new ContextVariable("[@content key='contentKey' title='title' /]", "Link to application content") );
        this.contextVariableList.push(new ContextVariable("[@applink url='url' title='title' /]", "Link to internal pages") );
        this.contextVariableList.push(new ContextVariable("[@link url='url' title='title' /]", "Link to external pages") );
        this.contextVariableList.push(new ContextVariable("[@break/]", "Force line break.") );
    }

    public processOrganizationList(result : Organization[] )
    {
        this.organizationList = [];
        this.organizationList.push( { label:"(none)", value: undefined } );

        for (let organization of result)
            this.organizationList.push( { label:organization.name, value: organization.orgId } );
    }
}
