import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PrimeNgModule } from './primeng.module';
import { PipesModule } from '../pipes/pipes.module';

import { ColorboxComponent } from './colorbox/colorbox.component';
import { PriorityRankComponent } from './priority-rank/priority-rank.component';
import { MiniVelocityOverviewComponent } from './velocity/mini-velocity-overview.component';
import { MiniDefectCountOverviewComponent } from './defect-count/mini-defect-count-overview.component';
import { MiniAppAlertCountOverviewComponent } from './app-alert-count/mini-app-alert-count-overview.component';
import { MiniAppAlertSeverityOverviewComponent } from './app-alert-severity/mini-app-alert-severity-overview.component';
import { MiniAppAlertCountSeverityOverviewComponent } from './app-alert-count-severity/mini-app-alert-count-severity-overview.component';
import { ProjectSprintHistoryComponent } from './sprint-history/project-sprint-history.component';
import { SprintHistoryComponent } from './sprint-history/sprint-history.component';
import { MiniSprintCommitmentComponent } from './sprint-commitment/mini-sprint-commitment.component';
import { SprintCommitmentTimelineComponent } from './sprint-commitment/sprint-commitment-timeline.component';
import { SprintBurndownTimelineComponent } from './sprint-burndown/sprint-burndown-timeline.component';
import { PointsInjectedComponent } from './points-injected/points-injected.component';
import { PointsInjectedTimelineComponent } from './points-injected/points-injected-timeline.component';
import { PointsCarriedOverTimelineComponent } from './points-carried-over/points-carried-over-timeline.component';
import { CommitmentAttainmentTimelineComponent } from './commitment-attainment/commitment-attainment-timeline.component';
import { ReleasePointBurnupComponent } from './release/release-point-burnup.component';
import { ReleaseCostBurnupComponent } from './release/release-cost-burnup.component';
import { ReleasePointStatusComponent } from './release/release-point-status.component';
import { ReleasePointPlaceholderStatusComponent } from './release/release-point-placeholder-status.component';
import { ReleaseStatusSelectorComponent } from './release/release-status-selector.component';
import { MiniDefectAgingComponent } from './defect-aging/mini-defect-aging.component';
import { MiniDefectPriorityComponent } from './defect-priority/mini-defect-priority.component';
import { ReleaseDefectPotentialTimelineComponent } from './release-defect-potential/release-defect-potential-timeline.component';
import { DefectDensityTimelineComponent } from './defect-density/defect-density-timeline.component';
import { DefectRemovalRateTimelineComponent } from './defect-removal-rate/defect-removal-rate-timeline.component';
import { DefectMTTRTimelineComponent } from './defect-mttr/defect-mttr-timeline.component';
import { EpicPointBurnupComponent } from './epic/epic-point-burnup.component';
import { EpicCostBurnupComponent } from './epic/epic-cost-burnup.component';
import { EpicStatusComponent } from './epic/epic-status.component';
import { EpicDefectPotentialTimeSeriesComponent } from './epic-defect-potential/epic-defect-potential-time-series.component';
import { MiniEpicSizedComponent } from './epic/mini-epic-sized.component';
import { MiniSprintPointsCarriedOverComponent } from './points-carried-over/mini-sprint-points-carried-over.component'; 
import { MiniBacklogCountComponent } from './backlog-count/mini-backlog-count.component'; 
import { MiniBacklogDepthComponent } from './backlog-depth/mini-backlog-depth.component'; 
import { SprintAgileStoryComponent } from './sprint-agile-story/sprint-agile-story.component';
import { SprintAcceptanceCriteriaComponent } from './sprint-acceptance-criteria/sprint-acceptance-criteria.component';
import { SprintVelocityComponent } from './velocity/sprint-velocity.component';
import { GoalProgressComponent } from './goal/goal-progress.component';  
import { StoryPointComponent } from './story-point/story-point.component';
import { SprintScopeComponent } from './sprint-scope/sprint-scope.component';
import { SprintCommitmentComponent } from './sprint-commitment/sprint-commitment.component';
import { SprintPointsInjectedComponent } from './points-injected/sprint-points-injected.component';
import { SprintPointsCarriedOverComponent } from './points-carried-over/sprint-points-carried-over.component';
import { CostPerPointTimelineComponent } from './cost-per-point/cost-per-point-timeline.component';
import { TeamAttainmentTimelineComponent } from './team-attainment/team-attainment-timeline.component';
import { TeamCommitmentTimelineComponent } from './team-commitment/team-commitment-timeline.component';
import { TopScrumMaturityTimelineComponent } from './top-scrum-maturity/top-scrum-maturity-timeline.component';
import { ScrumMaturityClosedSprintsTimelineComponent } from './scrum-maturity-closed-sprints/scrum-maturity-closed-sprints-timeline.component';
import { QualityStoriesTimelineComponent } from './quality-stories/quality-stories-timeline.component';
import { ZoomGraphComponent } from './zoom-graph/zoom-graph.component';
import { ZoomBubbleGraphComponent } from './zoom-graph/zoom-bubble-graph.component';
import { SprintBacklogCountTimelineComponent } from './sprint-backlog-count/sprint-backlog-count-timeline.component';
import { PointsPerStoryTimelineComponent } from './points-per-story/points-per-story-timeline.component';
import { PointDistributionTimelineComponent } from './point-distribution/point-distribution-timeline.component';
import { OrgScrumMaturityComponent } from './org-scrum-maturity/org-scrum-maturity.component';
import { OrgScrumMaturityTimelineComponent } from './org-scrum-maturity/org-scrum-maturity-timeline.component';
import { OrgCostPerPointTimelineComponent } from './org-cost-per-point/org-cost-per-point-timeline.component';
import { OrgCostPerPointAverageTimelineComponent } from './org-cost-per-point/org-cost-per-point-average-timeline.component';
import { OrgDefectDensityTimelineComponent } from './org-defect/org-defect-density-timeline.component';
import { OrgOpenDefectCountTimelineComponent } from './org-defect/org-open-defect-count-timeline.component';
import { OrgVelocityTimelineComponent } from './org-velocity/org-velocity-timeline.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SourceSystemSliderComponent } from './source-system-slider/source-system-slider.component';

import { ProjectStarRatingComponent } from './project-star-rating/project-star-rating.component';
import { LLAutoCompleteComponent } from './ll-auto-complete/ll-auto-complete.component';
import { SliderMoveDirective } from './slider-move-directive/slider-move-directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PrimeNgModule,
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  declarations: [
    ColorboxComponent,
    PriorityRankComponent,
    MiniVelocityOverviewComponent,
    MiniDefectCountOverviewComponent,
    MiniAppAlertCountOverviewComponent,
    MiniAppAlertSeverityOverviewComponent,
    MiniAppAlertCountSeverityOverviewComponent,
    ProjectSprintHistoryComponent,
    SprintHistoryComponent,
    MiniSprintCommitmentComponent,
    SprintCommitmentTimelineComponent,
    SprintBurndownTimelineComponent,    
    PointsInjectedComponent,
    PointsInjectedTimelineComponent,
    PointsCarriedOverTimelineComponent,    
    CommitmentAttainmentTimelineComponent,
    MiniSprintPointsCarriedOverComponent,  
    ReleasePointBurnupComponent,
    ReleaseCostBurnupComponent,
    ReleasePointStatusComponent,
    ReleasePointPlaceholderStatusComponent,
    ReleaseStatusSelectorComponent,
    ReleaseDefectPotentialTimelineComponent,
    MiniDefectAgingComponent,
    MiniDefectPriorityComponent,
    DefectDensityTimelineComponent,  
    DefectRemovalRateTimelineComponent, 
    DefectMTTRTimelineComponent,     
    EpicPointBurnupComponent,
    EpicCostBurnupComponent,
    EpicStatusComponent,
    EpicDefectPotentialTimeSeriesComponent,
    MiniEpicSizedComponent,
    MiniBacklogCountComponent,
    MiniBacklogDepthComponent,
    SprintAgileStoryComponent,
    SprintAcceptanceCriteriaComponent,
    GoalProgressComponent,
    StoryPointComponent,
    SprintScopeComponent,
    SprintCommitmentComponent,
    SprintPointsInjectedComponent,
    SprintPointsCarriedOverComponent,
    SprintVelocityComponent,
    CostPerPointTimelineComponent,  
    TeamAttainmentTimelineComponent,
    TeamCommitmentTimelineComponent,
    TopScrumMaturityTimelineComponent,  
    ScrumMaturityClosedSprintsTimelineComponent,
    QualityStoriesTimelineComponent,
    ProgressBarComponent,
    SourceSystemSliderComponent,    
    SprintBacklogCountTimelineComponent,  
    PointsPerStoryTimelineComponent,  
    PointDistributionTimelineComponent,        
    ZoomGraphComponent,
    ZoomBubbleGraphComponent,
    OrgScrumMaturityComponent,
    OrgScrumMaturityTimelineComponent,
    OrgCostPerPointTimelineComponent,
    OrgCostPerPointAverageTimelineComponent,
    OrgDefectDensityTimelineComponent,
    OrgOpenDefectCountTimelineComponent,
    OrgVelocityTimelineComponent,

    ProjectStarRatingComponent,
    LLAutoCompleteComponent,
    SliderMoveDirective
  ],
  exports: [
    ColorboxComponent,
    PriorityRankComponent,
    MiniVelocityOverviewComponent,
    MiniDefectCountOverviewComponent,
    DefectDensityTimelineComponent, 
    DefectRemovalRateTimelineComponent,  
    DefectMTTRTimelineComponent,     
    MiniAppAlertCountOverviewComponent,
    MiniAppAlertSeverityOverviewComponent,
    MiniAppAlertCountSeverityOverviewComponent,
    ProjectSprintHistoryComponent,
    SprintHistoryComponent,
    MiniSprintCommitmentComponent,
    SprintCommitmentTimelineComponent,
    SprintBurndownTimelineComponent,
    PointsInjectedComponent,
    PointsInjectedTimelineComponent,
    PointsCarriedOverTimelineComponent,    
    CommitmentAttainmentTimelineComponent,
    MiniSprintPointsCarriedOverComponent,  
    ReleasePointBurnupComponent,
    ReleaseCostBurnupComponent,
    ReleasePointStatusComponent,
    ReleasePointPlaceholderStatusComponent,
    ReleaseStatusSelectorComponent,
    ReleaseDefectPotentialTimelineComponent,    
    MiniDefectAgingComponent,
    MiniDefectPriorityComponent,
    EpicPointBurnupComponent,
    EpicCostBurnupComponent,
    EpicStatusComponent,
    EpicDefectPotentialTimeSeriesComponent,
    MiniEpicSizedComponent,
    MiniBacklogCountComponent,
    MiniBacklogDepthComponent,
    SprintAgileStoryComponent,
    SprintAcceptanceCriteriaComponent,
    GoalProgressComponent, 
    StoryPointComponent,
    SprintScopeComponent,
    SprintCommitmentComponent,
    SprintPointsInjectedComponent,
    SprintPointsCarriedOverComponent,
    SprintVelocityComponent,
    CostPerPointTimelineComponent,   
    TeamAttainmentTimelineComponent,
    TeamCommitmentTimelineComponent,
    TopScrumMaturityTimelineComponent, 
    ScrumMaturityClosedSprintsTimelineComponent,
    QualityStoriesTimelineComponent,
    ProgressBarComponent,
    SourceSystemSliderComponent,    
    SprintBacklogCountTimelineComponent,    
    PointsPerStoryTimelineComponent, 
    PointDistributionTimelineComponent,       
    ZoomGraphComponent,
    ZoomBubbleGraphComponent, 
    OrgScrumMaturityComponent,
    OrgScrumMaturityTimelineComponent,
    OrgCostPerPointTimelineComponent,
    OrgCostPerPointAverageTimelineComponent,
    OrgDefectDensityTimelineComponent,
    OrgOpenDefectCountTimelineComponent,
    OrgVelocityTimelineComponent,
    
    ProjectStarRatingComponent,
    LLAutoCompleteComponent,
    SliderMoveDirective
  ],
  providers: [
  ]
})

export class UIModule {}
