import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { BaseSprintTimeSeriesComponent } from '../../base/component/base-sprint-time-series.component';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { Content } from '../../content/content';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'points-carried-over-timeline',
  templateUrl: './points-carried-over-timeline.component.html',
  styleUrls: ['./points-carried-over-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class PointsCarriedOverTimelineComponent extends BaseSprintTimeSeriesComponent implements OnInit, OnChanges 
{
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    timeSeries : TimeSeries;

    pointsCarriedOverAverageFact : ProjectFact;
    pointsCarriedOverTrendFact : ProjectFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService : TimeSeriesService, 
                 private projectFactService : ProjectFactService) 
    { 
        super(router); 
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Points Carried Into Sprint By Sprint`;
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void 
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTPOINTSCARRIEDOVER, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.POINTSCARRIEDOVERAVERAGE)
            .subscribe(result => this.processPointsCarriedOverAverage(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.project, ProjectFact.POINTSCARRIEDOVERTREND)
            .subscribe(result => this.processPointsCarriedOverTrend(result),
                       error => this.handleError404Okay(error) );

    }

    private processPointsCarriedOverAverage(result : ProjectFact) : void
    {
        if (_.isEqual(this.pointsCarriedOverAverageFact, result) == false)
            this.pointsCarriedOverAverageFact = result;
    }

    private processPointsCarriedOverTrend(result : ProjectFact) : void
    {
        if (_.isEqual(this.pointsCarriedOverTrendFact, result) == false)
            this.pointsCarriedOverTrendFact = result;
    }

    public processTimeSeries(result: TimeSeries)
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;       
            
            this.data = {
                labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
                datasets: [
                    {
                        type: 'line',
                        label: 'Points',
                        backgroundColor: this.REDTRANSPARENT,
                        pointHoverBackgroundColor: this.RED,
                        borderColor: this.RED,
                        borderWidth: 2,
                        fill:true,
                        tension: .5,
                        data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("pointsCarriedOver"); } )
                    }
                ]
            }
        }
    }

    public get STORYCARRYOVER() : string
    {
        return Content.STORYCARRYOVER;
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }
}