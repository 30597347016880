<div class="card card-w-title grid col-12">
    <div *ngIf="activeReleaseList && activeReleaseList.length <= 0">
        <h3 class="col-12 sm:col-12 md:col-12">No active releases for project</h3>
    </div>

    <div class="col-12" *ngIf="activeReleaseList && activeReleaseList.length > 0">
        <div *ngFor="let release of activeReleaseList">
            <release-heading [release]="release" [showButton]="true"></release-heading>
            <div style="margin-bottom:20px"></div>
        </div>
    </div>

    <h3 class="col-12 sm:col-12 md:col-12">Inactive Releases</h3>
    <release-list class="col-12 sm:col-12 md:col-12" *ngIf="projectId" [releaseList]="inactiveReleaseList" [lazy]="true" 
        [rows]="maxRows" [total]="inactiveReleaseList.total" [sortField]="inactiveSortField" [sortOrder]="inactiveSortOrder"
        (onLazyLoad)="onLazyLoadInactive($event)" [paginator]="true" (onSortClicked)="onSortClickedInactive($event)">
    </release-list>

    <h3 class="col-12 sm:col-12 md:col-12">Future Releases</h3>
    <release-list class="col-12 sm:col-12 md:col-12" *ngIf="projectId" [releaseList]="futureReleaseList" [lazy]="true" 
        [rows]="maxRows" [total]="futureReleaseList.total" [sortField]="futureSortField" [sortOrder]="futureSortOrder"
        (onLazyLoad)="onLazyLoadFuture($event)" [paginator]="true" (onSortClicked)="onSortClickedFuture($event)">
    </release-list>

    <h3 class="col-12 sm:col-12 md:col-12">Completed Releases</h3>
    <release-list class="col-12 sm:col-12 md:col-12" *ngIf="projectId" [releaseList]="completedReleaseList" [lazy]="true" 
        [rows]="maxRows" [total]="completedReleaseList.total" [sortField]="completedSortField" [sortOrder]="completedSortOrder"
        (onLazyLoad)="onLazyLoadCompleted($event)" [paginator]="true" (onSortClicked)="onSortClickedCompleted($event)">
    </release-list>
</div>
