<div class="exception-body error-page">
    <div class="exception-type">
        <img src="../../public/images/error.svg">
    </div>
    
    <div class="card exception-panel">
        <i class="material-icons">&#xE000;</i>
        <h1>Exception Occured</h1>
        <div class="exception-detail">Please contact system administrator</div>
        <a href="/" class="p-button secondary button-width">
            <span class="p-button-label">Homepage</span>
        </a>
    </div>
</div>
