import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

export type PaneType = 'left' | 'right';

@Component({
  selector: 'source-system-slider',
  styleUrls: [ './source-system-slider.component.scss' ],
  templateUrl: './source-system-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class SourceSystemSliderComponent 
{
    @Input() 
    activePane: PaneType = 'left';
}