import { Injectable }    from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';

import { OrgSourceSystem } from './org-source-system';
import { Project } from '../project/project';

@Injectable()
export class OrgSourceSystemService extends BaseHttpService
{
    orgSourceSystemUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.orgSourceSystemUrl = this.config.baseUrl + '/rest/orgsourcesystem';
    }

    public retrieveAll() : Observable<OrgSourceSystem[]>
    {
        let url : string = this.orgSourceSystemUrl + "/list";
        return super.baseRetrieveList(OrgSourceSystem, url);
    }

    public retrieve(orgSourceSystemId : string) : Observable<OrgSourceSystem>
    {
        let url : string = this.orgSourceSystemUrl + "/" + orgSourceSystemId;
        return super.baseRetrieveObject(OrgSourceSystem, url);
    }

    public retrieveByProjectId(projectId : string) : Observable<OrgSourceSystem>
    {
        let url : string = this.orgSourceSystemUrl + "/project/" + projectId;
        return super.baseRetrieveObject(OrgSourceSystem, url);
    }

    public retrieveByReleaseId(releaseId : string) : Observable<OrgSourceSystem>
    {
        let url : string = this.orgSourceSystemUrl + "/release/" + releaseId;
        return super.baseRetrieveObject(OrgSourceSystem, url);
    }

    public retrieveByEpicId(epicId : string) : Observable<OrgSourceSystem>
    {
        let url : string = this.orgSourceSystemUrl + "/epic/" + epicId;
        return super.baseRetrieveObject(OrgSourceSystem, url);
    }

    public retrieveBySprintId(sprintId : string) : Observable<OrgSourceSystem>
    {
        let url : string = this.orgSourceSystemUrl + "/sprint/" + sprintId;
        return super.baseRetrieveObject(OrgSourceSystem, url);
    }
    
    public create(orgSourceSystem : OrgSourceSystem) : Observable<OrgSourceSystem>
    {
        return super.baseCreateObject(OrgSourceSystem, this.orgSourceSystemUrl, orgSourceSystem);    
    }

    public update(orgSourceSystem : OrgSourceSystem) : Observable<OrgSourceSystem>
    {
        return super.baseUpdateObject(OrgSourceSystem, this.orgSourceSystemUrl, orgSourceSystem);    
    }

    public delete(orgSourceSystem : OrgSourceSystem) : Observable<any>
    {
        let deleteUrl = this.orgSourceSystemUrl + "?orgSourceSystemId=" + orgSourceSystem.orgSourceSystemId;
        return super.baseDelete(deleteUrl, this.processDeleteResponse, this.handleError);
    }

    private processDeleteResponse(response: any) : any
    {
        return response;
    }

    private handleError(error: any): any 
    {
        return error.message || error;
    }
}