import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Release } from './release';
import { ReleaseService } from './release.service';

@Component({
  selector: 'release-link-list',
  templateUrl: './release-link-list.component.html',
  styleUrls: ['./release-link-list.component.scss' ],
  providers: [  ]
})

export class ReleaseLinkListComponent implements OnInit
{ 
    @Input()
    releases : Release[];

    @Input()
    displayVertical : boolean = false;

    constructor() 
    { 
    }

    ngOnInit() : void
    {
    }
}
