<ng-container>
    <div *ngIf="root && item.visible !== false">
        <span class="layout-menuitem-text">{{item.label}}</span>
    </div>
    <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="(!item.routerLink || item.items) && item.visible !== false" (keydown.enter)="itemClick($event)"
       [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple
       [pTooltip]="item.label" [tooltipDisabled]="active || !(root && dashboard.isSlim() && !dashboard.isMobile())">
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass" *ngIf="item.badge && !root">{{item.badge}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    </a>
    <a (click)="itemClick($event)" *ngIf="(item.routerLink && !item.items) && item.visible !== false"
       [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}"
       [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple
       [pTooltip]="item.label" [tooltipDisabled]="active || !(root && dashboard.isSlim() && !dashboard.isMobile())">
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass" *ngIf="item.badge && !root">{{item.badge}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    </a>
    <!-- FIXED PDC = This line replaced with the single line below to ensure
                     menu setup correctly.
    <ul *ngIf="((item.items && root) || (item.items && active)) && item.visible !== false" [@children]="root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">
    -->
    <ul *ngIf="item.items && item.visible != false" [@children]="root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li dashboard-submenu [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
    </ul>
</ng-container>

<!--
<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
    <li [ngClass]="{'active-menuitem': isActive(i)}" *ngIf="child.visible === false ? false : true">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="!child.routerLink" [attr.tabindex]="!visible ? '-1' : null">
            <i class="material-icons">{{child.icon}}</i>
            <span class="truncate">{{child.label}}</span>
            <i class="material-icons" *ngIf="child.items">keyboard_arrow_down</i>
        </a>

        <a (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="child.routerLink"
            [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null">
            <i class="material-icons">{{child.icon}}</i>
            <span class="truncate">{{child.label}}</span>
            <i class="material-icons" *ngIf="child.items">keyboard_arrow_down</i>
        </a>
        <ul dashboard-submenu [parentMenu]="thisObject()" [item]="child" *ngIf="child.items" [@children]="isActive(i) ? 'visible' : 'hidden'" [visible]="isActive(i)" [reset]="reset"></ul>
    </li>
</ng-template>
-->