import { BaseValueObject } from '../../base/value-object/base-value-object';

export class ProjectFilter extends BaseValueObject
{
    projectFilterId : string;
    orgSourceSystemId : string;
    sourceSystemKey : string;

    public initialize (object: ProjectFilter) : void
    {
        super.initialize(object);

        this.projectFilterId = object.projectFilterId;
        this.orgSourceSystemId = object.orgSourceSystemId;
        this.sourceSystemKey = object.sourceSystemKey;
    }
}