import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { UserStoryFact } from '../user-story-fact/user-story-fact';

export class UserStory extends BaseBacklogItemObject
{
    public userStoryId : string;
    public userStoryFactList : UserStoryFact[]

    public initialize(userStory : UserStory) : void
    {
        if (userStory == null)
            return;
        
        super.initialize(userStory);

        this.userStoryId = userStory.userStoryId;
        this.objectType = UserStory.USERSTORY;
        
        if (userStory.userStoryFactList != null)
            this.userStoryFactList = this.initializeList(UserStoryFact, userStory.userStoryFactList);
        else   
            this.userStoryFactList = undefined;

        this.backlogItemFactList = this.userStoryFactList;
    }

    public getFact(factName : string) : UserStoryFact
    {
        let result : UserStoryFact = null;

        if (this.userStoryFactList != null)
        {
            for (let userStoryFact of this.userStoryFactList)
            {
                if (userStoryFact.name == factName)
                {
                    result = userStoryFact;
                    break;
                }
            }
        }

        return result;
    }
}