import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';
import { Project } from '../../project/project';

import * as moment from 'moment';

@Component({
  selector: 'mini-defect-aging',
  templateUrl: './mini-defect-aging.component.html',
  styleUrls: ['./mini-defect-aging.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService ]
})

export class MiniDefectAgingComponent extends BaseUIComponent implements OnInit 
{ 
    @Input()
    project : Project;

    avgDefectDaysOutstanding : ProjectFact;
    defectDaysOutstanding : ProjectFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private projectFactService: ProjectFactService) 
    { 
        super(router);

        this.options = {
            indexAxis: 'y',
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Defect Aging'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                },
            },
            tooltips: { position : 'nearest'}
        }
    }

    public ngOnInit() : void
    {
        this.projectFactService.retrieve(this.project, ProjectFact.AVGDEFECTDAYSOUTSTANDING)
            .subscribe(result => this.processAvgDefectDaysOutstanding(result),
                       error => this.handleError (error) );
        this.projectFactService.retrieve(this.project, ProjectFact.DEFECTDAYSOUTSTANDING)
            .subscribe(result => this.processDefectDaysOutstanding(result),
                       error => this.handleError (error) );
    }

    private processAvgDefectDaysOutstanding(result : ProjectFact) : void
    {
        this.avgDefectDaysOutstanding = result;
    }

    private processDefectDaysOutstanding(result : ProjectFact) : void
    {
        this.defectDaysOutstanding = result;
        this.buildGraph();
    }

    private buildGraph()
    {
        this.data = {
            labels : [ 'Defect Age' ],
            datasets: [  
                {
                    type:'bar',
                    label: 'Under 30 days',
                    backgroundColor: this.GREENTRANSPARENT,
                    hoverBackgroundColor: this.GREEN,
                    borderColor: this.GREEN,
                    borderWidth: 2,
                    data: [ this.defectDaysOutstanding.getFactMap()[0].value]
                },
                {
                    type:'bar',
                    label: '30 - 60 days',
                    backgroundColor: this.YELLOWTRANSPARENT,
                    hoverBackgroundColor: this.YELLOW,
                    borderColor: this.YELLOW,
                    borderWidth: 2,
                    data: [ this.defectDaysOutstanding.getFactMap()[1].value ]
                },
                {
                    type:'bar',
                    label: 'Over 60 days',
                    backgroundColor: this.REDTRANSPARENT,
                    hoverBackgroundColor: this.RED,
                    borderColor: this.RED,
                    borderWidth: 2,
                    data: [ this.defectDaysOutstanding.getFactMap()[2].value ]
                }                             
            ]
        }
    }
}
