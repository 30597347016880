import { BaseFactObject } from '../base/value-object/base-fact-object';

export class ProjectFact extends BaseFactObject
{
	// PROJECT FACT CONSTANTS
	public static readonly NUMBEROFSPRINTS : string = "numberOfSprints";
	public static readonly AVERAGEVELOCITY : string = "projectAverageVelocity";	// three sprint average.
	public static readonly ALLSPRINTSAVGVELOCITY : string = "allSprintsAvgVelocity";	// average across all sprints.
	public static readonly ACTIVERELEASES : string = "activeReleases";
	public static readonly VELOCITYTREND : string = "projectVelocityTrend";
	public static readonly OPENDEFECTTREND : string = "projectOpenDefectTrend";
	public static readonly OPENDEFECTCOUNT : string = "projectOpenDefectCount";
	public static readonly CLOSEDDEFECTCOUNT : string = "projectClosedDefectCount";
	public static readonly TOTALDEFECTCOUNT : string = "projectTotalDefectCount";
	public static readonly DEFECTDAYSOUTSTANDING : string = "projectDefectDaysOutstanding";
	public static readonly AVGDEFECTDAYSOUTSTANDING : string = "avgProjectDefectDaysOutstanding";
	public static readonly DEFECTPRIORITYTOTALS : string = "projectDefectPriorityTotals";
	public static readonly BACKLOGDEPTH : string = "backlogBacklogDepth";
	public static readonly BACKLOGSTORIESSIZED : string	= "backlogStoriesSized";
	public static readonly BACKLOGSTORIESUNSIZED : string = "backlogStoriesUnsized";
	public static readonly TOTALSTORYCOUNT : string	= "projectTotalStoryCount";
	public static readonly OPENSTORYCOUNT : string = "projectOpenStoryCount";
	public static readonly CLOSEDSTORYCOUNT	: string = "projectClosedStoryCount";
	public static readonly TOTALCHORECOUNT : string	= "projectTotalChoreCount";
	public static readonly OPENCHORECOUNT : string = "projectOpenChoreCount";
	public static readonly CLOSEDCHORECOUNT : string = "projectClosedChoreCount";
	public static readonly TOTALSPIKECOUNT : string = "projectTotalSpikeCount";
	public static readonly OPENSPIKECOUNT : string = "projectOpenSpikeCount";
	public static readonly CLOSEDSPIKECOUNT : string = "projectClosedSpikeCount";
	public static readonly POINTSINJECTEDTREND : string = "projectPointsInjectedTrend";
	public static readonly POINTSINJECTEDAVERAGE : string = "projectPointsInjectedAverage";
	public static readonly POINTSCARRIEDOVERTREND : string = "projectPointsCarriedOverTrend";
	public static readonly POINTSCARRIEDOVERAVERAGE : string = "projectPointsCarriedOverAverage";
	public static readonly COMMITMENTATTAINMENTTREND : string = "projectCommitmentAttainmentTrend";
	public static readonly COMMITMENTATTAINMENTAVERAGE : string = "projectCommitmentAttainmentAverage";
	public static readonly SCRUMMATURITY : string = "projectScrumMaturity";
	public static readonly ACTIVERELEASESSIZED : string = "projectActiveReleasedSized";
	public static readonly PLACEHOLDERVELOCITY : string = "projectPlaceholderVelocity";
	public static readonly SPRINTSEXIST : string = "projectSprintsExist";
	public static readonly UPCOMINGBACKLOGITEMS : string = "projectUpcomingBacklogItems";
	public static readonly COSTPERPOINT : string = "projectCostPerPoint";
	public static readonly TEAMCOSTSEXIST : string = "projectTeamCostsExist";
	public static readonly PASTFUTURESPRINTS : string = "projectPastFutureSprints";
	public static readonly EXPECTEDSPRINTBEGINDATE : string = "projectExpectedSprintBeginDate";
	public static readonly SPRINTNOTSTARTEDASEXPECTED : string = "projectSprintNotStartedAsExpected";
	public static readonly UPCOMINGBACKLOGITEMSNOTREADY : string = "projectUpcomingBacklogItemsNotReady";
	public static readonly DEFECTDENSITY : string = "projectDefectDensity";
	public static readonly DEFECTREMOVALRATE : string = "projectDefectRemovalRate";
	public static readonly TOTALDEPENDENCYCOUNT : string = "projectTotalDependencyCount";
	public static readonly OPENDEPENDENCYCOUNT : string = "projectOpenDependencyCount";
	public static readonly CLOSEDDEPENDENCYCOUNT : string = "projectClosedDependencyCount";
	public static readonly TOPSCRUMATURITY : string	= "projectTopScrumMaturity";
	public static readonly TOPSCRUMMATURITYAVERAGE : string = "projectTopScrumMaturityAverage";
	public static readonly TOPSCRUMMATURITYTREND : string = "projectTopScrumMaturityTrend";
	public static readonly SCRUMMATURITYAVERAGECLOSEDSPRINTS : string = "projectScrumMaturityAverageClosedSprints";
	public static readonly SCRUMMATURITYTRENDCLOSEDSPRINTS : string = "projectScrumMaturityTrendClosedSprints";
	public static readonly PERCENTQUALITYSTORIESAVERAGE : string = "projectPercentQualityStoriesAverage";
	public static readonly PERCENTQUALITYSTORIESTREND : string = "projectPercentQualityStoriesTrend";
	public static readonly PERCENTSTORIESAVERAGE : string = "projectPercentStoriesAverage";
	public static readonly AVERAGEPOINTSPERSTORY : string = "projectAveragePointsPerStory";
	public static readonly AVERAGEPOINTSPERSTORYAVERAGE	: string = "projectAveragePointsPerStoryAverage";	// three sprint average of the average.
	public static readonly AVERAGEPOINTSPERSTORYTREND : string = "projectAveragePointsPerStoryTrend";
	public static readonly VELOCITYUNRELIABLE : string = "projectVelocityUnreliable";

	// DAYS OUTSTANDING KEYS
	public static readonly UNDER30 : string = "under30";
	public static readonly FROM30TO60 : string = "from30to60";
	public static readonly OVER60 : string = "over60";

    projectFactId : string;
	projectId : string;

    public initialize(object : ProjectFact) : void
    {
        super.initialize(object);
        this.projectFactId = object.projectFactId;
		this.projectId = object.projectId;
    }
}