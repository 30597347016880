import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { ReleaseBurnup } from './release-burnup';
import { Release } from './release';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class ReleaseService extends BaseHttpService
{
    releaseUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.releaseUrl = this.config.baseUrl + '/rest/release';
    }

    public retrieve(releaseId : string ) : Observable<Release>
    {
        let releaseDetailUrl : string = this.releaseUrl + "?releaseId=" + releaseId;

        return super.baseRetrieveObject(Release, releaseDetailUrl);        
    }

    public retrieveActive(projectId : string) : Observable<Release[]>
    {
        let activeReleasesUrl = this.releaseUrl + "/active?projectId=" + projectId; 

        return super.baseRetrieveList(Release, activeReleasesUrl);
    }

    public retrieveFuture(projectId : string, startAt : number, maxResult : number, sortBy : string, direction : number) : Observable<ValueObjectList<Release>>
    {
        let futureReleasesUrl = `${this.releaseUrl}/future/${projectId}/subset?sortBy=${sortBy}&direction=${direction}`; 
        return super.baseRetrieveSubset(Release, futureReleasesUrl, startAt, maxResult);
    }

    public retrieveCompleted(projectId : string, startAt: number, maxResult : number, sortBy : string, direction : number) : Observable<ValueObjectList<Release>>
    {
        let completedReleasesUrl = `${this.releaseUrl}/completed/${projectId}/subset?sortBy=${sortBy}&direction=${direction}`;
        return super.baseRetrieveSubset(Release, completedReleasesUrl, startAt, maxResult);
    }

    public retrieveInactive(projectId : string, startAt: number, maxResult : number, sortBy : string, direction : number) : Observable<ValueObjectList<Release>>
    {
        let inactiveReleasesUrl = `${this.releaseUrl}/inactive/${projectId}/subset?sortBy=${sortBy}&direction=${direction}`;
        return super.baseRetrieveSubset(Release, inactiveReleasesUrl, startAt, maxResult);
    }
    
}