<p-card class="sprint-points-injected" (click)="onclick($event)">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Injected</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="sprintScopeFact && pointsInjectedFact && beginningSprintFact && beginningSprintFact.getFactBoolean() == false">
                    <span class="title-text" tooltipPosition="left" pTooltip="% Injected">{{ percentInjected() | ceiling }}%</span>
                </span>
                <span *ngIf="pointsInjectedFact">
                    <a *ngIf="pointsInjectedFact.getFactNumber() > 0" [routerLink]="['/dashboard/content-detail/key', ADDEDTOSPRINT]">
                        <i pTooltip="Unplanned Work" tooltipPosition="left" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div *ngIf="beginningSprintFact" class="overview-footer">
            <div *ngIf="sprintScopeFact && pointsInjectedFact && beginningSprintFact.getFactBoolean() == false">
                <p-chart type="doughnut" [data]="data" [options]="options" [plugins]="plugins" [height]="'150px'"></p-chart>
            </div>    
        </div>
        <div *ngIf="beginningSprintFact" class="grace-period-text">
            <span *ngIf="beginningSprintFact.getFactBoolean() == true">Graph will appear when grace period is over.</span>
        </div>        
    </span>
</p-card>