import { PipeTransform, Pipe } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform 
{
    public transform(dateObject: any, relativeTime: any): string 
    {
        let result : string = "unsupported date";

        // Standardize on central time.
        if (dateObject instanceof Date)
            result = moment(dateObject).from(relativeTime);

        if (typeof(dateObject) == "string")
            result = moment(dateObject).from(relativeTime);
        
        return result;
    }
}