import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';

import * as moment from 'moment';

@Component({
  selector: 'project-sprint-history',
  templateUrl: './project-sprint-history.component.html',
  styleUrls: ['./project-sprint-history.component.scss' ],
  providers: [ TimeSeriesService, ProjectFactService ]
})

export class ProjectSprintHistoryComponent extends BaseComponent implements OnInit { 

    @Input()
    project : Project;
    
    timeSeries : TimeSeries;
    numberOfSprintsFact : ProjectFact;
    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private projectFactService : ProjectFactService) 
    { 
        super(router);

        this.options = {
            // scales: { yAxes: [{ beginAtZero:true }], 
            //           xAxes: [{ display: false }] 
            //         },
            responsive : true,
            maintainAspectRatio : false,
            title: {
                display: false,
                text: 'Sprint History'
            }, 
            legend: {
                display: false,
                position: 'bottom'
            },

            onClick: (event, array) => { this.graphClickEvent(event, array) }
        }
    }

    ngOnInit() : void
    {
        this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTVELOCITY, 10)
            .subscribe(result => this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
        
        this.projectFactService.retrieve(this.project, ProjectFact.NUMBEROFSPRINTS)
            .subscribe(result => this.processNumberOfSprintsFact(result),
                       error => this.handleError404Okay(error) );
    }

    processTimeSeries(result: TimeSeries)
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Commitment',
//                    backgroundColor: '#FF9B00',
                    backgroundColor: 'rgba(255, 155, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 155, 0, 1)',
                    borderColor: 'rgba(255, 155, 0, 1)',
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintCommitment"); } )
                },
                {
                    type:'bar',
                    label: 'Velocity',
//                    backgroundColor: '#42A5F5',
                    backgroundColor: 'rgba(66, 165, 245, .40)',
                    hoverBackgroundColor: 'rgba(66, 165, 245, 1)',
                    borderColor: 'rgba(66, 165, 245, 1)',
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("velocity"); } )
                },
                {
                    type:'line',
                    label: 'Three Sprint Average',
                    data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("averageVelocity") ); } )
                }
            ]
        }
    }

    private processNumberOfSprintsFact(result : ProjectFact) : void
    {
        this.numberOfSprintsFact = result;
    }

        public navigate(timeSeriesListIndex : number) : void
    {
        let sprintId : string = this.timeSeries.timeSeriesList[timeSeriesListIndex].sprintId;
        this.router.navigate(['/dashboard/sprint-detail', sprintId]);            
    }

    public graphClickEvent(event : MouseEvent, array: any[]) : void 
    {
        if (array.length > 0)
        {
            let index : number = array[0]._index;
            this.navigate(index);            
        }
    }


}