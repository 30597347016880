import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router } from '@angular/router';

import { Epic } from './epic';

@Component({
  selector: 'epic-list',
  templateUrl: './epic-list.component.html',
  styleUrls: ['./epic-list.component.scss' ],
  providers: [  ]
})

export class EpicListComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    rows : number = 0;

    @Input()
    total : number = 0;

    @Input()
    paginator : boolean = false;

    @Input()
    lazy : boolean = false;

    selectedEpic : Epic;

    @Input()
    epicList : Epic[];

//    @Input()
//    header : string;

    @Input()
    sortOrder : number;

    @Input()
    sortField : string = "name";

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    @Output()
    onSortClicked = new EventEmitter<any>();

    constructor(protected router : Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void
    {
    }

    public loadData(event : any) 
    {
        //event.first = First row offset
        //event.rows = Number of rows per page
        //add more records to the cars array
        this.onLazyLoad.emit(event);
    }

    public customSort(event) : void
    {
        this.onSortClicked.emit(event);
    }
}
