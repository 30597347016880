import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.scss' ],
  encapsulation : ViewEncapsulation.None,
  providers: [ ]
})

export class ErrorComponent extends BaseComponent implements OnInit
{ 
  constructor(protected router:Router)
  { 
    super(router);
  }

  public ngOnInit() : void
  {
  }

}
