<div *ngIf="userAccount" [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':dashboard.inlineMenuActive[key]}" [ngStyle]="style" [class]="styleClass">
    <a class="layout-inline-menu-action flex flex-row align-items-center" [ngClass]="dashboard.isHorizontal() ? 'p-3 lg:col-1 py-3' : 'p-3'" (click)="onClick($event)">
        <div class="profile-image"></div>
        <span class="flex flex-column" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
            <span class="profile-name">{{userAccount.firstName}} {{userAccount.lastName}}</span>
            <div *ngIf="userAccount && userAccount.lastLoginDate" class="last-login-date">Last Login: {{userAccount.lastLoginDate | dateFormat }}</div>
        </span>
        <i class="layout-inline-menu-icon pi pi-angle-down" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}"></i>
    </a>

    <ul class="layout-inline-menu-action-panel" [@menu]="dashboard.inlineMenuActive[key] ? isHorizontalActive() ? 'visible' : 'visibleAnimated' : isHorizontalActive() ? 'hidden' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item" pTooltip="Profile" [tooltipDisabled]="isTooltipDisabled">
            <a [routerLink]="['profile']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
                <i class="material-icons">person</i>
                <span>Profile</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" pTooltip="Alert Preferences" [tooltipDisabled]="isTooltipDisabled">
            <a [routerLink]="['user-alert-preference']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
                <i class="material-icons">notifications</i>
                <span>Alert Preferences</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled">
            <a [routerLink]="['/logout']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
                <i class="material-icons">power_settings_new</i>
                <span>Logout</span>
            </a>
        </li>
    </ul>
</div>
<!--
<div class="profile" [ngClass]="{'profile-expanded':active}">
    <div class="profile-image"></div>
    <a href="#" (click)="onClick($event)">
        <span *ngIf="userAccount" class="profile-name">{{userAccount.firstName}} {{userAccount.lastName}}</span>
        <i class="material-icons">keyboard_arrow_down</i>
    </a>
    <div *ngIf="userAccount && userAccount.lastLoginDate" class="last-login-date">Last Login: {{userAccount.lastLoginDate | dateFormat }}</div>
</div>

<ul class="ultima-menu profile-menu" [@menu]="active ? 'visible' : 'hidden'">
    <li role="menuitem">
        <a [routerLink]="['profile']" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
            <i class="material-icons">person</i>
            <span>Profile</span>
        </a>
    </li>
    <li role="menuitem">
        <a [routerLink]="['user-alert-preference']" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
            <i class="material-icons">notifications</i>
            <span>Alert Preferences</span>
        </a>
    </li>
    <li role="menuitem">
        <a [routerLink]="['/logout']" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
            <i class="material-icons">power_settings_new</i>
            <span>Logout</span>
        </a>
    </li>
</ul>
-->