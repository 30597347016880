import { BaseValueObject } from '../../base/value-object/base-value-object';

export class GoalText extends BaseValueObject
{
	public goalTextKey : string;				
	public goalTitle : string;	
	public goalDetail : string;
	public sequence : number;
	public contentKey : string;
		
	public static readonly SPRINTSEXIST : string = "sprintsExist";
	public static readonly POINTSONSTORIESSINGLESPRINT : string = "pointsOnStoriesSingleSprint";
	public static readonly GOODSTORIESSINGLESPRINT : string = "goodStoriesSingleSprint";
	public static readonly ACCEPTANCECRITERIASINGLESPRINT : string = "acceptanceCriteriaSingleSprint";
	public static readonly SPRINTCADENCESINGLESPRINT : string = "sprintCadenceSingleSprint";
	public static readonly SPRINTCADENCE : string = "sprintCadence";
	public static readonly POINTSONSTORIES : string = "pointsOnStories";
	public static readonly GOODSTORIES : string = "goodStories";
	public static readonly ACCEPTANCECRITERIA : string = "acceptanceCriteria";
	public static readonly POINTSONDEFECTS : string = "pointsOnDefects";
	public static readonly POINTSONCHORES : string = "pointsOnChores";
	public static readonly POINTSCHANGED : string = "pointsChanged";
	public static readonly ADDEDTOSPRINT : string = "addedToSprint";
	public static readonly BACKLOGDEPTH : string = "backlogDepth";
	public static readonly VELOCITYTREND : string = "velocityTrend";
	public static readonly DEFECTTREND : string = "defectTrend";
	public static readonly RELEASEESTIMATE : string = "releaseEstimate";	 
	public static readonly SPRINTCOMMITMENT1 : string = "sprintCommitment1";
	public static readonly SPRINTCOMMITMENT2 : string = "sprintCommitment2"; 

    initialize(goalText : GoalText) : void
    {
        super.initialize(goalText);     

        this.goalTextKey = goalText.goalTextKey;				
	    this.goalTitle = goalText.goalTitle;	
	    this.goalDetail = goalText.goalDetail;
	    this.sequence = goalText.sequence;
	    this.contentKey = goalText.contentKey;
    }
}
