<div *ngIf="epicList" class="grid card card-w-title">
    <div *ngIf="epicList && epicList.length <= 0">
        <h3>No active epics for project</h3>
    </div>

    <div *ngFor="let epic of epicList" class="col-12" (click)="onClick(epic)">
        <epic-heading [epic]="epic" [showButton]="true"></epic-heading>
    </div>
    
    <h3 class="col-12">Inactive Epics</h3>
    <epic-list class="col-12" *ngIf="projectId" [epicList]="inactiveEpicList" [lazy]="true" 
        [rows]="maxRows" [total]="inactiveEpicList.total" (onSortClicked)="onSortClickedInactive($event)"
        (onLazyLoad)="onLazyLoadInactive($event)" [paginator]="true" [sortOrder]="inactiveSortOrder">
    </epic-list>

    <h3 class="col-12">Future Epics</h3>
    <epic-list class="col-12" *ngIf="projectId" [epicList]="futureEpicList" [lazy]="true" 
        [rows]="maxRows" [total]="futureEpicList.total" (onSortClicked)="onSortClickedFuture($event)"
        (onLazyLoad)="onLazyLoadFuture($event)" [paginator]="true" [sortOrder]="futureSortOrder">
    </epic-list>

    <h3 class="col-12">Completed Epics</h3>
    <epic-list class="col-12" *ngIf="projectId" [epicList]="completedEpicList" [lazy]="true" 
        [rows]="maxRows" [total]="completedEpicList.total" (onSortClicked)="onSortClickedCompleted($event)"
        (onLazyLoad)="onLazyLoadCompleted($event)" [paginator]="true" [sortOrder]="completedSortOrder">
    </epic-list>
</div>

