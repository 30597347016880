import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { AppAlert } from './app-alert';
import { AppAlertCount } from './app-alert-count';
import { AppAlertSeverity } from './app-alert-severity';
import { Config } from '../config/config';

import { Project } from '../project/project';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';
import { Sprint } from '../sprint/sprint';
import { UserStory } from '../user-story/user-story';
import { Defect } from '../defect/defect';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class AppAlertService extends BaseHttpService
{
    appAlertUrl : string

    constructor(http: HttpClient, private config: Config) 
    {
        super(http);
        this.appAlertUrl = config.baseUrl + "/rest/appalert";
    }

    public retrieveByProject(projectId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/project/" + projectId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveByRelease(releaseId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/release/" + releaseId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveByEpic(epicId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/epic/" + epicId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveBySprint(sprintId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/sprint/" + sprintId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveByUserStory(userStoryId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/user-story/" + userStoryId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveByDefect(defectId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/defect/" + defectId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveByChore(choreId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/chore/" + choreId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveBySpike(spikeId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/spike/" + spikeId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }

    public retrieveByDependency(dependencyId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<AppAlert>>
    {
        let requestUrl = this.appAlertUrl + "/dependency/" + dependencyId + "/subset";
        return super.baseRetrieveSubset(AppAlert, requestUrl, startAt, maxResult);
    }
    
    public retrieveAppAlertSeverity(projectId: string) : Observable<AppAlertSeverity>
    {
        let appAlertCountUrl = this.appAlertUrl + "/severity/" + projectId;

        return super.baseRetrieve(appAlertCountUrl, this.processAppAlertSeverityResponse, super.baseHandleError);
    }

    private processAppAlertSeverityResponse(response: AppAlertSeverity) : AppAlertSeverity
    {
        let appAlertSeverity = new AppAlertSeverity();

        appAlertSeverity.initialize(response);

        return appAlertSeverity;
    }
}