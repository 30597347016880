import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { ProjectService } from '../project/project.service';
import { Project } from '../project/project';

import { SprintService } from './sprint.service';
import { Sprint } from './sprint';
import { SprintFact } from '../sprint-fact/sprint-fact';
import { Content } from '../content/content';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Component({
  selector: 'sprint-detail-summary',
  templateUrl: './sprint-detail-summary.component.html',
  styleUrls: ['./sprint-detail-summary.component.css' ],
  providers: [ ProjectService, SprintService ]
})

export class SprintDetailSummaryComponent extends BaseComponent implements OnInit 
{ 
    active : boolean = false;
    projectId : string;
    project : Project;
    sprintList : Sprint[];

    completedSprints : ValueObjectList<Sprint>;
    futureSprints : ValueObjectList<Sprint>;

    constructor( private projectService : ProjectService, 
                 private sprintService : SprintService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        this.completedSprints = new ValueObjectList<Sprint>();
        this.futureSprints = new ValueObjectList<Sprint>();
    }

    public ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        this.projectService.retrieve(this.projectId)
                .subscribe(result => this.processProject(result),
                           error => this.handleError(error) );

        this.sprintService.retrieveCurrentSprintList(this.projectId)
            .subscribe(result => this.processCurrentSprints(result),
                        error => this.handleError(error) );

        this.active = false;
    }

    public processProject(result: Project) : void
    {
        this.project = result;
    }

    public processCurrentSprints(result : Sprint[]) : void
    {
        this.sprintList = result;
        this.active = true;
    }

    public processCompletedSprints(result: ValueObjectList<Sprint>)
    {
        this.completedSprints = result;
    }

    public processFutureSprints(result: ValueObjectList<Sprint>)
    {
        this.futureSprints = result;
    }

    public onClick(sprint : Sprint) : void
    {
        this.router.navigate(['/dashboard/sprint-detail', sprint.sprintId]);
    }
    
    public onLazyLoadCompleted(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.sprintService.retrieveCompletedSubset(this.projectId, startAt, maxResult)
            .subscribe(result=>this.processCompletedSprints(result),
                       error => this.handleError(error) );
    }

    public onLazyLoadFuture(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.sprintService.retrieveFutureSubset(this.projectId, startAt, maxResult)
            .subscribe(result=>this.processFutureSprints(result),
                       error => this.handleError(error) );
    }

    public sprintDurationChanged(sprint : Sprint) : boolean
    {
        let result : boolean = false;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTDURATIONCHANGED) != null)
                result = sprint.getFact(SprintFact.SPRINTDURATIONCHANGED).getFactBoolean();
        }

        return result;
    }
    
    public sprintStartDayChanged(sprint : Sprint) : boolean
    {
        let result : boolean = false;

        if (sprint.sprintFactList != null)
        {
            if (sprint.getFact(SprintFact.SPRINTSTARTDAYCHANGED) != null)
                result = sprint.getFact(SprintFact.SPRINTSTARTDAYCHANGED).getFactBoolean();
        }

        return result;
    }

    public sprintCadenceChangedMessage(sprint : Sprint) : string
    {
        let message: string = "";

        if (this.sprintDurationChanged(sprint) && this.sprintStartDayChanged(sprint))
            message = "Sprint Start Day and Sprint Duration changed.  For more information on sprint cadence, please click here.";

        if (this.sprintDurationChanged(sprint) && this.sprintStartDayChanged(sprint) == false)
            message = "Sprint Duration changed.  For more information on sprint cadence, please click here.";
            
        if (this.sprintDurationChanged(sprint) == false && this.sprintStartDayChanged(sprint) )
            message = "Sprint Start Day changed.  For more information on sprint cadence, please click here.";

        return message;
    }
    
    public onSprintCadenceChangedClicked() : void 
    {
        this.router.navigate(['/dashboard/content-detail/key', Content.SPRINTCADENCE]);        
    }
    
}
