import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { AppAlert } from './app-alert';

@Component({
  selector: 'app-alert-detail',
  templateUrl: './app-alert-detail.component.html',
  styleUrls: ['./app-alert-detail.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ]
})

export class AppAlertDetailComponent extends BaseComponent implements OnInit 
{ 
    @Input() appAlert : AppAlert;

    appAlertSummary : SafeHtml = undefined;

    constructor(protected router : Router,
                private sanitizer: DomSanitizer) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.provideAppAlertSummary(this.appAlert.summary);
    }

    public provideAppAlertSummary(appAlertSummary:string) : void
    {
        this.appAlertSummary = this.sanitizer.bypassSecurityTrustHtml(appAlertSummary);
    }
}
