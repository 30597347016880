import { BaseFactObject } from '../base/value-object/base-fact-object';

export class DefectFact extends BaseFactObject
{
	// DEFECT FACT CONSTANTS
	public static readonly SPRINTSWORKEDON : string = "defectSprintsWorkedOn";
	public static readonly POINTSONDEFECT : string = "pointsOnDefect";
	public static readonly BLOCKED : string	= "defectBlocked";
	public static readonly POINTSINRANGE : string = "defectPointsInRange";
	public static readonly OPENDEFECTCLOSEDSPRINT : string = "openDefectClosedSprint";

    defectFactId : string;
	defectId : string;
    projectId : string;

    public initialize(defectFact : DefectFact) : void
    {
        super.initialize(defectFact);
        this.defectFactId = defectFact.defectFactId;
        this.defectId = defectFact.defectId;
        this.projectId = defectFact.projectId;
    }
}