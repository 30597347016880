import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { Epic } from '../../epic/epic';
import { EpicFact } from '../../epic-fact/epic-fact';
import { EpicFactService } from '../../epic-fact/epic-fact.service';

@Component({
  selector: 'mini-epic-sized',
  templateUrl: './mini-epic-sized.component.html',
  styleUrls: ['./mini-epic-sized.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ EpicFactService ]
})

export class MiniEpicSizedComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    epic : Epic;
    epicStoriesSizedFact : EpicFact;
    epicStoriesUnsizedFact : EpicFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private epicFactService : EpicFactService) 
    { 
        super(router);

        this.options = {
            responsive : true,
            maintainAspectRatio : false,
            plugins : {
                title: {
                    display: false,
                    text: 'Epic Sized'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    ngOnInit() : void
    {
        this.retrieveData();
    }

    private retrieveData() : void
    {
        this.epicFactService.retrieve(this.epic, EpicFact.EPICSTORIESSIZED)
            .subscribe(result => this.processEpicStoriesSizedFact(result),
                       error => this.handleError(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICSTORIESUNSIZED)
            .subscribe(result => this.processEpicStoriesUnsizedFact(result),
                       error => this.handleError(error) );
    }

    private processEpicStoriesSizedFact(result : EpicFact)
    {
        this.epicStoriesSizedFact = result;
        this.buildGraph();
    }

    private processEpicStoriesUnsizedFact(result : EpicFact)
    {
        this.epicStoriesUnsizedFact = result;
        this.buildGraph();
    }

    private buildGraph()
    {
        if (this.epicStoriesSizedFact == undefined || this.epicStoriesUnsizedFact == undefined)
            return;

        this.data = {
            labels : ['Unsized', 'Sized'],
            datasets: [
                {
                    type:'pie',
                    label: 'Stories',
                    data: [ this.epicStoriesUnsizedFact.getFactNumber(),
                            this.epicStoriesSizedFact.getFactNumber()
                    ],
                    backgroundColor: [
                        "rgba(256, 0, 0, .40)",
                        "rgba(0, 256, 0, .40)"
                    ],
                    borderColor: [
                        "rgba(256, 0, 0, 1)",
                        "rgba(0, 256, 0, 1)"
                    ],
                    borderWidth: [
                        2, 2
                    ],
                    hoverBackgroundColor: [
                        "#FF0000",
                        "#00FF00"
                    ]
                }
            ]
        }
    }

    private percentUnsized() : number
    {
        let result : number = 0;

        if (this.epicStoriesSizedFact != undefined && this.epicStoriesUnsizedFact != undefined)
        {
            let total : number = this.epicStoriesSizedFact.getFactNumber() + this.epicStoriesUnsizedFact.getFactNumber();

            if (total != 0)
                result = Math.ceil((this.epicStoriesUnsizedFact.getFactNumber() / total) * 100);
            else
                result = 0;
        }

        return result;
    }
}
