import { BaseAgileProjectObject } from './base-agile-project-object';
import { Project } from '../../project/project';
import { BacklogItem } from '../../backlog-item/backlog-item';
import { SprintFact } from '../../sprint-fact/sprint-fact';

import * as moment from 'moment';

export class BaseSprint extends BaseAgileProjectObject
{
    sprintId : string;
    altSourceSystemKey : string;
    title : string;
    description : string
    sprintBeginDate : Date;
    sprintEndDate : Date;
    sprintCompleteDate : Date;
	sourceSystemSprintBeginDate : Date;
	sourceSystemSprintEndDate : Date;
	sprintState : number
    
    sprintFactList : SprintFact[];

	// Sprint States.
	public static readonly FUTURE : number = 1;
	public static readonly CURRENT : number = 2;
	public static readonly COMPLETED : number = 3;

    initialize(baseSprint : BaseSprint) : void
    {
        super.initialize(baseSprint);

        this.sprintId = baseSprint.sprintId;
        this.altSourceSystemKey = baseSprint.altSourceSystemKey;
        this.title = baseSprint.title;
        this.description = baseSprint.description;

        this.sprintBeginDate = this.fromUTC(baseSprint.sprintBeginDate);
        this.sprintEndDate = this.fromUTC(baseSprint.sprintEndDate);
        this.sprintCompleteDate = this.fromUTC(baseSprint.sprintCompleteDate);

        this.sourceSystemSprintBeginDate = this.fromUTC(baseSprint.sourceSystemSprintBeginDate);
        this.sourceSystemSprintEndDate = this.fromUTC(baseSprint.sourceSystemSprintEndDate);
        this.sprintState = baseSprint.sprintState;
                
        this.sprintFactList = this.initializeList(SprintFact, baseSprint.sprintFactList);
    }
    
    public isFutureSprint() : boolean
    {
    	let result : boolean = false;
    	
    	if (this.sprintBeginDate == null || this.sprintEndDate == null)
    		result = true;
    	
    	return result;
    }

    public getFact(factName : string) : SprintFact
    {
        let result : SprintFact = null;

        if (this.sprintFactList != null)
        {
            for (let sprintFact of this.sprintFactList)
            {
                if (sprintFact.name == factName)
                {
                    result = sprintFact;
                    break;
                }
            }
        }

        return result;
    }

    public get shortTitle() : string
    {
        let result : string = this.title;

        if (this.title != undefined && this.title.length > 40)
            result = this.title.substr(0, 40);

        return result;
    }
}
