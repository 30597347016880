import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'project-star-rating',
  templateUrl: './project-star-rating.component.html',
  styleUrls: ['./project-star-rating.component.scss' ],
  encapsulation : ViewEncapsulation.None,
  providers: [ ]
})

export class ProjectStarRatingComponent implements OnInit, OnChanges 
{ 
  @Input()
  rating : number = undefined;
  style : string = undefined;

  constructor() 
  { 
  }

  public ngOnInit() : void
  {
    this.displayRating();
  }

  public ngOnChanges() : void
  {
    this.displayRating();
  }

  public displayRating() : void
  {
    let color : string = "#ffc058";
    let adjRating : number = this.rating;

    // Go up in .5 increments for display purposes....
    if (adjRating > 0 && adjRating < 1)
      adjRating = 0.5;

    if (adjRating > 1 && adjRating < 2)
      adjRating = 1.5;

    if (adjRating > 2 && adjRating < 3)
      adjRating = 2.5;

    if (adjRating > 3 && adjRating < 4)
      adjRating = 3.5;

    if (adjRating > 4 && adjRating < 5)
      adjRating = 4.5;

    // Set the colors.
    if (adjRating <= 1.5)
      color = "#f03c56";

    if (adjRating > 3.5)
      color = "#7ed321";

    this.style = `--rating: ${adjRating}; --star-background:${color};`;    
  }

}
