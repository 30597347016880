import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule }  from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { routes } from './app.routes';

import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';

import { LoginComponent } from './authentication/login.component';
import { ForgotPasswordComponent } from './authentication/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password.component';
import { UnauthorizedComponent } from './authentication/unauthorized.component';
import { ErrorComponent } from './error/error.component';

import { LoginService } from './authentication/login.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardTopbarComponent } from './dashboard/dashboard-topbar.component';
import { DashboardProfileComponent } from './dashboard/dashboard-profile.component';
import { DashboardMenuComponent } from './dashboard/dashboard-menu.component';
import { DashboardSubmenuComponent } from './dashboard/dashboard-submenu.component';
import { DashboardFooterComponent } from './dashboard/dashboard-footer.component';

import { LoginGuard } from './authentication/login-guard';
import { Config } from './config/config';

import { DashboardModule } from './dashboard/dashboard.module';
import { PrimeNgModule } from './ui/primeng.module';
import { NgIdleModule } from '@ng-idle/core';
import { MessagingService } from './event/messaging.service';
import { DashboardMenuService } from './dashboard/dashboard-menu.service';

import { PipesModule } from './pipes/pipes.module';


@NgModule({
  imports: [
    FormsModule, 
    BrowserModule, 
    BrowserAnimationsModule,
    HttpClientModule, 
    DashboardModule,
    PrimeNgModule,
    RouterModule.forRoot(routes),
    NgIdleModule.forRoot(), 
    PipesModule   
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UnauthorizedComponent,
    ErrorComponent,
    DashboardComponent,
    DashboardFooterComponent,
    DashboardMenuComponent,
    DashboardSubmenuComponent,
    DashboardProfileComponent,
    DashboardTopbarComponent
  ],
  providers: [
      LoginGuard, 
      LoginService,
      Config,
      {provide: LocationStrategy, useClass: HashLocationStrategy},
      MessagingService,
      DashboardMenuService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
