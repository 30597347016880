<div class="grid col-12">
    <span class="col-12 flex align-content-center">
        <h3 style="margin: auto 0px"><a [routerLink]="['/dashboard/epic-detail', epic.epicId]">Epic {{epic.formattedId}} - {{epic.name}}</a></h3>
        <span style="margin-right:5px">&nbsp;</span>
        <button *ngIf="showButton == true" pButton label="Details" class="detail secondary" (click)="onClick(epic)"></button>
    </span>

    <div class="grid col-12">
        <colorbox *ngIf="epicScopeFact" class="col-12 sm:col-12 md:col-6" [name]="'Scope'" [detail]="epicScopeFact.getFactNumber() | plural:'point'" [color]="'blue'" [icon]="'timeline'"></colorbox>                
        <colorbox *ngIf="epicPointsCompletedFact" class="col-12 sm:col-12 md:col-6" [name]="'Points Completed'" [detail]="epicPointsCompletedFact.getFactNumber() | plural:'point'" [color]="'green'" [icon]="'done'"></colorbox>                
    </div>
    <mini-epic-sized class="col-12 sm:col-12 md:col-4" [epic]="epic"></mini-epic-sized>
    <epic-point-burnup *ngIf="displayCosts == false" class="col-12 sm:col-12 md:col-4" [epic]="epic" [displayPoints]="true"></epic-point-burnup>
    <epic-cost-burnup *ngIf="displayCosts == true" class="col-12 sm:col-12 md:col-4" [epic]="epic"></epic-cost-burnup>
    <epic-status class="col-12 sm:col-12 md:col-4" [epic]="epic" [displayCosts]="displayCosts"></epic-status>

    <p-panel *ngIf="epic.description" class="col-12" header="Description">
        <div style="overflow-y:scroll; height:100px;" class="preserveWhitespace" *ngIf="epic.description" [innerHTML]="epic.description">
        </div>            
    </p-panel>
</div>
