<div class="grid card card-w-title">
    <h3 class="col-12">Content Administration</h3>
        
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <p-table *ngIf="organizationList != undefined && organizationList.length > 0" class="col-12" [responsive]="true" 
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="contentId" [rowHover]="true" 
        [value]="contentList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedContent">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:250px"><!-- content key -->
                <col style="width:250px"><!-- organization -->
                <col><!-- content title -->
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>
                    Content Key
                </th>
                <th>
                    Organization
                </th>
                <th>
                    Content Title
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-content>
            <tr [pSelectableRow]="rowData">
                <td>
                    {{ content.contentKey }}
                </td>
                <td>
                    <span>{{ getOrganizationName(content.orgId) }}</span>
                </td>
                <td>
                    {{ content.contentTitle }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="3">
                    No records found
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-2">
            <button class="col-12" #addButtonTag pButton type="button" label="Add" (click)="onAdd()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-2">
            <button class="col-12" #updateButtonTag pButton type="button" [disabled]="selectedContent == null" label="Update" (click)="onUpdate()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-2">
            <button class="col-12" #viewButtonTag pButton type="button" [disabled]="selectedContent ==null" label="View" (click)="onView()"></button>   
        </div>
        <div class="col-12 sm:col-12 md:col-2">
            <button class="col-12" #deleteButtonTag pButton type="button" [disabled]="selectedContent == null" label="Delete" (click)="onDelete()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-2">
            <button class="col-12 secondary" #cancelButtonTag pButton type="button" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

     <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
