import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseDetailComponent } from '../base/component/base-detail-component.component';

import { OrgSourceSystem } from '../org-source-system/org-source-system';
import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';

import { Release } from './release';
import { ReleaseService } from './release.service';

import { ReleaseFact } from '../release-fact/release-fact';
import { ReleaseFactService } from '../release-fact/release-fact.service';

import { ProjectFactService } from '../project-fact/project-fact.service';

import { BacklogItemService } from '../backlog-item/backlog-item.service';
import { BacklogItem } from '../backlog-item/backlog-item';

import { ValueObjectList } from '../base/value-object/value-object-list';

let backlogItemsByDueDateFact : ReleaseFact;
let releaseInOut = false;

@Component({
  selector: 'release-detail',
  templateUrl: './release-detail.component.html',
  styleUrls: ['./release-detail.component.css' ],
  providers: [ OrgSourceSystemService, ProjectFactService, ReleaseService, ReleaseFactService, BacklogItemService ]
})
export class ReleaseDetailComponent extends BaseDetailComponent implements OnInit 
{ 
    orgSourceSystem : OrgSourceSystem;

    release : Release;
    releaseId : string;

    releaseBacklog : ValueObjectList<BacklogItem>;
    closedItems : boolean = false;
    rows : number = 20;
    releaseInOut : boolean = false;
    futureReleaseFact : ReleaseFact;
    releaseScopeFact : ReleaseFact;

    constructor( private orgSourceSystemService : OrgSourceSystemService,
                 private releaseService : ReleaseService,
                 private releaseFactService : ReleaseFactService,
                 private backlogItemService : BacklogItemService,
                 private route: ActivatedRoute,
                 protected projectFactService : ProjectFactService,
                 protected router: Router) 
     { 
        super(router, projectFactService);

        this.route.params.forEach((params: Params) => {
            this.releaseId = params['releaseId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.release = undefined;
            this.releaseId = params['releaseId'];
            this.loadData();
        });

    }

    ngOnInit() : void
    {
        this.loadData();
    }


    protected loadData() : void 
    {
        this.releaseBacklog = new ValueObjectList<BacklogItem>();
        this.releaseInOut = false;
        backlogItemsByDueDateFact = undefined;
        releaseInOut = this.releaseInOut;

        // Build component via route.
        if (this.releaseId != undefined)
        {
            this.releaseService.retrieve(this.releaseId)
                .subscribe(result=>this.processRelease(result),
                           error => this.handleError(error) );

            this.orgSourceSystemService.retrieveByReleaseId(this.releaseId)
                .subscribe(result => this.processOrgSourceSystem(result), 
                           error => this.handleError(error) );

            this.releaseFactService.retrieveById(this.releaseId, ReleaseFact.BACKLOGITEMSBYDUEDATE)
                .subscribe(result => this.processBacklogItemsByDueDateFact(result),
                           error => this.handleError404Okay(error) );

            this.releaseFactService.retrieveById(this.releaseId, ReleaseFact.FUTURERELEASE)
                .subscribe(result => this.processFutureReleaseFact(result),
                           error => this.handleError404Okay(error) );

            this.releaseFactService.retrieveById(this.releaseId, ReleaseFact.RELEASESCOPE)
                .subscribe(result => this.processReleaseScopeFact(result),
                           error => this.handleError404Okay(error) );
        }

    }

    public processRelease(result : Release) : void
    {
        this.release = result;    
        this.retrieveCosts(this.release.project.projectId);    
    }

    public processOrgSourceSystem(result : OrgSourceSystem) : void
    {
        this.orgSourceSystem = result;    
    }

    public processReleaseBacklog(result:ValueObjectList<BacklogItem>) : void
    {
        this.releaseBacklog = result;
    }

    private processBacklogItemsByDueDateFact(result : ReleaseFact)
    {
        backlogItemsByDueDateFact = result;
    }

    private processFutureReleaseFact(result : ReleaseFact)
    {
        this.futureReleaseFact = result;
    }

    private processReleaseScopeFact(result : ReleaseFact)
    {
        this.releaseScopeFact = result;
    }
    
    private onReleaseInOutClicked() : void
    {
        releaseInOut = this.releaseInOut;
    }

    private onLazyLoad(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.backlogItemService.retrieveReleaseBacklog(this.release, this.closedItems, startAt, maxResult)
            .subscribe(result=>this.processReleaseBacklog(result) );
    }

    private onDefectClick() : void
    {
        this.router.navigate(['/dashboard/defect-backlog', this.release.project.projectId]);
    }

    public releaseRowStyleClass(backlogItem : BacklogItem, index : number) : string
    {
        let result : string = null;
        
        if (backlogItemsByDueDateFact != undefined && releaseInOut == true)
        {
            let inRelease : boolean = backlogItemsByDueDateFact.getFactStringList().includes(backlogItem.objectId);

            if (inRelease == true)
            {
                if (index % 2 == 0)
                    result = 'row-style-class-green-even';
                else
                    result = 'row-style-class-green-odd';
            }
            else
            {
                if (index % 2 == 0)
                    result = 'row-style-class-red-even';
                else
                    result = 'row-style-class-red-odd';
            }
        }

        return result;
    }

}
