import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { EpicFact } from './epic-fact';
import { Epic } from '../epic/epic';

@Injectable()
export class EpicFactService extends BaseHttpService
{
    private epicFactUrl: string;

    constructor(http: HttpClient, private config: Config)
    {
        super(http);
        this.epicFactUrl = this.config.baseUrl + '/rest/epicfact';
    }

    public retrieve(epic: Epic, factName : string) : Observable<EpicFact>
    {
        let url = this.epicFactUrl + `?epicId=${epic.epicId}&name=${factName}`;
        return super.baseRetrieveObject(EpicFact, url);
    }

    public retrieveById(epicId : string, factName : string) : Observable<EpicFact>
    {
        let url = this.epicFactUrl + `?epicId=${epicId}&name=${factName}`;
        return super.baseRetrieveObject(EpicFact, url);
    }

}