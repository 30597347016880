import { Component, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { DefectCount } from '../../defect/defect-count';

import { BaseFactObject } from '../../base/value-object/base-fact-object';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { Epic } from '../../epic/epic';
import { EpicFact } from '../../epic-fact/epic-fact';
import { EpicFactService } from '../../epic-fact/epic-fact.service';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service'; 

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'epic-defect-potential',
  templateUrl: './epic-defect-potential-time-series.component.html',
  styleUrls: ['./epic-defect-potential-time-series.component.scss' ],
  providers: [ EpicFactService, TimeSeriesService ]
})

export class EpicDefectPotentialTimeSeriesComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    epic : Epic;

    @Output() click = new EventEmitter<any>();

    defectCountList : DefectCount[];
    timeSeries : TimeSeries;

    defectPotentialTimeSeries : TimeSeries;

    openDefectCountFact : BaseFactObject;
    openDefectTrend : string;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private epicFactService : EpicFactService,
                 private timeSeriesService : TimeSeriesService) 
    {
        super(router);

        this.defectCountList = undefined;

        this.options = {
            // scales: { yAxes: [{ stacked : true, beginAtZero:true }], 
            //           xAxes: [{ stacked : true, display: false }] },
            responsive : true,
            maintainAspectRatio : false,
            title: {
                display: false,
                text: 'Defect Count'
            }, 
            legend: {
                display: false,
                position: 'bottom'
            }
        }
    }

    ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void
    {
        if (this.epic != undefined)
        {
            this.epicFactService.retrieve(this.epic, EpicFact.OPENDEFECTCOUNT)
                .subscribe(result => this.processOpenDefectCountFact(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.epic.project, this.epic.epicId, TimeSeries.EPICDEFECTCOUNT, 10)
                .subscribe(result=>this.processDefectCountTimeSeries(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.epic.project, this.epic.epicId, TimeSeries.EPICDEFECTPOTENTIAL, 10)
                .subscribe(result =>this.processDefectPotentialTimeSeries(result),
                           error => this.handleError404Okay(error) ); 
                                      
        }
    }

    private processDefectCount()
    {
        if (this.defectCountList == undefined || this.defectPotentialTimeSeries == undefined)
            return;

        this.data = {
            labels : this.defectCountList.map( function(a) { return moment(a.sprintEndDate).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Closed Defects',
                    backgroundColor: 'rgba(0, 255, 0, .40)',
                    pointHoverBackgroundColor: 'rgba(0, 255, 0, 1)',
                    borderColor: 'rgba(, 255, 0, 1)',
                    borderWidth: 2,
                    data: this.defectCountList.map( function(a) { return a.closedDefects; } )
                },
                {
                    type:'bar',
                    label: 'Open Defects',
                    backgroundColor: 'rgba(256, 0, 0, .40)',
                    pointHoverBackgroundColor: 'rgba(256, 0, 0, 1)',
                    borderColor: 'rgba(256, 0, 0, 1)',
                    borderWidth: 2,
                    data: this.defectCountList.map( function(a) { return a.openDefects; } )
                },
                {
                    type:'line',
                    label: 'Defect Potential',
                    data: this.defectPotentialTimeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber(EpicFact.DEFECTPOTENTIAL) ); } )
                }
            
            ]
        }
    }

    public processOpenDefectCountFact(result : BaseFactObject ) : void
    {
        if (_.isEqual(this.openDefectCountFact, result) == false)
            this.openDefectCountFact = result;
    }

    public onclick(event:any)
    {
        this.click.emit(null);
    }

    public processDefectCountTimeSeries(result: TimeSeries) : void
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;
            this.defectCountList = [];

            for (let timeSeriesItem of result.timeSeriesList)
            {
                let dc : DefectCount = new DefectCount();
                dc.sprintEndDate = moment(timeSeriesItem.timestamp).toDate();
                dc.openDefects = timeSeriesItem.getFieldNumber("openDefects");
                dc.closedDefects = timeSeriesItem.getFieldNumber("closedDefects");
                dc.totalDefects = timeSeriesItem.getFieldNumber("totalDefects");

                this.defectCountList.push(dc);
            }

            this.processDefectCount();
            this.setOpenDefectTrend();
        }
    }

    private setOpenDefectTrend() : void
    {
        let twoSprintsAgo : number = 0;
        let oneSprintAgo : number = 0;
        let lastSprint : number = 0;

        this.openDefectTrend = "neutral";

        if (this.defectCountList.length >= 3)
        {
            let length = this.defectCountList.length;

            twoSprintsAgo = this.defectCountList[length-3].openDefects;
            oneSprintAgo = this.defectCountList[length-2].openDefects;
            lastSprint = this.defectCountList[length-1].openDefects;

            if (twoSprintsAgo < oneSprintAgo && oneSprintAgo < lastSprint)
                this.openDefectTrend = "up";

            if (twoSprintsAgo > oneSprintAgo && oneSprintAgo > lastSprint)
                this.openDefectTrend = "down";
        }
    }

    public processDefectPotentialTimeSeries(result : TimeSeries) : void
    {
        this.defectPotentialTimeSeries = result;
        this.processDefectCount();
    }
}
