import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';

import { BaseOrgTimeSeriesComponent } from '../../base/component/base-org-time-series-component.component';

import { Project } from '../../project/project';

import { SprintFact } from '../../sprint-fact/sprint-fact';
import { LLUtils } from '../../utilities/ll-utils';

import * as _ from 'lodash';

// Internal class for this component.
class MaturityAverage {
    average : number;
    index : number;
}

@Component({
  selector: 'org-scrum-maturity-timeline',
  templateUrl: './org-scrum-maturity-timeline.component.html',
  styleUrls: ['./org-scrum-maturity-timeline.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService ]
})

export class OrgScrumMaturityTimelineComponent extends BaseOrgTimeSeriesComponent implements OnInit, OnChanges 
{ 
    @Input()
    maturityGoal : number = 3.00;
    
    lastMaturityGoal : number = undefined;

    constructor( protected router : Router,
                 protected timeSeriesService : TimeSeriesService) 
    { 
        super(router, timeSeriesService);
    }

    public ngOnInit() : void
    {
        this.options['plugins']['title'].text = `Scrum Maturity Trend By Project`;
        super.ngOnInit()
    }

    // Override base class
    public loadData() : void 
    {
        let deleteOccurred : boolean = false;
        let maturityGoalChanged : boolean = false;

        if (this.maturityGoal != this.lastMaturityGoal)
            maturityGoalChanged = true;

        // Clean up map - deselect projects if removed.
        for (let projectId of this.timeSeriesMap.keys() )
        {
            let found : boolean = false;

            for (let project of this.projectList)
            {
                if (projectId == project.projectId)
                {
                    found = true;
                    break;
                }
            }

            if (found == false)
            {
                this.timeSeriesMap.delete(projectId);
                deleteOccurred = true;
            }
        }

        // If a delete occurred, be sure to rebuild graph.
        if (deleteOccurred || maturityGoalChanged)
            this.buildGraph();

        for (let project of this.projectList)
            this.retrieveTimeSeriesByProject(project);
    }

    public retrieveTimeSeriesByProject(project : Project) : void
    {
        this.timeSeriesService.retrieve(project, project.projectId, TimeSeries.PROJECTSCRUMMATURITYCLOSEDSPRINTS, 10)
            .subscribe(result => this.processTimeSeries(project.projectId, result),
                       error => this.handleError404Okay(error) );
    }

    protected processTimeSeries(projectId : string, result: TimeSeries)
    {
        // Re-sort the time series for use later.  Make the most recent
        // items first in the list.
        if (_.isEqual(this.timeSeriesMap.get(projectId), result) == true)
            return;
    
        this.timeSeriesMap.set(projectId, result);
        this.buildGraph();
    }

    protected buildGraph() : void
    {
        // First, build all the labels.
        let labelArray : string[] = [];

        for (let i = 10; i > 0; i-- )
        {
            if (i == 1)
                labelArray.push("Last sprint");
            else
                labelArray.push(`${i} sprints ago`);
        }

        this.data = {
            labels: labelArray
        }

        // Next, plot the projects.
        // Put in logic to check if data changed.
        this.data.datasets = [];

        for (let project of this.projectList)
        {
            let timeSeries = this.timeSeriesMap.get(project.projectId);

            // If we didn't have our project loaded yet, continue.
            if (timeSeries == undefined)
                continue;

            let hexColor : string = LLUtils.uuidToColor(timeSeries.objectId);
            let color = LLUtils.hexToRgb(hexColor, 1.0);
            let transparentColor = LLUtils.hexToRgb(hexColor, .40);

            let paddedValues : number[] = this.padTimeSeries(timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber(SprintFact.SCRUMMATURITY); } ), 10);

            this.data.datasets.push({
                    type: 'line',
                    label: this.getProjectName(timeSeries.objectId),
                    backgroundColor: transparentColor,
                    hoverBackgroundColor: color,
                    borderColor: color,
                    borderWidth: 2,
                    fill: false,
                    tension: .5,
                    data: paddedValues
            });
        }

        // If we have more than one team selected, include an average of the teams.
        if (this.projectList.length > 1)
        {
            let maturityAverageList : MaturityAverage[] = this.buildTeamMaturityAverage();

            let paddedValues : number[] = this.padTimeSeries(maturityAverageList.map( function(a : MaturityAverage) { return a.average; } ), 10);

            this.data.datasets.push({
                type: 'line',
                label: 'Average',
                backgroundColor: this.BLACKTRANSPARENT,
                hoverBackgroundColor: this.BLACK,
                borderColor: this.BLACK,
                borderWidth: 5,
                fill: false,
                tension: .5,
                data: paddedValues
            });
        }

        this.buildMaturityAnnotation();
    }

    public buildMaturityAnnotation() : void
    {
        let enabled = false;

        if (this.projectList.length > 0)
            enabled = true;

        // Update the maturity goal.
        this.lastMaturityGoal = this.maturityGoal;

        // Try to annotate graph with goal.
        this.options['plugins']['annotation'] = {
            annotations: {
              line1: {
                type: 'line',
                display: enabled,
                yMin: this.maturityGoal,
                yMax: this.maturityGoal,
                borderColor: this.GREEN,
                borderWidth: 5,
                label: {
                    enabled: enabled,
                    backgroundColor: 'black',
                    borderColor: 'black',
                    borderRadius: 10,
                    borderWidth: 2,
                    content: 'Goal',
                    rotation: 'auto'
                  },
              }
            }
        }       

    }

    public buildTeamMaturityAverage() : MaturityAverage[]
    {
        let result : MaturityAverage[] = [];

        // if no teams then just return.
        if (this.projectList.length <= 0)
            return result;

        // Create a deep copy of our timeseries map.  We do this because
        // we need to resort the elements of the map.
        let timeSeriesMap : Map<string, TimeSeries> = _.cloneDeep(this.timeSeriesMap);

        // Accommodate up to 10 sprints.
        for (let i = 0; i < 10; i++)
        {
            let teamCount : number = 0;
            let totalMaturity : number = 0;

            for (let projectId of timeSeriesMap.keys() )
            {
                // If this project doesn't have a sprint at the index we're 
                // checking, continue...
                if (timeSeriesMap.get(projectId).timeSeriesList.length <= i)
                    continue;

                let timeSeriesDescending = timeSeriesMap.get(projectId);
                timeSeriesDescending.sortDescending();

                teamCount += 1;

                let teamMaturity = timeSeriesDescending.timeSeriesList[i].getFieldNumber(SprintFact.SCRUMMATURITY);
                totalMaturity = totalMaturity + teamMaturity;
            }

            if (teamCount > 0)
            {
                let average : number = LLUtils.round((totalMaturity / teamCount), 2);

                let maturityAverage : MaturityAverage = new MaturityAverage();
                maturityAverage.average = average;
                maturityAverage.index = i;

                result.push(maturityAverage);
            }
        }

        // Now, resort the result list so the most recent sprint is 
        // last and the oldest sprint is first.
        result = result.sort((maturityAverage1 : MaturityAverage, maturityAverage2 : MaturityAverage) => {

            // Sort descending by index so the oldest sprint is first and the
            // newest sprint is last.
            return maturityAverage2.index - maturityAverage1.index;
        });

        return result;
    }

}
