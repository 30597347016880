import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { TimeSeries } from './time-series';
import { Project } from '../project/project';

@Injectable()
export class TimeSeriesService extends BaseHttpService
{
    timeSeriesUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.timeSeriesUrl = this.config.baseUrl + '/rest/time-series';
    }

    public retrieve(project : Project, objectId : string, name: string, limit : number) : Observable<TimeSeries>
    {
        let url : string = this.timeSeriesUrl + "/project/" + project.projectId + "/" + objectId + "/" + name + "?limit=" + limit;
        return super.baseRetrieveObject(TimeSeries, url);        
    }

    public retrieveBySprint(objectId : string, sprintId: string, name: string, limit : number) : Observable<TimeSeries>
    {
        let url : string = this.timeSeriesUrl + "/sprint/" + sprintId + "/" + objectId + "/" + name + "?limit=" + limit;
        return super.baseRetrieveObject(TimeSeries, url);        
    }

}