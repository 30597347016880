<div class="grid col-12 card card-w-title">
    <h3 class="col-12">Defect Backlog</h3>
    <mini-defect-priority *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></mini-defect-priority>
    <mini-defect-count-overview *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></mini-defect-count-overview>
    <mini-defect-aging *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></mini-defect-aging>    
    <defect-density-timeline *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></defect-density-timeline>
    <defect-removal-rate-timeline *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></defect-removal-rate-timeline>
    <defect-mttr-timeline *ngIf="project" class="col-12 sm:col-12 md:col-4" [project]="project"></defect-mttr-timeline>

    <div class="col-12" *ngFor="let sprint of sprintDetailList">
        <h4>{{sprint.title}}</h4>
        <backlog-item-list class="col-12" [orgSourceSystem]="orgSourceSystem" [backlogItemList]="sprint.defectList" [completed]="true"> </backlog-item-list>
    </div>

    <h4 class="col-12">Backlog</h4>
    <div class="grid col-12">
        <label class="col-9 sm:col-9 md:col-3" for="closedDefects">Show Closed Defects:</label>
        <span class="col-3 sm:col-3 md:col-9">
            <p-inputSwitch id="closedDefects" #closedDefectsTag [(ngModel)]="closedDefects" name="closedDefects"></p-inputSwitch>
        </span>
    </div>
    <backlog-item-list *ngIf="project" class="col-12" [orgSourceSystem]="orgSourceSystem" [backlogItemList]="defectBacklog" [completed]="closedDefects"
        [lazy]="true" [rows]="rows" [total] = "defectBacklog.total"
        (onLazyLoad)="onLazyLoad($event)" [paginator]="true">
    </backlog-item-list>
</div>
