import { Component, OnInit, Injectable, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { UserAccountService } from '../../user-account/user-account.service';
import { UserAccount } from '../../user-account/user-account';

import { ValueObjectList } from '../../base/value-object/value-object-list';

import { BaseComponent } from '../../base/component/base-component.component';

@Injectable()
@Component({
  selector: 'user-account-list',
  templateUrl: './user-account-list.component.html',
  styleUrls: ['./user-account-list.component.css'],
  providers: [ UserAccountService, ConfirmationService ]
})
export class UserAccountListComponent extends BaseComponent implements OnInit, AfterViewInit
{ 
    userAccountList : ValueObjectList<UserAccount>;
    selectedUserAccount : UserAccount;
    total : number = 0;
    rows : number = 20;

    constructor(protected router:Router,
                protected changeDetector : ChangeDetectorRef,
                private confirmationService : ConfirmationService,
                private userAccountService: UserAccountService ) 
    {
      super(router);
    }

    public ngOnInit() : void
    {
        super.clearErrorMessages();
    }

    public ngAfterViewInit() : void
    {
      this.changeDetector.detectChanges();
    }

    public retrieveList() : void
    {
        this.userAccountService.retrieveAll(0, this.rows)
          .subscribe(result =>this.processUserAccountList(result),
                     error => this.handleError(error) );
    }

    private processUserAccountList(userAccountList : ValueObjectList<UserAccount>): void
    {
      this.userAccountList = userAccountList;
      this.total = userAccountList.total;
      this.selectedUserAccount = null;
    }

    public onAdd() : void
    {
      this.router.navigate(['/dashboard/admin/user-account-add']);
    }

    public onUpdate() : void
    {
      this.router.navigate(['/dashboard/admin/user-account-update', this.selectedUserAccount.userId]);
    }

    public onDelete() : void
    {
      this.confirmationService.confirm(
        { message: 'Are you sure you wish to delete the selected row?',
            accept: () => 
            {
              this.clearErrorMessages();
              this.deleteUserAccount(this.selectedUserAccount);
            }
        });
    }

    public onCancel() : void
    {
       this.router.navigate(['/dashboard']);
    }
    
    private deleteUserAccount(userAccount : UserAccount ) : void
    {
        this.userAccountService.delete(userAccount)
          .subscribe(result => this.handleDeleteSuccess(result),
                     error => this.handleDeleteError(error) );
    }

    private handleDeleteSuccess(result : any) : void
    {
        this.addInfoMessage("Account deleted successfully.");
        this.retrieveList();
    }

    private handleDeleteError(error: any) : void
    {
      this.addErrorMessage("Error Deleting User Account.  Please try again.");
    }

    public loadData(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.userAccountService.retrieveAll(startAt, maxResult)
          .subscribe(result =>this.processUserAccountList(result),
                     error => this.handleError(error) );
    }

}
