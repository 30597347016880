import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseComponent } from '../base/component/base-component.component';

import { Organization } from '../organization/organization';
import { Project } from '../project/project';

import { ProjectStatusService } from '../project-status/project-status.service';
import { ProjectStatus } from '../project-status/project-status';

import { UserAccount } from '../user-account/user-account';
import { UserDashboardPreference } from '../user-dashboard-preference/user-dashboard-preference';
import { UserDashboardPreferenceService } from '../user-dashboard-preference/user-dashboard-preference.service';

import { Epic } from '../epic/epic';
import { EpicFact } from '../epic-fact/epic-fact';
import { EpicService } from '../epic/epic.service';

import { DateService } from '../utilities/date.service';

import { LLUtils } from '../utilities/ll-utils';
import { DateUtils } from '../utilities/date-utils';
import { Subscription, Observable } from "rxjs";
import { timer } from "rxjs";

import * as _ from "lodash"; 
import { LoggedInUser } from '../authentication/logged-in-user';

@Component({
  selector: 'organization-summary',
  templateUrl: './organization-summary.component.html',
  styleUrls: ['./organization-summary.component.scss' ],
  providers: [ UserDashboardPreferenceService, ProjectStatusService, EpicService, DateService ]
})

export class OrganizationSummaryComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges
{ 
    @Input()
    userAccount : UserAccount = undefined;

    refreshSubscription: Subscription = undefined;
    refreshTimer : Observable<number>;

    organization : Organization = undefined;

    projectList : Project[] = [];
    filteredProjectList : Project[] = [];

    quarterList : string[] = [];
    filteredQuarterList : string[] = [];

    epicList : Epic[] = [];
    filteredEpicList : Epic[] = [];
    
    projectStatusMap : Map<string, ProjectStatus> = new Map<string, ProjectStatus>();

    userDashboardPreference : UserDashboardPreference = new UserDashboardPreference();

    epicListSortOrder : number = 1;
    maxRows : number = 10;
    includeCurrentPI : boolean = true;
    includeLastPI : boolean = true;
    selectedMaturity : number = 3;

    constructor( protected userDashboardPreferenceService : UserDashboardPreferenceService,
                 protected epicService : EpicService,
                 protected projectStatusService : ProjectStatusService, 
                 protected dateService : DateService,
                 private route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        if (this.userAccount != undefined)
        {
            this.organization = this.userAccount.organization;
            this.projectList = this.userAccount.userProjects;
            this.filteredProjectList = [];
            this.filteredQuarterList = [ DateUtils.currentQuarter() ];
        }
        
        this.setupRefresh();
        this.loadData();
    }

    public ngOnDestroy() : void
    {
        if (this.refreshSubscription != undefined)
           this.refreshSubscription.unsubscribe();
    }

    public ngOnChanges() : void
    {

    }

    protected setupRefresh() : void
    {
        // Make sure these are refreshing.
        this.refreshTimer = timer(0, 30000);
        this.refreshSubscription = this.refreshTimer.subscribe(t => { this.refreshStatus(t) } );
    }

    protected refreshStatus(ticks : number) : void
    {
        this.loadData();
    }

    public loadData() : void
    {
        for (let project of this.projectList)
        {
            this.projectStatusService.retrieve(project.projectId)
                .subscribe(result => this.processProjectStatus(result),
                            error => this.handleError404Okay(error) );
        }

        this.epicService.retrieveOrganizationDashboard()
            .subscribe(result => this.processEpicList(result), 
                       error => this.handleError(error) );

        this.userDashboardPreferenceService.retrieve()
            .subscribe(result => this.processUserDashboardPreference(result), 
                       error => this.handleError404Okay(error) );

        this.dateService.retrieveList()
            .subscribe(result => this.processQuarterList(result),
                       error => this.handleError(error) );
    }

    protected processProjectStatus(result : ProjectStatus)
    {
        this.projectStatusMap.set(result.projectId, result);
    }

    public processUserDashboardPreference(result : UserDashboardPreference) : void
    {
        this.userDashboardPreference = result;
        this.filteredProjectList = this.userDashboardPreference.filteredProjectList;
        this.filteredQuarterList = this.userDashboardPreference.filteredQuarterList;
        this.selectedMaturity = this.userDashboardPreference.maturityGoal;

        this.refreshFilters();
    }

    public processEpicList(result : Epic[]) 
    {
        this.epicList = result;
        this.filterEpics(this.epicList);
    }

    public processQuarterList(result : string[]) : void
    {
        this.quarterList = result;
    }

    public filterChange() : void
    {
        this.filteredProjectList = [... this.filteredProjectList ];

        this.userDashboardPreference.filteredProjectList = this.filteredProjectList;
        this.userDashboardPreference.filteredQuarterList = this.filteredQuarterList;
        this.userDashboardPreference.maturityGoal = this.selectedMaturity;

        // Update user preferences.
        this.userDashboardPreferenceService.update(this.userDashboardPreference)
            .subscribe(result => this.processUserDashboardPreference(result), 
                       error => this.handleError(error) );
    }

    public isProjectLoading(project : Project) : boolean
    {
        let result : boolean = false;

        if (this.projectStatusMap != undefined && this.projectStatusMap.get(project.projectId) != undefined)
        {
            let projectStatus : ProjectStatus = this.projectStatusMap.get(project.projectId);

            if (projectStatus.initialLoad == true)
                result = true;
        }

        return result;
    }

    public areAnyProjectsLoading() : boolean
    {
        let result : boolean = false;

        for (let project of this.projectList)
        {
            if (this.projectStatusMap != undefined && this.projectStatusMap.get(project.projectId) != undefined)
            {
                let projectStatus : ProjectStatus = this.projectStatusMap.get(project.projectId);

                if (projectStatus.initialLoad == true)
                {
                    result = true;
                    break;
                }
            }
        }

        return result;
    }

    public getProjectDescription(project : Project) : string
    {
        let result : string = `Initial load in progress for ${project.description}`;
        return result;
    }

    public onSortClicked(event : any) : void
    {

    }

    public refreshFilters() : void
    {
        this.filterEpics(this.epicList);
    }

    public filterEpics(epicList : Epic[]) : void
    {
        // Apply project filter
        this.filteredEpicList = this.epicList.filter(
            (epic : Epic) => {
                let result : boolean = false;

                for (let project of this.filteredProjectList) {
                    if (epic.project.projectId == project.projectId) {
                        result = true;
                        break;
                    }
                }

                return result;
            }
        );

        // Apply current PI filter.
        this.filteredEpicList = this.filteredEpicList.filter(
            (epic : Epic) => {
                let result : boolean = false;

                if (epic.getFact(EpicFact.EPICQUARTERSWORKEDON) != undefined && this.epicInSelectedQuarter(epic.getFact(EpicFact.EPICQUARTERSWORKEDON) ) )
                    result = true;

                return result;
            }
        );

    }

	protected epicInSelectedQuarter(pEpicQuartersWorkedOn : EpicFact) : boolean
	{
		let result : boolean = false;

		// If we get a null, return false.
		if (pEpicQuartersWorkedOn == undefined)
			return result;
		
		// If the epic was worked on in any quarter we are interested in, return true.
		for (let epicQuarterString of pEpicQuartersWorkedOn.getFactStringList() )
		{
			for (let searchQuarterString of this.filteredQuarterList)
			{
				if (LLUtils.equalsIgnoreCase(epicQuarterString, searchQuarterString) == true)
				{
					result = true;
					break;
				}
			}
			
			if (result == true)
				break;
		}
		
		return result;
	}

    public hasFinanicialAccess() : boolean
    {
        let result : boolean = false;
        
        if (LoggedInUser.getLoggedInUser() != undefined)        
            result = LoggedInUser.getLoggedInUser().isFinUser();

        return result;
    }

    public getQuarterSelectorStyle() : any
    {
        let result = {'width':'90%'};

        if (this.isMobile() == true)
            result['width'] = '200px';

        return result;
    }

    public getFieldSetStyle() : any
    {
        let result = {'width':'100%'};

        if (this.isMobile() == true)
        {
            let width : number = window.innerWidth - 20;
            result['width'] = `${width}px`;
        }

        return result;
    }

}
