import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../base/service/base-http.service';
import { SprintFact } from './sprint-fact';
import { BaseSprint } from '../base/value-object/base-sprint';

@Injectable()
export class SprintFactService extends BaseHttpService
{
    private sprintFactUrl: string;

    constructor(http: HttpClient, private config: Config)
    {
        super(http);
        this.sprintFactUrl = this.config.baseUrl + '/rest/sprintfact';
    }

    public retrieve(sprint: BaseSprint, factName : string) : Observable<SprintFact>
    {
        let url = this.sprintFactUrl + `?sprintId=${sprint.sprintId}&name=${factName}`;
        return super.baseRetrieveObject(SprintFact, url);
    }

    public retrieveById(sprintId : string, factName : string) : Observable<SprintFact>
    {
        let url = this.sprintFactUrl + `?sprintId=${sprintId}&name=${factName}`;
        return super.baseRetrieveObject(SprintFact, url);
    }

}