import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { ForgotPasswordService } from './forgot-password.service';
import { BaseComponent } from '../base/component/base-component.component';

import { LLUtils } from '../utilities/ll-utils';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [ './forgot-password.component.scss' ],
  providers: [ ForgotPasswordService ]
})

export class ForgotPasswordComponent extends BaseComponent
{ 
    emailAddress : string;

    constructor(private forgotPasswordService : ForgotPasswordService, protected router : Router ) 
    { 
        super(router);
        this.addInfoMessage("Please enter email address to reset password.");
    }

    public onSubmit() : void
    {
        this.clearErrorMessages(); 

        if (LLUtils.isStringEmpty(this.emailAddress) == true)
        {
            this.addInfoMessage("Email address must be supplied");
            return;
        }

        this.forgotPasswordService.forgotPassword(this.emailAddress)
            .subscribe(result =>this.processForgotPassword(),
                       error => this.handleForgotPasswordError(error) );

    }

    public processForgotPassword() : void
    {
        this.addInfoMessage("Details on how to reset your password have been emailed to you.");
    }

    public handleForgotPasswordError(error : HttpErrorResponse) : void
    {
        if (error.status == 404)
        {
            this.addInfoMessage("Invalid email address or account locked.");
        }
        else
        {
            this.addErrorMessage("Error occurred.  Please try again");
        }
    }

    public onCancel() : void
    {
        this.router.navigate(['login']);
    }
}
