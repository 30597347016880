import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BacklogItem } from '../backlog-item/backlog-item';

@Component({
  selector: 'search-result-detail',
  templateUrl: './search-result-detail.component.html',
  styleUrls: ['./search-result-detail.component.css' ],
  providers: [ ]
})

export class SearchResultDetailComponent extends BaseComponent implements OnInit 
{ 
    @Input()
    backlogItem : BacklogItem;

    @Input()
    searchText : string;

    constructor(protected router : Router) 
    { 
        super(router);
    }

    ngOnInit() : void
    {
    }
}
