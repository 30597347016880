<p-card class="release-defect-potential-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Open Defects</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="openDefectTrend">
                    <i *ngIf="openDefectTrend == 'up'" class="material-icons" tooltipPosition="left" pTooltip="Trending Up">trending_up</i>
                    <i *ngIf="openDefectTrend == 'down'" class="material-icons" tooltipPosition="left" pTooltip="Trending Down">trending_down</i>
                    <i *ngIf="openDefectTrend == 'neutral'" class="material-icons" tooltipPosition="left" pTooltip="Trending Flat">trending_flat</i>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="openDefectCountFact">{{openDefectCountFact.getFactNumber()}} total</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
