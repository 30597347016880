<div *ngIf="backlogItem">            
    <div *ngIf="backlogItem.isUserStory()">
        <span *ngIf="pointsInRange(backlogItem) == true && pointsOnStory(backlogItem) == true">{{backlogItem.storyPoints}}</span>
        <span *ngIf="pointsInRange(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="points-in-range-false" pTooltip="Original Points: {{ backlogItem.sourceSystemStoryPoints }}" (tooltipPosition)="tooltipPosition">
                <i class="material-icons">all_inclusive</i>
            </a>
        </span>
        <span *ngIf="pointsOnStory(backlogItem) == false && pointsInRange(backlogItem) == true">
            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="points-on-story-false" pTooltip="Unsized story" (tooltipPosition)="tooltipPosition">
                <i class="material-icons">help_outline</i>
            </a>
        </span>
    </div>
    <div *ngIf="backlogItem.isDefect()">
        <span *ngIf="backlogItem.storyPoints && backlogItem.storyPoints != 0 && pointsInRange(backlogItem) && unfinished(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', POINTSONDEFECTS]" class="points-on-defect" pTooltip="Warning - Points On Defect" (tooltipPosition)="tooltipPosition">
                {{backlogItem.storyPoints}}
            </a>
        </span>
        <span *ngIf="pointsInRange(backlogItem) == false && unfinished(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="points-in-range-false" pTooltip="Original Points: {{ backlogItem.sourceSystemStoryPoints }}" (tooltipPosition)="tooltipPosition">
                <i class="material-icons">all_inclusive</i>
            </a>
        </span>                
    </div>

    <div *ngIf="backlogItem.isChore()">
        <span *ngIf="backlogItem.storyPoints && backlogItem.storyPoints != 0 && pointsInRange(backlogItem) && unfinished(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', POINTSONCHORES]" class="points-on-chore" pTooltip="Warning - Points On Chore" (tooltipPosition)="tooltipPosition">
                {{backlogItem.storyPoints}}
            </a>
        </span>
        <span *ngIf="pointsInRange(backlogItem) == false && unfinished(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="points-in-range-false" pTooltip="Original Points: {{ backlogItem.sourceSystemStoryPoints }}" (tooltipPosition)="tooltipPosition">
                <i class="material-icons">all_inclusive</i>
            </a>
        </span>                
    </div>

    <div *ngIf="backlogItem.isSpike()">
        <span *ngIf="backlogItem.storyPoints && backlogItem.storyPoints != 0 && pointsInRange(backlogItem) && unfinished(backlogItem) == false">
            {{backlogItem.storyPoints}}
        </span>
        <span *ngIf="pointsInRange(backlogItem) == false && unfinished(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="points-in-range-false" pTooltip="Original Points: {{ backlogItem.sourceSystemStoryPoints }}" (tooltipPosition)="tooltipPosition">
                <i class="material-icons">all_inclusive</i>
            </a>
        </span>                
    </div>
    
    <div *ngIf="backlogItem.isDependency()">
        <span *ngIf="backlogItem.storyPoints && backlogItem.storyPoints != 0 && pointsInRange(backlogItem) && unfinished(backlogItem) == false">
            {{backlogItem.storyPoints}}
        </span>
        <span *ngIf="pointsInRange(backlogItem) == false && unfinished(backlogItem) == false">
            <a [routerLink]="['/dashboard/content-detail/key', STORYPOINTS]" class="points-in-range-false" pTooltip="Original Points: {{ backlogItem.sourceSystemStoryPoints }}" (tooltipPosition)="tooltipPosition">
                <i class="material-icons">all_inclusive</i>
            </a>
        </span>                
    </div>
    <div *ngIf="unfinished(backlogItem) == true">
        <a [routerLink]="['/dashboard/content-detail/key', UNFINISHEDVELOCITY]" class="unfinished-true" pTooltip="Original Points: {{ backlogItem.sourceSystemStoryPoints }}" (tooltipPosition)="tooltipPosition">
            <i class="material-icons">shuffle</i>
        </a>
    </div>
</div>