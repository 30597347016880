<p-card *ngIf="this.hasData != undefined && this.hasData == true" class="scrum-maturity-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Scrum Maturity&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="scrumMaturityTrendClosedSprints" class="overview-badge">
                    <i *ngIf="scrumMaturityTrendClosedSprints == 'up'" class="material-icons" pTooltip="Trending Up" tooltipPosition="left">trending_up</i>
                    <i *ngIf="scrumMaturityTrendClosedSprints == 'down'" class="material-icons" pTooltip="Trending Down" tooltipPosition="left">trending_down</i>
                    <i *ngIf="scrumMaturityTrendClosedSprints == 'neutral'" class="material-icons" pTooltip="Trending Flat" tooltipPosition="left">trending_flat</i>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="scrumMaturityAverageClosedSprints" class="overview-detail" tooltipPosition="bottom" pTooltip="Three Sprint Average">{{ scrumMaturityAverageClosedSprints.getFactNumber() | round:2 | number:'1.2-2' }}</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>

<p-card *ngIf="this.hasData != undefined && this.hasData == false" class="scrum-maturity-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="overview-title">Scrum Maturity&nbsp;</span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
    </ng-template>

    <span class="content">
        <div class="grace-period-text">
            <span >Graph will appear when scrum maturity trends are available (typically, after first sprint is completed).</span>
        </div>
    </span>
</p-card>

<zoom-graph #zoomGraph type="bar" [sourceData]="data" [sourceOptions]="options"></zoom-graph>