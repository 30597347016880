import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BaseBacklogItemDetailComponent } from '../base/component/base-backlog-item-detail.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { BaseFactObject } from '../base/value-object/base-fact-object';

import { OrgSourceSystem } from '../org-source-system/org-source-system';
import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';

import { Sprint } from '../sprint/sprint';
import { SprintService } from '../sprint/sprint.service';

import { BacklogItem } from '../backlog-item/backlog-item';
import { BacklogItemService } from '../backlog-item/backlog-item.service';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'backlog-item-detail',
  templateUrl: './backlog-item-detail.component.html',
  styleUrls: ['./backlog-item-detail.component.scss' ],
  providers: [ OrgSourceSystemService, SprintService, BacklogItemService ]
})

export class BacklogItemDetailComponent extends BaseBacklogItemDetailComponent implements OnInit, AfterViewInit 
{
    @Input()
    backlogItem : BaseBacklogItemObject;

    @Input()
    objectTypeTitle : string = undefined;

    @Input()
    objectId : string = undefined;

    @Input()
    sprintsWorkedOnFact : BaseFactObject = undefined;

    @Input()
    blockersFact : BaseFactObject = undefined;

    @Input()
    sprint : Sprint;

    sprintHistory : Sprint[];
    orgSourceSystem : OrgSourceSystem;

    blockers : BacklogItem[];
    
    constructor(private sprintService : SprintService,
                private backlogItemService : BacklogItemService,
                private orgSourceSystemService : OrgSourceSystemService,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                protected router: Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.initFields();

        this.orgSourceSystemService.retrieveByProjectId(this.backlogItem.project.projectId)
            .subscribe(result => this.processOrgSourceSystem(result), 
                       error => this.handleError(error) );
    }

    public ngAfterViewInit() : void
    {
    }

    private initFields() : void
    {
        this.blockers = undefined;
        this.sprintHistory = undefined;

        if (this.blockersFact != undefined)
        {
            let objectIdList : string[] = this.blockersFact.getFactStringList();
            if (objectIdList.length > 0)
                this.backlogItemService.retrieveList(this.backlogItem.project, objectIdList)
                    .subscribe(result=>this.processBlockers(result),
                               error => this.handleError(error) );
        }

        this.loadSprintHistory();
    }

    private loadSprintHistory()
    {
        if (this.sprintsWorkedOnFact != undefined)
        {
            let sprintIdList = this.sprintsWorkedOnFact.getFactStringList();

            if (this.sprint != undefined)
            {
                let currentSprintIndex = sprintIdList.indexOf(this.sprint.sprintId);

                if (currentSprintIndex >= 0)
                    sprintIdList.splice(currentSprintIndex);
            }

            if (sprintIdList.length > 0)
            {
                this.sprintService.retrieveByIdArray(this.backlogItem.project, sprintIdList)
                    .subscribe(result=>this.processSprintHistory(result),
                               error => this.handleError(error) );
            }
        }
    }

    protected processOrgSourceSystem(result : OrgSourceSystem)
    {
        this.orgSourceSystem = result;
    }

    private processSprintHistory(sprintHistory : Sprint[]) : void
    {
        this.sprintHistory = new Array<Sprint>(); 

        // Don't include the current sprint, if there is one.
        for (let sprint of sprintHistory)
        {
            if (this.sprint)
            {
                if (sprint.sprintId != this.sprint.sprintId)
                    this.sprintHistory.push(sprint);
            }
            else
            {
                if (this.backlogItem != undefined && this.backlogItem.sprint != undefined)
                {
                    if (this.backlogItem.sprint.sprintId != sprint.sprintId)
                        this.sprintHistory.push(sprint);
                }
                else
                {
                    this.sprintHistory.push(sprint);
                }
            }
        }
    }

    private processBlockers(result : Array<BacklogItem>) : void
    {
        this.blockers = result;
    }

    public provideDescription() : SafeHtml
    {
        return this.sanitizer.bypassSecurityTrustHtml(this.backlogItem.description);
    }

    public provideAcceptanceCriteria() : SafeHtml
    {
        return this.sanitizer.bypassSecurityTrustHtml(this.backlogItem.acceptanceCriteria);
    }

    public buildSourceSystemLink(backlogItem : BacklogItem) : string
    {
        let result = undefined;

        if (this.orgSourceSystem != undefined)
            result = this.orgSourceSystem.buildSourceSystemLink(backlogItem);

        return result;
    }

    
}
