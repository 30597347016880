import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { BrowserModule }  from '@angular/platform-browser';

// PrimeNG components
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea'
import { PasswordModule } from 'primeng/password';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button'; 
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ListboxModule } from 'primeng/listbox';
import { TooltipModule } from 'primeng/tooltip';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { PickListModule } from 'primeng/picklist';
import { EditorModule } from 'primeng/editor';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog'; 
import { MessagesModule } from 'primeng/messages'; 
import { MessageModule } from 'primeng/message';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputMaskModule } from 'primeng/inputmask';
import { BlockUIModule } from 'primeng/blockui';
import { RatingModule } from 'primeng/rating';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DragDropModule } from 'primeng/dragdrop';
import { AccordionModule } from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MegaMenuModule } from 'primeng/megamenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import { SliderModule } from 'primeng/slider';

@NgModule({
  imports: [
    AccordionModule,
    AutoCompleteModule,
    FormsModule,
    BadgeModule, 
    BrowserModule, 
    InputTextModule, 
    InputNumberModule,
    InputTextareaModule,
    PasswordModule,
    PanelModule, 
    ButtonModule,
    CardModule,
    ChartModule,
    SplitButtonModule,
    TooltipModule,
    MenuModule, 
    FileUploadModule,
    EditorModule,
    DialogModule,
    DropdownModule,
    InputMaskModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    InputSwitchModule,
    PickListModule,
    ProgressBarModule,
    BlockUIModule,
    RatingModule,
    CalendarModule,
    RadioButtonModule,
    FieldsetModule,
    TabViewModule,
    CheckboxModule,
    KeyFilterModule,
    ScrollPanelModule,
    TableModule,
    ListboxModule,
    DragDropModule,
    OverlayPanelModule,
    MultiSelectModule,
    ToastModule,
    ContextMenuModule,
    SelectButtonModule,
    SlideMenuModule,
    MegaMenuModule,
    TriStateCheckboxModule,
    DataViewModule, 
    SliderModule
  ],
  exports: [
    AccordionModule,
    AutoCompleteModule,
    BadgeModule,
    CardModule,
    InputTextModule, 
    InputNumberModule,
    InputTextareaModule,
    PasswordModule,
    PanelModule, 
    ButtonModule,
    ChartModule,
    SplitButtonModule,
    TooltipModule,
    MenuModule, 
    ProgressBarModule,
    EditorModule,
    DialogModule,
    DropdownModule,
    InputMaskModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    InputSwitchModule,
    PickListModule,
    BlockUIModule,
    RatingModule, 
    CalendarModule,
    RadioButtonModule,
    FieldsetModule,
    TabViewModule,
    CheckboxModule,
    KeyFilterModule,
    ScrollPanelModule, 
    TableModule, 
    ListboxModule,
    DragDropModule,
    OverlayPanelModule, 
    MultiSelectModule,
    ToastModule,
    ContextMenuModule,
    SelectButtonModule,
    SlideMenuModule,
    MegaMenuModule,
    TriStateCheckboxModule, 
    DataViewModule,
    FileUploadModule,
    SliderModule
  ],
  declarations: [
  ],
  providers: [
  ]
})
export class PrimeNgModule { }
