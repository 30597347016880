<p-card class="point-distribution-timeline">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-12 flex justify-content-start">
                <span class="title-text">Point Distribution&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
        </div>
    </ng-template>

    <span class="content">
        <p-chart type="bubble" [data]="data" [options]="options" [height]="'150px'"></p-chart>
    </span>
</p-card>

<zoom-bubble-graph #zoomGraph type="bubble" [sourceData]="data" [sourceOptions]="options"></zoom-bubble-graph>
