<p-card class="mini-sprint-points-carried-over">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Points Carried Over</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="pointsCarriedOver && sprintCommitment" class="overview-badge" pTooltip="Percent Carried Over" tooltipPosition="left">
                    {{ percentCarriedOver() | round }}%
                </span>
        
                <span class="overview-badge points-carried-over" *ngIf="pointsCarriedOver">
                    <a *ngIf="pointsCarriedOver.getFactNumber() > 0" [routerLink]="['/dashboard/content-detail/key', CARRYOVERKEY]">
                        <i pTooltip="Warning - Points Carried Over" tooltipPosition="left"
                            class="warning material-icons">warning</i>
                    </a>
                </span>        
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="pointsCarriedOver" class="overview-detail">{{ pointsCarriedOver.getFactNumber() }} points</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="pointsCarriedOver && sprintCommitment">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>