import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'ceiling' })
export class CeilingPipe implements PipeTransform 
{
    transform(value: number): number 
    {
        let result : number = Math.ceil(value);
        return result;
  }
}