import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'
import { BaseHttpService } from '../base/service/base-http.service';
import { Chore } from './chore';
import { Project } from '../project/project';
import { Config } from '../config/config';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class ChoreService extends BaseHttpService
{
    protected choreUrl : string

    constructor(http: HttpClient, config: Config) 
    {
        super(http);
        this.choreUrl = config.baseUrl + "/rest/chore";
    }

    public retrieve(choreId : string) : Observable<Chore>
    {
        let requestUrl = this.choreUrl + "/" + choreId;
        return super.baseRetrieveObject(Chore, requestUrl);
    }

    public retrieveChoreBacklog(project : Project, closed : boolean, startAt : number, maxResult : number) : Observable<ValueObjectList<Chore>>
    {
        let requestUrl = this.choreUrl + "/" + project.projectId + "/subset?closed=" + closed;
        return super.baseRetrieveSubset(Chore, requestUrl, startAt, maxResult);
    }

    public retrieveReleaseBacklog(release : Release) : Observable<Chore[]>
    {
        let requestUrl = this.choreUrl + "/releasebacklog/" + release.releaseId;
        return super.baseRetrieveList(Chore, requestUrl);
    }

    public retrieveEpicBacklog(epic : Epic) : Observable<Chore[]>
    {
        let requestUrl = this.choreUrl + "/epicbacklog/" + epic.epicId;
        return super.baseRetrieveList(Chore, requestUrl);
    }
}
