import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config/config';
import { BaseHttpService } from '../../base/service/base-http.service';
import { Observable } from 'rxjs';
import { Project } from '../../project/project';
import { ProjectConfig } from './project-config';

@Injectable()
export class ProjectConfigService extends BaseHttpService
{
    projectConfigUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.projectConfigUrl = this.config.baseUrl + '/rest/project-config';
    }

    public retrieve(projectId : string, configKey : string) : Observable<ProjectConfig>
    {
        let url = this.projectConfigUrl + "/" + projectId + "/" + configKey;
        return super.baseRetrieveObject(ProjectConfig, url);
    }

    public update(projectConfig : ProjectConfig) : Observable<ProjectConfig>
    {
        let url = this.projectConfigUrl;
        return super.baseUpdateObject(ProjectConfig, url, projectConfig);
    }

    public retrieveList(projectId : string, category : string) : Observable<ProjectConfig[]>
    {
        let url = this.projectConfigUrl + "/" + projectId + "/" + category + "/list";
        return super.baseRetrieveList(ProjectConfig, url);
    }

    public updateList(projectConfigList : ProjectConfig[] ) : Observable<ProjectConfig[]>
    {
        let url = this.projectConfigUrl + "/list";
        return super.baseUpdateList(ProjectConfig, url, projectConfigList);
    }
}
