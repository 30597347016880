<div class="grid card card-w-title">
    <p-blockUI [blocked]="blockedDocument"></p-blockUI>

    <h3 class="col-12">Project Filter Configuration</h3>
    <h4 class="col-12">
        This page is for selecting the projects lucidLIFT will manage.  
        Simply highlight the source system you are interested in and 
        select the project you wish to manage from the Source Projects
        column and move over to the Managed Projects column.  Once you have selected your
        projects, you can optionally specify additional configuration details by clicking
        <a [routerLink]="['/dashboard/admin/project-config-list']" [routerLinkActive]="['active-menulink']">here</a> 
        or activate the polling process on the Job Schedule page
        <a [routerLink]="['/dashboard/admin/org-job-schedule']" [routerLinkActive]="['active-menulink']">here</a>.
    </h4>

    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="col-12">
        <p-table *ngIf="orgSourceSystemList" class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
            dataKey="orgSourceSystemId" [rowHover]="true" [value]="orgSourceSystemList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedOrgSourceSystem"
            (onRowSelect)="orgSourceSystemSelect($event)" (onRowUnselect)="orgSourceSystemUnselect($event)">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col><!-- description -->
                        <col><!-- userid -->
                        <col><!-- apiurl -->
                        <col><!-- datecreated -->
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            Source System
                        </th>
                        <th>
                            User Id For Access
                        </th>
                        <th>
                            API Url
                        </th>
                        <th>
                            Date Created
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-orgSourceSystem>
                    <tr [pSelectableRow]="rowData">
                        <td>
                            {{ orgSourceSystem.sourceSystem.description }}
                        </td>
                        <td>
                            {{ orgSourceSystem.sourceSystemUserId }}
                        </td>
                        <td>
                            {{ orgSourceSystem.sourceSystemApiUrl }}
                        </td>
                        <td>
                            {{ orgSourceSystem.dateCreated | dateFormat }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="4">
                            No records found
                        </td>
                    </tr>
                </ng-template>
        </p-table>

        <p-pickList [responsive]="true" class="projectPickList col-12" [source]="sourceProjectList" 
            [target]="filteredProjectList" [sourceHeader]="'Source Projects'" [targetHeader]="'Managed Projects'"
            [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-project pTemplate="item">
                    <div>
                        {{project.description}}
                    </div>
                </ng-template>
        </p-pickList>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12" label="Update" (click)="onUpdate()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>        
</div>
