import { NgModule }       from '@angular/core';
import { FormsModule }    from '@angular/forms';
import { CommonModule }   from '@angular/common';

import { dashboardRouting } from './dashboard.routing';
import { UIModule } from '../ui/ui.module';
import { PipesModule } from '../pipes/pipes.module';
import { PrimeNgModule } from '../ui/primeng.module';

import { LogoutComponent } from '../authentication/logout.component';
import { MainComponent } from '../main/main.component';
import { OrganizationSummaryComponent } from '../organization/organization-summary.component';
import { EpicDashboardListComponent } from '../epic/epic-dashboard-list.component';
import { EpicDashboardSummaryComponent } from '../epic/epic-dashboard-summary.component';
import { ProjectSummaryComponent } from '../project/project-summary.component';
import { ProfileComponent } from '../user-account/profile.component';
import { UserAlertPreferenceComponent } from '../user-alert-preference/user-alert-preference.component';
import { UserAccountListComponent } from '../admin/user-account/user-account-list.component';
import { UserAccountUpdateComponent } from '../admin/user-account/user-account-update.component';
import { UserAccountAddComponent } from '../admin/user-account/user-account-add.component';
import { ProjectDetailComponent } from '../project/project-detail.component';
import { ReleaseDetailSummaryComponent } from '../release/release-detail-summary.component';
import { ReleaseDetailComponent } from '../release/release-detail.component';
import { ReleaseHeadingComponent } from '../release/release-heading.component';
import { ReleaseLinkListComponent } from '../release/release-link-list.component';
import { ReleaseListComponent } from '../release/release-list.component';
import { EpicDetailSummaryComponent } from '../epic/epic-detail-summary.component';
import { EpicDetailComponent } from '../epic/epic-detail.component';
import { EpicHeadingComponent } from '../epic/epic-heading.component';
import { EpicListComponent } from '../epic/epic-list.component';
import { SprintDetailReloadComponent } from '../sprint/sprint-detail-reload.component';
import { SprintDetailSummaryComponent } from '../sprint/sprint-detail-summary.component';
import { SprintDetailComponent } from '../sprint/sprint-detail.component';
import { SprintHeadingComponent } from '../sprint/sprint-heading.component';
import { SprintListComponent } from '../sprint/sprint-list.component';
import { UserStoryDetailComponent } from '../user-story/user-story-detail.component';
import { ProductBacklogComponent } from '../product-backlog/product-backlog.component';
import { AppAlertComponent } from '../app-alert/app-alert.component';
import { AppAlertDetailComponent } from '../app-alert/app-alert-detail.component';
import { AppAlertListComponent } from '../app-alert/app-alert-list.component';
import { SearchResultsListComponent } from '../search/search-results-list.component';
import { SearchResultDetailComponent } from '../search/search-result-detail.component';
import { AlertTextComponent } from '../admin/alert-text/alert-text.component';
import { GoalTextComponent } from '../admin/goal-text/goal-text.component';
import { ContextVariableListComponent } from '../admin/context-variable/context-variable-list.component';
import { ContentListComponent } from '../admin/content/content-list.component';
import { ContentAddComponent } from '../admin/content/content-add.component';
import { ContentUpdateComponent } from '../admin/content/content-update.component';
import { ContentViewComponent } from '../admin/content/content-view.component';
import { ContentDetailComponent } from '../content/content-detail.component';
import { DefectBacklogComponent } from '../defect/defect-backlog.component';
import { DefectDetailComponent } from '../defect/defect-detail.component';
import { ChoreDetailComponent } from '../chore/chore-detail.component';
import { SpikeDetailComponent } from '../spike/spike-detail.component';
import { DependencyDetailComponent } from '../dependency/dependency-detail.component';
import { BacklogItemListComponent } from '../backlog-item/backlog-item-list.component';
import { BacklogItemDeletedMessageComponent } from '../backlog-item/backlog-item-deleted-message.component';
import { InboxItemListComponent } from '../inbox-item/inbox-item-list.component';
import { InboxItemDetailComponent } from '../inbox-item/inbox-item-detail.component';
import { SprintEndReportComponent } from '../sprint-end-report/sprint-end-report.component';
import { GoalSummaryComponent } from '../goal/goal-summary.component';
import { ProjectTeamCostListComponent } from '../project-team-cost/project-team-cost-list.component';
import { ProjectTeamCostAddComponent } from '../project-team-cost/project-team-cost-add.component';
import { ProjectTeamCostUpdateComponent } from '../project-team-cost/project-team-cost-update.component';
import { BacklogItemDetailComponent } from '../backlog-item/backlog-item-detail.component';
import { PointChangeDetailComponent} from '../user-story/point-change-detail.component';

import { UserStoryTestComponent } from '../user-story/user-story-test.component';

import { OrgConfigComponent }
    from '../admin/org-config/org-config.component';

import { OrgSourceSystemListComponent } 
    from '../admin/org-source-system/org-source-system-list.component';

import { OrgSourceSystemAddComponent } 
    from '../admin/org-source-system/org-source-system-add.component';

import { OrgSourceSystemUpdateComponent } 
    from '../admin/org-source-system/org-source-system-update.component';

import { ProjectFilterComponent }
    from '../admin/project-filter/project-filter.component';

import { ProjectConfigListComponent }
    from '../admin/project-config/project-config-list.component';

import { ProjectConfigDetailComponent }
    from '../admin/project-config/project-config-detail.component';

import { OrgJobScheduleComponent }
    from '../admin/org-job-schedule/org-job-schedule.component';

import { AdminGuard } from '../authentication/admin-guard';
import { ContentGuard } from '../authentication/content-guard';

// Remove
import { ApolloComponent } from '../apollo/apollo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PrimeNgModule,
    dashboardRouting,
    UIModule,
    PipesModule
  ],
  declarations: [
    LogoutComponent,
    MainComponent, 
    OrganizationSummaryComponent,
    EpicDashboardListComponent,
    EpicDashboardSummaryComponent,    
    ProjectSummaryComponent,
    OrgConfigComponent,
    OrgSourceSystemListComponent,
    OrgSourceSystemAddComponent,
    OrgSourceSystemUpdateComponent,
    OrgJobScheduleComponent,
    ProjectFilterComponent,
    ProjectConfigListComponent,
    ProjectConfigDetailComponent,
    ProfileComponent,
    UserAlertPreferenceComponent,
    UserAccountListComponent,
    UserAccountUpdateComponent,
    UserAccountAddComponent,
    ProjectDetailComponent,
    ReleaseDetailSummaryComponent,
    ReleaseDetailComponent,
    ReleaseHeadingComponent,
    ReleaseListComponent,
    ReleaseLinkListComponent,
    EpicDetailSummaryComponent,
    EpicDetailComponent,
    EpicHeadingComponent,
    EpicListComponent,
    SprintDetailSummaryComponent,
    SprintDetailReloadComponent,
    SprintHeadingComponent,
    SprintDetailComponent,
    SprintListComponent,
    UserStoryDetailComponent,
    ProductBacklogComponent,
    AppAlertComponent,
    AppAlertDetailComponent,
    AppAlertListComponent,
    SearchResultsListComponent,
    SearchResultDetailComponent,
    AlertTextComponent,
    GoalTextComponent,
    ContextVariableListComponent,
    ContentListComponent,
    ContentAddComponent,
    ContentUpdateComponent,
    ContentViewComponent,
    ContentDetailComponent,
    DefectBacklogComponent,
    DefectDetailComponent,
    ChoreDetailComponent,
    SpikeDetailComponent,
    DependencyDetailComponent,
    InboxItemListComponent,
    InboxItemDetailComponent,
    SprintEndReportComponent,
    BacklogItemListComponent,
    BacklogItemDeletedMessageComponent,    
    GoalSummaryComponent,
    ProjectTeamCostListComponent,
    ProjectTeamCostAddComponent,
    ProjectTeamCostUpdateComponent,
    BacklogItemDetailComponent,
    UserStoryTestComponent,
    PointChangeDetailComponent, 
    
    ApolloComponent
],

  providers: [
      AdminGuard, ContentGuard
  ]
})

export class DashboardModule {}
