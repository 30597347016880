import { Component, OnInit, OnChanges, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

import { DefectCount } from '../../defect/defect-count';
import { ZoomGraphComponent } from '../zoom-graph/zoom-graph.component';

import { BaseFactObject } from '../../base/value-object/base-fact-object';

import { Project } from '../../project/project';
import { ProjectFact } from '../../project-fact/project-fact';
import { ProjectFactService } from '../../project-fact/project-fact.service';

import { Release } from '../../release/release';
import { ReleaseFact } from '../../release-fact/release-fact';
import { ReleaseFactService } from '../../release-fact/release-fact.service';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service'; 

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'mini-defect-count-overview',
  templateUrl: './mini-defect-count-overview.component.html',
  styleUrls: ['./mini-defect-count-overview.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService, ReleaseFactService, TimeSeriesService ]
})

export class MiniDefectCountOverviewComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @ViewChild("zoomGraph")
    zoomGraph : ZoomGraphComponent;

    @Input()
    project : Project;

    @Input()
    release : Release;

    @Output() click = new EventEmitter<any>();

    defectCountList : DefectCount[];
    timeSeries : TimeSeries;
    defectCountString: string;

    openDefectCountFact : BaseFactObject;
    openDefectTrend : string;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private projectFactService : ProjectFactService,
                 private releaseFactService : ReleaseFactService,
                 private timeSeriesService : TimeSeriesService) 
    {
        super(router);

        this.defectCountList = undefined;

        this.options = {
            plugins: {
                title: {
                    display: false
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    ngOnInit() : void
    {
        this.options['plugins']['title'].text = `${this.project.description} : Open Defect Count By Sprint` ;
        this.loadData();
    }

    public ngOnChanges() : void 
    {
        this.loadData();
    }

    public loadData() : void
    {
        if (this.project != undefined)
        {
            this.projectFactService.retrieve(this.project, ProjectFact.OPENDEFECTCOUNT)
                .subscribe(result => this.processOpenDefectCountFact(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.project, this.project.projectId, TimeSeries.PROJECTDEFECTCOUNT, 10)
                .subscribe(result =>this.processDefectCountTimeSeries(result),
                           error => this.handleError404Okay(error) ); 
                
        }

        if (this.release != undefined)
        {
            this.releaseFactService.retrieve(this.release, ReleaseFact.OPENDEFECTCOUNT)
                .subscribe(result => this.processOpenDefectCountFact(result),
                           error => this.handleError404Okay(error) ); 

            this.timeSeriesService.retrieve(this.release.project, this.release.releaseId, TimeSeries.RELEASEDEFECTCOUNT, 10)
                .subscribe(result=>this.processDefectCountTimeSeries(result),
                           error => this.handleError404Okay(error) ); 
        }
    }

    private processDefectCount(result: DefectCount[])
    {
        this.defectCountList = result;       
        this.defectCountString = (this.defectCountList.map( function(a) { return a.openDefects; } )).toString();

        this.data = {
            labels : this.defectCountList.map( function(a) { return moment(a.sprintEndDate).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'line',
                    label: 'Defects',
                    backgroundColor: 'rgba(256, 0, 0, .40)',
                    fill: true,
                    tension: .5,
                    pointHoverBackgroundColor: 'rgba(256, 0, 0, 1)',
                    borderColor: 'rgba(256, 0, 0, 1)',
                    borderWidth: 2,
                    data: this.defectCountList.map( function(a) { return a.openDefects; } )
                }
            ]
        }
    }

    processOpenDefectCountFact(result : BaseFactObject )
    {
        if (_.isEqual(this.openDefectCountFact, result) == false)
            this.openDefectCountFact = result;
    }

    public onclick()
    {
        this.click.emit(null);
    }

    processDefectCountTimeSeries(result: TimeSeries) : void
    {
        if (_.isEqual(this.timeSeries, result) == false)
        {
            this.timeSeries = result;
            this.defectCountList = [];

            for (let timeSeriesItem of result.timeSeriesList)
            {
                let dc : DefectCount = new DefectCount();
                dc.sprintEndDate = moment(timeSeriesItem.timestamp).toDate();
                dc.openDefects = timeSeriesItem.getFieldNumber("openDefects");
                dc.closedDefects = timeSeriesItem.getFieldNumber("closedDefects");
                dc.totalDefects = timeSeriesItem.getFieldNumber("totalDefects");

                this.defectCountList.push(dc);
            }

            this.processDefectCount(this.defectCountList);
            this.setOpenDefectTrend();
        }
    }

    private setOpenDefectTrend() : void
    {
        let twoSprintsAgo : number = 0;
        let oneSprintAgo : number = 0;
        let lastSprint : number = 0;

        this.openDefectTrend = "neutral";

        if (this.defectCountList.length >= 3)
        {
            let length = this.defectCountList.length;

            twoSprintsAgo = this.defectCountList[length-3].openDefects;
            oneSprintAgo = this.defectCountList[length-2].openDefects;
            lastSprint = this.defectCountList[length-1].openDefects;

            if (twoSprintsAgo < oneSprintAgo && oneSprintAgo < lastSprint)
                this.openDefectTrend = "up";

            if (twoSprintsAgo > oneSprintAgo && oneSprintAgo > lastSprint)
                this.openDefectTrend = "down";
        }
    }

    public onZoomClicked() : void 
    {
        this.zoomGraph.openDialog();
    }

    private onDefectClick() : void
    {
        this.router.navigate(['/dashboard/defect-backlog', this.project.projectId]);
    }
}
