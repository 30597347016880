<div class="grid card card-w-title">
    <h3 *ngIf="sprintEndReport" class="col-12">{{sprintEndReport.title}}</h3>
    <p-messages class="col-12" *ngIf="errorMessages && errorMessages.length == 0" [value]="expMessage"></p-messages>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <mini-sprint-commitment *ngIf="sprintEndReport" class="col-12 sm:col-12 md:col-6" [sprint]="sprintEndReport.sprint"></mini-sprint-commitment>
    <sprint-history *ngIf="sprintEndReport" class="col-12 sm:col-12 md:col-6" [sprint]="sprintEndReport.sprint"></sprint-history>
    <mini-sprint-points-carried-over *ngIf="sprintEndReport" class="col-12 sm:col-12 md:col-6" [sprint]="sprintEndReport.sprint"></mini-sprint-points-carried-over>
    <points-injected *ngIf="sprintEndReport" class="col-12 sm:col-12 md:col-6" [sprint]="sprintEndReport.sprint"></points-injected>

    <div *ngIf="sprintEndReportHtml" class="col-12 content-detail" [innerHTML]="sprintEndReportHtml">
    </div>
</div>
