import { Component, AfterViewInit, ElementRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DocumentInterruptSource, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { UserAccountService } from '../user-account/user-account.service';
import { UserAccount } from '../user-account/user-account';
import { BaseComponent } from '../base/component/base-component.component';
import { Project } from '../project/project';
import { InboxItem } from '../inbox-item/inbox-item';

import { DashboardMenuService } from './dashboard-menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from '../app.component';

import { MessagingService } from '../event/messaging.service';
import { LoggedInUser } from '../authentication/logged-in-user';
import { RefreshProjectListEvent } from '../event/refresh-project-list-event';

import { Subscription, Observable } from "rxjs";

import { BaseDashboardComponent } from './base-dashboard.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss'],
  providers : [ UserAccountService ]
})
export class DashboardComponent extends BaseDashboardComponent implements AfterViewInit, OnInit
{ 
    projectList : Project[];
    userAccount : UserAccount;
    timedOut = false;

    refreshProjectListSubscription : Subscription;
    
    constructor(protected router : Router,
        public renderer: Renderer2,
        public dashboardMenuService : DashboardMenuService,                
        public primeNGConfig : PrimeNGConfig,
        public app : AppComponent,
        private idle: Idle, 
        public messagingService : MessagingService, 
        protected userAccountService : UserAccountService) 
    {
        super(router, renderer, dashboardMenuService, primeNGConfig, app);
        
        // Set-up refreshProjectList subscription
        this.refreshProjectListSubscription = this.messagingService.of(RefreshProjectListEvent)
            .subscribe(message => { 
                if (message.userAccount != undefined)
                    this.setupUserAccountAndProjectList(message.userAccount);
        }); 

        // Timeout will be enabled if configured for organization below.
    }

    // Override base class.
    public ngOnInit() 
    {
        this.menuActive = this.isStatic() && !this.isMobile() && !this.isTablet();
        this.initFields();

        if (this.isTablet() == true)
        {
            this.app.menuMode = 'overlay';
        }
    }

    public ngOnDestroy() : void
    {
        this.refreshProjectListSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        if (LoggedInUser.getLoggedInUser() == undefined)
        {
            this.userAccountService.retrieve()
                .subscribe(result => this.processUserAccount(result),
                            error => { this.handleError(error) } ); 
        }
        else
        {
            this.setupUserAccountAndProjectList(LoggedInUser.getLoggedInUser() );
        }
    }
    
    private processUserAccount(userAccount : UserAccount) : void
    {
        LoggedInUser.setLoggedInUser(userAccount);  
        this.setupUserAccountAndProjectList(LoggedInUser.getLoggedInUser() );
    }
  
    private setupUserAccountAndProjectList(userAccount : UserAccount) : void
    {
        this.userAccount = userAccount;
        this.projectList = this.userAccount.userProjects;
        this.setupTimeout();
    }

    private setupTimeout() : void
    {
        if (this.userAccount != undefined && this.userAccount.organization != undefined && 
            this.userAccount.organization.timeoutEnabled == true)
        {
            // Set-up idle users.
            let defaultInterruptSources : any[] = [new DocumentInterruptSource(
                'focus blur click mousemove keydown keyup mousewheel scroll')];

            // sets an idle timeout of 20 minutes (60 seconds * 20 minutes).
            this.idle.setIdle(1200);
//            this.idle.setIdle(60);

            // Once you go idle, sets a timeout period of 30 seconds.
            this.idle.setTimeout(30);
            
            // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
            //idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            this.idle.setInterrupts(defaultInterruptSources);

            this.idle.onIdleEnd.subscribe(() => { this.clearErrorMessages(); this.idle.interrupt(); } );
            this.idle.onTimeout.subscribe(() => { this.idleLogout(); });

            this.idle.onTimeoutWarning.subscribe((countdown : any) =>
                {
                        this.clearErrorMessages();
                        this.addErrorMessage("You will be logged out in " + countdown + " seconds.  Click here to continue.");
                });
            
            // Do not perform idle processing on mobile devices as innerHTML()
            // is not playing well with this code.
            if (this.isMobile() != true)
            {
                this.idle.watch();
            }
        }
    }
    
    private idleLogout() : void
    {
        this.router.navigate(['logout']);
    }

    public forceInterrupt() : void
    {
        this.idle.stop();
        this.idle.watch();
        this.clearErrorMessages();
    }

    public get loggedInUser() : LoggedInUser
    {
        return LoggedInUser.getLoggedInUser();
    }

    public isTablet() {
        return window.innerWidth > 991 && window.innerWidth <= 1279;
    }
}
