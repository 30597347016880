import { Component, OnInit, OnChanges } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'zoom-graph',
  templateUrl: './zoom-graph.component.html',
  styleUrls: ['./zoom-graph.component.scss' ],
  providers: [ ]
})

export class ZoomGraphComponent implements OnInit, OnChanges 
{ 
    visible : boolean = false;

    @Input()
    sourceData : any = undefined;
    
    @Input()
    sourceOptions : any = undefined;

    @Input()
    type : string;

    data : any = undefined;
    options : any = undefined;

    dialogTitle : string = "";

    constructor() 
    { 
        this.options = {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                }
            },
            scales: { y: { beginAtZero:true }, 
                      x: { display: true } },
            responsive : false,
            maintainAspectRatio : false,
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                }
            }
        }
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void 
    {
        // Make a deep copy of our input parameters.
        if (this.sourceData != undefined)
            this.data = JSON.parse(JSON.stringify(this.sourceData) );
    }

    public openDialog() : void
    {
        // Modify the input parameters as appropriate.
        if (this.sourceOptions != undefined)
            this.options = JSON.parse(JSON.stringify(this.sourceOptions) );

        if (this.options != undefined)
        {
            this.options['responsive'] = false;
            this.options['animations'] = false;
            this.options['plugins']['legend'].position = 'bottom';
            this.options['plugins']['legend'].display = true;
            this.options['plugins']['title'].display = true;
            this.options['plugins']['title'].font = { size : 20 };
            // this.options['scales']['xAxes'] = [{ display: true, ticks: { autoSkip: false } } ];
        }

        if (this.options != undefined &&
            this.options['plugins'] != undefined &&
            this.options['plugins']['title'] != undefined &&
            this.options['plugins']['title'].text != undefined)
        {
            this.dialogTitle = this.options['plugins']['title'].text;
        }
        
        // Display
        this.visible = true;
    }

    public closeDialog() : void
    {
        this.visible = false;
    }
}