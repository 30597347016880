import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message } from 'primeng/api';

import { BaseDetailComponent } from '../base/component/base-detail-component.component';

import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';
import { OrgSourceSystem } from '../org-source-system/org-source-system';

import { ProjectService } from '../project/project.service';
import { Project } from '../project/project';

import { ProjectFactService } from '../project-fact/project-fact.service';
import { ProjectFact } from '../project-fact/project-fact';

import { SprintService } from '../sprint/sprint.service';
import { SprintDetail } from '../sprint/sprint-detail';

import { BacklogItemService } from '../backlog-item/backlog-item.service';
import { BacklogItem } from '../backlog-item/backlog-item';
import { ValueObjectList } from '../base/value-object/value-object-list';

import { UserStoryFact } from '../user-story-fact/user-story-fact';
import { DefectFact } from '../defect-fact/defect-fact';
import { ChoreFact } from '../chore-fact/chore-fact';
import { SpikeFact } from '../spike-fact/spike-fact';
import { DependencyFact } from '../dependency-fact/dependency-fact';

import { Content } from '../content/content';

let upcomingBacklogItemsFact : ProjectFact;
let sprintPlanner : boolean = false;

@Component({
  selector: 'product-backlog',
  templateUrl: './product-backlog.component.html',
  styleUrls: ['./product-backlog.component.css' ],
  providers: [ OrgSourceSystemService, ProjectService, ProjectFactService, SprintService, BacklogItemService ]
})

export class ProductBacklogComponent extends BaseDetailComponent implements OnInit 
{ 
    projectId : string;
    orgSourceSystem : OrgSourceSystem;
    project : Project;
    sprintDetailList : SprintDetail[];
    productBacklog : ValueObjectList<BacklogItem>;
    closedItems : boolean;

    sprintPlanner : boolean = sprintPlanner;
    sprintPlannerQueryParam : string = "off";
    upcomingBacklogItemsFact : ProjectFact = upcomingBacklogItemsFact;
    upcomingBacklogItemsNotReadyFact : ProjectFact;
    backlogDepthFact : ProjectFact;
    numberOfSprintsFact : ProjectFact;

    rows : number = 20;
    upcomingBacklogItemsNotReadyMessage : string = "There are upcoming backlog items that do not meet the definition of ready.  Click on this message for more information.";

    constructor( private orgSourceSystemService : OrgSourceSystemService,
                 private projectService : ProjectService,
                 private sprintService : SprintService,
                 private backlogItemService : BacklogItemService,
                 private route: ActivatedRoute,
                 protected projectFactService : ProjectFactService,
                 protected router: Router) 
    { 
        super(router, projectFactService);
        this.sprintDetailList = [];
        this.closedItems = false;
        sprintPlanner = this.sprintPlanner = false;
    }

    public ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.projectId = params['projectId'];
        });

        this.route.queryParams.forEach((params : Params) => {
            this.sprintPlannerQueryParam = params['sprintPlanner'];
        });

        if (this.sprintPlannerQueryParam == "on")
        {
            sprintPlanner = true;
            this.sprintPlanner = true;
        }

        this.projectService.retrieve(this.projectId)
            .subscribe(result => this.processProject(result),
                       error => this.handleError(error) );

        this.orgSourceSystemService.retrieveByProjectId(this.projectId)
            .subscribe(result => this.processOrgSourceSystem(result), 
                       error => this.handleError(error) );

        this.projectFactService.retrieveById(this.projectId, ProjectFact.UPCOMINGBACKLOGITEMS)
            .subscribe(result => this.processUpcomingBacklogItemsFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieveById(this.projectId, ProjectFact.NUMBEROFSPRINTS)
            .subscribe(result => this.processNumberOfSprintsFact(result),
                       error => this.handleError404Okay(error) );
           
        this.projectFactService.retrieveById(this.projectId, ProjectFact.UPCOMINGBACKLOGITEMSNOTREADY)
            .subscribe(result => this.processUpcomingBacklogItemsNotReadyFact(result),
                       error => this.handleError404Okay(error) );
                                  
        this.projectFactService.retrieveById(this.projectId, ProjectFact.BACKLOGDEPTH)
            .subscribe(result => this.processBacklogDepthFact(result),
                       error => this.handleError404Okay(error) );
                
        this.sprintService.retrieveCurrentSprintDetailList(this.projectId)
            .subscribe(result => this.processCurrentSprints(result),
                       error => this.handleError404Okay(error) );                

        this.retrieveCosts(this.projectId);

        this.productBacklog = new ValueObjectList<BacklogItem>();
    }

    private processProject(result: Project) : void
    {
        this.project = result;
    }

    private processOrgSourceSystem(result: OrgSourceSystem) : void
    {
        this.orgSourceSystem = result;
    }

    private processCurrentSprints(result : SprintDetail[]) : void
    {
        this.sprintDetailList = result;
    }

    private processProductBacklog(result: ValueObjectList<BacklogItem>) : void
    {
        this.productBacklog = result;
    }

    private processUpcomingBacklogItemsFact(result : ProjectFact)
    {
        upcomingBacklogItemsFact = result;
        this.upcomingBacklogItemsFact = upcomingBacklogItemsFact;
    }

    private processNumberOfSprintsFact(result : ProjectFact)
    {
        this.numberOfSprintsFact = result;
    }

    private processUpcomingBacklogItemsNotReadyFact(result : ProjectFact)
    {
        this.upcomingBacklogItemsNotReadyFact = result;
    }

    private processBacklogDepthFact(result : ProjectFact) : void
    {
        this.backlogDepthFact = result;
    }
    
    public disableSprintPlanner() : boolean
    {
        let result : boolean = true;

        if (this.backlogDepthFact != undefined && this.backlogDepthFact.getFactNumber() > 1)
        {
            result = false;
        }
        else
        {
            // Sprint Planner disabled.
            sprintPlanner = false;
            this.sprintPlanner = false;
        }

        return result;
    }

    private onSprintPlannerClicked() : void
    {
        sprintPlanner = this.sprintPlanner;
    }

    public onClosedClicked()
    {
        this.backlogItemService.retrieveProductBacklog(this.project, this.closedItems, 0, this.rows)
            .subscribe(result=>this.processProductBacklog(result),
                       error => this.handleError(error) );
    }

    public onLazyLoad(event:any)
    {
        let startAt : number = event.first;
        let maxResult : number = event.rows;

        this.backlogItemService.retrieveProductBacklog(this.project, this.closedItems, startAt, maxResult)
            .subscribe(result=>this.processProductBacklog(result) );
    }

    public productBacklogRowStyleClass(backlogItem : BacklogItem, index : number) : string
    {
        let result : string = null;
        
        if (upcomingBacklogItemsFact != undefined && sprintPlanner == true)
        {
            let upcomingBacklogItem : boolean = upcomingBacklogItemsFact.getFactStringList().includes(backlogItem.objectId);

            if (upcomingBacklogItem == true)
            {
                backlogItem.showFacts = true;
                
                if (index % 2 == 0)
                    result = 'row-style-class-green-even';
                else
                    result = 'row-style-class-green-odd';
            }
        }

        // Display open backlog items in a closed sprint fact.
        if (sprintPlanner == true && openBacklogItemClosedSprint(backlogItem ) )
            backlogItem.showFacts = true;

        return result;
    }    
/*
    public get upcomingBacklogItemsNotReadyMessage() : string 
    {
        let message : string = 
        let messages: Message[] = [];
        
        messages.push({ severity:'info', summary:'', detail: message });

        return messages;
    }
*/
    public get DEFINITIONOFREADY() : string
    {
        return Content.DEFINITIONOFREADY;
    }
}

// Note this is duplicated in backlog-item-list.component.
// Refactor at some point.
function openBacklogItemClosedSprint(backlogItem : BacklogItem) : boolean
{
    let result : boolean = false;

    if (backlogItem.backlogItemFactList != null)
    {
        if (backlogItem.getFact(UserStoryFact.OPENSTORYCLOSEDSPRINT) != null)
            if (backlogItem.getFact(UserStoryFact.OPENSTORYCLOSEDSPRINT).getFactBoolean() == true)
                result = true;

        if (backlogItem.getFact(DefectFact.OPENDEFECTCLOSEDSPRINT) != null)
            if (backlogItem.getFact(DefectFact.OPENDEFECTCLOSEDSPRINT).getFactBoolean() == true)
                result = true;

        if (backlogItem.getFact(ChoreFact.OPENCHORECLOSEDSPRINT) != null)
            if (backlogItem.getFact(ChoreFact.OPENCHORECLOSEDSPRINT).getFactBoolean() == true)
                result = true;

        if (backlogItem.getFact(SpikeFact.OPENSPIKECLOSEDSPRINT) != null)
            if (backlogItem.getFact(SpikeFact.OPENSPIKECLOSEDSPRINT).getFactBoolean() == true)
                result = true;

        if (backlogItem.getFact(DependencyFact.OPENDEPENDENCYCLOSEDSPRINT) != null)
            if (backlogItem.getFact(DependencyFact.OPENDEPENDENCYCLOSEDSPRINT).getFactBoolean() == true)
                result = true;
    }

    return result;
}
