import { BaseValueObject } from '../base/value-object/base-value-object';

export class State extends BaseValueObject
{
    stateId : string;
    stateName : string;

    public initialize(state : State)
    {
        super.initialize(state);

        this.stateId = state.stateId;
        this.stateName = state.stateName;
    }
}