<div class="grid card card-w-title">
    <h3 class="col-12">User Account Administration</h3>
        
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="userId" 
        [rowHover]="true" [value]="userAccountList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedUserAccount"
        [lazy]="true" [rows]="rows" [paginator]="true" [totalRecords]="total" (onLazyLoad)="loadData($event)">        
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- first name -->
                    <col><!-- last name -->
                    <col><!-- email address -->
                    <col style="width:200px"><!-- login date -->
                    <col style="width:125px"><!-- active -->
                    <col style="width:125px"><!-- admin -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        First Name
                    </th>
                    <th>
                        Last Name
                    </th>
                    <th>
                        Email Address
                    </th>
                    <th>
                        Last Login
                    </th>
                    <th>
                        Active
                    </th>
                    <th>
                        Admin
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-userAccount>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ userAccount.firstName }}
                    </td>
                    <td>
                        {{ userAccount.lastName }}
                    </td>
                    <td [style]="{'overflow':'hidden', 'text-overflow':'ellipsis'}">
                        {{ userAccount.emailAddress }}
                    </td>
                    <td>
                        <span *ngIf="userAccount.loginDate">{{ userAccount.loginDate | dateFormat }}</span>
                    </td>
                    <td>
                        {{ userAccount.isActive }}
                    </td>
                    <td>
                        {{ userAccount.isAdmin() }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="6">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12" id="addButton" #addButtonTag pButton type="button" label="Add" (click)="onAdd()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12" id="updateButton" #updateButtonTag pButton type="button" [disabled]="selectedUserAccount == null" label="Update" (click)="onUpdate()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12" id="deleteButton" #deleteButtonTag pButton type="button" [disabled]="selectedUserAccount == null" label="Delete" (click)="onDelete()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-3">
            <button class="col-12 secondary" id="cancelButton" #cancelButtonTag pButton type="button" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

     <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
