import { BaseValueObject } from './base-value-object';

export abstract class BaseAgileObject extends BaseValueObject
{
    orgSourceSystemId : string;
    sourceSystemKey : string;
    deleted : boolean;

    public initialize(object: BaseAgileObject)
    {
        super.initialize(object);
        
        this.orgSourceSystemId = object.orgSourceSystemId;
        this.sourceSystemKey = object.sourceSystemKey;
        this.deleted = object.deleted;
    }
}