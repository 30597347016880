
import { NgModule }       from '@angular/core';
import { FormsModule }    from '@angular/forms';
import { CommonModule }   from '@angular/common';

import { HighlightPipe } from './highlight.pipe';
import { Text2HtmlPipe } from './text2html.pipe';
import { RoundPipe } from './round.pipe';
import { CeilingPipe } from './ceiling.pipe';
import { FloorPipe } from './floor.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { PluralPipe } from './plural.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    HighlightPipe,
    Text2HtmlPipe,
    RoundPipe,
    CeilingPipe,
    FloorPipe,
    PluralPipe,
    DateFormatPipe,
    RelativeTimePipe
  ],
  exports: [
    HighlightPipe,
    Text2HtmlPipe,
    RoundPipe,
    CeilingPipe,
    FloorPipe,
    PluralPipe,
    DateFormatPipe,
    RelativeTimePipe
  ],
  providers: [
  ]
})

export class PipesModule {}
