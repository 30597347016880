import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';
import { BaseBacklogItemObject } from '../../base/value-object/base-backlog-item-object';

import { ContentDetail } from '../../content/content-detail';

@Component({
  selector: 'story-point',
  templateUrl: './story-point.component.html',
  styleUrls: ['./story-point.component.scss' ],
  providers: [  ]
})

export class StoryPointComponent extends BaseComponent implements OnInit 
{ 

    @Input()
    backlogItem : BaseBacklogItemObject;

    @Input()
    tooltipPosition : string = "left";
    
    constructor(protected router : Router) 
    { 
        super(router);
    }

    ngOnInit() : void
    {
    }

    public pointsInRange(backlogItem : BaseBacklogItemObject) : boolean
    {
        let result : boolean = true;

		if (backlogItem.storyPoints == null && backlogItem.sourceSystemStoryPoints != null && this.unfinished(backlogItem) == false)
            result = false;

        return result;
    }

    public unfinished(backlogItem : BaseBacklogItemObject) : boolean
    {
        let result : boolean = false;

		if (backlogItem.unfinished == true && backlogItem.storyPoints == null && backlogItem.sourceSystemStoryPoints != null)
            result = true;

        return result;
    }

    public pointsOnStory(backlogItem : BaseBacklogItemObject) : boolean
    {
        let result : boolean = true;

		if ((backlogItem.storyPoints == null || backlogItem.storyPoints == 0) && this.unfinished(backlogItem) == false)
            result = false;

        return result;
    }

    public get POINTSONDEFECTS() : string
    {
        return ContentDetail.POINTSONDEFECTS;
    }

    public get POINTSONCHORES() : string
    {
        return ContentDetail.POINTSONCHORES;
    }

    public get POINTSCHANGED() : string
    {
        return ContentDetail.POINTSCHANGED;
    }

    public get STORYPOINTS() : string 
    {
        return ContentDetail.STORYPOINTS;
    }

    public get UNFINISHEDVELOCITY() : string
    {
        return ContentDetail.UNFINISHEDVELOCITY;
    }
}
