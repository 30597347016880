import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform 
{
    public transform(itemCount: number, singularDescription: string): string 
    {
        let result : string = "";
        let description : string = "";

        // This isn't perfect for 'es' cases but will work for now.
        if (itemCount != 1)
            description = singularDescription + 's';
        else
            description = singularDescription;
        
        result = itemCount + ' ' + description;

        return result;
    }
}