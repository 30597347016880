import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { BaseHttpService } from '../base/service/base-http.service';
import { Observable } from 'rxjs';
import { Project } from '../project/project';
import { Sprint } from './sprint';
import { SprintDetail } from './sprint-detail';
import { UserStory } from '../user-story/user-story';
import { Defect } from '../defect/defect';
import { Chore } from '../chore/chore';
import { Spike } from '../spike/spike';
import { Dependency } from '../dependency/dependency';

import { ValueObjectList } from '../base/value-object/value-object-list';

@Injectable()
export class SprintService extends BaseHttpService
{
    sprintUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.sprintUrl = this.config.baseUrl + '/rest/sprint';
    }

    public retrieveById(sprintId : string) : Observable<SprintDetail>
    {
        let sprintUrl = this.sprintUrl + "/" + sprintId; 
        return super.baseRetrieveObject(SprintDetail, sprintUrl); 
    }

    public retrieve(sprint : Sprint) : Observable<SprintDetail>
    {
        let sprintUrl = this.sprintUrl + "/" + sprint.sprintId; 
        return super.baseRetrieveObject(SprintDetail, sprintUrl); 
    }

    public retrieveByIdArray(project : Project, sprintIdList : string[]) : Observable<Sprint[]>
    {
        let postData = { 
            projectId : project.projectId,
            sprintIdList : sprintIdList
        };

        let sprintUrl = this.sprintUrl + "/sprint-list-by-id-array"; 
        return super.baseCreateList(Sprint, sprintUrl, JSON.stringify(postData) );
    }

    public retrieveCompleted(projectId : string) : Observable<Sprint[]>
    {
        let sprintCompletedUrl = this.sprintUrl + "/list?projectId=" + projectId;
        return super.baseRetrieveList(Sprint, sprintCompletedUrl);
    }

    public retrieveCompletedSubset(projectId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<Sprint>>
    {
        let subsetCompletedUrl = `${this.sprintUrl}/completed/${projectId}/subset`;
        return super.baseRetrieveSubset(Sprint, subsetCompletedUrl, startAt, maxResult);
    }

    public retrieveFutureSubset(projectId : string, startAt : number, maxResult : number) : Observable<ValueObjectList<Sprint>>
    {
        let subsetFutureUrl = `${this.sprintUrl}/future/${projectId}/subset`;
        return super.baseRetrieveSubset(Sprint, subsetFutureUrl, startAt, maxResult);
    }
    
    public retrieveByUserStory(userStory: UserStory) : Observable<Sprint>
    {
        let userStorySprintUrl = this.sprintUrl + "/user-story/" + userStory.userStoryId; 
        return super.baseRetrieveObject(Sprint, userStorySprintUrl); 
    }

    public retrieveByDefect(defect: Defect) : Observable<Sprint>
    {
        let defectSprintUrl = this.sprintUrl + "/defect/" + defect.defectId; 
        return super.baseRetrieveObject(Sprint, defectSprintUrl); 
    }

    public retrieveByChore(chore: Chore) : Observable<Sprint>
    {
        let choreSprintUrl = this.sprintUrl + "/chore/" + chore.choreId; 
        return super.baseRetrieveObject(Sprint, choreSprintUrl); 
    }

    public retrieveBySpike(spike: Spike) : Observable<Sprint>
    {
        let spikeSprintUrl = this.sprintUrl + "/spike/" + spike.spikeId; 
        return super.baseRetrieveObject(Sprint, spikeSprintUrl); 
    }

    public retrieveByDependency(dependency: Dependency) : Observable<Sprint>
    {
        let dependencySprintUrl = this.sprintUrl + "/dependency/" + dependency.dependencyId; 
        return super.baseRetrieveObject(Sprint, dependencySprintUrl); 
    }

    public retrieveCurrentSprintList(projectId : string) : Observable<Sprint[]>
    {
        let currentSprintUrl = this.sprintUrl + "/current?projectId=" + projectId;
        return super.baseRetrieveList(Sprint, currentSprintUrl); 
    }

    public retrieveCurrentSprintDetailList(projectId : string) : Observable<SprintDetail[]>
    {
        let currentSprintUrl = this.sprintUrl + "/current-detail?projectId=" + projectId;
        return super.baseRetrieveList(SprintDetail, currentSprintUrl); 
    }
}