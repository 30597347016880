import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild} from '@angular/core';

import { BaseComponent } from '../../base/component/base-component.component';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css' ],
  providers: [ ]
})

export class ProgressBarComponent extends BaseComponent implements OnInit { 

    @Input()
    description : string = "Loading...";

    constructor( protected router : Router) 
    { 
        super(router);
    }

    ngOnInit() : void
    {
    }
}
