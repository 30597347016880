import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SprintEndReportService } from '../sprint-end-report/sprint-end-report.service';
import { SprintEndReport } from './sprint-end-report';
import { Message } from 'primeng/api';

import { BaseComponent } from '../base/component/base-component.component';

@Component({
  selector: 'sprint-end-report',
  templateUrl: './sprint-end-report.component.html',
  styleUrls: ['./sprint-end-report.component.css'],
  providers: [ SprintEndReportService ]
})
export class SprintEndReportComponent extends BaseComponent implements OnInit
{ 
    sprintEndReport : SprintEndReport;
    sprintEndReportId : string;
    sprintEndReportHtml : SafeHtml = undefined;

    expMessage : Message[] = [ {severity:'info', summary:'', detail: "The sprint report is an experimental feature.  Use information with caution!" } ];

    constructor(protected router:Router,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private sprintEndReportService: SprintEndReportService) 
    {
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.sprintEndReportId = params['sprintEndReportId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.sprintEndReport = undefined;
            this.sprintEndReportId = params['sprintEndReportId'];
            this.initFields();
        });
    }

    public ngOnInit() : void
    {
    }

    public initFields() : void
    {
        super.clearErrorMessages();

        this.sprintEndReportService.retrieve(this.sprintEndReportId)
            .subscribe(result=>this.processSprintEndReport(result),
                       error => this.handleError(error) );
    }

    processSprintEndReport(result: SprintEndReport) : void
    {
        this.sprintEndReport = result;
        this.sprintEndReportHtml = this.sanitizer.bypassSecurityTrustHtml(this.sprintEndReport.detail);
    }

    protected handleError(error : any) : any
    {
        if (error.status == 403)
        {
            super.addErrorMessage("You are not authorized to view this sprint report.");
        }
        else
        {
            super.addErrorMessage("Error occurred. Please try again.");
        }

        return error.message || error;
     }
}
