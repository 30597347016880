import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { Project } from '../project/project';
import { Release } from '../release/release';
import { Epic } from '../epic/epic';
import { Sprint } from '../sprint/sprint';

import { ChoreFact } from '../chore-fact/chore-fact';

export class Chore extends BaseBacklogItemObject
{
    public choreId : string;
    public choreFactList : ChoreFact[];

    public initialize(chore : Chore) : void
    {
        if (chore == null)
            return;
        
        super.initialize(chore);

        this.choreId = chore.choreId;
        this.objectType = Chore.CHORE;
        this.choreFactList = this.initializeList(ChoreFact, chore.choreFactList);

        this.backlogItemFactList = this.choreFactList;
    }

    public getFact(factName : string) : ChoreFact
    {
        let result : ChoreFact = null;

        if (this.choreFactList != null)
        {
            for (let choreFact of this.choreFactList)
            {
                if (choreFact.name == factName)
                {
                    result = choreFact;
                    break;
                }
            }
        }

        return result;
    }
}