import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config/config';
import { BaseHttpService } from '../../base/service/base-http.service';
import { Observable } from 'rxjs';
import { OrgSourceSystem } from '../../org-source-system/org-source-system';
import { Project } from '../../project/project';
import { ProjectFilter } from './project-filter';

@Injectable()
export class ProjectFilterService extends BaseHttpService
{
    projectFilterUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.projectFilterUrl = this.config.baseUrl + '/rest/projectfilter';
    }

    public retrieveProjectList(orgSourceSystem : OrgSourceSystem) : Observable<Project[]>
    {
        let url = this.projectFilterUrl + "/" + orgSourceSystem.orgSourceSystemId + "/projectlist";
        return super.baseRetrieveList(Project, url);
    }

    public retrieveFilteredProjectList(orgSourceSystem : OrgSourceSystem) : Observable<Project[]>
    {
        let url = this.projectFilterUrl + "/" + orgSourceSystem.orgSourceSystemId + "/filtered-projectlist";
        return super.baseRetrieveList(Project, url);
    }

    public createProjectFilterList(projectList : Project[], orgSourceSystem : OrgSourceSystem) : Observable<ProjectFilter[]>
    {
        let projectFilterList : Array<ProjectFilter> = new Array<ProjectFilter>();
        let url = this.projectFilterUrl + "?orgSourceSystemId=" + orgSourceSystem.orgSourceSystemId;

        for (let project of projectList)
        {
            let projectFilter = new ProjectFilter();
            projectFilter.orgSourceSystemId = orgSourceSystem.orgSourceSystemId;
            projectFilter.sourceSystemKey = project.sourceSystemKey;

            projectFilterList.push(projectFilter);
        }

        let data = JSON.stringify(projectFilterList);

        return super.baseCreateList(ProjectFilter, url, data);    
    }
}
