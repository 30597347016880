import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config/config';
import { BaseHttpService } from '../../base/service/base-http.service';
import { Observable } from 'rxjs';

import { AlertText } from './alert-text';

@Injectable()
export class AlertTextService extends BaseHttpService
{
    alertTextUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.alertTextUrl = this.config.baseUrl + '/rest/alert-text';
    }

    public retrieveAll() : Observable<AlertText[]>
    {
        let url = this.alertTextUrl + "/list";
        return super.baseRetrieveList(AlertText, url);
    }

    public update(alertText: AlertText) : Observable<AlertText>
    {
        let url = this.alertTextUrl;
        return super.baseUpdateObject(AlertText, url, alertText);   
    }
}
