import { BaseValueObject } from '../base/value-object/base-value-object';

export class SourceSystem extends BaseValueObject
{
    sourceSystemId: string;
    description: string;

	public static readonly JIRA_API : string = "b71a9e0e-3dc1-4489-a38a-4a5825c19013";
	public static readonly JIRA_AGILE : string = "eee3ec68-171a-4a15-b33d-e61ae8c6f592";
	public static readonly TFS : string = "a22ed474-c329-45aa-807c-9de19cb8fa0d";
	public static readonly AGILECENTRAL : string = "248bd273-8053-4282-a1d6-75b10fef0a99";

    public initialize(object : SourceSystem) : void
    {
        super.initialize(object);
        
        this.sourceSystemId = object.sourceSystemId;
        this.description = object.description;
    }
}