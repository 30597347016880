import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { ProjectFact } from '../../project-fact/project-fact';

import { ReleaseFactService } from '../../release-fact/release-fact.service';
import { ReleaseFact } from '../../release-fact/release-fact';
import { Release } from '../../release/release';

import { Content } from '../../content/content';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'release-point-status',
  templateUrl: './release-point-status.component.html',
  styleUrls: ['./release-point-status.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ ProjectFactService, ReleaseFactService ]
})

export class ReleasePointStatusComponent extends BaseUIComponent implements OnInit 
{ 
    @Input()
    release : Release;

    @Input()
    displayCosts : boolean = false;

    averageVelocity : ProjectFact;
    numberOfSprints : ReleaseFact;
    sprintsRemaining : ReleaseFact;
    predictedCompletion : ReleaseFact;
    releaseOnTarget : ReleaseFact;

    costPerPoint : ReleaseFact;
    scope : ReleaseFact;

    data: any;
    options: any;

    constructor(protected router : Router,
                private projectFactService: ProjectFactService,
                private releaseFactService: ReleaseFactService) 
    { 
        super(router);

        this.options = {
            indexAxis : 'y',
            plugins: {
                title: {
                    display: false,
                    text: 'Sprints Remaining'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            },
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
        }
    }

    ngOnInit() : void
    {
        this.projectFactService.retrieve(this.release.project, ProjectFact.AVERAGEVELOCITY)
            .subscribe(result => this.processProjectAverageVelocity(result),
                       error => this.handleError (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.SPRINTSREMAINING)
            .subscribe(result =>this.processSprintsRemaining(result),
                       error => this.handleError404Okay (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASENUMBEROFSPRINTS)
            .subscribe(result =>this.processNumberOfSprints(result),
                       error => this.handleError (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.PREDICTEDCOMPLETION)
            .subscribe(result =>this.processPredictedCompletion(result),
                       error => this.handleError404Okay(error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASEONTARGET)
            .subscribe(result =>this.processReleaseOnTarget(result),
                       error => this.handleError404Okay(error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASESCOPE)
            .subscribe(result =>this.processScope(result),
                       error => this.handleError (error) );

        this.releaseFactService.retrieve(this.release, ReleaseFact.RELEASECOSTPERPOINT)
            .subscribe(result =>this.processCostPerPoint(result),
                       error => this.handleError404Okay(error) );
           

                                  
    }

    private processProjectAverageVelocity(result : ProjectFact) : void
    {
        this.averageVelocity = result;
        this.buildGraph();
    }

    private processSprintsRemaining(result : ReleaseFact) : void
    {
        // -1 indicated an average velocity of zero.
        if (result.getFactNumber() > -1)
        {
            this.sprintsRemaining = result;
            this.buildGraph();
        }
    }

    private processNumberOfSprints(result : ReleaseFact) : void
    {
        this.numberOfSprints = result;
        this.buildGraph();
    }

    private processPredictedCompletion(result : ReleaseFact) : void
    {
        this.predictedCompletion = result;
    }

    private processReleaseOnTarget(result : ReleaseFact) : void
    {
        this.releaseOnTarget = result;
    }

    private processScope(result : ReleaseFact) : void
    {
        if (_.isEqual(this.scope, result) == false)
            this.scope = result;
    }

    private processCostPerPoint(result: ReleaseFact) : void
    {
        if (_.isEqual(this.costPerPoint, result) == false)
            this.costPerPoint = result;
    }

    private buildGraph()
    {
        if (this.sprintsRemaining == undefined || this.numberOfSprints == undefined)
            return;

        this.data = {
            labels : ['Sprints'],
            datasets: [  
                {
                    type:'bar',
                    label: 'Remaining',
                    backgroundColor: this.BLUETRANSPARENT,
                    hoverBackgroundColor: this.BLUE,
                    borderColor: this.BLUE,
                    borderWidth: 2,
                    data: [ this.sprintsRemaining.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Completed',
                    backgroundColor: this.ORANGETRANSPARENT,
                    hoverBackgroundColor: this.ORANGE,
                    borderColor: this.ORANGE,
                    borderWidth: 2,
                    data: [ this.numberOfSprints.getFactNumber() ]
                }
                              
            ]
        }
    }

    public get totalCost() : number
    {
        let result : number = 0;

        if (this.costPerPoint && this.scope)
            result = this.costPerPoint.getFactNumber() * this.scope.getFactNumber();

        return result;
    }


    public get RELEASEDATEATRISK() : string {
        return Content.RELEASEDATEATRISK;
    }
}
