import { BaseValueObject } from '../base/value-object/base-value-object';
import { TimeSeriesItem } from './time-series-item';

export class TimeSeries extends BaseValueObject {

	public timeSeriesId : string;
	public objectId : string;
	public name : string;
	public timeSeriesList : TimeSeriesItem[] = new Array<TimeSeriesItem>();
	
	public static readonly RELEASEDEFECTCOUNT : string = "releaseDefectCount";
    public static readonly PROJECTVELOCITY : string = "projectVelocity";
	public static readonly RELEASEBURNUP : string = "releaseBurnup";
	public static readonly RELEASECOSTBURNUP : string = "releaseCostBurnup";
	public static readonly RELEASEDEFECTPOTENTIAL : string = "releaseDefectPotential";
	public static readonly EPICDEFECTCOUNT : string = "epicDefectCount";
	public static readonly EPICBURNUP : string = "epicBurnup";
	public static readonly EPICCOSTBURNUP : string = "epicCostBurnup";
	public static readonly EPICDEFECTPOTENTIAL : string = "epicDefectPotential";
	public static readonly PROJECTDEFECTCOUNT : string = "projectDefectCount";
	public static readonly PROJECTPOINTSINJECTED : string = "projectPointsInjected";
	public static readonly PROJECTPOINTSCARRIEDOVER : string = "projectPointsCarriedOver";
	public static readonly PROJECTALERTCOUNT : string = "projectAlertCount";
	public static readonly COMMITMENTATTAINMENT : string = "projectCommitmentAttainment";
	public static readonly SPRINTBURNDOWN : string = "sprintBurnDown";
	public static readonly PROJECTCOSTPERPOINT : string = "projectCostPerPoint";
	public static readonly PROJECTDEFECTDENSITY : string = "projectDefectDensity";
	public static readonly PROJECTDEFECTREMOVALRATE	:string = "projectDefectRemovalRate";
	public static readonly PROJECTAVERAGEDEFECTMTTR : string = "projectAverageDefectMTTR";
	public static readonly PROJECTTOPSCRUMMATURITY : string = "projectTopScrumMaturity";
	public static readonly PROJECTSCRUMMATURITYCLOSEDSPRINTS : string = "projectScrumMaturityClosedSprints";
	public static readonly PROJECTPERCENTQUALITYSTORIES : string = "projectPercentQualityStories";
	public static readonly PROJECTSPRINTBACKLOGITEMCOUNT : string = "projectSprintBacklogItemCount";
	public static readonly PROJECTAVERAGEPOINTSPERSTORY : string = "projectAveragePointsPerStory";
	public static readonly PROJECTPOINTDISTRIBUTION : string = "projectPointDistribution";
	
    public initialize(timeSeries : TimeSeries) : void {

		super.initialize(timeSeries);

        this.timeSeriesId = timeSeries.timeSeriesId;
        this.objectId = timeSeries.objectId;
        this.name = timeSeries.name;

        this.timeSeriesList = this.initializeList(TimeSeriesItem, timeSeries.timeSeriesList);
    }

	// Re-sort the timeseries list in descending order.
	public sortDescending() : void {
		this.timeSeriesList = this.timeSeriesList.sort(
			(timeSeriesItem1 : TimeSeriesItem, timeSeriesItem2 : TimeSeriesItem) => {

				// If the timestamps are equal, then go based on date created timestamp.
				if (timeSeriesItem2.timestamp.getTime() == timeSeriesItem1.timestamp.getTime() )
					return timeSeriesItem2.dateCreated.getTime() - timeSeriesItem1.dateCreated.getTime();
				else
					return timeSeriesItem2.timestamp.getTime() - timeSeriesItem1.timestamp.getTime();
			});
	}

	// Re-sort the timeseries list in ascending order.
	public sortAscending() : void {
		this.timeSeriesList = this.timeSeriesList.sort(
			(timeSeriesItem1 : TimeSeriesItem, timeSeriesItem2 : TimeSeriesItem) => {

				// If the timestamps are equal, then go based on date created timestamp.
				if (timeSeriesItem1.timestamp.getTime() == timeSeriesItem2.timestamp.getTime() )
					return timeSeriesItem1.dateCreated.getTime() - timeSeriesItem2.dateCreated.getTime();
				else
					return timeSeriesItem1.timestamp.getTime() - timeSeriesItem2.timestamp.getTime();
			});
	}

}