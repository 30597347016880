import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild} from '@angular/core';

import { Goal } from '../../goal/goal';

import { Content } from '../../content/content';
import { ContentService } from '../../content/content.service';

import { BaseComponent } from '../../base/component/base-component.component';

import * as moment from 'moment';
import * as _ from 'lodash'; 

@Component({
  selector: 'goal-progress',
  templateUrl: './goal-progress.component.html',
  styleUrls: ['./goal-progress.component.scss' ],
  encapsulation : ViewEncapsulation.None,
  providers: [ ContentService ]
})

export class GoalProgressComponent extends BaseComponent implements OnInit, OnChanges { 

    @Input()
    goal : Goal;

    content : Content;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private contentService : ContentService ) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        this.loadData();
    }

    public ngOnChanges() : void
    {
        this.loadData();
    }

    public loadData() : void
    {
        if (this.goal.goalText.contentKey != undefined)
        {
            this.contentService.retrieveByKey(this.goal.goalText.contentKey)
                .subscribe(result => this.processContent(result),
                           error => super.handleError404Okay(error) );
        }
    }

    protected processContent(result : Content) : void 
    {
        if (_.isEqual(this.content, result) == false)
            this.content = result;
    }

    public onContent() : void
    {
        this.router.navigate(['/dashboard/content-detail/key', this.content.contentKey], { queryParams : { "projectId" : this.goal.projectId } } );
    }    
}
