import { BaseValueObject } from '../base/value-object/base-value-object';
import { UserAccount } from '../user-account/user-account';

export class PasswordReset extends BaseValueObject
{
	public passwordResetId : string;
    public userAccount : UserAccount;
    public resetTokenId : string;
    public tokenExpires : string;
    public password : string;

    public initialize(passwordReset : PasswordReset) : void
    {
        super.initialize(passwordReset);

        this.passwordResetId = passwordReset.passwordResetId;
        this.resetTokenId = passwordReset.resetTokenId;
        this.tokenExpires = passwordReset.tokenExpires;
        this.userAccount = this.initializeObject(UserAccount, passwordReset.userAccount);
    }
}