import { Component, Input } from '@angular/core';
import { ContextVariable } from './context-variable';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/component/base-component.component';

@Component({
  selector: 'context-variable-list',
  templateUrl: './context-variable-list.component.html',
  styleUrls: ['./context-variable-list.component.css'],
  providers: [ ]
})
export class ContextVariableListComponent extends BaseComponent
{
    @Input()
    contextVariableList : ContextVariable[];

    @Input()
    header: string;

    constructor(protected router : Router) 
    {
        super(router);
    }
}
