import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BaseProjectTeamCostComponent } from './base-project-team-cost.component';
import { ConfirmationService } from 'primeng/api';

import { ProjectTeamCost } from './project-team-cost';
import { ProjectTeamCostService } from './project-team-cost.service';

@Component({
  selector: 'project-team-cost-update',
  templateUrl: './project-team-cost-update.component.html',
  styleUrls: ['./project-team-cost-update.component.scss' ],
  providers: [ ProjectTeamCostService, ConfirmationService ]
})

export class ProjectTeamCostUpdateComponent extends BaseProjectTeamCostComponent implements OnInit 
{ 
    projectTeamCostId : string;
    projectTeamCost : ProjectTeamCost;

    constructor( protected projectTeamCostService : ProjectTeamCostService,
                 protected confirmationService : ConfirmationService,
                 protected route: ActivatedRoute,
                 protected router: Router) 
    { 
        super(router);
        
        this.route.params.forEach((params: Params) => {
            this.projectTeamCostId = params['projectTeamCostId'];
        });

        // Subscribe to changes in route parameters.
        this.route.params.subscribe(params => {
            this.projectTeamCostId = params['projectTeamCostId'];
            this.loadData();
        });

    }

    public ngOnInit() : void
    {
        this.clearErrorMessages();
    }

    public loadData() : void
    {
        this.projectTeamCostService.retrieve(this.projectTeamCostId)
                .subscribe(result => this.processProjectTeamCost(result),
                           error => this.handleError(error) );
    }

    private processProjectTeamCost(result : ProjectTeamCost) : void
    {
        this.projectTeamCost = result;
    }

    public onUpdate() : void
    {
        this.clearErrorMessages();

        if (this.validatePage(this.projectTeamCost.effectiveDate, 
                    this.projectTeamCost.endDate,
                    this.projectTeamCost.currentDateEndDate,
                    this.projectTeamCost.costPerMonth) == false)
            return;

        this.confirmationService.confirm(
            { message: 'Are you sure you wish to update these costs?',
                accept: () => 
                {
                    this.updateProjectTeamCost();
                }
            });
    }

    public updateProjectTeamCost() : void 
    {
        this.projectTeamCostService.update(this.projectTeamCost)
            .subscribe(result => this.updateProjectTeamCostSuccessful(result),
                       error => this.handleError(error) );
    }

    private updateProjectTeamCostSuccessful(result : ProjectTeamCost) : void
    {
        this.addInfoMessage("Successfully updated team costs!");
        this.projectTeamCost = result;
    }

    public onCancel() : void
    {
        this.router.navigate(['/dashboard/project-team-cost-list', this.projectTeamCost.project.projectId] );
    }

}
