import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { UserAccountService } from '../../user-account/user-account.service';
import { UserAccount } from '../../user-account/user-account';
import { BaseComponent } from '../../base/component/base-component.component';
import { State } from '../../state/state';
import { Zipcode } from '../../zipcode/zipcode';
import { StateService } from '../../state/state.service';
import { ZipcodeService } from '../../zipcode/zipcode.service';
import { ProjectService } from '../../project/project.service';
import { Project } from '../../project/project';

import { Role } from '../../role/role';
import { Preference } from '../../preference/preference';

import { MessagingService } from '../../event/messaging.service';
import { RefreshProjectListEvent } from '../../event/refresh-project-list-event';
import { LoggedInUser } from '../../authentication/logged-in-user';

import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'user-account-update',
  templateUrl: './user-account-update.component.html',
  styleUrls: ['./user-account-update.component.css'],
  providers: [ ConfirmationService, UserAccountService, StateService, ZipcodeService, ProjectService ]
})
export class UserAccountUpdateComponent extends BaseComponent implements OnInit
{ 
    admin : boolean = false;
    finuser : boolean = false;
    finadmin : boolean = false;
    
    displayMaturity : boolean = true;
    userId : string;
    userAccount : UserAccount;
    stateList : State[];
    stateItems : SelectItem[];
    sourceProjectList : Project[];

    selectedStateId : string;

    constructor(protected router:Router,
                private route: ActivatedRoute,
                private confirmationService: ConfirmationService,
                private userAccountService: UserAccountService,
                private projectService: ProjectService,
                private stateService: StateService,
                private zipcodeService: ZipcodeService,
                private messagingService : MessagingService)
    {
        super(router);
        this.userAccount = new UserAccount();
        this.stateList = [];
        this.sourceProjectList = [];
    }

    public ngOnInit() : void
    {
        this.route.params.forEach((params: Params) => {
            this.userId = params['userId'];
        });

        this.initFields();
    }

    public initFields() : void
    {
        this.errorMessages = [];

        this.userAccountService.retrieveByUserId(this.userId)
          .subscribe(result => this.processUserAccount(result),
                     error => this.handleError(error) ); 

        this.projectService.retrieveAvailable(this.userId)
          .subscribe(result => this.processSourceProjectList(result),
                     error => this.handleError(error) ); 

        this.stateService.retrieveAll()
          .subscribe(result => this.processStates(result),
                     error => this.handleError(error) ); 
    }

    private processUserAccount(userAccount : UserAccount)
    {
        this.userAccount = userAccount;
        this.selectedStateId = userAccount.stateId;

        this.admin = this.userAccount.isAdmin();
        this.finuser = this.userAccount.hasRole(Role.FINUSER);
        this.finadmin = this.userAccount.hasRole(Role.FINADMIN);
        this.displayMaturity = this.userAccount.hasPreference(Preference.DISPLAYMATURITYRATING);

        // Make sure we assign a user role.
        this.userAccount.makeUser();
    }

    private processSourceProjectList(projectList : Project[])
    {
        this.sourceProjectList = projectList;
    }

    private processStates(states : State[])
    {
        this.stateList = states;
        this.stateItems = [];

        this.stateItems.push({label: '[None Selected]', value:null } );
        
        for(var state of this.stateList)
        {
            this.stateItems.push({label: state.stateName.trim(), value:state.stateId })
        }
    }

     public onUpdate() : void
     {
        this.clearErrorMessages();
        
        if (this.userAccount.zipcode != null && this.userAccount.zipcode.length == 0)
            this.userAccount.zipcode = undefined;
        
        if (this.userAccount.zipcode != undefined)
        {
            this.zipcodeService.retrieve(this.userAccount.zipcode, this.selectedStateId)
                .subscribe(result => this.validateFormAndUpdate(),
                           error => this.handleZipcodeError(error) )  
        }
        else
        {
            this.validateFormAndUpdate();
        }
    }

     private handleUpdate(result : UserAccount) : void
     {
         this.processUserAccount(result);
         this.addInfoMessage("Update succeeded!");

         // If we just updated the project list for the logged in user
         // then refresh the list.
         if (LoggedInUser.getLoggedInUser().userId == result.userId)
         {  
            LoggedInUser.setLoggedInUser(result);
            this.messagingService.publish(new RefreshProjectListEvent(result) );
         }
     }

     private handleZipcodeError(result: any) : void
     {
            super.addErrorMessage("Invalid zipcode!");
     }

     public onCancel() : void
     {
        this.router.navigate(['/dashboard/admin/user-account-list']);
     }

     private validateFormAndUpdate() : void
     {
        super.clearErrorMessages();

        let valid = true;
        
        if (LLUtils.isStringEmpty(this.userAccount.firstName) )
        {
            valid = false;
            this.addErrorMessage("First name must be provided.");
        }

        if (LLUtils.isStringEmpty(this.userAccount.lastName) )
        {
            valid = false;
            this.addErrorMessage("Last name must be provided.");
        }

        if (this.userAccount.isValidEmailAddress() == false )
        {
            valid = false;
            super.addErrorMessage("Invalid email address.");
        }

         // Update the database.
         if (valid)
         {  
            this.userAccount.stateId = this.selectedStateId;

            if (this.admin)
                this.userAccount.makeAdmin();
            else
                this.userAccount.removeAdmin();

            if (this.finuser == true)
                this.userAccount.addRole(Role.FINUSER);
            else
                this.userAccount.removeRole(Role.FINUSER);

            if (this.finadmin == true)
                this.userAccount.addRole(Role.FINADMIN);
            else
                this.userAccount.removeRole(Role.FINADMIN);

            // Enable / display maturity preference.
            if (this.displayMaturity == true)
                this.userAccount.addPreference(Preference.DISPLAYMATURITYRATING);
            else
                this.userAccount.removePreference(Preference.DISPLAYMATURITYRATING);

            this.userAccountService.update(this.userAccount)
                .subscribe(result => this.handleUpdate(result),
                           error => this.handleError(error) );
         }
     }

     public onResetPassword() : void
     {
         this.confirmationService.confirm(
             { message: 'Are you sure you wish to reset the password for this user?',
                 accept: () => 
                 {
                     super.clearErrorMessages();
                     this.userAccountService.resetPasswordByUser(this.userAccount)
                         .subscribe(result => this.resetPasswordSuccessful(result),
                                    error => this.resetPasswordFailed(error) );
                 }
             });
     }
 
     private resetPasswordSuccessful(response : any) : void
     {
         this.addInfoMessage("Reset password request succeeded and password reset email sent.");
        }
 
     private resetPasswordFailed(error: any) : void
     {
         this.addErrorMessage("Reset password failed.  Please try again");
     }
 
}
