import { BaseFactObject } from '../base/value-object/base-fact-object';

export class EpicFact extends BaseFactObject
{
	// Epic FACT CONSTANTS
	public static readonly EPICSCOPE : string = "epicScope";
	public static readonly EPICPOINTSCOMPLETED : string = "epicPointsCompleted";
	public static readonly EPICSTORIESSIZED	: string = "epicStoriesSized";
	public static readonly EPICSTORIESUNSIZED : string = "epicStoriesUnsized";
	public static readonly EPICINCURRENTSPRINT : string = "epicInCurrentSprint";
	public static readonly ACTIVEEPIC : string = "epicActiveEpic";
	public static readonly INACTIVEEPIC : string = "epicInactiveEpic";
	public static readonly COMPLETEDEPIC : string = "epicCompletedEpic";
	public static readonly FUTUREEPIC : string = "epicFutureEpic";
	public static readonly EPICCOSTPERPOINT : string = "epicCostPerPoint";
	public static readonly OPENDEFECTCOUNT : string = "epicOpenDefectCount";
	public static readonly CLOSEDDEFECTCOUNT : string = "epicClosedDefectCount";
	public static readonly TOTALDEFECTCOUNT : string = "epicTotalDefectCount";
	public static readonly EPICINPROGRAMINCREMENT : string = "epicInProgramIncrement";
	public static readonly EPICINPROGRAMINCREMENTCURRENTQUARTER : string = "epicInProgramIncrementCurrentQuarter";
	public static readonly EPICINPROGRAMINCREMENTLASTQUARTER : string = "epicInProgramIncrementLastQuarter";
	public static readonly EPICPOINTSCOMPLETEDCURRENTQUARTERPI : string = "epicPointsCompletedCurrentQuarterPI";
	public static readonly EPICPOINTSCOMPLETEDLASTQUARTERPI : string = "epicPointsCompletedLastQuarterPI";
	public static readonly EPICQUARTERSWORKEDON : string = "epicQuartersWorkedOn";

    // Not Audited facts
	public static readonly DEFECTPOTENTIAL : string = "epicDefectPotential";

    epicFactId : string;
	epicId : string;
    projectId : string;

    public initialize(object : EpicFact) : void
    {
        super.initialize(object);
        this.epicFactId = object.epicFactId;
        this.epicId = object.epicId;
        this.projectId = object.projectId;
    }
}