<p-card class="sprint-commitment" (click)="onclick($event)">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Commitment</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="sprintCommitmentFact && pointsCompletedFact">
                    <span tooltipPosition="left" pTooltip="% Completed">{{ percentComplete() | floor }}%</span>
                </span>

                <span class="sprint-overloaded" *ngIf="sprintOverloadedFact">
                    <a *ngIf="sprintOverloadedFact.getFactBoolean()" [routerLink]="['/dashboard/content-detail/key', OVERLOADEDSPRINT]" [queryParams]="{sprintId: sprint.sprintId}">
                        <i tooltipPosition = "left" pTooltip="Sprint Overloaded" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <span class="content">
        <div *ngIf="sprintCommitmentFact && pointsCompletedFact">
            <p-chart type="doughnut" [data]="data" [options]="options" [plugins]="plugins" [height]="'150px'"></p-chart>
        </div>    
    </span>
</p-card>