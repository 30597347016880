<div class="grid card card-w-title">
    <h3 class="col-12">Project Configuration List</h3>
    <h4 class="col-12">
        This page is for specifying additional configuration options for 
        projects being managed by lucidLIFT.  In particular, from this page
        you can customize the "definition of done" for backlog items on a project
        by project basis.  This configuration is optional.  For new projects, be sure
        to activate polling by configuring the job schedule 
        <a [routerLink]="['/dashboard/admin/org-job-schedule']" [routerLinkActive]="['active-menulink']">here</a>.
    </h4>
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <div class="col-12">

        <p-table class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="projectId" [rowHover]="true" 
            [value]="projectList" responsiveLayout="scroll" selectionMode="single" [(selection)]="selectedProject" (onRowSelect)="projectSelect($event)" 
            (onRowUnselect)="projectUnselect($event)">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- description -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Description
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-project>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ project.description }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="1">
                        No records found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12" label="Configure" (click)="onUpdate()"></button>
        </div>

        <div class="col-12 sm:col-12 md:col-6">
            <button pButton class="col-12 secondary" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

</div>
