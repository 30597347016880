<div class="grid col-12 card card-w-title">
    <h3 class="col-12">Search Results for "{{searchText}}"</h3>

    <div class="col-12" *ngIf="!backlogItemList">
        Searching....
    </div>

    <div class="col-12" *ngIf="backlogItemList && backlogItemList.length <= 0">
    No results found
    </div>

    <p-dataView *ngIf="backlogItemList && backlogItemList.length > 0" class="search-result-scroller col-12" layout="list" [value]="backlogItemList" [paginator]="true" 
        [rows]="rows" [lazy]="true" (onLazyLoad)="loadData($event)" alwaysShowPaginator="false">
        <ng-template let-backlogItem pTemplate="listItem">
            <search-result-detail class="col-12" [backlogItem]="backlogItem" [searchText]="searchText"></search-result-detail>
        </ng-template>
    </p-dataView>
</div>
