import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router } from '@angular/router';

import { Epic } from './epic';
import { ContentDetail } from '../content/content-detail';

import { OrgSourceSystem } from '../org-source-system/org-source-system';
import { OrgSourceSystemService } from '../org-source-system/org-source-system.service';
import { Project } from '../project/project';

@Component({
  selector: 'epic-dashboard-list',
  templateUrl: './epic-dashboard-list.component.html',
  styleUrls: ['./epic-dashboard-list.component.scss' ],
  providers: [ OrgSourceSystemService ]
})

export class EpicDashboardListComponent extends BaseComponent implements OnInit
{ 
    @Input()
    rows : number = 0;

    @Input()
    total : number = undefined;

    @Input()
    paginator : boolean = false;

    @Input()
    lazy : boolean = false;

    selectedEpic : Epic;

    @Input()
    project : Project;

    @Input()
    epicList : Epic[];

    @Input()
    sortOrder : number = -1;

    @Input()
    sortField : string = "percentComplete";

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    @Output()
    onSortClicked = new EventEmitter<any>();

    orgSourceSystem : OrgSourceSystem = undefined;

    constructor(protected router : Router,
                protected orgSourceSystemService : OrgSourceSystemService) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
        if (this.total == undefined && this.epicList != undefined)
            this.total = this.epicList.length;

        if (this.project != undefined)
        {
            this.orgSourceSystemService.retrieve(this.project.orgSourceSystemId)
                .subscribe(result => this.processOrgSourceSystem(result), 
                           error => this.handleError(error) );
        }
    }

    public loadData(event : any) 
    {
        //event.first = First row offset
        //event.rows = Number of rows per page
        //add more records to the cars array
        this.onLazyLoad.emit(event);
    }

    public processOrgSourceSystem(result : OrgSourceSystem)
    {
        this.orgSourceSystem = result;
    }   

    public customSort(event) : void
    {
        this.onSortClicked.emit(event);
    }

    public totalScope() : number
    {
        let result : number = 0;

        for (let epic of this.epicList)
            result = result + epic.scope;

        return result;
    }

    public totalPointsCompleted() : number
    {
        let result : number = 0;

        for (let epic of this.epicList)
            result = result + epic.pointsCompleted;

        return result;
    }

    public totalPercentComplete() : number
    {
        let result = 0;

        if (this.totalScope() != 0)
            result = this.totalPointsCompleted() / this.totalScope() * 100;

        return Math.round(result);
    }

    public get STORYPOINTS() : string
    {
        return ContentDetail.STORYPOINTS;
    }

    public epicRowStyleClass(epic : Epic, index : number) : string
    {
        let result : string = null;
        
        if (epic.completed)
        {
            if (index % 2 == 0)
                result = 'row-style-class-green-even';
            else
                result = 'row-style-class-green-odd';
        }

        return result;
    }

    public buildEpicSourceSystemLink(epic : Epic) : string
    {
        let result = undefined;

        if (this.orgSourceSystem != undefined)
            result = this.orgSourceSystem.buildEpicSourceSystemLink(epic);

        return result;
    }


}
