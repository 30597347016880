import { BaseValueObject } from '../base/value-object/base-value-object';
import { Project } from '../project/project';

import { LoggedInUser  } from '../authentication/logged-in-user';
import { UserAccount } from '../user-account/user-account';

import { LLUtils } from '../utilities/ll-utils';

import * as random from 'random-utility';
import * as moment from 'moment';

export class UserDashboardPreference extends BaseValueObject {

    public userDashboardPreferenceId : string = "";
    public userId : string = "";
    public preference : string = "";

    public static readonly FILTEREDPROJECTLIST : string = "filteredProjectList";
    public static readonly FILTEREDQUARTERLIST : string = "filteredQuarterList";
    public static readonly MATURITYGOAL : string = "maturityGoal";

    public static readonly INCLUDECURRENTPROGRAMINCREMENT : string = "includeCurrentProgramIncrement";
    public static readonly INCLUDELASTPROGRAMINCREMENT : string = "includeLastProgramIncrement";

    constructor()
    {
        super();

        this.userDashboardPreferenceId = random.uuid().toUpperCase();
        this.dateCreated = moment().toDate();
        this.dateModified = moment().toDate();

        // Set defaults
        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            let userAccount : UserAccount = LoggedInUser.getLoggedInUser();
            this.userId = userAccount.userId;
            this.createdByUserId = userAccount.userId;
            this.modifiedByUserId = userAccount.userId;
        }
    }

    public initialize(userDashboardPreference : UserDashboardPreference) : void
    {
        if (userDashboardPreference == null)
            return;
        
        super.initialize(userDashboardPreference);

        this.userDashboardPreferenceId = userDashboardPreference.userDashboardPreferenceId;
        this.userId = userDashboardPreference.userId;
        this.preference = userDashboardPreference.preference;
    }

    public set filteredProjectList(projectList : Project[])
    {
        let preference : Object = {};

        if (LLUtils.isStringEmpty(this.preference) == true)
            this.preference = JSON.stringify(preference);

        preference = JSON.parse(this.preference);
        preference[UserDashboardPreference.FILTEREDPROJECTLIST] = projectList;

        this.preference = JSON.stringify(preference);

        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            let userAccount : UserAccount = LoggedInUser.getLoggedInUser();
            this.userId = userAccount.userId;
        }
    }

    public get filteredProjectList() : Project[]
    {
        let result : Project[] = [];

        if (LLUtils.isStringEmpty(this.preference) == false)
        {
            let preference = JSON.parse(this.preference);

            if (preference[UserDashboardPreference.FILTEREDPROJECTLIST] != undefined)
                result = preference[UserDashboardPreference.FILTEREDPROJECTLIST];
        }

        return result;
    }

    public set includeCurrentProgramIncrement(value : boolean)
    {
        let preference : Object = {};

        if (LLUtils.isStringEmpty(this.preference) == true)
            this.preference = JSON.stringify(preference);

        preference = JSON.parse(this.preference);
        preference[UserDashboardPreference.INCLUDECURRENTPROGRAMINCREMENT] = value;

        this.preference = JSON.stringify(preference);

        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            let userAccount : UserAccount = LoggedInUser.getLoggedInUser();
            this.userId = userAccount.userId;
        }
    }

    public get includeCurrentProgramIncrement() : boolean
    {
        let result : boolean = true;

        if (LLUtils.isStringEmpty(this.preference) == false)
        {
            let preference = JSON.parse(this.preference);

            if (preference[UserDashboardPreference.INCLUDECURRENTPROGRAMINCREMENT] != undefined)
                result = preference[UserDashboardPreference.INCLUDECURRENTPROGRAMINCREMENT];
        }

        return result;
    }

    public set includeLastProgramIncrement(value : boolean)
    {
        let preference : Object = {};

        if (LLUtils.isStringEmpty(this.preference) == true)
            this.preference = JSON.stringify(preference);

        preference = JSON.parse(this.preference);
        preference[UserDashboardPreference.INCLUDELASTPROGRAMINCREMENT] = value;

        this.preference = JSON.stringify(preference);

        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            let userAccount : UserAccount = LoggedInUser.getLoggedInUser();
            this.userId = userAccount.userId;
        }
    }

    public get includeLastProgramIncrement() : boolean
    {
        let result : boolean = true;

        if (LLUtils.isStringEmpty(this.preference) == false)
        {
            let preference = JSON.parse(this.preference);

            if (preference[UserDashboardPreference.INCLUDELASTPROGRAMINCREMENT] != undefined)
                result = preference[UserDashboardPreference.INCLUDELASTPROGRAMINCREMENT];
        }

        return result;
    }

    public set filteredQuarterList(quarterList : string[])
    {
        let preference : Object = {};

        if (LLUtils.isStringEmpty(this.preference) == true)
            this.preference = JSON.stringify(preference);

        preference = JSON.parse(this.preference);
        preference[UserDashboardPreference.FILTEREDQUARTERLIST] = quarterList;

        this.preference = JSON.stringify(preference);

        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            let userAccount : UserAccount = LoggedInUser.getLoggedInUser();
            this.userId = userAccount.userId;
        }
    }

    public get filteredQuarterList() : string[]
    {
        let result : string[] = [];

        if (LLUtils.isStringEmpty(this.preference) == false)
        {
            let preference = JSON.parse(this.preference);

            if (preference[UserDashboardPreference.FILTEREDQUARTERLIST] != undefined)
                result = preference[UserDashboardPreference.FILTEREDQUARTERLIST];
        }

        return result;
    }

    public set maturityGoal(goal : number)
    {
        let preference : Object = {};

        if (LLUtils.isStringEmpty(this.preference) == true)
            this.preference = JSON.stringify(preference);

        preference = JSON.parse(this.preference);
        preference[UserDashboardPreference.MATURITYGOAL] = goal;

        this.preference = JSON.stringify(preference);

        if (LoggedInUser.getLoggedInUser() != undefined)
        {
            let userAccount : UserAccount = LoggedInUser.getLoggedInUser();
            this.userId = userAccount.userId;
        }
    }

    public get maturityGoal() : number
    {
        let result : number = 3;

        if (LLUtils.isStringEmpty(this.preference) == false)
        {
            let preference = JSON.parse(this.preference);

            if (preference[UserDashboardPreference.MATURITYGOAL] != undefined)
                result = preference[UserDashboardPreference.MATURITYGOAL];
        }

        return result;
    }

}