<p-card class="mini-defect-count-overview" (click)="onclick()">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Open Defects&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="openDefectTrend" class="overview-badge">
                    <i *ngIf="openDefectTrend == 'up'" class="material-icons" tooltipPosition="left" pTooltip="Trending Up">trending_up</i>
                    <i *ngIf="openDefectTrend == 'down'" class="material-icons" tooltipPosition="left" pTooltip="Trending Down">trending_down</i>
                    <i *ngIf="openDefectTrend == 'neutral'" class="material-icons" tooltipPosition="left" pTooltip="Trending Flat">trending_flat</i>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="openDefectCountFact" class="overview-detail">{{openDefectCountFact.getFactNumber()}} total</span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="timeSeries">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'" (click)="onDefectClick()"></p-chart>
        </div>    
    </span>
</p-card>

<!--
<div class="card overview mini-defect-count-overview" (click)="onclick">
    <div class="overview-content clearfix">
        <span class="overview-title">Open Defects&nbsp;<i class="material-icons no-phone" (click)="onZoomClicked()">zoom_in</i></span>
        <span *ngIf="openDefectTrend" class="overview-badge">
            <i *ngIf="openDefectTrend == 'up'" class="material-icons" tooltipPosition="left" pTooltip="Trending Up">trending_up</i>
            <i *ngIf="openDefectTrend == 'down'" class="material-icons" tooltipPosition="left" pTooltip="Trending Down">trending_down</i>
            <i *ngIf="openDefectTrend == 'neutral'" class="material-icons" tooltipPosition="left" pTooltip="Trending Flat">trending_flat</i>
        </span>
        <span *ngIf="openDefectCountFact" class="overview-detail">{{openDefectCountFact.getFactNumber()}} total</span>
    </div>
    <div class="overview-footer">
        <div *ngIf="defectCountList">
            <p-chart type="line" [data]="data" [options]="options" [height]="'100px'" (click)="onDefectClick()"></p-chart>
        </div>    
    </div>
</div>
-->
<zoom-graph #zoomGraph type="line" [sourceData]="data" [sourceOptions]="options"></zoom-graph>