import { BaseValueObject } from '../base/value-object/base-value-object';
import { AlertText } from '../admin/alert-text/alert-text';

export class UserAlertPreference extends BaseValueObject {

    public userAlertPreferenceId : string;
    public userId : string;
    public alertText : AlertText;
    public enabled : boolean;

    public initialize(userAlertPreference : UserAlertPreference) : void
    {
        if (userAlertPreference == null)
            return;
        
        super.initialize(userAlertPreference);

        this.userAlertPreferenceId = userAlertPreference.userAlertPreferenceId;
        this.userId = userAlertPreference.userId;
        this.enabled = userAlertPreference.enabled;

        this.alertText = this.initializeObject(AlertText, userAlertPreference.alertText);
    }
}