<div class="grid card card-w-title">
    <h3 class="col-12">Job Schedule Administration</h3>
    <h4 class="col-12">
        This page is for configuring how often lucidLIFT will
        poll your source system.  Also, you can deactivate polling
        by toggling the active switch for each project.  New projects 
        are set to inactive by default - you must explicitly
        activate polling.  Once a project has be activated, the source
        system will be polled according to the interval specified.
        <br><br>
        Do not forget to configure which projects users have access to!  You 
        can configure project access by clicking <a [routerLink]="['/dashboard/admin/user-account-list']" [routerLinkActive]="['active-menulink']">here</a>
    </h4>
        
    <p-messages class="col-12" [value]="errorMessages"></p-messages>

    <p-table *ngIf="orgJobScheduleList && projectList" class="col-12" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
        dataKey="orgJobScheduleId" [rowHover]="true" [value]="orgJobScheduleList" responsiveLayout="scroll">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col><!-- description -->
                    <col style="width:300px"><!-- source -->
                    <col style="width:250px"><!-- interval -->
                    <col style="width:100px"><!-- active -->
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        Project Description
                    </th>
                    <th>
                        Source
                    </th>
                    <th>
                        Refresh Interval
                    </th>
                    <th>
                        Active
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-orgJobSchedule>
                <tr [pSelectableRow]="rowData">
                    <td>
                        {{ getProjectDescription(orgJobSchedule) }}
                    </td>
                    <td>
                        {{ orgJobSchedule.orgSourceSystem.sourceSystem.description }}             
                    </td>
                    <td>
                        <p-dropdown [style]="{'width':'200px'}" appendTo="body" [options]="intervalList" [(ngModel)]="orgJobSchedule.interval"></p-dropdown>
                    </td>
                    <td>
                        <p-inputSwitch [(ngModel)]="orgJobSchedule.active"></p-inputSwitch>                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="4">
                        No records found
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <div class="grid col-12">
        <div class="col-12 sm:col-12 md:col-6">
            <button class="col-12" #updateButtonTag pButton type="button" label="Update" (click)="onUpdate()"></button>
        </div>
        <div class="col-12 sm:col-12 md:col-6">
            <button class="col-12 secondary" #cancelButtonTag pButton type="button" label="Cancel" (click)="onCancel()"></button>
        </div>
    </div>

     <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="500"></p-confirmDialog>
</div>
