import { BaseFactObject } from '../base/value-object/base-fact-object';

export class SprintFact extends BaseFactObject
{
	// SPRINT FACT CONSTANTS
	public static readonly BEGINNINGSPRINT : string = "beginningSprint";
	public static readonly ENDINGSPRINT : string = "endingSprint";
	public static readonly SPRINTCOMMITMENT : string = "sprintCommitment";
	public static readonly POINTSCOMPLETED : string	= "pointsCompleted";
	public static readonly POINTSREMAINING : string = "pointsRemaining";
	public static readonly AVERAGEVELOCITY : string = "sprintAverageVelocity"; 
	public static readonly RELEASESCOPE : string = "sprintReleaseScope";
	public static readonly EPICSCOPE : string =	"sprintEpicScope";
	public static readonly SPRINTSCOPE : string	= "sprintScope";
	public static readonly SPRINTVELOCITY : string = "sprintVelocity";
	public static readonly ZEROPOINTSPRINT : string	= "zeroPointSprint";
	public static readonly SPRINTSTARTDAY : string = "sprintStartDay";
	public static readonly SPRINTDURATION : string = "sprintDuration";
	public static readonly SPRINTSTARTDAYCHANGED : string = "sprintStartDayChanged";
	public static readonly SPRINTDURATIONCHANGED : string = "sprintDurationChanged";
	public static readonly SPRINTOVERLOADED	: string = "sprintOverloaded";
	public static readonly SPRINTCOMMITMENTMET : string	= "sprintCommitmentMet";
	public static readonly VELOCITYABOVEBELOWAVERAGE : string = "velocityAboveBelowAverage"; 
	public static readonly COMMITMENTATTAINMENT : string = "commitmentAttainment";
	public static readonly POINTSCARRIEDOVER : string = "pointsCarriedOver";
	public static readonly POINTSINJECTED : string	= "pointsInjected";
	public static readonly SPRINTENDED : string	= "sprintEnded";
	public static readonly SPRINTSTARTED : string = "sprintStarted";
	public static readonly VELOCITYTREND : string = "sprintVelocityTrend";
	public static readonly OPENDEFECTCOUNT : string	= "sprintOpenDefectCount";
	public static readonly CLOSEDDEFECTCOUNT : string = "sprintClosedDefectCount";
	public static readonly TOTALDEFECTCOUNT : string = "sprintTotalDefectCount";
	public static readonly OPENBACKLOGITEMCLOSEDSPRINT	= "sprintOpenBacklogItemClosedSprint";
	public static readonly BACKLOGITEMS : string = "sprintBacklogItems";
	public static readonly POINTSONSTORIESCOUNT : string = "sprintPointsOnStoriesCount";
	public static readonly STORYAGILEFORMCOUNT : string = "sprintStoryAgileFormCount";
	public static readonly SPRINTSTORYCOUNT : string = "sprintStoryCount";
	public static readonly SPRINTDEFECTCOUNT : string = "sprintDefectCount";
	public static readonly SPRINTCHORECOUNT : string = "sprintChoreCount";
	public static readonly SPRINTSPIKECOUNT : string = "sprintSpikeCount";
	public static readonly SPRINTDEPENDENCYCOUNT : string = "sprintDependencyCount";
	public static readonly STORYACCEPTANCECRITERIACOUNT : string = "sprintStoryAcceptanceCriteriaCount";
	public static readonly POINTSONDEFECTSCOUNT : string = "sprintPointsOnDefectCount";
	public static readonly POINTSONCHORESCOUNT : string = "sprintPointsOnChoreCount";
	public static readonly STORIESADDEDCOUNT : string = "sprintStoriesAddedCount";
	public static readonly STORIESPOINTSCHANGEDCOUNT : string = "sprintPointsChangedCount";
	public static readonly COSTPERPOINT	: string = "sprintCostPerPoint";
	public static readonly AVERAGECOSTPERPOINT : string = "sprintAverageCostPerPoint";
	public static readonly DEFECTDENSITY : string = "sprintDefectDensity";
	public static readonly DEFECTREMOVALRATE : string = "sprintDefectRemovalRate";
	public static readonly AVERAGEDEFECTMTTR : string = "sprintAverageDefectMTTR";
	public static readonly CARRIEDOVERMORETHANAVGVELOCITY : string = "sprintCarriedOverMoreThanAvgVelocity";
	public static readonly VELOCITYCHANGEDAFTERSPRINTENDED : string = "sprintVelocityChangedAfterSprintEnded";
	public static readonly SCRUMMATURITY : string= "sprintScrumMaturity";
	public static readonly TOPSCRUMATURITY : string = "sprintTopScrumMaturity";
	public static readonly TOPSCRUMMATURITYAVERAGE : string = "sprintTopScrumMaturityAverage";
	public static readonly TOPSCRUMMATURITYTREND : string = "sprintTopScrumMaturityAverageTrend";
	public static readonly SCRUMMATURITYAVERAGECLOSEDSPRINTS = "sprintScrumMaturityAverageClosedSprints";
	public static readonly SCRUMMATURITYTRENDCLOSEDSPRINTS	= "sprintScrumMaturityAverageTrendClosedSprints";
	public static readonly PERCENTQUALITYSTORIES : string = "sprintPercentQualityStories";
	public static readonly AVERAGEPOINTSPERSTORY : string = "sprintAveragePointsPerStory";
	public static readonly AVERAGEPOINTSPERSTORYAVERAGE : string = "sprintAveragePointsPerStoryAverage";	// three sprint average of the average.
	public static readonly AVERAGEPOINTSPERSTORYTREND : string = "sprintAveragePointsPerStoryTrend";
	public static readonly SPRINTDARKSPRINT : string = "sprintDarkSprint";

    sprintFactId : string;
	sprintId : string;
	projectId : string;

    public initialize(object : SprintFact) : void
    {
        super.initialize(object);
        this.sprintFactId = object.sprintFactId;
		this.sprintId = object.sprintId;
		this.projectId = object.projectId;
    }
}