<p-card class="mini-backlog-depth">
    <ng-template pTemplate="header">
        <div class="col-12 flex grid align-content-center">
            <span class="col-8 flex justify-content-start">
                <span class="title-text">Backlog Depth</span>
            </span>
            <span class="col-4 flex justify-content-end">
                <span *ngIf="backlogDepth">
                    <a *ngIf="backlogDepth.getFactNumber() < 2" [routerLink]="['/dashboard/content-detail/key', BACKLOGDEPTH]">
                        <i pTooltip="Backlog Depth Critical" tooltipPosition="left" class="warning material-icons">warning</i>
                    </a>
                </span>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="title">
        <span class="flex justify-content-center">
            <span *ngIf="backlogDepth">
                {{ backlogDepth.getFactNumber() | floor | plural:'sprint' }}
            </span>
            <span *ngIf="!backlogDepth">
                N/A
            </span>
        </span>
    </ng-template>

    <span class="content">
        <div *ngIf="sizedStories && unsizedStories" class="chart-style">
            <p-chart type="bar" [data]="data" [options]="options" [height]="'100px'"></p-chart>
        </div>    
    </span>
</p-card>
