import { BaseValueObject } from '../../base/value-object/base-value-object';

export class AlertText extends BaseValueObject
{
    eventClass : string;
	eventName : string;
	eventType : number;
	alertTitle : string;
	alertSummary : string;
	alertDetail : string;
	severity : number;
	contentId : string;

	// Event type
	public static readonly USERSTORY : number = 1;
	public static readonly SPRINT : number = 2;
	public static readonly DEFECT : number = 3;
	public static readonly CHORE : number = 4;
	public static readonly RELEASE : number = 5;
	public static readonly PROJECT : number = 6;
	public static readonly SPIKE : number = 7;
	
	// Severity
	public static readonly CRITICAL : number = 1;
	public static readonly WARNING : number = 2;
	public static readonly INFORMATIONAL : number = 3;

	public initialize(alertText : AlertText) : void
	{
		super.initialize(alertText);

        this.eventClass = alertText.eventClass;
        this.eventName = alertText.eventName;
        this.eventType = alertText.eventType;
        this.alertTitle = alertText.alertTitle;
        this.alertSummary = alertText.alertSummary;
        this.alertDetail = alertText.alertDetail;
		this.severity = alertText.severity;
		this.contentId = alertText.contentId;
	}
}