<div class="exception-body accessdenied-page">
    <div class="exception-type">
        <img src="../../public/images/401.svg">
    </div>
    
    <div class="card exception-panel">
        <i class="material-icons">&#xE32A;</i>
        <h1>Access Denied</h1>
        <div class="exception-detail">You are not authorized to access this resource.</div>
        <div>
            <a href="/" class="p-button secondary button-width">
                <span class="p-button-label">Homepage</span>
            </a>
        </div>
        <div>
            <a href="/#/logout" class="p-button secondary button-width">
                <span class="p-button-label">Logout</span>
            </a>
        </div>
    </div>
</div>
