import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { TimeSeriesService } from '../../time-series/time-series.service';
import { TimeSeries } from '../../time-series/time-series';
import { Epic } from '../../epic/epic';
import { EpicFact } from '../../epic-fact/epic-fact';
import { EpicFactService } from '../../epic-fact/epic-fact.service';

import { BaseUIComponent } from '../../base/component/base-ui-component.component';

import * as moment from 'moment';

@Component({
  selector: 'epic-point-burnup',
  templateUrl: './epic-point-burnup.component.html',
  styleUrls: ['./epic-point-burnup.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, EpicFactService ]
})

export class EpicPointBurnupComponent extends BaseUIComponent implements OnInit 
{ 
    @Input()
    epic : Epic;

    @Input()
    displayPoints : boolean = false;

    timeSeries : TimeSeries;

    epicScope : EpicFact;
    epicPointsCompleted : EpicFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private epicFactService : EpicFactService) 
    { 
        super(router);

        this.options = {
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Epic Burnup'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    public ngOnInit() : void
    {
        this.timeSeriesService.retrieve(this.epic.project, this.epic.epicId, TimeSeries.EPICBURNUP, 10)
            .subscribe(result => this.processEpicBurnup(result),
                       error => this.handleError(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICSCOPE)
            .subscribe(result => this.processEpicScope(result),
                       error => this.handleError404Okay(error) );

        this.epicFactService.retrieve(this.epic, EpicFact.EPICPOINTSCOMPLETED)
            .subscribe(result => this.processEpicPointsCompleted(result),
                       error => this.handleError404Okay(error) );
    }

    public processEpicScope (result : EpicFact) : void
    {
        this.epicScope = result;
    }

    public processEpicPointsCompleted (result : EpicFact) : void
    {
        this.epicPointsCompleted = result;
    }

    public processEpicBurnup(result: TimeSeries) : void
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Points Completed',
//                    backgroundColor: '#FF9B00',
                    backgroundColor: this.GREENTRANSPARENT,
                    borderColor: this.GREEN,
                    hoverBackgroundColor: this.GREEN,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintPointsComplete"); } )
                },
                {
                    type:'bar',
                    label: 'Total Completed',
//                    backgroundColor: '#42A5F5',
                    backgroundColor: this.ORANGETRANSPARENT,
                    borderColor: this.ORANGE,
                    hoverBackgroundColor: this.ORANGE,
                    borderWidth: 2,
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("totalPointsComplete"); } )
                },
                {
                    type:'line',
                    label: 'Epic Scope',
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintScope"); } )
                }
            ]
        }
    }

    public percentComplete() : number
    {
        let result : number = 0.00;
        
        if (this.epicScope && this.epicPointsCompleted)
           if (this.epicScope.getFactNumber() != 0)
                result = (this.epicPointsCompleted.getFactNumber() / this.epicScope.getFactNumber() ) * 100;

        return result;
    }

    public pointsRemaining() : number
    {
        let result : number = 0.00;
        
        if (this.epicScope && this.epicPointsCompleted)
        {
            result = this.epicScope.getFactNumber() - this.epicPointsCompleted.getFactNumber();
            if (result < 0) result = 0;
        }

        return result;
    }
}
