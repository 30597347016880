import { Injectable }    from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

@Injectable()
export class ForgotPasswordService
{
    forgotPasswordUrl : string;
    loggedIn = false;

    constructor(private http: HttpClient, private config: Config) 
    { 
      this.forgotPasswordUrl = config.baseUrl + "/rest/forgot-password";
    }

    public forgotPassword(emailAddress: string): Observable<any> 
    {
        let requestUrl = this.forgotPasswordUrl;
        let headers = this.createHeaders();

        return this.http.put(requestUrl, emailAddress, { headers: headers } );
    }
    
    private createHeaders() : HttpHeaders
    {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'text/plain');
        return headers;
    }

}