import { Component, OnInit, AfterViewInit, forwardRef, ViewChild, OnChanges } from '@angular/core';
import { Input, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';

import { AutoComplete } from 'primeng/autocomplete';
import { LLUtils } from '../../utilities/ll-utils';

@Component({
  selector: 'll-autoComplete',
  templateUrl: './ll-auto-complete.component.html',
  styleUrls: ['./ll-auto-complete.component.scss' ],
  providers: [    
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LLAutoCompleteComponent),
        multi: true,
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LLAutoCompleteComponent),
        multi: true,
      }     
    ]
})

export class LLAutoCompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges
{
  @ViewChild("llAutoCompleteTag")
  autoCompleteComponent : AutoComplete;
  
  @Input()
  label: string = undefined;
  
  @Input()
  width : string = undefined;
  
  text : any = undefined;
  value : any = undefined;
  valueObject : any = undefined;

  @Input()
  sourceList : any[];

  filteredList : any[] = [];

  @Input()
  displayField : string;

  @Input()
  valueField : string;

  @Input()
  disabled : boolean = false;

  @Input()
  readonly : boolean = false;

  @Input()
  tabindex : string = "0";

  @Input()
  autofocus : boolean = false;

  @Input()
  allowEmpty : boolean = false;

  // the method set in registerOnChange, it is just 
  // a placeholder for a method that takes one parameter, 
  // we use it to emit changes back to the form
  private propagateChange = (_: any) => { };

  private propagateTouched = (_: any) => { };

  constructor(public el: ElementRef) 
  {
  }

  public ngOnInit()
  {
    this.loadFilteredList();
  }

  public ngAfterViewInit()
  {
    // Remove the drop down button from the tab order.
    if (this.readonly == false)
      this.autoCompleteComponent.dropdownButton.nativeElement.tabIndex = "-1";
  }

  public ngOnChanges() : void
  {
    this.valueObject = this.findObjectByValue(this.value);

    if (this.valueObject != undefined)
      this.text = this.valueObject[this.displayField];
    else
      this.text = undefined;

    this.loadFilteredList();
  }

  public onKeyUp(event : KeyboardEvent) : void
  {
    if (this.autoCompleteComponent != undefined && event.code == "ArrowDown")
    {
      if (this.autoCompleteComponent.overlayVisible == false)
        this.autoCompleteComponent.search(event, "");
    }
  }

  public onDropdownClick(event : any) : void
  {
  }

  // this is the initial value set to the component
  public writeValue(obj: any) 
  {
    this.value = obj;
    this.valueObject = this.findObjectByValue(this.value);

    if (this.valueObject != undefined)
      this.text = this.valueObject[this.displayField];
    else
      this.text = undefined;
  }
   
  // validates the form, returns null when valid else the validation object
  public validate(c: FormControl) : any 
  {
    return null;
  }

  // registers 'fn' that will be fired when changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) 
  {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn : any) 
  { 
    this.propagateTouched = fn;
  } 

  @HostListener('focusin', ['$event'])
  public llAutoCompleteFocus(event : any) : void
  {
    this.onFocus();
  }

  public onFocus() : void
  {
    if (this.value != undefined)
      this.autoCompleteComponent.inputEL.nativeElement.select();
  }

  @HostListener('focusout', ['$event'])
  public llAutoCompleteBlur (event: any): void 
  {
    setTimeout(() => {
      let currentValue : any = this.value;

      if (this.autoCompleteComponent.overlayVisible == true)
        return;

      this.filteredList = this.applyFilter(this.text);

      // First, see if we have an exact match.
      let obj : any = this.findObjectByText(this.text)

      if (obj != undefined)
      {
        // found an exact match.
        this.valueObject = obj;
        this.value = this.valueObject[this.valueField];        

        if (currentValue != this.value)
          this.propagateChange(this.value);

        return;
      }

      // Didn't find an exact match, so, select the first out of the
      // remaining items in the filtered list...if allowEmpty is false.
      if (this.filteredList.length == 1)
      {
        this.text = this.filteredList[0];
        this.valueObject = this.findObjectByText(this.text);

        if (this.valueObject != undefined)
          this.value = this.valueObject[this.valueField];
        else
          this.value = undefined;

        if (currentValue != this.value)
          this.propagateChange(this.value);

        return;
      }

      if (this.filteredList.length > 1 && this.allowEmpty == false)
      {
        this.text = this.filteredList[0];
        this.valueObject = this.findObjectByText(this.text);

        if (this.valueObject != undefined)
          this.value = this.valueObject[this.valueField];
        else
          this.value = undefined;

        if (currentValue != this.value)
          this.propagateChange(this.value);
      }
      else
      {
        this.onClear();
      }


    }, 200);

  }

  public onBlur(event)
  {
    // Make sure we have a good value otherwise null.
    /*
    if (this.text != undefined)
    {
      this.valueObject = this.findObjectByText(this.text);

      if (this.valueObject != undefined)
        this.value = this.valueObject.getValue(this.valueField);
      else
        this.value = undefined;

      this.propagateChange(this.value);
    }
    else
    {
      this.value = undefined;
      this.valueObject = undefined;
      this.propagateChange(this.value);
    }
    */

  } 

  public onSelect(pSelectedValue : any)
  {
    this.text = pSelectedValue;
    this.valueObject = this.findObjectByText(this.text);

    if (this.valueObject != undefined)
      this.value = this.valueObject[this.valueField];
    else
      this.value = undefined;

    this.propagateChange(this.value);
  }

  public onClear()
  {
    this.text = undefined;
    this.value = undefined;
    this.valueObject = undefined;

    this.propagateChange(this.value);
  }

  public get autoCompleteInputStyle() : any
  {
    let result = { color:'inherit'};

    if (this.width != undefined)
      result['width'] = `${this.width}`;

    return result;
  }

  public filterList(event : any)
  {
    this.filteredList = this.applyFilter(event.query);
  }

  public loadFilteredList()
  {
    if (this.sourceList != undefined)
    {
      this.filteredList = [];

      for (let element of this.sourceList)
        this.filteredList.push(element);
    }
    else
    {
      this.sourceList = undefined;
      this.filteredList = [];
    }
  }

  protected applyFilter(query : string) : any[]
  {
    let result : any[] = [];

    if (LLUtils.isStringEmpty(query) == true)
    {
      for (let element of this.sourceList)
        result.push(element[this.displayField] );
    }
    else
    {
      for (let element of this.sourceList)
      { 
        let field : string = element[this.displayField];

        field = field.toLocaleLowerCase();
        let key = query.toLocaleLowerCase();

        if (field.startsWith(key) == true)
          result.push(element[this.displayField] );
      }
    }

    return result;
  }

  protected findObjectByValue(pValue : any) : any
  {
    let result : any = undefined;

    for (let element of this.sourceList)
    {
      if (element[this.valueField] == pValue)
      {
        result = element;
        break;
      }
    }

    return result;
  }

  protected findObjectByText(pText : any) : any
  {
    let result : any = undefined;

    for (let element of this.sourceList)
    {
      if (element[this.displayField] == pText)
      {
        result = element;
        break;
      }
    }

    return result;
  }

  public focus() : void
  {
    setTimeout(() => { this.autoCompleteComponent.focusInput() }, 100);
  }

  public reset() : void
  {
    this.text = undefined;
    this.value = undefined;
  }

}
