import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config/config';
import { BaseHttpService } from '../../base/service/base-http.service';
import { Observable } from 'rxjs';
import { OrgJobSchedule } from './org-job-schedule';

@Injectable()
export class OrgJobScheduleService extends BaseHttpService
{
    orgJobScheduleUrl : string;

    constructor(http: HttpClient, private config: Config ) 
    { 
        super(http);
        this.orgJobScheduleUrl = this.config.baseUrl + '/rest/org-job-schedule';
    }

    public retrieveAll() : Observable<OrgJobSchedule[]>
    {
        let url = this.orgJobScheduleUrl + "/list";
        return super.baseRetrieveList(OrgJobSchedule, url);
    }

    public update(orgJobScheduleList: OrgJobSchedule[]) : Observable<OrgJobSchedule[]>
    {
        let url = this.orgJobScheduleUrl;
        return super.baseUpdateList(OrgJobSchedule, url, orgJobScheduleList);   
    }
}
