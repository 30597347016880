import { BaseValueObject } from '../base/value-object/base-value-object';
import { BaseBacklogItemObject } from '../base/value-object/base-backlog-item-object';
import { Organization } from '../organization/organization';
import { SourceSystem } from '../source-system/source-system';

import { Epic } from '../epic/epic';
import { TFSProjectKey } from '../utilities/tfs-project-key';

export class OrgSourceSystem extends BaseValueObject
{
    orgSourceSystemId : string;
	organization : Organization;
    sourceSystem : SourceSystem;
    sourceSystemApiUrl : string;
    sourceSystemUserId : string;
    sourceSystemPassword : string;
	sourceAuthType : number;
	sourceAuthEndPoint : string;
	deleted : boolean;

	public static readonly BASICAUTH : number = 1;
	public static readonly COOKIEAUTH : number = 2;
	public static readonly OAUTH : number = 3;

    public initialize(object : OrgSourceSystem) : void
    {
        super.initialize(object);

        this.orgSourceSystemId = object.orgSourceSystemId;
        this.sourceSystemApiUrl = object.sourceSystemApiUrl;
        this.sourceSystemUserId = object.sourceSystemUserId;
        this.sourceSystemPassword = object.sourceSystemPassword;
	    this.sourceAuthType = object.sourceAuthType;
	    this.sourceAuthEndPoint = object.sourceAuthEndPoint;
	    this.deleted = object.deleted;

        this.organization = new Organization();
        this.organization.initialize(object.organization);

        this.sourceSystem = new SourceSystem();
        this.sourceSystem.initialize(object.sourceSystem);
    }

    public buildSourceSystemLink(backlogItem : BaseBacklogItemObject) : string
    {
        let result = undefined;

        // Remove trailing slash, if there is one.
        let baseUrl = this.sourceSystemApiUrl.replace(/\/+$/, "");

        if (this.sourceSystem.sourceSystemId == SourceSystem.JIRA_AGILE)
            result = encodeURI(`${baseUrl}/browse/${backlogItem.sourceSystemKey}` );

        if (this.sourceSystem.sourceSystemId == SourceSystem.TFS) {
            let urlFragment = backlogItem.project.description;
            let tfsProjectKey : TFSProjectKey = TFSProjectKey.of(backlogItem.project.sourceSystemKey);

            if (tfsProjectKey.isComposite() )
                urlFragment = tfsProjectKey.getProjectName();

            result = encodeURI(`${baseUrl}/${urlFragment}/_workitems/edit/${backlogItem.sourceSystemKey}` );
        }

        if (this.sourceSystem.sourceSystemId == SourceSystem.AGILECENTRAL)
        {
            if (backlogItem.isDefect() )
                result = encodeURI(`${baseUrl}/#/detail/defect/${backlogItem.altSourceSystemKey}` );
            else
                result = encodeURI(`${baseUrl}/#/detail/userstory/${backlogItem.altSourceSystemKey}` );
        }

        return result;
    }

    public buildEpicSourceSystemLink(epic : Epic) : string
    {
        let result = undefined;

        // Remove trailing slash, if there is one.
        let baseUrl = this.sourceSystemApiUrl.replace(/\/+$/, "");

        if (this.sourceSystem.sourceSystemId == SourceSystem.JIRA_AGILE)
            result = encodeURI(`${baseUrl}/browse/${epic.sourceSystemKey}` );          

        if (this.sourceSystem.sourceSystemId == SourceSystem.TFS)
            result = encodeURI(`${baseUrl}/${epic.project.description}/_workitems/edit/${epic.sourceSystemKey}` );

/*
        if (this.sourceSystem.sourceSystemId == SourceSystem.AGILECENTRAL)
        {
            if (backlogItem.isDefect() )
                result = encodeURI(`${baseUrl}/#/detail/defect/${backlogItem.altSourceSystemKey}` );
            else
                result = encodeURI(`${baseUrl}/#/detail/userstory/${backlogItem.altSourceSystemKey}` );
        }
*/
        return result;
    }

}