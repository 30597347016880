import { BaseFactObject } from '../base/value-object/base-fact-object';

export class ChoreFact extends BaseFactObject
{
	// CHORE FACT CONSTANTS
	public static readonly SPRINTSWORKEDON : string = "choreSprintsWorkedOn";
	public static readonly POINTSONCHORE : string = "pointsOnChore";
	public static readonly BLOCKED : string	= "choreBlocked";
	public static readonly POINTSINRANGE : string = "chorePointsInRange";
	public static readonly OPENCHORECLOSEDSPRINT : string = "openChoreClosedSprint";

    choreFactId : string;
	choreId : string;
    projectId : string;

    public initialize(choreFact : ChoreFact) : void
    {
        super.initialize(choreFact);
        this.choreFactId = choreFact.choreFactId;
        this.choreId = choreFact.choreId;
        this.projectId = choreFact.projectId;
    }
}