import { HomeComponent } from './home.component';
import { LoginComponent } from './authentication/login.component';
import { LogoutComponent } from './authentication/logout.component';
import { ForgotPasswordComponent } from './authentication/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password.component';
import { UnauthorizedComponent } from './authentication/unauthorized.component';
import { ErrorComponent } from './error/error.component';

export const routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'forgot-password', component : ForgotPasswordComponent },
    { path: 'reset-password/:resetTokenId', component : ResetPasswordComponent },
    { path: 'login', component: LoginComponent },
    { path: 'unauthorized', component : UnauthorizedComponent },
    { path: 'error', component : ErrorComponent },
    { path: 'logout', component: LogoutComponent }
];
