import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base/component/base-component.component';
import { Router } from '@angular/router';

import { Release } from './release';
import { ReleaseFact } from '../release-fact/release-fact';

@Component({
  selector: 'release-list',
  templateUrl: './release-list.component.html',
  styleUrls: ['./release-list.component.scss' ],
  providers: [  ]
})

export class ReleaseListComponent extends BaseComponent implements OnInit, OnChanges 
{ 
    @Input()
    rows : number = 0;

    @Input()
    total : number = 0;

    @Input()
    paginator : boolean = false;

    @Input()
    lazy : boolean = false;

    selectedRelease : Release;

    @Input()
    releaseList : Release[];

    @Input()
    header : string;

    @Input()
    sortOrder : number;

    @Input()
    sortField : string = "name";

    @Output()
    onSortClicked = new EventEmitter<any>();

    @Output() 
    onLazyLoad = new EventEmitter<any>();

    constructor(protected router : Router) 
    { 
        super(router);
    }

    public ngOnInit() : void
    {
    }

    public ngOnChanges() : void
    {
    }

    public loadData(event : any) 
    {
        //event.first = First row offset
        //event.rows = Number of rows per page
        //add more records to the cars array
        this.onLazyLoad.emit(event);
    }

    public customSort(event) : void
    {
        this.onSortClicked.emit(event);
    }

}
