import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { ProjectFactService } from '../../project-fact/project-fact.service';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';

import { TimeSeries } from '../../time-series/time-series';
import { TimeSeriesService } from '../../time-series/time-series.service';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { ProjectFact } from '../../project-fact/project-fact';

import { BaseComponent } from '../../base/component/base-component.component';

import * as moment from 'moment';

@Component({
  selector: 'sprint-history',
  templateUrl: './sprint-history.component.html',
  styleUrls: ['./sprint-history.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ TimeSeriesService, SprintFactService, ProjectFactService ]
})

export class SprintHistoryComponent extends BaseComponent implements OnInit { 

    @Input()
    sprint : BaseSprint;
    
    timeSeries : TimeSeries;
    averageVelocityFact : SprintFact;
    velocityTrendFact : SprintFact;

    projectAverageVelocityFact : ProjectFact;

    data: any;
    options: any;

    constructor( protected router : Router,
                 private timeSeriesService: TimeSeriesService,
                 private sprintFactService : SprintFactService,
                 private projectFactService : ProjectFactService) 
    { 
        super(router);

        this.options = {
            scales: { y: { beginAtZero:true }, 
                      x: { display: false } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Sprint History'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                },
            },

            onClick: (event, array) => { this.graphClickEvent(event, array) }
        }
    }

    ngOnInit() : void
    {
        this.timeSeriesService.retrieveBySprint(this.sprint.project.projectId, this.sprint.sprintId, TimeSeries.PROJECTVELOCITY, 10)
            .subscribe(result =>this.processTimeSeries(result),
                       error => this.handleError404Okay(error) );
    
        this.sprintFactService.retrieve(this.sprint, SprintFact.AVERAGEVELOCITY)
            .subscribe(result =>this.processAverageVelocityFact(result),
                       error => this.handleError404Okay(error) );

        this.projectFactService.retrieve(this.sprint.project, ProjectFact.AVERAGEVELOCITY)
            .subscribe(result =>this.processProjectAverageVelocityFact(result),
                       error => this.handleError404Okay(error) );

        this.sprintFactService.retrieve(this.sprint, SprintFact.VELOCITYTREND)
            .subscribe(result =>this.processVelocityTrendFact(result),
                       error => this.handleError404Okay(error) );
    }

    processTimeSeries(result: TimeSeries)
    {
        this.timeSeries = result;       

        this.data = {
            labels : this.timeSeries.timeSeriesList.map( function(a) { return moment(a.timestamp).format("MMM Do YYYY"); } ),
            datasets: [
                {
                    type:'bar',
                    label: 'Commitment',
//                    backgroundColor: '#FF9B00',
                    backgroundColor: 'rgba(255, 155, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 155, 0, 1)',
                    borderColor: 'rgba(255, 155, 0, 1)',
                    borderWidth: 2,

                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("sprintCommitment"); } )
                },
                {
                    type:'bar',
                    label: 'Velocity',
 //                   backgroundColor: '#42A5F5',
                    backgroundColor: 'rgba(66, 165, 245, .40)',
                    hoverBackgroundColor: 'rgba(66, 165, 245, 1)',
                    borderColor: 'rgba(66, 165, 245, 1)',
                    borderWidth: 2,
                    
                    data: this.timeSeries.timeSeriesList.map( function(a) { return a.getFieldNumber("velocity"); } )
                },
                {
                    type:'line',
                    label: 'Three Sprint Average',
                    data: this.timeSeries.timeSeriesList.map( function(a) { return Math.round(a.getFieldNumber("averageVelocity")); } )
                }
            ]
        }
    }

    private processAverageVelocityFact(result : SprintFact) : void
    {
        this.averageVelocityFact = result;
    }

    private processProjectAverageVelocityFact(result : ProjectFact) : void
    {
        this.projectAverageVelocityFact = result;
    }

    private processVelocityTrendFact(result : SprintFact )
    {
        this.velocityTrendFact = result;
    }

    public navigate(timeSeriesListIndex : number) : void
    {
        let sprintId : string = this.timeSeries.timeSeriesList[timeSeriesListIndex].sprintId;
        this.router.navigate(['/dashboard/sprint-detail-reload', sprintId]);            
    }

    public graphClickEvent(event : MouseEvent, array: any[]) : void 
    {
        if (array.length > 0)
        {
            let index : number = array[0]._index;
            this.navigate(index);            
        }
    }

}