import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSprint } from '../../base/value-object/base-sprint';
import { SprintFactService } from '../../sprint-fact/sprint-fact.service';
import { SprintFact } from '../../sprint-fact/sprint-fact';
import { ContentDetail } from '../../content/content-detail';

import { BaseComponent } from '../../base/component/base-component.component';

import * as moment from 'moment';

@Component({
  selector: 'mini-sprint-points-carried-over',
  templateUrl: './mini-sprint-points-carried-over.component.html',
  styleUrls: ['./mini-sprint-points-carried-over.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SprintFactService ]
})

export class MiniSprintPointsCarriedOverComponent extends BaseComponent implements OnInit { 

    @Input()
    sprint : BaseSprint;
    
    sprintCommitment : SprintFact;
    pointsCarriedOver : SprintFact;
    data: any;
    options: any;

    constructor(protected router : Router,
                private sprintFactService: SprintFactService) 
    { 
        super(router);

        this.options = {
            indexAxis : 'y',
            scales: { y: { display: false }, 
                      x: { display: true, beginAtZero: true } 
                    },
            responsive : true,
            maintainAspectRatio : false,
            plugins: {
                title: {
                    display: false,
                    text: 'Points Carried Over'
                }, 
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
    }

    ngOnInit() : void
    {
        this.retrieveData();
    }

    private retrieveData() : void
    {
        this.sprintFactService.retrieve(this.sprint, SprintFact.SPRINTCOMMITMENT)
            .subscribe(result =>this.processSprintCommitment(result),
                       error => this.handleError(error) );        

        this.sprintFactService.retrieve(this.sprint, SprintFact.POINTSCARRIEDOVER)
            .subscribe(result =>this.processPointsCarriedOver(result),
                       error => this.handlePointsCarriedOverError(error) );        
    }

    private processSprintCommitment(result : SprintFact)
    {
        this.sprintCommitment = result;
        this.buildGraph();
    }

    private processPointsCarriedOver(result : SprintFact)
    {
        this.pointsCarriedOver = result;
        this.buildGraph();
    }

    private handlePointsCarriedOverError(error : any)
    {
        if (error.status == 404)
        {
            this.pointsCarriedOver = new SprintFact();
            this.pointsCarriedOver.sprintId = this.sprint.sprintId;
            this.pointsCarriedOver.name = SprintFact.POINTSCARRIEDOVER;
            this.pointsCarriedOver.setFactNumber(0);
        }
    }

    buildGraph()
    {
        if (this.sprintCommitment == undefined || this.pointsCarriedOver == undefined)
            return;

        this.data = {
            labels : [ moment(this.sprint.sprintEndDate).format("MMM Do YYYY") ],
            datasets: [
                {
                    type:'bar',
                    label: 'Points Carried Over',
//                    backgroundColor: '#42A5F5',
                    backgroundColor: 'rgba(66, 165, 245, .40)',
                    hoverBackgroundColor: 'rgba(66, 165, 245, 1)',
                    borderColor: 'rgba(66, 165, 245, 1)',
                    borderWidth: 2,
                    data: [ this.pointsCarriedOver.getFactNumber() ]
                },
                {
                    type:'bar',
                    label: 'Commitment',
//                    backgroundColor: '#FF9B00',
                    backgroundColor: 'rgba(255, 155, 0, .40)',
                    hoverBackgroundColor: 'rgba(255, 155, 0, 1)',
                    borderColor: 'rgba(255, 155, 0, 1)',
                    borderWidth: 2,
                    data: [ this.sprintCommitment.getFactNumber() ]
                }
                              
            ]
        }
    }

    private percentCarriedOver() : number
    {
        let result : number = 0.00;

        if (this.sprintCommitment.getFactNumber() != 0)
        {
            result = (this.pointsCarriedOver.getFactNumber() / this.sprintCommitment.getFactNumber() ) * 100;
        }

        return result;
    }

    public get CARRYOVERKEY() : string
    {
        return ContentDetail.CARRYOVERKEY;
    }

}
