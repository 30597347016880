<p-table *ngIf="releaseList" [responsive]="true" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="releaseId"
    [rowHover]="true" [value]="releaseList" [lazy]="lazy" [rows]="rows" [paginator]="paginator" [totalRecords]="total" responsiveLayout="scroll"
    (onLazyLoad)="loadData($event)" (onSort)="customSort($event)" [sortField]="sortField" [sortOrder]="sortOrder">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col><!-- name -->
                <col style="width:150px"><!-- startDate -->
                <col style="width:150px"><!-- dueDate -->
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [pSortableColumn]="'name'">
                    Name
                </th>
                <th [pSortableColumn]="'startDate'">
                    Start Date
                </th>
                <th [pSortableColumn]="'dueDate'">
                    Due Date
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-release let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td>
                    <div class="release-center">
                        <a [routerLink]="['/dashboard/release-detail', release.releaseId]">{{release.name}}</a>
                    </div>
                </td>
                <td>
                    <div *ngIf="release.startDate">{{release.startDate | dateFormat }}</div>
                </td>
                <td>
                    <div *ngIf="release.dueDate">{{release.dueDate | dateFormat }}</div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="3">
                    No records found
                </td>
            </tr>
        </ng-template>
</p-table>
